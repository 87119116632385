import { convertToDateFormat, separatePhoneNumber } from "../utils/utils";
import { doApiCall } from "./util";

const { REACT_APP_MEMBER_PORTAL_ENDPOINT_URL } = process.env;

export const confirmMemberChanges = () => {
  const payload = {
    confirmed: true
  };
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

/**
 *
 * @param {*} method
 * @param {*} route
 * @returns
 * @description
 */
export const getMemberData = () => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "GET",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member`
  };

  return doApiCall(request).then((response) => {
    response.data.dob = response.data.dob
      ? convertToDateFormat(response.data.dob, "MM/DD/YYYY")
      : "";
    return response;
  });
};

/**
 * @param {object} memberInformation the object for member information
 * @returns {Promise} the promise response from the endpoint
 */
export const patchMemberInformation = (memberInformation) => {
  if (memberInformation.phoneNumber) {
    memberInformation = {
      ...memberInformation,
      ...separatePhoneNumber(memberInformation.phoneNumber)
    };
  }
  if (memberInformation.dob) {
    memberInformation.dob = convertToDateFormat(
      memberInformation.dob,
      "YYYY-MM-DD"
    );
  }

  // Replace empty strings with nulls for now, the back end demands it.
  memberInformation = Object.entries(memberInformation).reduce(
    (acc, [key, value]) => {
      acc[key] = value === "" ? null : value;
      return acc;
    },
    {}
  );

  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "PATCH",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member`,
    data: memberInformation,
    body: JSON.stringify(memberInformation)
  };

  return doApiCall(request);
};

/**
 * @description This help us to save the notifications settings for the user
 * @param {payload} This contains the user notification preferences
 * @returns
 */
export const saveNotificationSettings = (payload) => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/settings`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

export const update = (accountData) => {
  const payload = {
    data: accountData
  };

  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "PATCH",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

export const patchMemberMfaEnforcedStatus = (status) => {
  const payload = { mfaEnforcedOnLogin: status };
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "PATCH",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/mfa`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

export const getMemberMfaEnforcedStatus = () => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "GET",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/mfa`
  };

  return doApiCall(request);
};
