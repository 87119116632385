import React, { useEffect } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import FinalInfoStyles from "./FinalOnboardingInfo.styles";
import GlGenoLogo from "../../../../assets/images/GL-Logo.svg";
import LoadingBarsGif from "../../../../assets/images/LoadingBars.gif";
import { LABELS } from "../../../../constants/labelConstants";
import { PATHS } from "../../../../constants/pathConstants";
import Translate from "../../../../translate/Translate";

const FinalOnboardingInfo = (props) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const classes = FinalInfoStyles();
  const history = useHistory();

  useEffect(() => {
    let timeId = setTimeout(() => {
      history.push(PATHS.TERMS_OF_SERVICE);
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [history]);

  return (
    <Grid container spacing={3} className={classes.contentFinal}>
      <Grid item className={classes.boxGlGenoLogo}>
        <img
          src={GlGenoLogo}
          className={classes.glGenoLogo}
          alt={`${LABELS.LABEL_CANCER_GUARDIAN_LOGO_ALT_TEXT}`}
        />
      </Grid>
      <Grid item className={classes.boxLoadingGif}>
        <img
          src={LoadingBarsGif}
          className={classes.loadingGif}
          alt={`${LABELS.LABEL_CANCER_GUARDIAN_LOGO_ALT_TEXT}`}
        />
      </Grid>
      <Grid item className={classes.boxTitle}>
        <Typography variant={smDown ? "h2" : "h1"} className={classes.title}>
          <Translate text="Let’s get started..." />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FinalOnboardingInfo;
