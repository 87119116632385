import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../constants/v2/colors";

const FileListingStyles = makeStyles((theme) => ({
  itemCard: {
    backgroundColor: COLORS.LIGHT_BACKGROUND,
    borderRadius: "10px",
    padding: "15px 20px 15px 15px",
    minHeight: "80px"
  },
  iconButton: {
    width: "30px",
    height: "30px",
    "& img": { position: "relative", left: "17px" }
  },
  iconButtonHover: {
    position: "relative",
    right: "15px",
    minWidth: "31px",
    height: "31px",
    borderRadius: "50%",
    backgroundColor: "rgba(0,0,0,0)",
    "-webkit-transition": "background-color 100ms linear",
    "-ms-transition": "background-color 100ms linear",
    transition: "background-color 100ms linear",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.15)"
    }
  }
}));

export default FileListingStyles;
