import React from "react";
import { Grid, Typography } from "@mui/material";
import { Link } from "@mui/material";
import helpBubble from "../../../../assets/icons/helpBubble.svg";
import Translate from "../../../../translate/Translate";
import HelpLinkStyles from "./HelpLink.styles";

const HelpLink = () => {
  const classes = HelpLinkStyles();
  return (
    <Grid item xs={12}>
      <Grid item container direction="row" className={classes.helpLink}>
        <img src={helpBubble} alt={"HelpBubble"} />
        <Typography className={classes.helpBubbleText}>
          <Translate text={"Need assistance?"} />
        </Typography>
        <Typography variant="subtitle">
          <Link
            href="https://genomiclife.com/help/"
            target={"_blank"}
            className={classes.helpBubbleLinkText}
            underline="hover">
            <Translate text="Click here" />
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HelpLink;
