import { Box, Grid, Typography } from "@mui/material";
import RegistrationWelcomePageStyles from "./RegistrationWelcomePage.styles";
import RegistrationPageLayout from "./RegistrationPageLayout";
import Translate from "../../../../translate/Translate";
import GLButton from "../../sharedComponents/GLButton";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import RegistrationHelpModal from "./RegistrationHelpModal";
import { useLocation } from "react-router-dom";

const RegistrationWelcomePage = () => {
  const classes = RegistrationWelcomePageStyles();
  const { loginWithRedirect } = useAuth0();
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const possibleAccessCode = searchParams.get("accessCode");

  return (
    <RegistrationPageLayout>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <RegistrationHelpModal
          isOpen={contactModalOpen}
          onClose={() => {
            setContactModalOpen(false);
          }}
        />
        <Grid item xs={11}>
          <Typography variant="h4" className={classes.titleHeader}>
            <Translate text="Welcome to Genomic Life!" />
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h6" className={classes.titleSubHeader}>
            <Translate text="Before you continue, please have the following information ready." />
          </Typography>
        </Grid>
        <Grid item xs={11} sm={8} md={5} lg={4} className={classes.bodyPaper}>
          <Grid item xs={11} className={classes.paperItem}>
            <Grid item className={classes.itemHeader}>
              <Typography variant="h6">
                <Translate text="1. Look for your access code" />
              </Typography>
            </Grid>
            <Grid item className={classes.itemContent}>
              <Typography>
                <Translate text="You should have received an access code. Please check your Genomic Life Welcome Email for it. The welcome email would have been sent to the email address provided during enrollment." />
              </Typography>
              <Typography className={classes.lightSubheader}>
                <Translate text="Don't have your access code? " />
                <Box
                  className={classes.link}
                  onClick={() => {
                    setContactModalOpen(true);
                  }}
                >
                  <Translate text="Give us a call." />
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={11} className={classes.paperItem}>
            <Grid item className={classes.itemHeader}>
              <Typography variant="h6">
                <Translate text="2. Keep your email ready" />
              </Typography>
            </Grid>
            <Grid item className={classes.itemContent}>
              <Typography>
                <Translate text="For your security and privacy, we’ll need to authenticate via email." />
              </Typography>
            </Grid>
            <Grid item className={classes.itemContent}>
              <div className={classes.dividerLineLeft} />
              <div className={classes.dividerLineRight} />
              <Typography className={classes.centered}>
                <Translate text="Please make sure that" />
              </Typography>
            </Grid>
            <Grid item className={classes.itemContent}>
              <Typography>
                <Translate text="You are enrolled in a Genomic Life membership. This was offered to you by your employer at open enrollment." />
              </Typography>
              {possibleAccessCode}
            </Grid>
          </Grid>
        </Grid>
        <GLButton
          color="primary"
          onClick={loginWithRedirect}
          className={classes.continueButton}
        >
          Continue
        </GLButton>
      </Grid>
    </RegistrationPageLayout>
  );
};

export default RegistrationWelcomePage;
