import React, { useEffect, useState } from "react";
import MemberInformationFormStyles from "./MemberInformationForm.styles";
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import Translate from "../../../../translate/Translate";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import ModalComponent from "../../../sharedComponents/ModalComponent";
import { useFormFieldState } from "../FormUtils";
import GLSelectField from "../FormComponents/GLSelectField";
import GLTextField from "../FormComponents/GLTextField";
import GLEmailTextField from "../FormComponents/GLEmailTextField";
import GLPhoneNumberField from "../FormComponents/GLPhoneNumberField";
import { maskUnformattedUsPhoneNumber } from "../../../../utils/utils";
import GLNameTextField from "../FormComponents/GLNameTextField";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import GLButton from "../../sharedComponents/GLButton";

const MemberInformationForm = (props) => {
  const { memberData, onSubmit } = props;
  const classes = MemberInformationFormStyles();
  const windowSize = useWindowSize();
  const usableHeight = windowSize.height - 205;
  const buttonPadding = usableHeight * 0.1;
  const hasInfoBeenLoaded = true;

  //Items for menu
  const PRONOUNS = [
    { id: "empty", value: "", label: "I prefer not to say" },
    { id: "him", value: "he/him/his", label: "He/Him/His" },
    { id: "her", value: "she/her/hers", label: "She/Her/Hers" },
    { id: "they", value: "they/them/their", label: "They/Them/Their" }
  ];
  const SEX = [
    { id: "male", value: "M", label: "Male" },
    { id: "female", value: "F", label: "Female" },
    { id: "intersex", value: "I", label: "Intersex" },
    { id: "unknown", value: "U", label: "Unknown" }
  ];

  const GENDER = [
    { id: "empty", value: "", label: "I prefer not to say" },
    { id: "male", value: "male", label: "Male" },
    { id: "female", value: "female", label: "Female" },
    {
      id: "transgender-maleToFemale",
      value: "transgender - male to female",
      label: "Transgender - male to female"
    },
    {
      id: "transgender-femaleToMale",
      value: "transgender - female to male",
      label: "Transgender - female to male"
    },
    { id: "agender", value: "agender", label: "Agender" },
    {
      id: "genderqueer",
      value: "genderqueer/gender-fluid/gender nonconforming",
      label: "Genderqueer/gender-fluid/gender nonconforming"
    },
    {
      id: "unknown - unborn child",
      value: "unknown - unborn child",
      label: "Unknown - unborn child"
    },
    { id: "other", value: "other", label: "Other" }
  ];
  const validSexes = ["M", "F", "U", "I"];
  const validGenders = [
    "",
    "male",
    "female",
    "transgender - male to female",
    "transgender - female to male",
    "unknown - unborn child",
    "agender",
    "genderqueer/gender-fluid/gender nonconforming",
    "other"
  ];
  const validPronouns = ["", "he/him/his", "she/her/hers", "they/them/their"];

  const assesValueValidityByArray = (array, valueToAsess) => {
    if (
      array &&
      array.length > 0 &&
      valueToAsess &&
      valueToAsess.length > 0 &&
      array.includes(valueToAsess)
    ) {
      return valueToAsess;
    }
    return "";
  };

  const initialState = {
    firstName: memberData.firstName,
    middleName: memberData.middleName,
    lastName: memberData.lastName,
    preferredName: memberData.preferredName,
    email: memberData.email,
    phoneNumber: maskUnformattedUsPhoneNumber(memberData.phoneNumber),
    countryCode: "1",
    sexAssignedAtBirth: assesValueValidityByArray(
      validSexes,
      memberData.sexAssignedAtBirth
    ),
    gender: assesValueValidityByArray(validGenders, memberData.gender),
    pronouns: assesValueValidityByArray(validPronouns, memberData.pronouns),
    dob: memberData.dob
  };

  const { formFields, formStatus, handleInputChange } = useFormFieldState(
    initialState
  );

  //Validator states
  const [previousEmail, setPreviousEmail] = useState(formFields.email);
  const [hasUserBeenWarned, setHasBeenWarned] = useState(false);

  useEffect(() => {
    if (
      !hasInfoBeenLoaded ||
      (memberData && memberData.email === "") ||
      (formFields && formFields.email === "")
    ) {
      setPreviousEmail(
        memberData && memberData.email !== ""
          ? memberData.email
          : formFields.email
      );
      setHasBeenWarned(false);
    }
  }, [memberData, hasInfoBeenLoaded, formFields]);
  const handleClose = () => {
    setHasBeenWarned(true);
  };

  const handleDismiss = () => {
    setHasBeenWarned(true);
    formFields.email = memberData.email;
  };

  const renderEmailModal = () => {
    const modalBody = (
      <div>
        <Grid container className={classes.modalContainer}>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h3" className={classes.title}>
                <Translate text="E-mail change Warning" />
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Close
                id="email-change-close"
                onClick={handleDismiss}
                className={classes.icon}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={classes.modalBody}>
              <Translate text="If you change this field, this will change your login credentials to this e-mail." />
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <Button
              id="email-change-agree"
              color="primary"
              variant="contained"
              className={classes.requestKitButton}
              onClick={handleClose}
            >
              <Translate text="I Agree" />
            </Button>
          </Grid>
        </Grid>
      </div>
    );
    return (
      <ModalComponent
        open={
          !!(
            formFields.email !== "" &&
            previousEmail &&
            previousEmail !== formFields.email &&
            !hasUserBeenWarned
          )
        }
        body={modalBody}
      />
    );
  };
  const renderArrayAsMenuItems = (array) => {
    return array.map((item) => {
      return (
        <MenuItem key={item.id} name={item.id} value={item.value}>
          <Translate text={item.label} />
        </MenuItem>
      );
    });
  };

  const renderMenuItems = (id, label, value, items, isRequired) => {
    return (
      <Grid container spacing={4}>
        <GLSelectField
          label={label}
          id={id}
          value={value}
          onChange={handleInputChange}
          required={isRequired}
        >
          {renderArrayAsMenuItems(items)}
        </GLSelectField>
      </Grid>
    );
  };

  const validateDateText = (dateToTest) => {
    const regex = new RegExp(
      "^((0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)[0-9]{2}){1}$"
    );
    if (!regex.test(dateToTest)) {
      return <Translate text="The date format should be MM/DD/YYYY" />;
    }
  };
  const renderNameTextField = (id, label, value, isRequired) => {
    return (
      <Grid container spacing={4}>
        <GLNameTextField
          id={id}
          label={label}
          value={value}
          required={isRequired}
          onChange={handleInputChange}
        />
      </Grid>
    );
  };
  return (
    <Grid container direction="row" justifyContent="center">
      {renderEmailModal()}
      <Grid item xs={2} lg={3}></Grid>
      <Grid item xs={8} lg={6} className={classes.titleContainer}>
        <Typography variant="h6" gutterBottom>
          <Translate text="Member information" />
        </Typography>
        <Grid container className={classes.containerSpacing} spacing={2}>
          <Grid item xs={12} md={6}>
            {renderNameTextField(
              "firstName",
              "First Name",
              formFields.firstName,
              true
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderNameTextField(
              "middleName",
              "Middle Name",
              formFields.middleName,
              false
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.containerSpacing} spacing={2}>
          <Grid item xs={12} md={6}>
            {renderNameTextField(
              "lastName",
              "Last Name",
              formFields.lastName,
              true
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderNameTextField(
              "preferredName",
              "Preferred Name",
              formFields.preferredName,
              false
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h6" gutterBottom>
            <Translate text="Contact information" />
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={4}>
                <GLEmailTextField
                  id="email"
                  name="Email"
                  label="Email"
                  required
                  value={formFields.email}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 140 }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <GLPhoneNumberField
                required
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                value={formFields.phoneNumber}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h6" gutterBottom>
            <span>{<Translate text="Sex & gender identity" />}</span>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              {renderMenuItems(
                "sexAssignedAtBirth",
                "Sex assigned at birth",
                formFields.sexAssignedAtBirth,
                SEX,
                true
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderMenuItems(
                "gender",
                "Gender",
                formFields.gender,
                GENDER,
                false
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderMenuItems(
                "pronouns",
                "Pronouns",
                formFields.pronouns,
                PRONOUNS,
                false
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.containerSpacing}>
          <Typography variant="h6" gutterBottom className={classes.geneticText}>
            <Translate text="Genetic Screening is available to adults 18+ only" />
          </Typography>
          <Grid item xs={12} md={5}>
            <GLTextField
              required
              label="Date of Birth"
              id="dob"
              name="dob"
              onChange={handleInputChange}
              value={formFields.dob}
              additionalValidation={validateDateText}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} lg={3}></Grid>
      <Grid
        container
        style={{
          marginTop: buttonPadding,
          marginBottom: buttonPadding
        }}
        justifyContent="center"
        alignItems="center"
      >
        <GLButton
          variant="contained"
          color="primary"
          type="submit"
          id="submit-member-information"
          disabled={formStatus.invalidFields.length > 0}
          loadingSpinnerWhenClicked
          onClick={() => {
            onSubmit(formFields);
          }}
        >
          <Translate text="Save" />
        </GLButton>
      </Grid>
    </Grid>
  );
};
export default MemberInformationForm;

MemberInformationForm.propTypes = {
  hasInfoBeenLoaded: PropTypes.bool,
  buttonPadding: PropTypes.number,
  memberData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};
