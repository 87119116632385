import { Grid, Typography } from "@mui/material";
import React from "react";
import SecurityMessageStyles from "./SecurityMessage.styles";
import SecureIcon from "../../../assets/images/secure_icon.svg";
import Translate from "../../../translate/Translate";

const SecurityMessage = (props) => {
  const { title, desc, fullWidth } = props;
  const classes = SecurityMessageStyles();
  return (
    <Grid
      container
      className={`${classes.securityMessageContainer} ${
        fullWidth ? classes.securityMessageFullWidth : null
      }`}
    >
      <Grid item xs={2}>
        <img
          src={SecureIcon}
          alt="secure-icon"
          className={classes.SecurityCardIcon}
        />
      </Grid>
      <Grid item xs={10}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" className={classes.securityCardTitle}>
            <Translate text={title} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            className={classes.securityCardSubtitle}
          >
            <Translate text={desc} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SecurityMessage;
