import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  existingOrderS3ResultFileContainers: [],
  existingOrders: [],
  availableKitsToOrder: [],
  uuidsOfKitsSelectedToBeOrdered: [],
  kitsJustOrderedToBeShownOnConclusionScreen: []
};

export const OrderInformationSlice = createSlice({
  name: "orderInformation",
  initialState,
  reducers: {
    setExistingOrderS3ResultFileContainers: (state, { payload }) => {
      state.existingOrderS3ResultFileContainers.push(payload);
    },
    setAvailableKitsToOrder: (state, { payload }) => {
      state.availableKitsToOrder = payload;
    },
    setKitsSelectedToBeOrdered: (state, { payload }) => {
      state.uuidsOfKitsSelectedToBeOrdered = payload;
    },
    setExistingOrders: (state, { payload }) => {
      state.existingOrders = payload.data.orders;
    },
    setUuidsOfKitsJustOrderedToBeShownOnConclusionScreen: (
      state,
      { payload }
    ) => {
      state.kitsJustOrderedToBeShownOnConclusionScreen = payload;
    },

    reset: (state) => (state = initialState)
  }
});

export const {
  setExistingOrderS3ResultFileContainers,
  setAvailableKitsToOrder,
  setKitsSelectedToBeOrdered,
  setExistingOrders,
  setUuidsOfKitsJustOrderedToBeShownOnConclusionScreen,
  reset
} = OrderInformationSlice.actions;

export const selectExistingOrderS3ResultFileContainers = (state) =>
  state.order ? state.order.existingOrderS3ResultFileContainers : [];
export const selectTestKitsGLIsCurrentlyOffering = (state) =>
  state.order.availableKitsToOrder;
export const selectKitsSelectedToBeOrdered = (state) =>
  state.order && state.order.uuidsOfKitsSelectedToBeOrdered
    ? state.order.uuidsOfKitsSelectedToBeOrdered
    : [];
export const selectExistingOrders = (state) =>
  state.order && state.order.existingOrders ? state.order.existingOrders : [];
export const selectUuidsOfKitsJustOrderedToBeShownOnConclusionScreen = (
  state
) =>
  state.order && state.order.kitsJustOrderedToBeShownOnConclusionScreen
    ? state.order.kitsJustOrderedToBeShownOnConclusionScreen
    : [];

export default OrderInformationSlice.reducer;
