import React from "react";
import PropTypes from "prop-types";
import Translate from "../../../../../translate/Translate";
import { validateEmailString } from "../../../../../utils/utils";
import GLTextField from "../GLTextField";

const GLEmailTextField = (props) => {
  const {
    id,
    label,
    value,
    onChange,
    required,
    disabled,
    additionalValidation
  } = props;

  const stripSpaces = (stringToStripOfSpaces) => {
    return stringToStripOfSpaces &&
      typeof stringToStripOfSpaces === "string" &&
      stringToStripOfSpaces.length > 0
      ? stringToStripOfSpaces.split(" ").join("")
      : "";
  };

  const emailValidator = (newValue) => {
    const valueToValidate = stripSpaces(newValue);
    if (
      valueToValidate &&
      valueToValidate.length > 0 &&
      !validateEmailString(valueToValidate)
    ) {
      return <Translate text="Email must be valid" />;
    }
    if (additionalValidation) {
      return additionalValidation(valueToValidate);
    }
  };

  const changeHandler = (event) => {
    // Prevents users from entering or pasting in spaces
    onChange({
      ...event,
      e: {
        ...event.e,
        target: {
          name: event.e.target.name,
          value: stripSpaces(event.e.target.value)
        }
      }
    });
  };

  return (
    <GLTextField
      {...props}
      id={id}
      label={label}
      value={value}
      required={required}
      disabled={disabled}
      onChange={changeHandler}
      additionalValidation={emailValidator}
      onKeyPress={(ev) => {
        // Prevents users from entering spaces
        if (ev.key === " ") {
          ev.preventDefault();
        }
      }}
    />
  );
};

export default GLEmailTextField;

GLEmailTextField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  additionalValidation: PropTypes.func,
  disabled: PropTypes.bool
};
