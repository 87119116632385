import { makeStyles } from "@mui/styles";

const GLCheckboxFieldStyles = makeStyles((theme) => ({
  checkboxContainer: {
    "& label.MuiFormControlLabel-root": {
      alignItems: "flex-start"
    },
    "& .MuiCheckbox-root": {
      position: "relative",
      top: "-12px",
      left: "-3px",
      padding: "12px"
    }
  },
  textError: {
    marginLeft: "33px",
    marginTop: 0
  }
}));
export default GLCheckboxFieldStyles;
