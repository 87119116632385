function disableEnterKey(event) {
  return event.key === "Enter" && event.preventDefault();
}
function disableSpaceKey(event) {
  return event.code === "Space" && event.preventDefault();
}
// removes the blue background on the TextField autocomplete
const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

export { disableEnterKey, disableSpaceKey, inputStyle };
