import { COLORS } from "../../../constants/v2/colors";

export const TYPOGRAPHY = {
  v2_h2: {
    fontFamily: "Inter, sans-serif",
    fontSize: 36,
    fontWeight: 600,
    fontStyle: `normal`,
    lineHeight: `40px`,
    color: COLORS.OCEAN
  },
  v2_h3: {
    fontFamily: "Inter, sans-serif",
    fontSize: 30,
    fontWeight: 600,
    fontStyle: `normal`,
    lineHeight: `36px`,
    color: COLORS.AQUA
  },
  v2_h4: {
    fontFamily: "Inter, sans-serif",
    fontSize: 24,
    fontWeight: 600,
    fontStyle: `normal`,
    lineHeight: `32px`,
    color: COLORS.OCEAN
  },
  v2_h5: {
    fontFamily: "Inter, sans-serif",
    fontSize: 20,
    fontWeight: 600,
    fontStyle: `normal`,
    lineHeight: `28px`,
    color: COLORS.AQUA
  },
  v2_body_dark: {
    fontFamily: "Inter, sans-serif",
    fontSize: 16,
    fontWeight: 400,
    fontStyle: `normal`,
    lineHeight: `24px`,
    color: COLORS.DARK_GRAY
  },
  v2_body_dark_heavy: {
    fontFamily: "Inter, sans-serif",
    fontSize: 16,
    fontWeight: 600,
    fontStyle: `normal`,
    lineHeight: `24px`,
    color: COLORS.DARK_GRAY
  },
  v2_body_white_heavy: {
    fontFamily: "Inter, sans-serif",
    fontSize: 16,
    fontWeight: 600,
    fontStyle: `normal`,
    lineHeight: `24px`,
    color: COLORS.WHITE
  },
  v2_body_medium: {
    fontFamily: "Inter, sans-serif",
    fontSize: 16,
    fontWeight: 400,
    fontStyle: `normal`,
    lineHeight: `24px`,
    color: COLORS.MEDIUM_GRAY
  },
  v2_body_light: {
    fontFamily: "Inter, sans-serif",
    fontSize: 16,
    fontWeight: 400,
    fontStyle: `normal`,
    lineHeight: `24px`,
    color: COLORS.LIGHT_GRAY
  },
  v2_body_extra_small_light: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeight: 400,
    fontStyle: `normal`,
    lineHeight: `16px`,
    color: COLORS.LIGHT_GRAY
  },
  v2_body_small: {
    fontFamily: "Inter, sans-serif",
    fontSize: 14,
    fontWeight: 400,
    fontStyle: `normal`,
    lineHeight: `20px`,
    color: COLORS.DARK_GRAY
  },
  v2_body_small_light: {
    fontFamily: "Inter, sans-serif",
    fontSize: 14,
    fontWeight: 400,
    fontStyle: `normal`,
    lineHeight: `20px`,
    color: COLORS.LIGHT_GRAY
  },
  v2_body_small_white_heavy: {
    fontFamily: "Inter, sans-serif",
    fontSize: 14,
    fontWeight: 600,
    fontStyle: `normal`,
    lineHeight: `20px`,
    color: COLORS.WHITE
  }
};

export const TYPOGRAPHY_VARIANT_MAPPING = {
  defaultProps: {
    variantMapping: {
      v2_h2: "h2",
      v2_h3: "h3"
    }
  }
};
