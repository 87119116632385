import { COLOR_CONSTANTS } from "../../../constants/StyleConstants";
import { makeStyles } from "@mui/styles";

const InviteSpousePageStyles = makeStyles((theme) => ({
  containerContent: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.font_blue,
    height: `100%`
  },
  container: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    flexDirection: `row`,
    [theme.breakpoints.down('lg')]: {
      flexDirection: `column`
    },
    [theme.breakpoints.down(703)]: {
      flexDirection: `row`
    }
  },
  formCard: {
    borderRadius: `50px 50px 0 0`,
    background: theme.palette.primary.white,
    marginTop: 45,
    paddingTop: 40,
    paddingRight: 83,
    paddingLeft: 83,
    width: 465,
    height: 680,
    marginLeft: 32,
    [theme.breakpoints.down('lg')]: {
      width: 740,
      height: 615,
      marginLeft: -20,
      marginTop: 0
    },
    [theme.breakpoints.down(703)]: {
      width: 445,
      height: 690,
      marginTop: 70
    }
  },
  title: {
    textAlign: `center`,
    color: theme.palette.primary.font_blue,
    fontWeight: 700
  },
  subTitle: {
    textAlign: `center`,
    color: theme.palette.secondary.gray_button,
    margin: `20px 0`
  },
  fieldsPadding: {
    marginBottom: 5
  },
  link: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY,
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: 15
  },
  modalHeader: {
    color: COLOR_CONSTANTS.COLOR_INFO,
    marginBottom: 20
  },
  outlinedWell: {
    border: "2px solid",
    borderColor: COLOR_CONSTANTS.COLOR_GRAY_60,
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
    fontSize: 16,
    lineHeight: "30px"
  },
  modalButtons: {
    justifyContent: "right"
  }
}));

export default InviteSpousePageStyles;
