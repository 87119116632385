import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const RegistrationSuccessPageStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#FFFFFF",
    padding: "35px 10px",
    textAlign: "center",
    boxShadow: "0px 0px 11px 0px #0000000D",
    width: "500px"
  },
  bodyPaper: {
    backgroundColor: "#FFFFFF",
    padding: "35px",
    textAlign: "center",
    boxShadow: "0px 4px 10px 0px #00000024"
  },
  party: { fontSize: "40px", marginBottom: "20px" },
  header: {
    "& h4": { fontSize: "32px", fontWeight: 400 },
    marginBottom: "30px"
  },
  name: { "& h6": { fontWeight: 700 } },
  userId: {
    marginBottom: "30px",
    color: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY
  },
  thinGreenLine: {
    width: "100%",
    borderBottom: `1px solid ${COLOR_CONSTANTS.COLOR_SUCCESS}`,
    marginBottom: "20px"
  },
  continueButton: {
    borderRadius: 0,
    marginTop: "40px",
    width: "100%"
  }
}));

export default RegistrationSuccessPageStyles;
