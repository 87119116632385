import React from "react";
import { Grid, Typography, Snackbar, Slide } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpIcon from "@mui/icons-material/Help";
import ErrorIcon from "@mui/icons-material/Error";
import SecureIcon from "../../../assets/images/secure_icon.svg";
import { useDispatch, useSelector } from "react-redux";

import GLSnackbarStyles from "./GlSnackbar.styles";
import Translate from "../../../translate/Translate";
import {
  closeSnackbar,
  selectSnackbarStatus
} from "../../../store/reducers/snackbar/openGLSnackbarSlice";

const GLSnackbar = () => {
  const dispatch = useDispatch();
  const snackbarStatus = useSelector(selectSnackbarStatus);
  // const { header, open, subText, variant } = snackbarStatus;
  const classes = GLSnackbarStyles();

  //Handle close action, this change the open status, to false.
  const handleClose = () => {
    setTimeout(() => {
      dispatch(closeSnackbar());
    }, 5000);
  };

  const infoMessage = (variant) => {
    if (variant) {
      const lower = variant.toLowerCase();
      return `${variant.charAt(0).toUpperCase() + lower.slice(1)} message`;
    }
    return "";
  };

  const variantIcon = {
    success: <CheckCircleIcon className={classes.successIcon} />,
    warning: <NotificationsIcon className={classes.warningIcon} />,
    error: <ErrorIcon className={classes.errorIcon} />,
    info: <HelpIcon className={classes.infoIcon} />,
    security: (
      <img
        src={SecureIcon}
        alt="secure-icon"
        className={classes.securityIcon}
      />
    )
  };

  const Icon = variantIcon[snackbarStatus?.variant];
  const SnackbarContent = (
    <div>
      <Grid
        container
        className={`${classes.fullWidth} ${classes.container}  ${
          snackbarStatus?.variant
        } ${!snackbarStatus?.header && `header`}`}
        spacing={2}
      >
        <Grid item>{Icon}</Grid>
        <Grid item xs>
          {snackbarStatus?.header && (
            <Grid item xs={12} className={classes.content}>
              <Typography variant="subtitle1" className={classes.title}>
                <Translate
                  text={
                    snackbarStatus?.header && snackbarStatus?.header.length > 0
                      ? snackbarStatus?.header
                      : `Message header`
                  }
                />
              </Typography>
            </Grid>
          )}
          {snackbarStatus?.subText && (
            <Grid
              item
              xs={12}
              className={`${classes.content} ${!snackbarStatus?.header &&
                `header`}`}
            >
              <Typography variant="subtitle2" className={classes.text}>
                <Translate
                  text={
                    snackbarStatus?.subText &&
                    snackbarStatus?.subText.length > 0
                      ? snackbarStatus?.subText
                      : infoMessage(snackbarStatus?.variant)
                  }
                />
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={snackbarStatus?.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Slide
          direction="up"
          in={snackbarStatus?.open}
          mountOnEnter
          unmountOnExit
        >
          {SnackbarContent}
        </Slide>
      </Snackbar>
    </div>
  );
};

export default GLSnackbar;
