import { makeStyles } from "@mui/styles";

const FinalInfoStyles = makeStyles((theme) => ({
  contentFinal: {
    width: `100%`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `center`,
    marginTop: 40
  },
  title: {
    color: theme.palette.primary.font_blue
  },
  boxTitle: {
    width: 628,
    height: 153,
    textAlign: `center`,
    [theme.breakpoints.down('lg')]: {
      width: 628,
      height: 153
    },
    [theme.breakpoints.down(703)]: {
      width: 335,
      height: 61,
      textAlign: `left`
    }
  },
  glGenoLogo: {
    width: 220,
    height: 85,
    [theme.breakpoints.down(703)]: {
      width: 110,
      height: 45
    }
  },
  boxGlGenoLogo: {
    marginTop: 65
  },
  loadingGif: {
    width: 80,
    height: 39
  },
  boxLoadingGif: {
    marginTop: 45
  }
}));

export default FinalInfoStyles;
