import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../constants/v2/colors";

const FooterStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: COLORS.DARK_GRAY,
    minHeight: "235px",
    padding: "35px 60px",
    "& a": {
      color: "inherit"
    }
  },
  narrowContainer: {
    padding: "35px 20px"
  },
  contactUs: {
    marginBottom: "10px"
  },
  emailLink: {
    marginTop: "10px",
    "& svg": {
      fontSize: "26px",
      position: "relative",
      top: "6px",
      marginRight: "10px"
    }
  },
  bigStuff: {
    borderBottom: `1px solid ${COLORS.MEDIUM_GRAY}`,
    height: "120px"
  },
  spaced: {
    marginTop: "15px"
  }
}));

export default FooterStyles;
