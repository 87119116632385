import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const GutterCardStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #C4C4C4",
    borderRadius: 10,
    padding: "10px 20px 30px",
    fontSize: 16,
    color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
    "& h2": {
      ...theme.typography.h6HeadlineBlack
    },
    "& a": {
      textDecoration: "none",
      cursor: "pointer",
      fontSize: 16,
      fontWeight: 700,
      color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY
    },
    marginBottom: "20px"
  }
}));

export default GutterCardStyles;
