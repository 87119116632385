import { makeStyles } from "@mui/styles";

const ImageCardStyles = makeStyles((theme) => ({
  cardContent: {
    background: theme.palette.primary.white,
    borderRadius: 10,
    width: 294,
    height: 291
  },
  image: {
    width: 294,
    height: 241
  },
  actions: {
    display: `block`,
    cursor: `pointer`,
    padding: 18
  },
  title: {
    color: theme.palette.primary.font_blue,
    fontWeight: 700
  },
  horizontalCard: {
    [theme.breakpoints.down('md')]: {
      width: "100%",
      height: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: 461,
      height: 291
    }
  },
  horizontalImage: {
    [theme.breakpoints.down('md')]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: 461
    },
    height: 241
  }
}));

export default ImageCardStyles;
