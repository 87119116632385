import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import Theme from "./theme";
import "./translate/i18n";
const { REACT_APP_AUTH0_CLIENT_ID } = process.env;
const { REACT_APP_AUTH0_DOMAIN } = process.env;
const { REACT_APP_AUTH0_AUDIENCE } = process.env;

const theme = Theme;

let persistantStore = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistantStore}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Auth0Provider
                domain={REACT_APP_AUTH0_DOMAIN}
                clientId={REACT_APP_AUTH0_CLIENT_ID}
                authorizationParams={{
                  redirect_uri: `${window.location.origin}/loginCallback`,
                  audience: REACT_APP_AUTH0_AUDIENCE,
                  scope: "openid profile email offline_access"
                }}
                cacheLocation="localstorage"
                useRefreshTokens={true}
              >
                <Route component={App} />
              </Auth0Provider>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
