import { doApiCall } from "./util";

const { REACT_APP_MEMBER_PORTAL_ENDPOINT_URL } = process.env;

/**
 * @param {object} surveyAnswers the object for member information
 * @returns {Promise} the promise response from the endpoint
 */

export const sendSurveyAnswers = (surveyAnswers) => {
  const payload = {
    data: {
      ...surveyAnswers
    }
  };
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "PATCH",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

export const getSurveyJson = (surveyId) => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "GET",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/survey/${surveyId}`
  };

  return doApiCall(request);
};

export const postSurveyAnswers = (payload) => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/survey/`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};
