import { Button } from "@mui/material";
import React from "react";
import SideScrollingMenuStyles from "./SideScrollingMenu.styles";

export function ScrollingMenuItem({ title, selected, onClick }) {
  const classes = SideScrollingMenuStyles();

  return (
    <Button
      size={"small"}
      name={title}
      className={`${classes.menuItemButton} ${
        selected ? classes.menuItemButtonSelected : ""
      }`}
      variant={"outlined"}
      tabIndex={0}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}
