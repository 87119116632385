import { makeStyles } from "@mui/styles";

const FormSummaryStyles = makeStyles((theme) => ({
  container: { borderRadius: 10, border: "1px solid rgba(0, 0, 0, 0.1)" },
  titleContainer: {
    backgroundColor: theme.palette.primary.white,
    paddingTop: 10,
    paddingLeft: 20,
    paddingBottom: 5,
    borderBottom: "2px solid",
    borderBottomColor: theme.palette.info.light,
    color: theme.palette.primary.font_blue
  },
  title: {
    fontWeight: 700
  },
  editButton: {
    marginRight: 8,
    cursor: `pointer`,
    paddingRight: 10,
    paddingTop: 2
  },
  fieldContainer: { paddingTop: 25, paddingLeft: 20 },
  boxGrid: { paddingBottom: 28 },
  bundleSubFieldsTitle: {
    color: theme.palette.secondary.gray_button,
    marginLeft: 25,
    marginTop: 10
  },
  bundleSubfields: {
    marginLeft: 25
  },
  formLabel: { marginRight: 6 }
}));

export default FormSummaryStyles;
