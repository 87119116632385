import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const HelpLinkStyles = makeStyles((theme) => ({
  helpLink: {
    width: "300px",
    justifyContent: "center",
    marginTop: "50px"
  },
  helpBubbleText: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY,
    fontSize: "14px",
    marginLeft: "12px",
    marginRight: "6px"
  },
  helpBubbleLinkText: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY,
    fontFamily: "inherit",
    fontSize: "14px",
    textDecoration: "underline",
    lineHeight: "23px"
  }
}));

export default HelpLinkStyles;
