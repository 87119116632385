import { makeStyles } from "@mui/styles";

const BreadcrumbStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    [theme.breakpoints.down('lg')]: {
      height: 40
    },
    [theme.breakpoints.up("md")]: {
      height: 50
    }
  },
  breadcrumbLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    cursor: `pointer`,
    textDecoration: `none`,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 19
    },
    [theme.breakpoints.between("md", 'xl')]: {
      marginLeft: 33
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 168
    }
  },
  breadcrumbText: {
    color: theme.palette.primary.link,
    marginTop: 4
  },
  breadcrumbIcon: {
    color: theme.palette.primary.link,
    marginRight: 15,
    fontSize: 25
  }
}));

export default BreadcrumbStyles;
