import { Grid, Typography } from "@mui/material";
import React from "react";
import { PATHS } from "../../../../constants/pathConstants";
import Translate from "../../../../translate/Translate";
import Layout from "../../../layout/Layout";
import BackTo from "../../../sharedComponents/BackTo";
import CenteredContent from "../../../sharedComponents/CenteredContent";
import SecurityMessage from "../../../sharedComponents/SecurityMessage";
import OptionCard from "../../../sharedComponents/OptionCard";
import AccountManagementTermsOfServiceStyles from "./AccountManagementTermsOfService.styles";
import PageTitle from "../../../../utils/PageTitle";

const AccountManagementTermsOfService = () => {
  const classes = AccountManagementTermsOfServiceStyles();
  const optionCardElements = [
    {
      id: "pri-pol",
      option: "Privacy Policy",
      redirectTo: PATHS.TERMS_OF_SERVICE_PRIVACY_POLICY
    },
    {
      id: "terms-cond",
      option: "Terms & Conditions",
      redirectTo: PATHS.TERMS_OF_SERVICE_TERMS_AND_CONDITIONS
    },
    {
      id: "hip-aut",
      option: "HIPAA Authorization",
      redirectTo: PATHS.TERMS_OF_SERVICE_HIPAA
    }
  ];

  PageTitle("Terms of Service");

  return (
    <Layout headerType="primary">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.backToContainer}>
          <BackTo
            text="Privacy & Security"
            backTo={PATHS.ACCOUNT_MANAGEMENT_PRIVACY_AND_POLICY}
          />
        </Grid>
        <Grid item xs={12}>
          <CenteredContent className={classes.privacyPolicyTitle}>
            <Typography variant="h3">
              <Translate text="Terms of Service" />
            </Typography>
          </CenteredContent>
        </Grid>

        <Grid item xs={12}>
          {optionCardElements.map((element) => (
            <OptionCard
              text={element.option}
              key={element.id}
              redirectTo={element.redirectTo}
            />
          ))}
        </Grid>
      </Grid>

      <CenteredContent className={classes.secureInformationContainer}>
        <SecurityMessage
          title="Your information is safe with us"
          desc="Genomic Life uses SSL encryption to protect your information."
        />
      </CenteredContent>
    </Layout>
  );
};

export default AccountManagementTermsOfService;
