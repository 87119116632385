import React, { useState } from "react";
import FileListingStyles from "./FileListing.styles";
import downloadFileIcon from "../../../../assets/icons/icon-downloadFile.svg";
import viewFileIcon from "../../../../assets/icons/icon-viewFile.svg";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { downloadOrderDocuments } from "../../../../apis/ordersApi";
import { downloadFile } from "../../../../utils/utils";
import Mixpanel from "../../../../utils/mixpanel";
import ErrorMessageSupportModal from "../../sharedComponents/Modals/ErrorMessageSupportModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PATHS } from "../../../../constants/pathConstants";

const FileListing = (props) => {
  const classes = FileListingStyles();
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const history = useHistory();
  const { order } = props;

  const downloadDocument = (orderId, documentId) => {
    downloadOrderDocuments(orderId, documentId)
      .then((response) => {
        downloadFile(documentId, response.data);
        Mixpanel.track(`Test result downloaded`, {
          documentId: documentId
        }).catch((error) => {
          Mixpanel.track(`Test result document not found`, {
            error: error,
            documentId: documentId
          });
          //setErrorModalIsOpen(true);
        });
      })
      .catch((error) => {
        Mixpanel.track(`Test result download failed`, {
          error: error,
          documentId: documentId
        });
        //setErrorModalIsOpen(true);
      });
  };

  const renderFileList = () => {
    return order.documentIds.map((id, index) => {
      return (
        <Grid item xs={12} key={index}>
          <Grid container className={classes.itemCard}>
            <Grid container direction={"column"} item xs={9}>
              <Typography variant={"v2_body_dark_heavy"}>
                Results File
              </Typography>
              <Typography variant={"v2_body_dark"}>{id}.pdf</Typography>
            </Grid>
            <Grid item xs={3} alignContent="center">
              <Grid
                container
                justifyContent={"right"}
                columnSpacing={1}
                rowSpacing={1}
              >
                <Grid item>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      downloadDocument(order.glOrderId, id);
                    }}
                    data-testid={`download-button-${index}`}
                  >
                    <img
                      src={downloadFileIcon}
                      alt={"download-file"}
                      className={classes.icon}
                    />
                    <Box className={classes.iconButtonHover} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      history.push(
                        `${PATHS.TEST_RESULTS_PAGE}/${order.glOrderId}/${id}`
                      );
                    }}
                    data-testid={`preview-button-${index}`}
                  >
                    <img
                      src={viewFileIcon}
                      alt={"view-file"}
                      className={classes.icon}
                    />
                    <Box className={classes.iconButtonHover} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Grid container>
          <Typography variant="v2_h3">Your Files</Typography>
        </Grid>
      </Grid>
      {renderFileList()}
      <ErrorMessageSupportModal
        isOpen={errorModalIsOpen}
        onClose={() => setErrorModalIsOpen(false)}
      />
    </Grid>
  );
};
export default FileListing;
