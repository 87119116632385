import React, { Fragment } from "react";
import { Grid, Typography, Button } from "@mui/material";
import SessionTimerDialogStyles from "./SessionTimerDialog.styles";
import Translate from "../../../translate/Translate";

const SessionTimerDialog = (props) => {
  const { handleClose, logOut, second } = props;
  const classes = SessionTimerDialogStyles();

  return (
    <Fragment>
      <Grid
        container
        className={classes.modalContainer}
        id="idle-timer-component"
      >
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h3" className={classes.modalTitle}>
              <Translate text="Session Timeout" />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="subtitle1">
              <Translate text="You're being timed out due to inactivity. Please choose to stay signed in or to logout. Otherwise you will logged out automatically in" />{" "}
              <span className={classes.second}>{second} </span>
              <Translate text="seconds." />
            </Typography>
          </Grid>
          <Grid container className={classes.modalFooterContainer}>
            <Button
              className={classes.dismissBtn}
              onClick={logOut}
              id="idle-timer-logout-button"
            >
              <Translate text="Log out" />
            </Button>
            <Button
              id="confirm"
              name="stay-login-btn"
              color="secondary"
              variant="contained"
              onClick={handleClose}
            >
              <Translate text="Stay Logged In" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SessionTimerDialog;
