import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getCookie, setCookie } from "../../../../utils/CookieUtil";
import { Box, Grid, Typography } from "@mui/material";
import HeaderLogo from "../../../../assets/images/LogoIconDefault.svg";
import LoginCallbackPageStyles from "./LoginCallbackPage.styles";

import {
  setUserPersonalInformation,
  updateFieldsInUserPersonalInformation
} from "../../../../store/reducers/user/UserAccountSlice";
import Mixpanel from "../../../../utils/mixpanel";
import { PATHS } from "../../../../constants/pathConstants";
import Translate from "../../../../translate/Translate";
import GLTextField from "../../Forms/FormComponents/GLTextField";
import { useFormFieldState } from "../../Forms/FormUtils";
import GLButton from "../../sharedComponents/GLButton";
import { testAccessCode } from "../../../../apis/authApi";
import RegistrationHelpModal from "../RegistrationPage/RegistrationHelpModal";
import RegistrationPageLayout from "../RegistrationPage/RegistrationPageLayout";
import LoadingPage from "../../../sharedComponents/LoadingPage";

const LoginCallbackPage = () => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims
  } = useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = LoginCallbackPageStyles();
  const { formFields, handleInputChange } = useFormFieldState({});
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [needsAccessToken, setNeedsAccessToken] = useState(false);
  const [lastCodeWasInvalid, setLastCodeWasInvalid] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  //This feels gross, but there are a few reasons we need the useEffect loop to
  //  kick over again in during the back and forth redirects required by the auth0 flow
  //  this lets us tell the effect to run agian on the next page load.
  const [refreshLoopCount, setRefreshLoopCount] = useState(0);

  const [userInfo, setUserInfo] = useState({});
  // Trash this as soon as the glid gets moved to app_metadata
  //  instead of user_metadata
  function findValueByKey(obj, key) {
    let foundValue = null;
    const search = (obj, key) => {
      Object.entries(obj).find(([prop, value]) => {
        if (prop === key) {
          foundValue = value;
          return true;
        } else if (typeof value === "object") {
          search(value, key);
        }
      });
    };
    search(obj, key);
    return foundValue;
  }

  useEffect(() => {
    const getToken = () => {
      getAccessTokenSilently({ cacheMode: "off" })
        .then((token) => {
          getIdTokenClaims().then((userDataFromAuth0Token) => {
            const userGlid = findValueByKey(
              userDataFromAuth0Token,
              "glMemberId"
            );
            const auth0UserName = findValueByKey(
              userDataFromAuth0Token,
              "email"
            );
            const auth0UserId = findValueByKey(userDataFromAuth0Token, "sub");
            dispatch(
              updateFieldsInUserPersonalInformation({
                glMemberId: userGlid,
                email: userDataFromAuth0Token.email
              })
            );
            if (userGlid) {
              Mixpanel.identify(userGlid);
            }
            setUserInfo({
              glid: userGlid,
              auth0UserName: auth0UserName,
              auth0UserId: auth0UserId
            });
            setCookie("accessToken", token);
            setRefreshLoopCount(refreshLoopCount + 1);
            if (userGlid) {
              history.push(PATHS.API_SCRAPE);
            } else {
              setNeedsAccessToken(true);
            }
          });
        })
        .catch((error) => {
          if (
            [
              "invalid_grant",
              "missing_refresh_token",
              "consent_required"
            ].includes(error.error)
          ) {
            //access token reported not got, but that's bunk. Ignoring
            setRefreshLoopCount(refreshLoopCount + 1);
            //loginWithRedirect();
          } else if (error.error === "Unknown or invalid refresh token") {
            history.push(PATHS.LOG_OUT);
          } else {
            Mixpanel.track(
              "Login callback page was unable to get access token"
            );
            throw error;
          }
        });
    };

    if (!getCookie("accessToken")) {
      getToken();
    }
  }, [isAuthenticated, refreshLoopCount]);

  const sendAccessCodeTest = () => {
    setShowLoadingSpinner(true);
    testAccessCode(
      formFields.accessCode,
      userInfo.auth0UserName,
      userInfo.auth0UserId
    )
      .then((response) => {
        if (response.status === 200) {
          const userInfoFromResponse = {
            firstName: response.data.firstName,
            lastName: response.data.lastName
          };
          setUserInfo({ ...userInfo, ...userInfoFromResponse });
          dispatch(
            setUserPersonalInformation({
              ...userInfoFromResponse,
              email: userInfo.auth0UserName
            })
          );
          if (response.data.glMemberId) {
            Mixpanel.identify(response.data.glMemberId);
            Mixpanel.track("Access code lookup successful");
          }
          history.push(PATHS.REGISTRATION_SUCCESS);
        } else if (
          JSON.stringify(JSON.parse(JSON.stringify(response)).status) === "400"
        ) {
          setShowLoadingSpinner(false);
          setLastCodeWasInvalid(true);
        } else {
          // Probably got a 403, but can't test for it 😡
          history.push(PATHS.LOG_OUT);
        }
      })
      .catch((error) => {
        setLastCodeWasInvalid(true);
        setShowLoadingSpinner(false);
      });
  };

  const resetInvalidCodeFlagAndUpdateInput = (input) => {
    setLastCodeWasInvalid(false);
    handleInputChange(input);
  };

  const checkIfLastCodeWasInvalid = () => {
    if (lastCodeWasInvalid) {
      return (
        <Translate text="We’re having trouble finding your code, please check and try again." />
      );
    }
  };

  const renderAccessCodeForm = () => {
    return (
      <RegistrationPageLayout>
        <RegistrationHelpModal
          isOpen={contactModalOpen}
          onClose={() => {
            setContactModalOpen(false);
          }}
        />
        <Grid item xs={11} sm={8} md={5} className={classes.formContainer}>
          <Grid item className={classes.center}>
            <img src={HeaderLogo} className={classes.headerLogo} />
          </Grid>
          <Grid item className={classes.center}>
            <Typography variant="h5">
              <Translate text="Please enter information below" />
            </Typography>
            <Typography variant="subtitle2">
              <Translate text="Please enter your access code below. You can find this code in your welcome email that would have been sent to the email address provided during enrollment." />
            </Typography>
          </Grid>
          <Grid item className={classes.center}>
            <GLTextField
              id="accessCode"
              name="accessCode"
              variant="outlined"
              label={"Access Code"}
              md={12}
              value={formFields.accessCode}
              onChange={resetInvalidCodeFlagAndUpdateInput}
              additionalValidation={checkIfLastCodeWasInvalid}
              required
            />
          </Grid>
          <Grid item className={classes.center}>
            <GLButton
              loading={showLoadingSpinner}
              name="continue"
              color="primary"
              onClick={sendAccessCodeTest}
              disabled={!formFields.accessCode}
              className={classes.continueButton}
            >
              <Translate text="Continue" />
            </GLButton>
            <Typography className={classes.lightSubheader}>
              <Translate text="Don't have your access code? " />
              <Box
                className={classes.link}
                onClick={() => {
                  setContactModalOpen(true);
                }}
              >
                <Translate text="Give us a call." />
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </RegistrationPageLayout>
    );
  };

  if (needsAccessToken) {
    return renderAccessCodeForm();
  } else {
    return <LoadingPage />;
  }
};

export default LoginCallbackPage;
