import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ResultSelectionStep from "../ResultSelectionStep";
import {
  selectExistingOrderS3ResultFileContainers,
  selectExistingOrders
} from "../../../../store/reducers/order/OrderInformationSlice";
import {
  selectConnections,
  selectTransmitSurveyStep,
  selectTransmitSurveyResults,
  setTransmitSurveyStep,
  setTransmitSurveyResults,
  resetTransmitSurveyResults,
  setSurveySentState
} from "../../../../store/reducers/connection/ConnectionsInformationSlice";
import Layout from "../../../layout/Layout";
import { PATHS } from "../../../../constants/pathConstants";
import ConnectionManagmentStep from "./ConnectionManagmentStep";
import { useHistory } from "react-router";
import TransmissionReasonManagmentStep from "./TransmissionReasonManagmentStep";
import TransmitConfirmationStep from "./TransmitConfirmationStep";

import StepperWrapper from "../../../v2/sharedComponents/StepperWrapper";
import LoadingPage from "../../../sharedComponents/LoadingPage";
import { sendTransmitSurvey } from "../../../../apis/transmitSurveyApi";
import { openGLSnackbar } from "../../../../store/reducers/snackbar/openGLSnackbarSlice";

const ReportingSharingPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const orderFiles = useSelector(selectExistingOrderS3ResultFileContainers);
  const existingOrders = useSelector(selectExistingOrders);
  const availableConnections = useSelector(selectConnections);
  const currentSurveyStep = useSelector(selectTransmitSurveyStep);
  const currentSurveyResults = useSelector(selectTransmitSurveyResults);

  const [careProviderConnections, setCareProviderConnections] = useState(
    availableConnections.filter((connection) => {
      return (
        connection &&
        (connection.relationship === "Primary care provider" ||
          connection.relationship === "Practitioner")
      );
    })
  );

  const getSteps = () => {
    return [
      "Report Selection",
      "Care Team Connections",
      "Reason for Transmission",
      "Confirmation"
    ];
  };
  const steps = getSteps();
  const [isLoading, setIsLoading] = useState(null);
  const [activeStep, setActiveStep] = useState(currentSurveyStep);
  const [reportsToSend, setReportsToSend] = useState(
    currentSurveyResults && currentSurveyResults.reportIds
  );

  const isReportInResults = (reportName) => {
    return (
      reportsToSend &&
      reportsToSend.find((report) => {
        return report.name === reportName;
      })
    );
  };

  const mapReportOptions = () => {
    let reportOptions = [];
    if (
      orderFiles &&
      orderFiles.length > 0 &&
      existingOrders &&
      existingOrders.length > 0
    ) {
      orderFiles.forEach((file) => {
        const orderInfo = existingOrders.find((order) => {
          return order.uuid === file.uuid;
        });
        if (file.fileIDs && file.fileIDs.length > 0) {
          reportOptions.push({
            id: file.uuid,
            label: orderInfo ? orderInfo.name : null,
            isChecked: isReportInResults(orderInfo.name),
            fileIDs: file.fileIDs
          });
        }
      });

      if (reportOptions.length > 1) {
        reportOptions = [
          {
            id: "all",
            label: "All of my result reports",
            isChecked: false
          },
          ...reportOptions
        ];
      }
    }

    return reportOptions;
  };

  const [reportsAvailable, setReportsAvailable] = useState(mapReportOptions);

  const [transmissionModes, setTransmissionModes] = useState(
    currentSurveyResults && currentSurveyResults.recipientChannels
  );
  const [reasonsToTransmit, setTransmissionReasons] = useState(
    currentSurveyResults && currentSurveyResults.reasons
  );
  const [otherReason, setOtherReason] = useState(
    currentSurveyResults && currentSurveyResults.otherReason
  );
  const [additionalComments, setAdditionalComments] = useState(
    currentSurveyResults && currentSurveyResults.additionalInformation
  );
  const [aknowledgementState, setAknowledgementState] = useState(
    currentSurveyResults && currentSurveyResults.aknowledgment
  );
  const [adviceState, setAdviceState] = useState(
    currentSurveyResults && currentSurveyResults.adviceAknowledgment
  );
  const [redirectToStep, setRedirectToStep] = useState(null);

  const setReportsHandler = (reports) => {
    setReportsAvailable(reports);
  };

  useEffect(() => {
    setCareProviderConnections(
      availableConnections.filter((connection) => {
        return (
          connection &&
          (connection.relationship === "Primary care provider" ||
            connection.relationship === "Practitioner")
        );
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableConnections]);

  const reasonsForTransmitHandler = (state) => {
    setTransmissionReasons(state);
    dispatch(
      setTransmitSurveyResults({
        ...currentSurveyResults,
        reasons: state
      })
    );
  };

  const setOtherReasonHandler = (state) => {
    setOtherReason(state);
    dispatch(
      setTransmitSurveyResults({
        ...currentSurveyResults,
        otherReason: state
      })
    );
  };

  const setOtherAdditionalCommentsHandler = (state) => {
    setAdditionalComments(state);
    dispatch(
      setTransmitSurveyResults({
        ...currentSurveyResults,
        additionalInformation: state
      })
    );
  };

  const setAknowledgementStateHandler = (state) => {
    setAknowledgementState(state);
    dispatch(
      setTransmitSurveyResults({
        ...currentSurveyResults,
        aknowledgment: state
      })
    );
  };
  const setAdviceStateHandler = (state) => {
    setAdviceState(state);
    dispatch(
      setTransmitSurveyResults({
        ...currentSurveyResults,
        adviceAknowledgment: state
      })
    );
  };

  const getSelectedFilesArray = () => {
    let allReports = [];

    const selectedReports = reportsAvailable.filter((option) => {
      return option.isChecked;
    });

    const isAllSelected = selectedReports.find((option) => {
      return option.id === "all";
    });

    const selectedArray = isAllSelected ? reportsAvailable : selectedReports;

    selectedArray.forEach((file) => {
      if (file.fileIDs && file.fileIDs.length > 0) {
        if (allReports.length > 0) {
          allReports = [
            ...allReports,
            { name: file.label, fileIds: file.fileIDs }
          ];
        } else {
          allReports = [{ name: file.label, fileIds: file.fileIDs }];
        }
      }
    });

    return allReports;
  };

  const isDisabledHandler = (stepToTest) => {
    const selectedReports = reportsAvailable.findIndex((option) => {
      return option.isChecked;
    });
    if (stepToTest === 0 && selectedReports !== -1) {
      return false;
    }
    if (stepToTest === 1 && transmissionModes.length > 0) {
      return false;
    }
    if (stepToTest === 2) {
      return false;
    }
    return !(stepToTest === 3 && aknowledgementState && adviceState);
  };

  const calculateLastStepIndex = () => {
    let minimumStep = 0;
    if (!isDisabledHandler(0)) {
      minimumStep = 1;
    }
    if (!isDisabledHandler(0) && !isDisabledHandler(1)) {
      minimumStep = 2;
    }

    if (
      !isDisabledHandler(0) &&
      !isDisabledHandler(1) &&
      !isDisabledHandler(2)
    ) {
      minimumStep = 3;
    }

    return minimumStep;
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Grid item xs={12}>
            <ResultSelectionStep
              availableReports={reportsAvailable}
              reportsHandler={setReportsHandler}
              allOption={"all"}
            />
          </Grid>
        );
      case 1:
        return (
          <Grid item xs={12}>
            <ConnectionManagmentStep
              careProviderConnections={careProviderConnections}
              transmissionModes={transmissionModes}
              setTransmissionModes={setTransmissionModes}
            />
          </Grid>
        );
      case 2:
        return (
          <Grid item xs={12}>
            <TransmissionReasonManagmentStep
              reasonsToTransmit={reasonsToTransmit}
              reasonsForTransmitHandler={reasonsForTransmitHandler}
              setOtherReason={setOtherReasonHandler}
              otherReason={otherReason}
              additionalComments={additionalComments}
              additionalCommentsHandler={setOtherAdditionalCommentsHandler}
            />
          </Grid>
        );
      case 3:
        return (
          <Grid item xs={12}>
            <TransmitConfirmationStep
              reports={reportsToSend}
              transmissionModes={transmissionModes}
              aknowledgementState={aknowledgementState}
              aknowledgementStateHandler={setAknowledgementStateHandler}
              adviceAknowledgementState={adviceState}
              adviceAknowledgementStateHandler={setAdviceStateHandler}
              handleBackReportSelection={() => {
                handleStepOverride(3);
              }}
              handleBackCareTeamConnections={() => {
                handleStepOverride(2);
              }}
            />
          </Grid>
        );
      default:
        return null;
    }
  };
  const mapReports = () => {
    let reportsToSend = [];
    const isAllChecked = reportsAvailable.find((selectedOption) => {
      return selectedOption.id === "all" && selectedOption.isChecked;
    });
    reportsAvailable.forEach((option) => {
      if (option.id !== "all") {
        if (reportsToSend.length > 0) {
          reportsToSend = [...reportsToSend, option];
        } else {
          reportsToSend = [option];
        }
      }
    });
    if (!isAllChecked) {
      reportsToSend = reportsToSend.filter((filteredIds) => {
        return filteredIds.id && filteredIds.isChecked;
      });
    }

    return reportsToSend.map((reportObject) => {
      return {
        documentUUID: reportObject.fileIDs[0],
        testName: reportObject.label
      };
    });
  };

  const mapConnectionsToPayload = () => {
    return transmissionModes.map((transmission) => {
      const foundConnection = availableConnections.find((connection) => {
        return transmission.connectionId === connection.connectionId;
      });
      if (foundConnection) {
        return {
          connection: foundConnection,
          channels: transmission.channels
        };
      }
      return null;
    });
  };

  const mapConnectionId = () => {
    return transmissionModes.map((transmission) => {
      const foundConnection = availableConnections.find((connection) => {
        return transmission.connectionId === connection.connectionId;
      });
      if (foundConnection) {
        return foundConnection.connectionId;
      }
      return null;
    });
  };

  const handleNext = (stepToTest) => {
    if (stepToTest === 1) {
      setReportsToSend(getSelectedFilesArray());
      dispatch(
        setTransmitSurveyResults({
          ...currentSurveyResults,
          reportIds: getSelectedFilesArray()
        })
      );
    }
    if (stepToTest === steps.length) {
      const payload = {
        reports: mapReports(),
        recipients: mapConnectionsToPayload(),
        preDefinedReasons: reasonsToTransmit,
        otherReason: otherReason,
        additionalInformation: additionalComments,
        connectionAccuracyAcknowledged: aknowledgementState,
        connectionFollowupAcknowledged: adviceState
      };
      setIsLoading(true);
      sendTransmitSurvey(payload)
        .then(() => {
          dispatch(setSurveySentState(true));
          dispatch(setTransmitSurveyStep(0));
          dispatch(resetTransmitSurveyResults());
          setIsLoading(false);
          history.push(PATHS.REPORT_SHARING_CONCLUSION, {
            recipientIds: mapConnectionId()
          });
        })
        .catch(() => {
          dispatch(
            openGLSnackbar({
              variant: "error",
              header: false,
              subText:
                "Something went wrong... We apologize for the inconvenience, we couldn’t transmit your results to your care team."
            })
          );
          dispatch(setSurveySentState(false));
        });
    }
  };

  const handleStepOverride = (stepsBack) => {
    if (stepsBack >= 0) {
      window.scrollTo(0, 0);
      setActiveStep((prevActiveStep) => prevActiveStep - stepsBack);
      dispatch(setTransmitSurveyStep(activeStep - stepsBack));
    }
    setRedirectToStep(3);
  };

  const handleStep = (step) => {
    setActiveStep(step);
    setReportsToSend(getSelectedFilesArray());
    dispatch(setTransmitSurveyStep(step));
  };

  if (!orderFiles.length > 0) {
    history.goBack();
  }

  return (
    <Fragment>
      <Layout>
        <LoadingPage
          isProccesing={isLoading}
          loadingText={"Processing your request ..."}
        />
        <StepperWrapper
          stepsArray={steps}
          currentStepIndex={activeStep}
          stepToRedirectIndex={redirectToStep}
          lastAvailableStep={calculateLastStepIndex()}
          setCurrentStepIndexHandler={handleStep}
          nextCallbackHandler={handleNext}
          isNextStepDisabledHandler={isDisabledHandler}
          breadcrumbText="Dashboard"
          breadcrumbRoute={PATHS.LANDING_PAGE}
        >
          {getStepContent()}
        </StepperWrapper>
      </Layout>
    </Fragment>
  );
};
export default ReportingSharingPage;
