import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  privacyPolicy: {
    latestVersion: null,
    acceptedVersion: null,
    acceptedDate: null,
    agreementHtml: null
  },
  termsAndConditions: {
    latestVersion: null,
    acceptedVersion: null,
    acceptedDate: null,
    agreementHtml: null
  },
  hipaaAuthorization: {
    latestVersion: null,
    acceptedVersion: null,
    acceptedDate: null,
    agreementHtml: null
  }
};

export const TermsOfServiceSlice = createSlice({
  name: "termsOfService",
  initialState,
  reducers: {
    setUserAgreementVersionAcceptanceStates: (state, { payload }) => {
      if (payload.data.agreements) {
        const privacy = payload.data.agreements.find((agreement) => {
          return agreement.name === "privacyPolicy";
        });
        const terms = payload.data.agreements.find((agreement) => {
          return agreement.name === "termsConditions";
        });
        const hipaa = payload.data.agreements.find((agreement) => {
          return agreement.name === "HIPAA";
        });
        state.privacyPolicy.latestVersion = privacy.latestVersion;
        state.privacyPolicy.acceptedVersion = privacy.acceptedVersion;
        state.privacyPolicy.acceptedDate = privacy.acceptedDate;

        state.termsAndConditions.latestVersion = terms.latestVersion;
        state.termsAndConditions.acceptedVersion = terms.acceptedVersion;
        state.termsAndConditions.acceptedDate = terms.acceptedDate;

        state.hipaaAuthorization.latestVersion = hipaa.latestVersion;
        state.hipaaAuthorization.acceptedVersion = hipaa.acceptedVersion;
        state.hipaaAuthorization.acceptedDate = hipaa.acceptedDate;
      }
    },
    setUserAgreementHtmlFromApiReponse(state, { payload }) {
      // 🤮 - Fix all this when next we change how agreements work.
      const htmlByKey = payload.data[0].value.reduce(
        (resultStructure, docHtmlStructure) => {
          resultStructure[docHtmlStructure.name] = docHtmlStructure.text;
          return resultStructure;
        },
        {}
      );
      const versionByKey = payload.data[0].value.reduce(
        (resultStructure, docHtmlStructure) => {
          resultStructure[docHtmlStructure.name] = docHtmlStructure.version;
          return resultStructure;
        },
        {}
      );
      state.privacyPolicy.agreementHtml = htmlByKey.policy;
      state.privacyPolicy.latestVersion = versionByKey.policy;
      state.termsAndConditions.agreementHtml = htmlByKey.terms;
      state.termsAndConditions.latestVersion = versionByKey.terms;
      state.hipaaAuthorization.agreementHtml = htmlByKey.hipaa;
      state.hipaaAuthorization.latestVersion = versionByKey.hipaa;
    },

    reset: (state) => (state = initialState)
  }
});

export const {
  setUserAgreementVersionAcceptanceStates,
  setUserAgreementHtmlFromApiReponse,
  reset
} = TermsOfServiceSlice.actions;
export const selectUserAgreementData = (state) => state.termsOfService;
// I saw a bug where null latest and null accepted matched and read
//  as all accepted, so I'm checking that they exist AND match.
export const selectHasAcceptedCurrentPrivacyPolicy = (state) =>
  state.termsOfService.privacyPolicy.latestVersion &&
  state.termsOfService.privacyPolicy.latestVersion ===
    state.termsOfService.privacyPolicy.acceptedVersion;
export const selectHasAcceptedCurrentTermsAndConditions = (state) =>
  state.termsOfService.termsAndConditions.latestVersion &&
  state.termsOfService.termsAndConditions.latestVersion ===
    state.termsOfService.termsAndConditions.acceptedVersion;
export const selectHasAcceptedCurrentHipaaAuthorization = (state) =>
  state.termsOfService.hipaaAuthorization.latestVersion &&
  state.termsOfService.hipaaAuthorization.latestVersion ===
    state.termsOfService.hipaaAuthorization.acceptedVersion;
export const selectUserHasAcceptedAllCurrentAgreements = (state) =>
  state.termsOfService.hipaaAuthorization.latestVersion &&
  state.termsOfService.hipaaAuthorization.latestVersion ===
    state.termsOfService.hipaaAuthorization.acceptedVersion &&
  state.termsOfService.termsAndConditions.latestVersion &&
  state.termsOfService.termsAndConditions.latestVersion ===
    state.termsOfService.termsAndConditions.acceptedVersion &&
  state.termsOfService.privacyPolicy.latestVersion &&
  state.termsOfService.privacyPolicy.latestVersion ===
    state.termsOfService.privacyPolicy.acceptedVersion;

export default TermsOfServiceSlice.reducer;
