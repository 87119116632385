import React, { Fragment } from "react";
import ScreeningCardsInfoStyles from "./ScreeningCardsInfo.styles";
import { Grid, Typography } from "@mui/material";
import Translate from "../../../../translate/Translate";
import HexagonFamilyIcon from "../../../../assets/images/hexagonFamilyIcon.svg";
import HexagonMonitorIcon from "../../../../assets/images/hexagonMonitorIcon.svg";
import PageTitle from "../../../../utils/PageTitle";

const ScreeningCardsInfo = () => {
  const classes = ScreeningCardsInfoStyles();

  PageTitle("Invite Spouse");

  return (
    <Fragment>
      <Typography variant="h2" className={classes.mainTitle2} name="title">
        <Translate text="Why should your spouse also get a screening?" />
      </Typography>
      <Grid item className={classes.containerInfo}>
        <Typography variant="h2" className={classes.mainTitle} name="title">
          <Translate text="Why should your spouse also get a screening?" />
        </Typography>
        <Grid item className={classes.cardInfo}>
          <Grid item xs={3} className={classes.imgContent}>
            <img src={HexagonMonitorIcon} alt="Hexagon Monitor Icon" />
          </Grid>
          <Grid item xs={9} className={classes.textContentFirst}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                className={classes.title}
                name="card-title-1"
              >
                <Translate text="Preventative Health Measures" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subTitle}>
                <Translate text="Genomic Life believes that affordable clinical-grade genomic testing should be available to everyone, providing peace of mind and improved health." />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.cardInfo}>
          <Grid item xs={3} className={classes.imgContent}>
            <img src={HexagonFamilyIcon} alt="Hexagon Family Icon" />
          </Grid>
          <Grid item xs={9} className={classes.textContentSec}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                className={classes.title}
                name="card-title-2"
              >
                <Translate text="Your Family’s Future" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.subTitle}>
                <Translate text="Protect yourself and your family with advanced cancer support. Both you and your family will have access to our team for cancer related questions, concerns, risk-mitigation strategies, and caregiving advice." />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ScreeningCardsInfo;
