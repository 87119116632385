import Translate from "../../../../../../../translate/Translate";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PATHS } from "../../../../../../../constants/pathConstants";
import ToDoListCardContainer from "../../ToDoListCardContainer";
import GLButton from "../../../../../sharedComponents/GLButton";
import { selectAllPreviousSurveyAnswers } from "../../../../../../../store/reducers/survey/SurveySlice";
import inviteSpouseIcon from "../../../../../../../assets/icons/icon-getting-started.svg";
import { hasUserCompletedEnoughOfGettingStartedSurvey } from "../../../../../Surveys/Utilities/util";
import Mixpanel from "../../../../../../../utils/mixpanel";
import { selectUserPersonalInformation } from "../../../../../../../store/reducers/user/UserAccountSlice";

const GetStartedToDoCard = () => {
  const history = useHistory();
  const previousSurveyAnswers = useSelector(selectAllPreviousSurveyAnswers);
  const isMemberActive = useSelector(selectUserPersonalInformation)
    .isMemberActive;

  if (hasUserCompletedEnoughOfGettingStartedSurvey(previousSurveyAnswers)) {
    return null;
  }

  const renderButton = () => {
    return (
      <GLButton
        text={"Start"}
        size={"small"}
        disabled={!isMemberActive}
        onClick={() => {
          Mixpanel.track(`To Do Card CTA Clicked - Get started survey`);
          history.push(PATHS.GETTING_STARTED_SURVEY);
        }}
      />
    );
  };

  return (
    <ToDoListCardContainer
      icon={inviteSpouseIcon}
      title={<Translate text={"Get started"} />}
      body={
        <Translate
          text={"Access your personal recommendations and next steps."}
        />
      }
      buttons={renderButton()}
    ></ToDoListCardContainer>
  );
};

export default GetStartedToDoCard;
