import React from "react";
import SpinnerStyles from "./Spinner.styles";

const Spinner = (props) => {
  const classes = SpinnerStyles(props);

  return (
    <svg
      data-testid={"spinner-icon"}
      className={`${classes.spinner} MuiSvgIcon-root MuiSvgIcon-fontSizeSmall spinner-icon`}
      viewBox="0 0 50 50"
    >
      <circle
        className={classes.path}
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      ></circle>
    </svg>
  );
};

export default Spinner;
