import { makeStyles } from "@mui/styles";
import {
  FLEX_CONSTANTS,
  GENERIC_CONSTANTS
} from "../../../constants/StyleConstants";

const StepBoxStyles = makeStyles((theme) => ({
  body: {
    display: FLEX_CONSTANTS.FLEX,
    width: 304,
    minHeight: 111,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.white,
    alignItems: FLEX_CONSTANTS.FLEX_START,
    justifyContent: FLEX_CONSTANTS.CENTER,
    border: theme.card.borderDarker,
    "&:hover": {
      border: "2px solid",
      borderColor: theme.palette.secondary.gray
    },
    cursor: GENERIC_CONSTANTS.POINTER
  },
  stepIndicator: {
    width: 28,
    height: 25,
    borderRadius: "50%",
    position: GENERIC_CONSTANTS.RELATIVE,
    right: -14,
    top: 41,
    backgroundColor: theme.palette.primary.white,
    alignItems: FLEX_CONSTANTS.FLEX_START,
    justifyContent: FLEX_CONSTANTS.CENTER,
    border: theme.card.borderDarker,
    color: theme.palette.secondary.gray_25
  },
  stepIndicatorHidden: {
    width: 28,
    height: 28,
    position: GENERIC_CONSTANTS.RELATIVE,
    right: -14,
    top: 41,
    alignItems: FLEX_CONSTANTS.FLEX_START,
    justifyContent: FLEX_CONSTANTS.CENTER
  },
  titleContainer: {
    marginTop: theme.paddings.xs
  },
  iconContainer: {
    marginTop: theme.paddings.xs,
    marginLeft: theme.paddings.xs + 3
  },
  descriptionContainer: {
    marginTop: theme.paddings.xs,
    marginLeft: theme.paddings.sm,
    marginBottom: theme.paddings.xs,
    color: theme.palette.primary.black_75
  },
  stepContainer: {
    display: FLEX_CONSTANTS.FLEX,
    flex: 1,
    alignItems: FLEX_CONSTANTS.CENTER,
    marginTop: 3,
    justifyContent: FLEX_CONSTANTS.CENTER
  },
  stepSelected: {
    border: "2px solid",
    borderColor: theme.palette.info.main
  },
  stepSelectedIndicator: {
    border: "2px solid",
    color: theme.palette.info.main
  },
  stepSuccess: {
    backgroundColor: theme.palette.success.light,
    border: "2px solid",
    borderColor: theme.palette.success.main
  }
}));

export default StepBoxStyles;
