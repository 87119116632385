import React from "react";
import { ENVIRONMENT_CONSTANTS } from "../../../../constants/environmentConstants";
import { Typography, Link, Grid } from "@mui/material";
import iconContactUsMailSecondary from "../../../../assets/images/mail_dark.svg";
import Translate from "../../../../translate/Translate";
import SupportContactInfoBlockStyles from "./SupportContactinfoBlock.styles";

const SupportContactInfoBlock = (props) => {
  const MEMBER_SERVICES_EMAIL =
    process.env[
      ENVIRONMENT_CONSTANTS.REACT_APP_MEMBER_PORTAL_MEMBER_SERVICES_EMAIL
    ];
  const classes = SupportContactInfoBlockStyles();

  return <>
    <Grid container direction={"column"} className={classes.contactLinks}>
      <Grid item className={classes.contactLink}></Grid>
      <Grid item className={classes.contactLink}>
        <Typography>
          <Link href={`mailto: ${MEMBER_SERVICES_EMAIL}`} underline="hover">
            <img
              src={iconContactUsMailSecondary}
              className={classes.icon}
              alt="contact us mail icon"
              style={{ color: "blue" }}
            />
            {MEMBER_SERVICES_EMAIL}
          </Link>
        </Typography>
        <Typography className={classes.emailSubheader}>
          <Translate
            text={
              "To ensure your privacy and security, it's best to avoid sending any medical information via email."
            }
          />
        </Typography>
      </Grid>
    </Grid>
  </>;
};

export default SupportContactInfoBlock;
