import React, { Fragment, useState } from "react";
import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";
import AsperatoPaymentFormStyles from "./AsperatoPaymentForm.styles";
import Translate from "../../../../../translate/Translate";
import LoadingBarsGif from "../../../../../assets/images/LoadingBars.gif";
import { LABELS } from "../../../../../constants/labelConstants";

const AsperatoPaymentForm = (props) => {
  const { handleClose, formUrl, formTitle } = props;
  const [isLoading, setIsLoading] = useState(true);
  const classes = AsperatoPaymentFormStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));

  const waitHandler = () => {
    setTimeout(() => {
      setIsLoading(!isLoading);
    }, 1000);
  };
  const spinnerHandler = () => {
    if (isLoading) {
      return (
        <Grid item xs={12} className={classes.loadingContainer}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={LoadingBarsGif}
              style={
                smDown ? { width: 80, height: 39 } : { width: 160, height: 78 }
              }
              alt={`${LABELS.LABEL_CANCER_GUARDIAN_LOGO_ALT_TEXT}`}
            />
          </Grid>
        </Grid>
      );
    }
    return null;
  };
  return (
    <Fragment>
      <Grid
        container
        xs={11}
        sm={10}
        md={4}
        className={classes.modalContainer}
        id="modal-container"
      >
        <Grid container className={classes.modalTitleContainer}>
          <Grid item xs={10} md={10}>
            <Typography variant="h3" className={classes.title}>
              <Translate text={formTitle} />
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={2}
            md={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Close onClick={handleClose} className={classes.icon} />
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          className={classes.modalBodyContainer}
        >
          {spinnerHandler()}
          <Grid item xs={12}>
            {formUrl ? (
              <iframe
                className={classes.form}
                title="asperatoForm"
                src={formUrl}
                style={{ display: isLoading ? "none" : null }}
                width={"100%"}
                height={700}
                frameBorder="0"
                onLoad={() => {
                  waitHandler();
                }}
              ></iframe>
            ) : null}
          </Grid>
        </Grid>
        <Grid container className={classes.modalFooterContainer}></Grid>
      </Grid>
    </Fragment>
  );
};

export default AsperatoPaymentForm;
