import { makeStyles } from "@mui/styles";

const OptionWithIconCardStyles = makeStyles((theme) => ({
  linkContainer: { textDecoration: "none" },
  container: {
    background: "white",
    padding: 25,
    borderRadius: 10,
    border: "1px solid rgba(0, 0, 0, 0.15)",
    minHeight: 260,
    color: theme.palette.primary.font_blue
  },
  icon: { height: 75 },
  header: { fontWeight: 750, marginTop: 20 },
  text: { marginTop: 20 }
}));

export default OptionWithIconCardStyles;
