import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from "@mui/material";
import Translate from "../../../../../translate/Translate";
import { SharedFormFieldStyles } from "../../Form.styles";
import GLCheckboxFieldStyles from "./GLCheckboxField.styles";
import { defaultSizingProps, extractSizingProps } from "../../FormUtils";

// There is a lot of bizwack in here around validation to support requests
//  from design. They want the field not to light up invalid until a user
//  has focused and defocused it, but they still want the save button
//  disabled. So the validity we report to the form that drives the button
//  disable is more strict than the validty the field itself shows, since
//  it may not show invalid if you are working on it or have not focused it before.
//  It's brittle. Be careful with it.

const GLCheckboxField = (props) => {
  const { label, id, checked, required, onChange, error, disabled } = props;
  const [hasReceivedFocus, setHasReceivedFocus] = useState(false);

  useEffect(() => {
    onChange({
      e: { target: { name: id, value: checked } },
      isInvalid: required && !checked
    });
  }, [hasReceivedFocus]); // eslint-disable-line
  // I want this hook only dependent on that one var so that it only
  //  fires when the form loads and if the input is ever focused

  const sharedFormClasses = SharedFormFieldStyles();
  const checkboxClasses = GLCheckboxFieldStyles();

  const errorMessage = !!error ? (
    <FormHelperText
      className={`${sharedFormClasses.textError} ${checkboxClasses.textError}`}
    >
      <Translate text={error} />
    </FormHelperText>
  ) : null;

  const handleChange = () => {
    onChange({
      e: { target: { name: id, value: !checked } },
      isInvalid: required && checked
    });
  };

  return (
    <Grid
      item
      className={checkboxClasses.checkboxContainer}
      {...extractSizingProps(props)}
    >
      <FormControlLabel
        value={checked}
        control={
          <Checkbox
            size="small"
            color="secondary"
            name={id}
            id={id}
            onChange={handleChange}
            checked={checked}
            className={error ? sharedFormClasses.textError : ""}
            disabled={disabled}
            onBlur={() => {
              setHasReceivedFocus(true);
            }}
          />
        }
        label={label}
        labelPlacement="end"
      />
      {errorMessage}
    </Grid>
  );
};

GLCheckboxField.defaultProps = defaultSizingProps;

export default GLCheckboxField;

GLCheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool
};
