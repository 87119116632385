import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../constants/v2/colors";

const TestResultDocumentPageStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "60px",
    paddingBottom: "50px"
  },
  docContainer: {
    [theme.breakpoints.up("xl")]: {
      maxWidth: "1600px",
      width: "1600px",
      height: "2124px"
    },
    [theme.breakpoints.down("xl")]: {
      maxWidth: "930px",
      width: "930px",
      height: "1260px"
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "750px",
      width: "750px",
      height: "1027px"
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "670px",
      height: "924px"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "360px",
      height: "524px"
    },
    marginTop: "50px",
    border: "1px solid #cccccc",
    borderRadius: "10px",
    padding: "10px"
  },
  loading: {
    height: "100%"
  },
  buttonIconBack: {
    margin: "0px 15px 2px 0px"
  },
  buttonIconForward: {
    margin: "0px 0px 2px 15px"
  },
  pageButton: {
    padding: "0 15px"
  }
}));

export default TestResultDocumentPageStyles;
