import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const TransmissionReasonManagmentStepStyles = makeStyles((theme) => ({
  connectionContainer: {
    marginTop: theme.paddings.xxl,
    marginBottom: theme.paddings.md
  },
  titleText: {
    color: COLOR_CONSTANTS.COLOR_TITLES
  },
  contactInformationCard: {
    marginTop: theme.paddings.sm
  }
}));

export default TransmissionReasonManagmentStepStyles;
