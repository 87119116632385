import { makeStyles } from "@mui/styles";

const TestKitSelectionPageStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.paddings.md,
    marginBottom: theme.paddings.md
  },
  requestScreeningTitle: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 50,
      marginRight: 40
    }
  },
  selectedChipText: {
    ...theme.typography.subtitleHeavy,
    color: theme.palette.primary.main
  },
  selectedChip: {
    cursor: "pointer",
    borderRadius: 10,
    backgroundColor: theme.palette.primary.chip_blue,
    [theme.breakpoints.down('lg')]: {
      height: 42,
      width: 117
    },
    [theme.breakpoints.up("md")]: {
      height: 42,
      width: 217
    }
  },
  unSelectedChip: {
    cursor: "pointer",
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.down('lg')]: {
      height: 42,
      width: 117
    },
    [theme.breakpoints.up("md")]: {
      height: 42,
      width: 217
    }
  },
  unSelectedChipText: {
    ...theme.typography.subtitle1,
    color: theme.palette.primary.brand_blue
  },
  buttonsContainer: {
    marginBottom: 50
  },
  productCardContainer: {
    paddingBottom: 112
  },
  productCardContainerAlone: {
    paddingBottom: 112,
    marginTop: 100
  },
  productCard: {
    margin: theme.paddings.xl
  },
  standAloneProductCard: {
    marginTop: theme.paddings.xl * 2.7,
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.paddings.xl * 2.6
    }
  },
  bundleContainer: {
    marginBottom: 100
  },

  orderKitBar: { position: "fixed", bottom: 0 },
  stickyContainer: {
    marginTop: theme.paddings.xl
  }
}));

export default TestKitSelectionPageStyles;
