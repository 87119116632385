import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import ArticleStyles from "./Article.styles";
import ContentResolver from "../ContentResolver";
import GutterCard from "../GutterCard";

const Article = (props) => {
  const { content, title, summary, showGutter, gutterElements } = props;
  const classes = ArticleStyles();
  const theme = useTheme();
  const screenIsSmallOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

  const renderPossibleGutterCards = () => {
    const gutterCards =
      showGutter &&
      showGutter.map((gutterTag) => {
        const gutterElement = gutterElements.filter((element) => {
          return element.elementId === gutterTag;
        })[0];
        return <GutterCard text={gutterElement.text}></GutterCard>;
      });
    return gutterCards && !screenIsSmallOrSmaller ? (
      <Grid item xs={4}>
        {gutterCards}
      </Grid>
    ) : null;
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={11}>
        <Typography variant="h1" className={classes.header}>
          <ContentResolver content={title.en} />
        </Typography>
        <Typography className={classes.summary}>
          <ContentResolver content={summary.en} />
        </Typography>
      </Grid>
      <Grid item className={classes.articleContainer} xs={11} md={7}>
        <ContentResolver content={content.en} />
      </Grid>
      {renderPossibleGutterCards()}
    </Grid>
  );
};

export default Article;
