import React from "react";
import PropTypes from "prop-types";
import { Grid, Modal, Fade, Typography, Button } from "@mui/material";
import ModalMessageWrapperStyles from "./ModalMessageWrapper.styles";
import { Close } from "@mui/icons-material";
import Translate from "../../../../translate/Translate";

const ModalMessageWrapper = (props) => {
  const {
    modalTitle,
    modalVisibilityHandler,
    isOpen,
    modalBodyMessage,
    dissmisButtonMessage,
    secondaryActionForButton,
    canBeClickedOut
  } = props;
  const classes = ModalMessageWrapperStyles();

  const renderModalBodyContent = () => {
    if (modalBodyMessage) {
      return (
        <Typography className={classes.modalMessage}>
          <Translate id="modal-message-text" text={modalBodyMessage} />
        </Typography>
      );
    }
    if (props.children) {
      return props.children;
    }
    return null;
  };

  const dissmisButtonActionHandler = () => {
    if (secondaryActionForButton) {
      return () => {
        secondaryActionForButton();
        modalVisibilityHandler();
      };
    }
    return modalVisibilityHandler();
  };

  return (
    <Modal
      id="modal-message"
      open={isOpen}
      onClose={canBeClickedOut ? modalVisibilityHandler : null}
      aria-labelledby="title-modal"
      aria-describedby="simple-modal-description"
      className={classes.modal}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Grid item xs={11} sm={8} md={6} className={classes.modalContainer}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={9}>
              <Typography className={classes.modalTitle}>
                <Translate id="modal-message-title" text={modalTitle} />
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignContent="center" justifyContent="flex-end">
                <Close
                  onClick={modalVisibilityHandler}
                  className={classes.icon}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                id="modal-message-body"
                container
                justifyContent="center"
                alignItems="center"
                className={classes.bodyContainer}
              >
                {renderModalBodyContent()}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="flex-end"
                className={classes.bodyContainer}
              >
                <Button
                  id="modal-message-button"
                  color="primary"
                  variant="contained"
                  onClick={() => dissmisButtonActionHandler()}
                >
                  <Translate
                    text={
                      dissmisButtonMessage ? dissmisButtonMessage : "Dissmis"
                    }
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ModalMessageWrapper;

ModalMessageWrapper.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modalVisibilityHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  timeoutTimeInMiliseconds: PropTypes.number,
  modalBodyMessage: PropTypes.string,
  dissmisButtonMessage: PropTypes.string,
  secondaryActionForButton: PropTypes.func,
  canBeClickedOut: PropTypes.bool
};
