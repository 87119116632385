import { makeStyles } from "@mui/styles";

const ConnectionsPageStyles = makeStyles((theme) => ({
  boxTitles: {
    marginTop: theme.margins.xxl,
    marginBottom: theme.margins.lg,
    [theme.breakpoints.down(475)]: {
      margin: theme.margins.lg
    }
  },
  connectionTitle: {
    marginBottom: theme.margins.md
  },
  connectionSubtitle: {
    marginBottom: theme.margins.lg
  },
  addConCard: {
    marginBottom: theme.margins.sm
  },
  iconFontSize: {
    [theme.breakpoints.up("md")]: {
      fontSize: 38
    }
  }
}));

export default ConnectionsPageStyles;
