import Translate from "../../../../../../../translate/Translate";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ToDoListCardContainer from "../../ToDoListCardContainer";
import chatWithOncologyIcon from "../../../../../../../assets/icons/icon-chat-with-oncology-navigator.svg";
import NeedSupportModal from "../../../../../sharedComponents/Modals/NeedSupportModal";
import GLButton from "../../../../../sharedComponents/GLButton";
import { selectAllPreviousSurveyAnswers } from "../../../../../../../store/reducers/survey/SurveySlice";
import { selectSubsetOfAnswersFromSurveyAnswers } from "../../../../../Surveys/Utilities/util";
import Mixpanel from "../../../../../../../utils/mixpanel";

const ContactOncologyToDoCard = () => {
  const [supportModalIsOpen, setSupportModalIsOpen] = useState(false);
  const previousSurveyAnswers = useSelector(selectAllPreviousSurveyAnswers);

  const relevantSurveyAnswers = selectSubsetOfAnswersFromSurveyAnswers(
    previousSurveyAnswers,
    ["active-cancer-diagnosis"]
  );

  if (relevantSurveyAnswers["active-cancer-diagnosis"] !== true) {
    return null;
  }

  const body = (
    <div
      dangerouslySetInnerHTML={{
        __html: `Thank you for sharing about your <strong>cancer history</strong>. Our recommendation is to chat with a oncology navigator to access the personalized services you deserve. We look forward to meeting you!`
      }}
    />
  );

  const renderButton = () => {
    return (
      <GLButton
        text={"Contact Us"}
        size={"small"}
        onClick={() => {
          Mixpanel.track(`To Do Card CTA Clicked - Contact oncology`);
          setSupportModalIsOpen(true);
        }}
      />
    );
  };

  return (
    <>
      <ToDoListCardContainer
        icon={chatWithOncologyIcon}
        title={<Translate text={"Chat with your oncology navigator"} />}
        body={body}
        buttons={renderButton()}
      ></ToDoListCardContainer>
      <NeedSupportModal
        headerText={"Contact us"}
        isOpen={supportModalIsOpen}
        onClose={() => setSupportModalIsOpen(false)}
      />
    </>
  );
};

export default ContactOncologyToDoCard;
