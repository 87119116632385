import { makeStyles } from "@mui/styles";
import { GENERIC_CONSTANTS } from "../../../../../constants/StyleConstants";

const AsperatoPaymentFormStyles = makeStyles((theme) => ({
  modalContainer: {
    borderRadius: 10,
    padding: 25,

    [theme.breakpoints.down(375)]: {
      minHeight: 481
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: 481
    },
    [theme.breakpoints.up("md")]: {
      minHeight: 439
    },
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  },
  modalTitleContainer: {
    height: `15%`,
    marginBottom: theme.paddings.sm
  },
  title: {
    fontSize: `24px !important`,
    color: theme.palette.primary.link,
    lineHeight: `28px`
  },
  bodyText: {
    lineHeight: 22,
    paddingBottom: 20
  },
  icon: {
    paddingLeft: 30,
    cursor: `pointer`,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 30
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 60
    }
  },
  modalBodyContainer: {
    height: `75%`
  },
  modalFooterContainer: {
    height: `10%`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    [theme.breakpoints.down('md')]: {
      justifyContent: "center",
      alignItems: "center"
    }
  },
  dismissBtn: {
    color: theme.palette.primary.cancel_button_text
  },
  helpIcon: {
    color: theme.palette.primary.link,
    fontSize: 30
  },
  addressTitle: {
    color: theme.palette.primary.black,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 24,
    [theme.breakpoints.up("md")]: {
      width: 461,
      height: 19,
      left: 33,
      top: 80
    },
    [theme.breakpoints.down('md')]: {
      width: 295,
      height: 40,
      left: 40,
      top: 207
    }
  },
  textAddress: {
    display: `flex`,
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 28,
    alignItems: `center`,
    [theme.breakpoints.up("md")]: {
      width: 177,
      height: 47,
      left: 176,
      top: 139
    },
    [theme.breakpoints.down('md')]: {
      width: 179,
      height: 47,
      left: 98,
      top: 287
    }
  },
  firstInfo: {
    display: `flex`,
    border: `2px solid ${theme.palette.secondary.gray_60}`,
    boxSizing: `border-box`,
    borderRadius: 5,
    justifyContent: `center`,
    alignItems: `center`,
    [theme.breakpoints.up("md")]: {
      width: 462,
      height: 87,
      left: 33,
      top: 119
    },
    [theme.breakpoints.down('md')]: {
      width: 315,
      height: 87,
      left: 30,
      top: 267
    }
  },
  secondInfo: {
    background: theme.palette.primary.blue_light,
    border: `1px solid ${theme.palette.primary.link}`,
    borderRadius: 4,
    boxSizing: `border-box`,
    padding: `18px 15px 19px 18px`,
    [theme.breakpoints.up("md")]: {
      width: 462,
      height: 126,
      left: 33,
      top: 352
    },
    [theme.breakpoints.down('md')]: {
      width: 315,
      height: 138,
      left: 30,
      top: 512
    },
    form: { border: GENERIC_CONSTANTS.NONE },
    loadingContainer: {
      [theme.breakpoints.down(375)]: {
        minHeight: 481
      },
      [theme.breakpoints.down('lg')]: {
        minHeight: 481
      },
      [theme.breakpoints.up("md")]: {
        minHeight: 439
      }
    },
    loadingImage: { width: 160, height: 78 },
    loadingTitle: { marginTop: theme.paddings.md }
  }
}));

export default AsperatoPaymentFormStyles;
