/**
 * @function openWebBroserPage
 * @param {url} url
 * @description You can open a new broser web page
 */
export const openWebBrowserPage = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) {
    newWindow.opener = null;
  }
};
