import { makeStyles } from "@mui/styles";
import { FONT_CONSTANTS } from "../../../constants/StyleConstants";

const HeaderStyles = makeStyles((theme) => ({
  appBar: {
    fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_BLACK,
    fontSize: `12px`,
    lineHeight: `40px`
  },
  links: {
    textDecoration: "none",
    textTransform: `uppercase`,
    fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_HEAVY,
    color: theme.palette.primary.main,
    fontSize: 14,
    lineHeight: `20px`,
    letterSpacing: `0.75px`,
    "&:hover": {
      textDecoration: "none"
    }
  },
  activeLink: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 20,
    lineHeight: `20px`
  },
  menu: {
    justifyContent: "right"
  },
  logoutButton: {
    textDecoration: "none",
    textTransform: `uppercase`,
    fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_HEAVY,
    fontSize: 14,
    color: theme.palette.error.main,
    lineHeight: `20px`,
    letterSpacing: `0.75px`,
    "&:hover": {
      textDecoration: "none"
    },
    [theme.breakpoints.between(960, 1060)]: {
      fontSize: 12,
      letterSpacing: `0.50px`
    }
  },
  headerLogo: {
    maxWidth: `127px`,
    maxHeight: `30px`,
    marginLeft: `17px`,
    marginTop: "8px"
  },
  headerLogoContainer: {
    marginRight: 20
  },
  centerImage: {
    display: `flex`,
    alignItems: "center"
  }
}));

export default HeaderStyles;
