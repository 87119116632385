import { makeStyles } from "@mui/styles";

const QuoteCarouselStyles = makeStyles((theme) => ({
  container: {
    padding: "20px 60px 40px",
    color: "black",
    overflow: "visible"
  },
  quoteContainer: {
    padding: "20px 15px 0 15px",
    position: "relative"
  },
  quotesIcon: { position: "absolute", top: 0, left: 0 },
  header: {
    marginBottom: 35,
    fontSize: 25,
    lineHeight: "35px",
    fontFamily: "AvenirLTProBook",
    fontWeight: 500
  },
  quoth: {
    fontSize: 18,
    fontWeight: 700,
    marginTop: 35
  },
  relationToGL: {
    fontSize: 12,
    marginTop: 3,
    color: "rgba(0,0,0,0.5)"
  }
}));

export default QuoteCarouselStyles;
