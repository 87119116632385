import { makeStyles } from "@mui/styles";

const ModalComponentStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default ModalComponentStyles;
