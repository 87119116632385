import { makeStyles } from "@mui/styles";

const ModalMessageWrapperStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalTitle: {
    ...theme.typography.h5,
    color: theme.palette.primary.brand_blue
  },
  icon: {
    paddingLeft: 30,
    cursor: `pointer`,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 30
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 60
    }
  },
  bodyContainer: {
    marginTop: theme.paddings.lg
  },
  modalMessage: {
    ...theme.typography.subtitle1
  },
  modalContainer: {
    borderRadius: 10,
    padding: 25,
    [theme.breakpoints.down(375)]: {
      width: `345px`,
      maxHeight: `642px`
    },
    [theme.breakpoints.down('lg')]: {
      width: `345px`,
      maxHeight: `642px`
    },
    [theme.breakpoints.up("md")]: {
      width: `525px`,
      maxHeight: `558px`
    },

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  }
}));
export default ModalMessageWrapperStyles;
