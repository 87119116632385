import { makeStyles } from "@mui/styles";

const ForgotPasswordSuccessStyles = makeStyles((theme) => ({
  img: {
    marginTop: 50,
    height: 61
  },
  title: {
    marginTop: 40,
    fontSize: 24,
    fontFamily: "DomaineDisplaySemibold",
    fontStyle: "normal",
    fontWeight: `600`
  },
  successContainer: {
    marginTop: 150,
    width: `300px`,
    height: `405px`,
    borderRadius: `10px`
  },
  successMessage: {
    textAlign: `center`
  },
  avatar: {
    width: `45px`,
    height: `45px`,
    backgroundColor: theme.palette.success.main,
    marginBottom: 30,
    marginTop: 56,
    animation: `$fade .5s `
  },
  "@keyframes fade": {
    from: { transform: `scale(0)` },
    to: { transform: `scale(1)` }
  },
  icon: {
    fontSize: 30
  },
  BackToLogin: {
    fontFamily: "AvenirLTProMedium",
    fontWeight: `500`,
    fontSize: `13px`,
    marginTop: 130,
    textDecoration: `none`,
    color: theme.palette.primary.main
  }
}));

export default ForgotPasswordSuccessStyles;
