import React from "react";

import { PATHS } from "../../../../constants/pathConstants";
import { useHistory } from "react-router-dom";
import SurveyWrapper from "../SurveyComponents/SurveyWrapper";
import { useSelector } from "react-redux";
import { selectKitsSelectedToBeOrdered } from "../../../../store/reducers/order/OrderInformationSlice";

const SURVEY_ID = "informed-consent-survey";

const hardcodedSurveyJson = null;

const InformedConsentSurvey = (props) => {
  const history = useHistory();
  const kitsSelectedToBeOrdered = useSelector(selectKitsSelectedToBeOrdered);
  const handleOnComplete = () => {
    history.push(PATHS.ORDER_KIT);
  };

  const handleOnSurveyInstantiated = (surveyModel) => {
    kitsSelectedToBeOrdered.forEach((kit_uuid) => {
      surveyModel.getQuestionByName(kit_uuid).value = true;
    });
    surveyModel.completeText = "CONTINUE";
  };

  return (
    <SurveyWrapper
      focusFirstQuestionAutomatic={false}
      surveyId={SURVEY_ID}
      onComplete={handleOnComplete}
      onSurveyInstantiated={handleOnSurveyInstantiated}
      //surveyJsonOverride={hardcodedSurveyJson}
    ></SurveyWrapper>
  );
};

export default InformedConsentSurvey;
