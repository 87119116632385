import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const RegistrationPageLayoutStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: COLOR_CONSTANTS.GENOMIC_LIFE_EXTRA_LIGHT_BLUE,
    width: "100%",
    height: "100%"
  }
}));

export default RegistrationPageLayoutStyles;
