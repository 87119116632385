import { makeStyles } from "@mui/styles";

const GLSnackbarStyles = makeStyles((theme) => ({
  container: {
    display: `flex`,
    width: 365,
    height: `auto`,
    borderRadius: 4,
    justifyContent: `center`,
    "&.header": {
      alignItems: `center`
    },
    boxSizing: `border-box`,
    margin: 5,
    boxShadow: "0px 2px 10px rgba(79, 138, 201, 0.2)",
    "&.success": {
      background: theme.palette.success.light
    },
    "&.info": {
      background: theme.palette.info.light
    },
    "&.warning": {
      background: theme.palette.warning.light
    },
    "&.error": {
      background: theme.palette.error.light
    },
    "&.security": {
      background: theme.palette.security.light
    }
  },
  fullWidth: {
    display: `flex`,
    width: "100%",
    height: `auto`,
    borderRadius: 4,

    justifyContent: `center`,
    "&.header": {
      alignItems: `center`
    },
    boxSizing: `border-box`,
    margin: 5,
    boxShadow: "0px 2px 10px rgba(79, 138, 201, 0.2)",
    "&.success": {
      background: theme.palette.success.light
    },
    "&.info": {
      background: theme.palette.info.light
    },
    "&.warning": {
      background: theme.palette.warning.light
    },
    "&.error": {
      background: theme.palette.error.light
    },
    "&.security": {
      background: theme.palette.security.light
    }
  },
  successIcon: {
    display: `flex`,
    fontSize: 30,
    color: theme.palette.success.main,
    paddingLeft: 5
  },
  infoIcon: {
    display: `flex`,
    fontSize: 30,
    color: theme.palette.info.main,
    paddingLeft: 5
  },
  warningIcon: {
    display: `flex`,
    fontSize: 18,
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.warning.main,
    borderRadius: `50%`,
    padding: 5
  },
  errorIcon: {
    display: `flex`,
    fontSize: 30,
    color: theme.palette.error.main,
    paddingLeft: 5
  },
  securityIcon: {
    display: `flex`,
    paddingLeft: 5
  },
  iconClose: {
    color: theme.palette.secondary.gray_button,
    "&:hover": {
      background: "none"
    },
    "&.header": {
      bottom: 10
    }
  },
  title: {
    fontWeight: 700
  },
  text: {
    fontWeight: 500
  },
  content: {
    marginTop: 5,
    "&.header": {
      marginTop: 0
    }
  }
}));

export default GLSnackbarStyles;
