import React from "react";
import SideScrollingMenuStyles from "./SideScrollingMenu.styles";

import { LeftArrow, RightArrow } from "./Arrows";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import usePreventBodyScroll from "../../../../hooks/usePreventBodyScroll";
import { ScrollingMenuItem } from "./ScrollingMenuItem";

// This thing feels a bit gross, it's mostly following the example of this
//  slider library. Could probably be less wonky. Feel free to tidy up.

const SideScrollingMenu = (props) => {
  const { menuItems, onSelectItem, currentlySelectedItemIndex } = props;
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const classes = SideScrollingMenuStyles();

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  return (
    <div
      onMouseEnter={disableScroll}
      onMouseLeave={enableScroll}
      className={classes.container}
    >
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        onWheel={onWheel}
      >
        {menuItems.map((menuItem, index) => {
          return (
            <ScrollingMenuItem
              title={menuItem}
              itemId={menuItem}
              key={menuItem}
              selected={currentlySelectedItemIndex === index}
              onClick={() => {
                onSelectItem(index);
              }}
            />
          );
        })}
      </ScrollMenu>
    </div>
  );
};

export default SideScrollingMenu;
