import { makeStyles } from "@mui/styles";

const BackToStyles = makeStyles((theme) => ({
  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    cursor: `pointer`,
    textDecoration: `none`
  },
  backToText: {
    color: theme.palette.info.main,
    fontSize: 14,
    marginTop: 3
  },
  backToIcon: {
    color: theme.palette.info.main,
    marginRight: 15,
    fontSize: 25
  }
}));

export default BackToStyles;
