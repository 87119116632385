import { makeStyles } from "@mui/styles";
import { FLEX_CONSTANTS } from "../../../../constants/StyleConstants";

const SecondaryHeaderStyles = makeStyles((theme) => ({
  header: {
    display: FLEX_CONSTANTS.FLEX,
    flex: 1,
    alignSelf: FLEX_CONSTANTS.CENTER,
    justifyContent: FLEX_CONSTANTS.CENTER,
    ...theme.header
  },
  headerTitle: {
    color: theme.palette.primary.white,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24
    }
  },
  container: {
    marginTop: theme.paddings.md,
    marginBottom: theme.paddings.md
  }
}));

export default SecondaryHeaderStyles;
