import { doApiCall } from "./util";
const { REACT_APP_MEMBER_PORTAL_ENDPOINT_URL } = process.env;
const url = `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`;
/**
 * @param {payload} payload transmission information required
 * @returns {Object} Promise response
 * @description This endpoint sends report ids, connection ids and survey answers to send files to the connections
 * previously set up.
 */
export const sendTransmitSurvey = (payload) => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `${url}/member/queue-reports`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};
