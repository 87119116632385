import { makeStyles } from "@mui/styles";
import {
  FLEX_CONSTANTS,
  GENERIC_CONSTANTS
} from "../../../../constants/StyleConstants";

const AccountManagementMemberInfoPageStyles = makeStyles((theme) => ({
  backToContainer: {
    display: FLEX_CONSTANTS.FLEX,
    flexDirection: FLEX_CONSTANTS.ROW,
    flex: 1,
    alignItems: FLEX_CONSTANTS.CENTER,
    marginTop: theme.paddings.sm,
    textDecoration: GENERIC_CONSTANTS.NONE,
    color: theme.palette.info.main,
    marginBottom: 60
  }
}));

export default AccountManagementMemberInfoPageStyles;
