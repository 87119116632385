import React from "react";
import { Grid, Typography } from "@mui/material";
import ProactiveHealthResourcesStyles from "./ProactiveHealthResources.styles";
import ImageCard from "../../../sharedComponents/ImageCard";
import YellowImage from "../../../../assets/images/YellowImage.svg";
import BlueImage from "../../../../assets/images/BlueImage.svg";
import CenteredContent from "../../../sharedComponents/CenteredContent";
import Translate from "../../../../translate/Translate";
import { PATHS } from "../../../../constants/pathConstants";

const ProactiveHealthResources = () => {
  const classes = ProactiveHealthResourcesStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <CenteredContent>
          <Typography
            variant="h3"
            className={classes.proactiveTitle}
            name="proactive-resources-section"
          >
            <Translate text="Proactive Health Resources" />
          </Typography>
        </CenteredContent>
      </Grid>
      <Grid item xs={12}>
        <CenteredContent>
          <Typography variant="subtitle2" className={classes.proactiveSubtitle}>
            <Translate text="Genomic insights, research, and disease-related resources." />
          </Typography>
        </CenteredContent>
      </Grid>
      <Grid className={classes.cardContent}>
        <Grid item className={classes.first}>
          <ImageCard
            img={YellowImage}
            title="WHY GENOMICS"
            redirectTo={PATHS.WHY_GENOMICS}
          />
        </Grid>
        <Grid item>
          <ImageCard
            img={BlueImage}
            title="CANCER AND GENOMICS"
            redirectTo={PATHS.CANCER_AND_GENOMICS}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProactiveHealthResources;
