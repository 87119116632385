import { makeStyles } from "@mui/styles";

export const SharedFormFieldStyles = makeStyles((theme) => ({
  inputContainer: {
    position: "relative"
  },
  textInput: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(14px, 17px) scale(1)"
    },

    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      width: "110%"
    },

    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      padding: "16px 14px 12px 14px"
    },
    "&  .MuiFormLabel-asterisk": {
      display: "none"
    },
    "& .MuiAutocomplete-inputRoot": {
      height: 45,
      paddingTop: 0
    },
    //Fixes weird horizontal scroll bar flicker when field is focused
    overflowX: "clip"
  },
  textError: {
    color: theme.palette.error.main
  },
  requiredFlag: {
    zIndex: 1,
    top: "9px",
    left: "24px",
    fontSize: "10px",
    position: "absolute",
    backgroundColor: "white",
    width: "48px",
    paddingLeft: "6px",
    color: theme.palette.primary.cancel_button_text
  },
  inputFullWidth: { width: "100%" }
}));

export const FormStyles = makeStyles((theme) => ({
  section: {
    marginTop: theme.paddings.xs,
    marginBottom: theme.paddings.sm,
    "& .MuiGrid-spacing-xs-4": {
      marginBottom: "8px"
    }
  },
  boxContainer: {
    marginBottom: theme.margins.xxl
  },

  textError: {
    color: theme.palette.error.main
  }
}));
