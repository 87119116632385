import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getAvailableUserConnections } from "../apis/connectionsApi";
//Reducers
import {
  setConnections,
  resetConnectionsArray
} from "../store/reducers/connection/ConnectionsInformationSlice";

const useFetchConnectionList = () => {
  const dispatchReducer = useDispatch();
  const loaded = useRef(false);

  useEffect(() => {
    const fetchConnectionList = async () => {
      try {
        await getAvailableUserConnections()
          .then((res) => {
            dispatchReducer(resetConnectionsArray());
            res?.data?.length > 0 &&
              res.data.forEach((element) => {
                dispatchReducer(setConnections(element));
              });
          })
          .catch((error) => {
            console.error("error fetching connections", error);
          });
        loaded.current = true;
        return true;
      } catch (error) {
        loaded.current = true;
        return error;
      }
    };
    if (!loaded.current) {
      fetchConnectionList();
    }
  }, [dispatchReducer]);
};

export default useFetchConnectionList;
