import React from "react";
import Translate from "../translate/Translate";

export const ERROR_MESSAGES = {
  ERROR_MSG_USERNAME_IS_REQUIRED: <Translate text="Username is required" />,
  ERROR_MSG_PASSWORD_IS_REQUIRED: <Translate text="Password is required" />,
  ERROR_MSG_ACCOUNT_OR_PASSWORD_INCORRECT: (
    <Translate text="Your account or password is incorrect. If you don't remember your password, reset it now." />
  ),
  ERROR_MSG_FIELD_IS_REQUIRED: <Translate text="this field is required" />,
  ERROR_MSG_NAME_ON_ACCOUNT_MUST_CONTAIN_LETTERS: (
    <Translate text="Name On Account must contain only A-Z or a-z" />
  ),
  ERROR_MSG_ROUTING_NUMBER_MUST_BE_NUMBERS: (
    <Translate text="Routing Number must contain only numbers and be less than 20 numbers" />
  ),
  ERROR_MSG_BANK_NAME_NOT_EMPTY: (
    <Translate text="Bank Name must not be empty" />
  ),
  ERROR_MSG_ACCOUNT_NUMBER_MUST_BE_NUMBERS: (
    <Translate text="Account Number must contain only numbers and be less than 20 numbers" />
  ),
  ERROR_MSG_APT_SUITE_MUST_HAVE_NO_SPACE: (
    <Translate text="Apt/Suite must not contain only empty spaces" />
  ),
  ERROR_MSG_CITY_MUT_BE_LETTERS: (
    <Translate text="City must contain only A-Z or a-z" />
  ),
  ERROR_MSG_STATE_MUST_BE_2_LETTERS: (
    <Translate text="State must contain only 2 letter uppercase abbreviation" />
  ),
  ERROR_MSG_ZIP_MUST_BE_VALID: (
    <Translate text="Zip Code must contain valid us postal code" />
  ),
  ERROR_MSG_COUNTRY_MUST_HAVE_NO_SPACE: (
    <Translate text="Country must not contain only empty spaces" />
  ),
  ERROR_MSG_CARD_HOLDER_NAME_MUST_BE_LETTERS: (
    <Translate text="Card Holder Name must contain only A-Z or a-z" />
  ),
  ERROR_MSG_CARD_NUMBER_NOT_VALID: (
    <Translate text="Card Number is not valid" />
  ),
  ERROR_MSG_CVV_INVALID: <Translate text="CVV must contain 3 to 4 numbers" />,
  ERROR_MSG_EMAIL_INVALID: (
    <Translate text="Invalid email. Check that your spelling and domain" />
  ),
  ERROR_MSG_SYSTEM_FAIL: (
    <Translate text="There was an error in our system when trying to log you in, please try again." />
  )
};
