import { makeStyles } from "@mui/styles";
import { GENERIC_CONSTANTS } from "../../../constants/StyleConstants";

const OptionCardStyles = makeStyles((theme) => ({
  optionCardContainer: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    background: theme.palette.primary.white,
    boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.14)`,
    cursor: `pointer`,
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      minWidth: 374,
      minHeight: 81,
      maxWidth: 374,
      maxHeight: 81,
      marginBottom: 10
    },
    [theme.breakpoints.between("md", 'xl')]: {
      minWidth: 546,
      minHeight: 100,
      maxWidth: 546,
      maxHeight: 100,
      marginBottom: 10
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 470,
      minHeight: 100,
      maxWidth: 470,
      maxHeight: 100,
      marginBottom: 10
    }
  },
  optionBigCardContainer: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    background: theme.palette.primary.white,
    boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.14)`,
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      minWidth: 374,
      minHeight: 81,
      maxWidth: 374,
      marginBottom: 10
    },
    [theme.breakpoints.between("md", 'xl')]: {
      minWidth: 630,
      minHeight: 81,
      maxWidth: 630,
      marginBottom: theme.margins.sm
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 630,
      minHeight: 81,
      maxWidth: 630,
      marginBottom: theme.margins.sm
    }
  },
  contactIconContainer: {
    position: "relative",
    marginBottom: 5,
    [theme.breakpoints.down('md')]: {
      right: -180
    },
    [theme.breakpoints.up("md")]: {
      right: -330
    }
  },
  optionCardIconContainer: {
    width: 58,
    height: 58,
    [theme.breakpoints.down('md')]: {
      marginLeft: 40
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 48
    }
  },
  optionCardTitleContainer: {
    marginLeft: 25,
    cursor: `pointer`
  },
  optionCardTitle: {
    fontWeight: `bold`,
    color: theme.palette.primary.blue_font,
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 20
    },
    marginBottom: theme.margins.xs
  },
  optionCardSubTitle: {
    fontWeight: `bold`,
    color: theme.palette.secondary.gray_30,
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 20
    }
  },
  optionCardNotIconTitle: {
    fontWeight: `bold`,
    color: theme.palette.primary.font_blue,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      marginLeft: 40
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
      marginLeft: 65
    }
  },
  icon: {
    marginRight: 25
  },
  secondIcon: {
    [theme.breakpoints.down('md')]: {
      marginRight: 20
    },
    [theme.breakpoints.between("md", 'xl')]: {
      marginRight: 25
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 20
    }
  },
  iconFontSize: {
    color: theme.palette.secondary.gray_button,
    [theme.breakpoints.between("md", 'xl')]: {
      fontSize: 28
    }
  },
  chip: {
    background: theme.palette.secondary.green_muted,
    color: theme.palette.primary.black,
    padding: 10
  },
  chipCard: {
    marginRight: 20
  },
  chipCardText: {
    ...theme.typography.subtitleHeavy
  },
  editButton: {
    cursor: `pointer`,
    borderRadius: 40,
    position: "relative",
    border: "1px solid",
    width: 35,
    height: 35,
    borderColor: theme.palette.primary.brand_blue
  },
  clickableElement: {
    cursor: GENERIC_CONSTANTS.POINTER
  }
}));

export default OptionCardStyles;
