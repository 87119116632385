import { withStyles, makeStyles } from "@mui/styles";
import StepConnector from "@mui/material/StepConnector";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const StepperWrapperStyles = makeStyles((theme) => ({
  rootClass: {
    "& .MuiStepLabel-label": {
      color: theme.palette.primary.font_blue,
      fontWeight: "bold",
      opacity: 0.25,
      "&$done": {
        color: theme.palette.info.main,
        fontWeight: "bold",
        opacity: 1
      },
      "&$active": {
        color: theme.palette.primary.font_blue,
        fontWeight: "bold",
        opacity: 1
      }
    },
    "& .MuiSvgIcon-root": {
      fill: COLOR_CONSTANTS.COLOR_GRAY_90,
      "&.MuiStepIcon-completed": { fill: theme.palette.primary.green }
    },
    done: {},
    "& .MuiStepIcon-text": {
      fill: theme.palette.primary.font_blue
    }
  },
  rootClassDefault: {
    "& .MuiStepLabel-label": {
      color: theme.palette.primary.font_blue,
      fontWeight: "bold",
      "&$done": { color: theme.palette.info.main, fontWeight: "bold" },
      "&$active": {
        color: theme.palette.primary.font_blue,
        fontWeight: "bold"
      }
    },
    "& .MuiSvgIcon-root": {
      fill: theme.palette.secondary.green_step,
      "&.MuiStepIcon-completed": { fill: theme.palette.primary.green }
    },
    done: {},
    "& .MuiStepIcon-text": {
      fill: theme.palette.primary.font_blue
    }
  },
  rootClassPointer: {
    "& .MuiStepLabel-label": {
      color: theme.palette.primary.font_blue,
      fontWeight: "bold",
      "&$done": { color: theme.palette.info.main, fontWeight: "bold" },
      "&$active": {
        color: theme.palette.primary.font_blue,
        fontWeight: "bold"
      },
      cursor: "pointer"
    },
    "& .MuiSvgIcon-root": {
      fill: theme.palette.secondary.green_step,
      "&.MuiStepIcon-completed": { fill: theme.palette.primary.green }
    },
    done: {},
    "& .MuiStepIcon-text": {
      fill: theme.palette.primary.font_blue
    }
  },
  done: {}, // this empty property is necessary
  active: {}, // this empty property is necessary
  boxStepper: {
    display: `flex`,
    flex: 1,
    flexDirection: "column",
    boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.07)`
  },
  stepper: {
    [theme.breakpoints.up("md")]: {
      minWidth: `60%`
    }
  },
  stepPointer: {
    cursor: "pointer"
  },
  titlebackTo: {
    display: `flex`,
    height: 40,
    marginLeft: 150
  },
  label: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  stepperCentercontainer: {
    minWidth: "60%"
  },
  buttonSection: {
    backgroundColor: COLOR_CONSTANTS.COLOR_WHITE,
    position: "fixed",
    bottom: 0
  }
}));
const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.green
    }
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.primary.green
    }
  },
  line: {
    borderColor: theme.palette.secondary.gray_30,
    borderTopWidth: 2,
    borderRadius: 1
  }
}))(StepConnector);
export { StepperWrapperStyles, QontoConnector };
