import { makeStyles } from "@mui/styles";
import {
  COLOR_CONSTANTS,
  GENERIC_CONSTANTS
} from "../../../../constants/StyleConstants";

const PaymentInformationStyles = makeStyles((theme) => ({
  paymentMethodText: {
    marginBottom: 25,
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      marginRight: 190
    },
    [theme.breakpoints.between("md", 'xl')]: {
      marginRight: 460
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: 630
    }
  },
  cardContainer: {
    marginTop: theme.paddings.sm,
    marginBottom: theme.paddings.sm,
    cursor: GENERIC_CONSTANTS.POINTER
  },
  creditCardContainer: {
    minHeight: "150px",
    [theme.breakpoints.down('md')]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: 800
    }
  },
  chipText: {
    color: COLOR_CONSTANTS.COLOR_WHITE
  },
  plusIcon: {
    color: COLOR_CONSTANTS.COLOR_TITLES
  },
  benefitsPackage: {
    ...theme.typography.subtitleHeavy,
    color: theme.palette.primary.link,
    marginTop: 20,
    marginLeft: 20
  },
  chipSuccess: {
    backgroundColor: theme.palette.primary.chip_green,
    minWidth: 113
  },
  chipInfo: {
    backgroundColor: theme.palette.info.dark,
    minWidth: 113
  },
  benefitPackageCard: {
    width: 800
  },
  benefitDescription: {
    ...theme.typography.subtitleRegular,
    color: theme.palette.secondary.gray_button,
    marginTop: 20,
    marginRight: 100,
    [theme.breakpoints.down('md')]: {
      marginLeft: 10
    }
  },
  chipBenefits: {
    fontWeight: 700
  }
}));

export default PaymentInformationStyles;
