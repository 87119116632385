import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const ResourceGridStyles = makeStyles((theme) => ({
  container: {
    padding: "0 55px 50px 55px",
    margin: "40px 0 40px",
    "& p": {
      fontSize: 18
    }
  },
  header: {
    marginBottom: 40,
    fontSize: "34px",
    lineHeight: "44px",
    color: "black"
  },
  linkContainer: {
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main
    }
  },
  cardContainer: {
    boxShadow: "0px 2.7096774578094482px 13.548386573791504px 0px #4F8AC933",
    "&:hover": {
      transitionDuration: ".5s",
      boxShadow: "0px 2.7096774578094482px 13.548386573791504px 0px #2b548169"
    },
    height: "100%",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main
    },
    color: theme.palette.primary.main,
    "& h6": {
      fontWeight: 700
    },
    borderRadius: 10
  },
  innerCardContainer: {
    padding: "30px 20px",
    height: "100%"
  },
  cardHeaderContainer: { alignSelf: "center" },
  arrow: {
    alignItems: "center",
    display: "flex",
    fontSize: "2.5rem",
    color: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY
  }
}));

export default ResourceGridStyles;
