import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import ImageAnchoredParagraphStyles from "./ImageAnchoredParagraph.styles";

const ImageAnchoredParagraph = (props) => {
  const { image, header, text, imageOnRight } = props;
  const classes = ImageAnchoredParagraphStyles();

  const imageSection = (
    <Grid item xs={4}>
      <img className={classes.anchorImage} {...image} />
    </Grid>
  );

  const languageSection = (
    <Grid item xs={8} className={classes.textContainer}>
      <Grid item xs={11}>
        <Box mt={4}>
          <Typography variant="h2" className={classes.header}>
            {header.en}
          </Typography>
          <Typography variant="subtitle1">
            <div dangerouslySetInnerHTML={{ __html: text.en }} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  const contents = imageOnRight ? (
    <>
      {languageSection}
      {imageSection}
    </>
  ) : (
    <>
      {imageSection}
      {languageSection}
    </>
  );

  return (
    <Grid container className={classes.container}>
      {contents}
    </Grid>
  );
};

export default ImageAnchoredParagraph;
