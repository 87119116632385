import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ChipRowInfoCardStyles from "./ChipRowInfoCard.styles";
import Translate from "../../../../translate/Translate";
import propTypes from "prop-types";

/*
interface ChipRowInfoCardProps {
  id: string
  className: object
  chipRowDescription: JSX.Element || string
  chipRowElements: JSX.Element || string
  leftThirdContent: JSX.Element || string
  hasNotContent?: ?boolean
}
*/

const ChipRowInfoCard = (props) => {
  const {
    id,
    className,
    chipRowDescription,
    chipRowElements,
    leftThirdContent,
    hasNotContent
  } = props;

  const classes = ChipRowInfoCardStyles(props);

  const renderChipRowDescription = () => {
    if (typeof chipRowDescription === "string") {
      return (
        <Typography variant="body1" className={classes.chipCardDescription}>
          <Translate text={chipRowDescription} />
        </Typography>
      );
    } else {
      return chipRowDescription;
    }
  };

  return (
    <Grid
      id={id}
      container
      className={`${classes.cardContainer} ${className && className}`}
    >
      {hasNotContent ? (
        <Grid item sm={12} className={classes.leftThirdContent}>
          {leftThirdContent}
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item sm={3} className={classes.leftThirdContent}>
            {leftThirdContent}
          </Grid>
          <Grid item sm={9} className={classes.chipCardSecondSection}>
            <Box className={classes.chipAndDescriptionSection}>
              <Grid item xs={12}>
                {chipRowElements}
              </Grid>
              <Grid item xs={10} />
              {renderChipRowDescription()}
            </Box>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default ChipRowInfoCard;

// eslint-disable-next-line react/no-typos
ChipRowInfoCard.propTypes = {
  id: propTypes.string.isRequired,
  className: propTypes.object,
  chipRowDescription: propTypes.any,
  chipRowElements: propTypes.object,
  leftThirdContent: propTypes.object,
  hasNotContent: propTypes.bool
};
