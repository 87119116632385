export const COLORS = {
  OCEAN: "#284993",
  OCEAN_LIGHT: "#D4DBE9",
  OCEAN_EXTRA_LIGHT: "#FAFAFD",
  AQUA: "#007580",
  FOREST: "#83A098",
  LIGHT_GRAY: "#CCCCCC",
  MEDIUM_GRAY: "#6B7280",
  DARK_GRAY: "#222222",
  LIGHT_BACKGROUND: "#F4F6F9",
  WHITE: "#FFFFFF"
};
