import { makeStyles } from "@mui/styles";

const InfoStepComponentStyles = makeStyles((theme) => ({
  content: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`
  },
  infoStep: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    flexDirection: `row`,
    width: 830,
    height: 290,
    marginTop: 20,
    [theme.breakpoints.down('lg')]: {
      flexDirection: `row`
    },
    [theme.breakpoints.down(703)]: {
      flexDirection: `column`
    }
  },
  img: {
    width: 365,
    height: 299,
    left: 573,
    top: 284,
    [theme.breakpoints.down('lg')]: {
      width: 304,
      height: 304,
      marginRight: 20
    },
    [theme.breakpoints.down(703)]: {
      width: 242,
      height: 224,
      marginRight: 0
    }
  },
  title: {
    color: theme.palette.primary.font_blue
  },
  boxTitle: {
    width: 465,
    height: 271,
    paddingTop: 30,
    [theme.breakpoints.down('lg')]: {
      width: 431,
      height: 271
    },
    [theme.breakpoints.down(703)]: {
      width: 295,
      height: 274,
      paddingTop: 0
    }
  },
  button: {
    color: theme.palette.primary.link,
    fontSize: 14,
    fontWeight: 700,
    marginTop: 40
  },
  boxButton: {
    marginBottom: 80,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`
  }
}));

export default InfoStepComponentStyles;
