import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const ChipRowInfoCardStyles = makeStyles((theme) => ({
  cardContainer: {
    background: COLOR_CONSTANTS.COLOR_WHITE,
    boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.14)`,
    borderRadius: 5,
    paddingBottom: theme.paddings.sm,
    marginBottom: 24,
    width: "100%"
  },
  chipAndDescriptionSection: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 90,
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      marginLeft: 10
    }
  },
  chipCardDescription: {
    marginTop: 25,
    marginLeft: 10
  },
  rightThirdContents: {
    width: "100%"
  },
  chipCardSecondSection: {
    width: "100%"
  }
}));

export default ChipRowInfoCardStyles;
