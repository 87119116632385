import React from "react";
import { Grid } from "@mui/material";
import OrderKitShipmentInformationStyles from "./OrderKitShipmentInformation.styles";
import { useSelector, useDispatch } from "react-redux";
import MemberShippingInformationForm from "../../../v2/Forms/MemberShippingInformationForm";
import {
  selectUserPersonalInformation,
  updateFieldsInUserPersonalInformation
} from "../../../../store/reducers/user/UserAccountSlice";
import {
  getMemberData,
  patchMemberInformation
} from "../../../../apis/membersApi";
import { openGLSnackbar } from "../../../../store/reducers/snackbar/openGLSnackbarSlice";

const OrderKitShipmentInformation = (props) => {
  const { handleNext } = props;
  const dispatcher = useDispatch();
  const classes = OrderKitShipmentInformationStyles();
  const shipmentInformation = useSelector(selectUserPersonalInformation);

  const handleClick = (newShippingInformation) => {
    patchMemberInformation(newShippingInformation)
      .then((response) => {
        if (response.status === 200) {
          dispatcher(
            openGLSnackbar({
              variant: "success",
              header: "Success",
              subText: "Shipping information was saved successfully!"
            })
          );
          getMemberData().then(({ data }) => {
            dispatcher(updateFieldsInUserPersonalInformation(data));
          });
          handleNext();
          return true;
        } else {
          dispatcher(
            openGLSnackbar({
              variant: "error",
              header: "There was an error updating your information",
              subText: response.data.statusMessage
            })
          );
        }
      })
      .catch((error) => {
        dispatcher(
          openGLSnackbar({
            variant: "error",
            header: "Error on save",
            subText:
              "Sorry, something went wrong with saving your info. Please reach out to us!"
          })
        );
        return false;
      });
  };
  return (
    <div className={classes.root}>
      <Grid
        className={classes.titleContainer}
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      ></Grid>
      <Grid container>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item xs={12} spacing={2}></Grid>
          <Grid item xs={2} lg={3}></Grid>
          <MemberShippingInformationForm
            memberShippingData={shipmentInformation}
            onSubmit={handleClick}
          />
          <Grid item xs={2} lg={3}></Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderKitShipmentInformation;
