import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import CustomizedMobileStepper from "../../sharedComponents/CustomizedMobileStepper";
import OnboardingIntroPageStyles from "./OnboardingIntroPage.styles";
import ManyHandsDNA from "../../../assets/images/ManyHandsDNA.svg";
import Group from "../../../assets/images/Group.svg";
import TwoHandsDNA from "../../../assets/images/TwoHandsDNA.svg";
import IntroInfo from "./IntroInfo";
import InfoStepComponent from "./InfoStepComponent";
import Translate from "../../../translate/Translate";
import { PATHS } from "../../../constants/pathConstants";
//Reducers
import { useDispatch, useSelector } from "react-redux";
import { setIntroPageVisited } from "../../../store/reducers/user/UserAccountSlice";
import { selectIntroPageVisited } from "../../../store/reducers/user/UserAccountSlice";
import { saveNotificationSettings } from "../../../apis/membersApi";

const OnboardingIntroPage = () => {
  const classes = OnboardingIntroPageStyles();
  const dispatch = useDispatch();
  const [lastStepIndex, setLastStepIndex] = useState(0);
  const history = useHistory();
  const hasBeenOnboarded = useSelector(selectIntroPageVisited);

  const saveIntroPageCompleted = () => {
    const payload = {
      data: {
        introPageVisited: true
      }
    };
    saveNotificationSettings(payload)
      .then(() => {
        dispatch(setIntroPageVisited(payload.data));
        history.push(PATHS.FINAL_ONBOARDING_INFO);
      })
      .catch(() => {
        console.error("Something wrong while save notification settings");
      });
  };

  const onboardingSteps = [
    <IntroInfo onSkip={saveIntroPageCompleted} />,
    <InfoStepComponent
      img={ManyHandsDNA}
      text={
        <Translate text="Genomic Life enables members to identify their risk factors for cancer and other genetic diseases and proactively develop a personalized health plan. If faced with a cancer diagnosis, we provide additional support and technology available to the patient and their family." />
      }
    />,
    <InfoStepComponent
      img={Group}
      text={[
        <p>
          <Translate text="We offer proactive health programs that help prevent and manage diseases by combining the" />{" "}
          <span className={classes.greenColor}>
            <Translate text="power of genomics" />
          </span>{" "}
          <Translate text="with the support of clinical experts." />
        </p>
      ]}
    />,
    <InfoStepComponent
      img={TwoHandsDNA}
      text={[
        <p>
          <Translate text="You now have one centralized location where you can obtain information and navigate your genomic life. Your membership gives you access to genetic insights that enable" />{" "}
          <span className={classes.greenColor}>
            <Translate text="personalized" />
          </span>{" "}
          <Translate text="health through technology, clinical expertise, and trusted support." />
        </p>
      ]}
    />
  ];

  const handlePrevious = () => {
    if (lastStepIndex !== 0) {
      setLastStepIndex((prevCount) => prevCount - 1);
    }
  };

  const handleNext = () => {
    if (lastStepIndex < onboardingSteps.length - 1) {
      setLastStepIndex((prevCount) => prevCount + 1);
    }
    if (lastStepIndex === onboardingSteps.length - 1) {
      saveIntroPageCompleted();
    }
  };

  if (hasBeenOnboarded) {
    history.push(PATHS.TERMS_OF_SERVICE);
  }

  return (
    <Grid className={classes.onboardingContainer}>
      <CustomizedMobileStepper
        variant="text"
        position="bottom"
        showContent
        contentSteps={onboardingSteps}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        activeStep={lastStepIndex}
        fullWidth
      />
    </Grid>
  );
};

export default OnboardingIntroPage;
