import { doApiCall } from "./util";
const { REACT_APP_MEMBER_PORTAL_ENDPOINT_URL } = process.env;

export const getAsperatoForm = (glMemberId) => {
  const payload = {
    GLID: glMemberId,
    paymentOption: "Card"
  };

  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/asperato`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};
