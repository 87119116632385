import React from "react";
import * as SurveyReact from "survey-react-ui";
import Select, { createFilter } from "react-select";
import { FixedSizeList as List } from "react-window";

class ChipSelect extends SurveyReact.SurveyQuestionCheckbox {
  // Convert the `visibleChoices` array to a format supported by React Select
  get options() {
    return (
      this.question.visibleChoices
        //      .filter((choice) =>
        //        choice.locTextValue.values.default
        //          .toLowerCase()
        //          .includes(this.question.value.toLowerCase())
        //      )
        //.slice(0, 30)
        .map((c) => {
          return { value: c.value, label: c.locTextValue.values.default };
        })
    );
  }
  // Retrieve an option object based on the question value
  get selectedOption() {
    const res = [];
    const opts = this.options;
    let qValue = this.question.value;
    if (!qValue) qValue = [];

    for (var i = 0; i < opts.length; i++) {
      if (qValue.indexOf(opts[i].value) > -1) res.push(opts[i]);
    }
    return res;
  }
  // Set the question value to the selected option value
  onSelectChange = (selectedOption) => {
    const newValue = [];
    for (var i = 0; i < selectedOption.length; i++) {
      newValue.push(selectedOption[i].value);
    }
    this.question.value = newValue;
  };
  renderElement() {
    // If the question is non-editable, render a stylized div
    if (this.isDisplayMode) {
      return (
        <div
          id={this.question.inputId}
          className={this.question.getControlClass()}
          disabled
        >
          {" "}
          {this.question.displayValue || this.question.placeholder}{" "}
        </div>
      );
    }
    return (
      <Select
        id={this.question.inputId}
        value={this.selectedOption}
        onChange={this.onSelectChange}
        options={this.options}
        isMulti={true}
        required={this.question.isRequired}
        menuPortalTarget={document.querySelector("body")}
      />
    );
  }
}

export default ChipSelect;
