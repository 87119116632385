import React from "react";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "../../../hooks/useWindowSize";
import TermsOfServiceReviewPageStyles from "./TermsOfServiceReviewPage.styles";
import PageTitle from "../../../utils/PageTitle";
import { PATHS } from "../../../constants/pathConstants";
import UserAgreementDetail from "../TermsOfServicePage/UserAgreementDetail";
import Layout from "../../layout/Layout";

const TermsOfServiceReviewPage = (props) => {
  const { title, agreementKey } = props;
  PageTitle(title);
  const classes = TermsOfServiceReviewPageStyles();
  const windowSize = useWindowSize();
  const history = useHistory();

  const layoutPropsValues = {
    headerType: "secondary",
    headerText: title,
    goBackPath: PATHS.TERMS_OF_SERVICE,
    goBackTitle: "Terms of Service"
  };

  return (
    <div style={{ minHeight: windowSize.height * 0.95 }}>
      <Layout {...layoutPropsValues}>
        <Grid
          className={classes.container}
          container
          xs={12}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <UserAgreementDetail
            agreementKey={agreementKey}
            onSave={() => {
              history.go(-1);
            }}
          />
        </Grid>
      </Layout>
    </div>
  );
};
export default TermsOfServiceReviewPage;
