import Translate from "../../../../../../../translate/Translate";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ToDoListCardContainer from "../../ToDoListCardContainer";
import GLButton from "../../../../../sharedComponents/GLButton";
import { selectUserPersonalInformation } from "../../../../../../../store/reducers/user/UserAccountSlice";
import contactUsIcon from "../../../../../../../assets/icons/icon-contact-us.svg";
import NeedSupportModal from "../../../../../sharedComponents/Modals/NeedSupportModal";
import Mixpanel from "../../../../../../../utils/mixpanel";

const HaveAQuestionToDoCard = () => {
  const userFirstName = useSelector(selectUserPersonalInformation).firstName;
  const [supportModalIsOpen, setSupportModalIsOpen] = useState(false);

  const renderBody = () => {
    return (
      <>
        {userFirstName},{" "}
        <Translate
          text={
            "our team is here to help answer questions about a cancer diagnosis or your membership."
          }
        />
      </>
    );
  };

  const renderButton = () => {
    return (
      <GLButton
        text={"Contact Us"}
        size={"small"}
        onClick={() => {
          Mixpanel.track(`To Do Card CTA Clicked - Have a question`);
          setSupportModalIsOpen(true);
        }}
      />
    );
  };

  return (
    <>
      <ToDoListCardContainer
        icon={contactUsIcon}
        title={<Translate text={"Have a question? We're here for you"} />}
        body={renderBody()}
        buttons={renderButton()}
      ></ToDoListCardContainer>
      <NeedSupportModal
        isOpen={supportModalIsOpen}
        onClose={() => setSupportModalIsOpen(false)}
      />
    </>
  );
};

export default HaveAQuestionToDoCard;
