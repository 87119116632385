import { makeStyles } from "@mui/styles";

const RequestPasswordResetEmailPageStyles = makeStyles((theme) => ({
  img: {
    marginTop: 50,
    height: 61
  },
  title: {
    marginTop: 40
  },
  inputSuccess: {
    marginTop: 30
  },
  inputError: {
    marginTop: 30,
    color: `red`
  },
  RoundedButton: {
    borderRadius: `100px`,
    marginTop: 40,
    height: `45px`,
    width: `200px`,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    },
    color: theme.palette.primary.white,
    fontFamily: "AvenirLTProMedium",
    fontWeight: `800`
  },
  BackToLogin: {
    fontFamily: "AvenirLTProMedium",
    fontWeight: `500`,
    fontSize: `13px`,
    marginTop: 25,
    textDecoration: `none`,
    color: theme.palette.primary.main
  }
}));

export default RequestPasswordResetEmailPageStyles;
