import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, Grid } from "@mui/material";
import Translate from "../../../../../translate/Translate";

import ReactInputMask from "react-input-mask";
import { validateUSPhoneNumber } from "../../../../../utils/utils";
import { SharedFormFieldStyles } from "../../Form.styles";
import {
  translateLabelStringWithRequiredIfAppropriate,
  translateLabelStringWithOptionalIfAppropriate,
  defaultSizingProps,
  extractSizingProps
} from "../../FormUtils";

// There is a lot of bizwack in here around validation to support requests
//  from design. They want the field not to light up invalid until a user
//  has focused and defocused it, but they still want the save button
//  disabled. So the validity we report to the form that drives the button
//  disable is more strict than the validty the field itself shows, since
//  it may not show invalid if you are working on it or have not focused it before.
//  It's brittle. Be careful with it.

const GLPhoneNumberField = (props) => {
  const { label, id, value, required, disabled, onChange } = props;

  const [hasFocus, setHasFocus] = useState(false);
  const [hasReceivedFocus, setHasReceivedFocus] = useState(false);

  useEffect(() => {
    onChange({
      e: {
        target: { name: id, value: value }
      },
      isInvalid: !!calculateHelperText(value)
    });
  }, [hasReceivedFocus]); // eslint-disable-line
  // I want this hook only dependent on that one var so that it only
  //  fires when the form loads and if the input is ever focused

  const classes = SharedFormFieldStyles();

  const digitsFromNumberString = (stringWithNumbers) => {
    return (stringWithNumbers && stringWithNumbers.replace(/\D/g, "")) || "";
  };

  // We only call the parent with an entry if the number is valid
  const handleChange = (e) => {
    onChange({
      e: {
        target: {
          name: id,
          value: e.target.value
        }
      },
      isInvalid: !!calculateHelperText(e.target.value)
    });
  };

  // I had a phone number entered but missed a digit in the middle.
  // That possibility is a weird artifact of the mask we are using.
  // It was hard to notice that was the problem. I don't think we
  // want to show the error message on incomplete numbers while
  // the user is typing, so I added the char is a digit case, to
  // highlight the number as wrong if it looks like the user thinks
  // they have typed a whole number, but is missing a digit in the middle.
  const calculateHelperText = (valueToValidate) => {
    // This is a gross hack to support the messy kit order process so we can release.
    //  When that gets cleaned up, pull this
    if (
      valueToValidate &&
      valueToValidate.length === 10 &&
      !valueToValidate.startsWith("1")
    ) {
      valueToValidate = "1" + valueToValidate;
    }
    // End gross hack

    if (
      required &&
      (!valueToValidate || valueToValidate === "+1(___)___-____")
    ) {
      return <Translate text="This field is required" />;
    }
    if (!required && [null, "+1(___)___-____"].includes(valueToValidate))
      return "";
    if (
      valueToValidate &&
      valueToValidate.length > 0 &&
      !validateUSPhoneNumber(valueToValidate)
    ) {
      return <Translate text="Please enter a valid U.S. phone number." />;
    }
    if (!required && digitsFromNumberString(valueToValidate).length === 0) {
      return "";
    }
    return "";
  };

  const translatedLabel = required
    ? translateLabelStringWithRequiredIfAppropriate(
        label,
        required,
        digitsFromNumberString(value).length > 10
      )
    : translateLabelStringWithOptionalIfAppropriate(label, required);

  const shouldBeShowingErrorState =
    !hasFocus &&
    ((value && !hasReceivedFocus) || hasReceivedFocus) &&
    !!calculateHelperText(value);

  return (
    <Grid item {...extractSizingProps(props)}>
      <ReactInputMask
        mask="+1(999)999-9999"
        value={value}
        required={required}
        disabled={disabled}
        alwaysShowMask={true}
        onChange={handleChange}
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasReceivedFocus(true);
          setHasFocus(false);
        }}
      >
        {() => (
          <TextField
            {...extractSizingProps(props)}
            label={translatedLabel}
            name={id}
            id={id}
            variant="outlined"
            fullWidth={true}
            value={value}
            className={classes.textInput}
            error={shouldBeShowingErrorState}
            helperText={
              shouldBeShowingErrorState ? calculateHelperText(value) : null
            }
          />
        )}
      </ReactInputMask>
    </Grid>
  );
};

GLPhoneNumberField.defaultProps = defaultSizingProps;

export default GLPhoneNumberField;

GLPhoneNumberField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
