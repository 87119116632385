import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  connectionTypes: [
    { id: "care-team-connect", name: "Care team", isSelected: false }
  ],
  connections: [],
  connectionId: "",
  transmitSurveyStep: 0,
  transmitSurveyResults: {
    reportIds: [],
    recipients: [],
    recipientChannels: [],
    reasons: [],
    otherReason: "",
    additionalInformation: "",
    aknowledgment: false,
    adviceAknowledgment: false
  },
  sentResults: false,
  connectionTypeSelected: {},
  updateConnection: [
    {
      status: false,
      connectionId: ""
    }
  ]
};

const handleConnectionAddition = (connectionState, newPayload) => {
  if (connectionState.connections.length === 0) {
    return [newPayload];
  }
  const existingPayload = connectionState.connections
    ? connectionState.connections.find((connection) => {
        return connection.connectionId === newPayload.connectionId;
      })
    : null;
  if (existingPayload && connectionState && connectionState.connections) {
    return connectionState.connections.map((connection) => {
      if (connection.connectionId === existingPayload.connectionId) {
        return { ...newPayload };
      }
      return connection;
    });
  }
  return [...connectionState.connections, newPayload];
};

const updateConnectionHandler = (state, payload) => {
  const newConnections = [...state.connections];
  const connections = newConnections.filter(
    (connect) => connect.connectionId !== payload.connectionId
  );
  return [...connections, payload];
};

const deleteConnectionHandler = (state, payload) => {
  const newConnections = [...state.connections];
  const connections = newConnections.filter(
    (connect) => connect.connectionId !== payload.connectionId
  );
  return [...connections];
};

export const ConnectionsInformationSlice = createSlice({
  name: "connectionsInformation",
  initialState,
  reducers: {
    setConnections(state, { payload }) {
      state.connections = handleConnectionAddition(state, payload);
    },
    setUpdateConnection(state, { payload }) {
      state.updateConnection = payload;
    },
    setConnectionTypes(state, { payload }) {
      state.connectionTypes = payload;
    },
    updateConnection(state, { payload }) {
      state.connections = updateConnectionHandler(state, payload);
    },
    deleteConnection(state, { payload }) {
      state.connections = deleteConnectionHandler(state, payload);
    },
    setConnectionTypeSelected: (state, { payload }) => {
      state.connectionTypeSelected = payload;
    },
    setConnectionId: (state, { payload }) => {
      state.connectionId = payload;
    },
    setTransmitSurveyStep: (state, { payload }) => {
      state.transmitSurveyStep = payload;
    },
    setTransmitSurveyResults: (state, { payload }) => {
      state.transmitSurveyResults = {
        ...state.transmitSurveyResults,
        ...payload
      };
    },
    resetTransmitSurveyResults: (state) => {
      state.transmitSurveyResults = initialState.transmitSurveyResults;
    },
    setSurveySentState(state, { payload }) {
      state.sentResults = payload;
    },
    resetConnectionsArray(state) {
      state.connections = [];
    },
    reset: (_state) => initialState
  }
});

export const {
  setConnections,
  setConnectionTypes,
  updateConnection,
  deleteConnection,
  setConnectionTypeSelected,
  setTransmitSurveyStep,
  setTransmitSurveyResults,
  resetTransmitSurveyResults,
  setUpdateConnection,
  setConnectionId,
  setSurveySentState,
  resetConnectionsArray,
  reset
} = ConnectionsInformationSlice.actions;

export const selectConnectionTypes = (state) =>
  state.connection ? state.connection.connectionTypes : null;
export const selectConnection = (state, connectionId) => {
  return state.connection.connections.find((c) => {
    return c.connectionId === connectionId;
  });
};
export const selectConnections = (state) =>
  state.connection ? state.connection.connections : [];
export const selectConnectionTypeSelected = (state) =>
  state.connection ? state.connection.connectionTypeSelected : null;
export const totalOfConnections = (state) =>
  state.connection ? state.connection.connections.length : null;
export const selectTransmitSurveyStep = (state) =>
  state.connection ? state.connection.transmitSurveyStep : 0;
export const selectTransmitSurveyResults = (state) =>
  state.connection ? state.connection.transmitSurveyResults : null;
export const selectUpdateConnection = (state) =>
  state.connection ? state.connection.updateConnection : false;
export const selectConnectionId = (state) =>
  state.connection ? state.connection.connectionId : false;
export const selectSentSurveyResults = (state) =>
  state.connection ? state.connection.sentResults : false;

export default ConnectionsInformationSlice.reducer;
