import { loadScript } from "../../../../../utils/utils";
import { ENVIRONMENT_CONSTANTS } from "../../../../../constants/environmentConstants";
export const loadPlacesScript = () => {
  if (typeof window !== "undefined") {
    const PLACES_API_KEY =
      process.env[ENVIRONMENT_CONSTANTS.REACT_APP_GOOGLE_PLACES_API_KEY];
    if (document.querySelector("#google-maps") === null) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${PLACES_API_KEY}&libraries=places&callback=initMap`,
        "google-maps"
      );
    }
  }
};

export const getPlacePredictions = async (text) =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject("Need valid text input");
    }
    if (typeof window === "undefined") {
      return reject("Need valid window object");
    }
    const autocompleteService = new window.google.maps.places.AutocompleteService();

    if (autocompleteService) {
      try {
        autocompleteService.getPlacePredictions(
          {
            input: text,
            componentRestrictions: { country: "us" }
          },
          resolve
        );
      } catch (e) {
        reject(e);
      }
    } else {
      return reject("service not initialized");
    }
  });

export const getPlacePredictionsByRegion = async (text) =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject("Need valid text input");
    }
    if (typeof window === "undefined") {
      return reject("Need valid window object");
    }
    const autocompleteService = new window.google.maps.places.AutocompleteService();

    if (autocompleteService) {
      try {
        autocompleteService.getPlacePredictions(
          {
            input: text,
            types: ["(regions)"],
            componentRestrictions: { country: "us" }
          },
          resolve
        );
      } catch (e) {
        reject(e);
      }
    } else {
      return reject("service not initialized");
    }
  });

export const getPlaceDetailsByPlaceId = async (placeId) =>
  new Promise((resolve, reject) => {
    if (!placeId) {
      return reject("Need valid placeId to search");
    }
    if (typeof window === "undefined") {
      return reject("Need valid window object");
    }

    try {
      new window.google.maps.places.PlacesService(
        document.createElement("div")
      ).getDetails(
        {
          placeId,
          fields: ["address_components", "name"]
        },
        (details) => {
          return resolve(details);
        }
      );
    } catch (e) {
      reject(e);
    }
  });

const selectPlaceElement = (placesArray, placeKey) => {
  let placeElement = "";
  if (placesArray && placeKey) {
    return placesArray.find((placeElements) => {
      return placeElements?.types.includes(placeKey);
    });
  }
  return placeElement;
};

export const parseFullAddress = (placeDetailComponents) => {
  const streetNumber = selectPlaceElement(
    placeDetailComponents,
    "street_number"
  );
  const route = selectPlaceElement(placeDetailComponents, "route");
  const subpremise = selectPlaceElement(placeDetailComponents, "subpremise");
  const firstElement =
    streetNumber && streetNumber.long_name ? streetNumber.long_name : null;
  const secondElement = route && route.long_name ? route.long_name : null;
  const thirdElement =
    subpremise && subpremise.long_name ? subpremise.long_name : null;

  if (!firstElement || !secondElement) {
    return "";
  }

  return `${firstElement} ${secondElement}${thirdElement ? " " : ""}${
    thirdElement ? thirdElement : ""
  }`;
};
