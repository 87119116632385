import React, { useEffect } from "react";
import ContactUs from "./ContactUs/";
import Header from "./Header";
import { useWindowSize } from "../../hooks/useWindowSize";
import SecondaryHeader from "./Header/SecondaryHeader";
import HeaderLogo from "../../assets/images/GL-Logo.svg";
import { LABELS } from "../../constants/labelConstants";
import LayoutStyles from "./Layout.styles";
import Breadcrumb from "../sharedComponents/Breadcrumb";
import Footer from "../v2/sharedComponents/Footer";
const Layout = (props) => {
  let {
    headerType,
    headerText,
    children,
    goBackTitle,
    goBackPath,
    hasNoFooter,
    hasNotHeader,
    footerType
  } = props;
  const classes = LayoutStyles();
  const windowSize = useWindowSize();
  let headerHeight = 74;
  let footerHeight = 208;
  const PrimaryUsableHeight = windowSize.height - (headerHeight + footerHeight);
  const SecondaryUsableHeight = windowSize.height - 308;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const FOOTER_VARIANTS = {
    primary: <ContactUs />,
    secondary: <ContactUs type="secondary" />,
    tertiary: <ContactUs type="secondary" />,
    integrated: <ContactUs type="secondary" />,
    v2: <Footer />
  };
  const renderLogoAsHeader = () => {
    return (
      <div className={classes.logoHeader}>
        <img
          src={HeaderLogo}
          style={{ height: headerHeight }}
          alt={`${LABELS.LABEL_CANCER_GUARDIAN_LOGO_ALT_TEXT}`}
        />
      </div>
    );
  };

  const renderFooter = () => {
    if (footerType === "v2") {
      return <Footer />;
    } else {
      return !hasNoFooter && FOOTER_VARIANTS[headerType];
    }
  };
  return (
    <div>
      {headerType === "primary" && <Header />}
      {headerType === "tertiary" && <Header />}
      {headerType === "logo" && renderLogoAsHeader()}
      {headerType === "secondary" && (
        <SecondaryHeader headerText={headerText} />
      )}
      {headerType === "integrated" && (
        <div>
          {!hasNotHeader && <Header />}
          {goBackPath ? (
            <Breadcrumb
              text={goBackTitle ? goBackTitle : null}
              redirectTo={goBackPath}
            />
          ) : null}
          <SecondaryHeader headerText={headerText} />
        </div>
      )}
      <div
        style={{
          position: "relative",
          minHeight:
            headerType === "primary"
              ? PrimaryUsableHeight
              : SecondaryUsableHeight
        }}
      >
        {children}
      </div>
      {renderFooter()}
    </div>
  );
};

export default Layout;
