import React from "react";

import { PATHS } from "../../../../constants/pathConstants";
import { useHistory } from "react-router-dom";
import SurveyWrapper from "../SurveyComponents/SurveyWrapper";
import { useDispatch } from "react-redux";
import { setHasActiveCancerDiagnosis } from "../../../../store/reducers/user/UserAccountSlice";
import Mixpanel from "../../../../utils/mixpanel";

const SURVEY_ID = "getting-started-survey-with-preferred-contact";

const hardcodedSurveyJson = {};

const GettingStartedSurvey = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnComplete = (surveyModel) => {
    if (
      surveyModel.getQuestionByName("prior-cancer-diagnosis").value === true
    ) {
      Mixpanel.track("Survey completed with prior cancer diagnosis");
    }
    if (
      surveyModel.getQuestionByName("active-cancer-diagnosis").value === true
    ) {
      Mixpanel.track("Survey completed with active cancer diagnosis");
      dispatch(setHasActiveCancerDiagnosis(true));
      history.push(PATHS.LANDING_PAGE);
    } else {
      dispatch(setHasActiveCancerDiagnosis(false));
      history.push(PATHS.LANDING_PAGE);
    }
  };

  const handleOnCurrentPageChanging = (surveyModel) => {
    if (
      surveyModel.getQuestionByName("prior-cancer-diagnosis").value === true
    ) {
      Mixpanel.track("Prior cancer selected in survey");
    }
    if (
      surveyModel.getQuestionByName("active-cancer-diagnosis").value === true
    ) {
      Mixpanel.track("Active cancer selected in survey");
    }
  };

  return (
    <SurveyWrapper
      surveyId={SURVEY_ID}
      onComplete={handleOnComplete}
      //surveyJsonOverride={hardcodedSurveyJson}
      onCurrentPageChanging={handleOnCurrentPageChanging}
      overrideNextButtonEnabled={true}
    ></SurveyWrapper>
  );
};

export default GettingStartedSurvey;
