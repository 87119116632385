import { Grid, Typography } from "@mui/material";
import RegistrationSuccessPageStyles from "./RegistrationSuccessPage.styles";
import RegistrationPageLayout from "./RegistrationPageLayout";
import Translate from "../../../../translate/Translate";
import GLButton from "../../sharedComponents/GLButton";
import { PATHS } from "../../../../constants/pathConstants";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserPersonalInformation } from "../../../../store/reducers/user/UserAccountSlice";

const RegistrationSuccessPage = () => {
  const classes = RegistrationSuccessPageStyles();
  const history = useHistory();
  const userInfo = useSelector(selectUserPersonalInformation);

  return (
    <RegistrationPageLayout>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item xs={12} className={classes.paper}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={10}>
              <Grid item className={classes.party}>
                🎉
              </Grid>
              <Grid item className={classes.header}>
                <Typography variant="h4">
                  <Translate text="Welcome to Genomic Life!" />
                </Typography>
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.bodyPaper}
              >
                <Grid item className={classes.name}>
                  <Typography variant="h6">
                    <Translate
                      text={`${userInfo.firstName} ${userInfo.lastName}`}
                    />
                  </Typography>
                </Grid>
                <Grid item className={classes.userId}>
                  <Typography>
                    <Translate text={userInfo.email} />
                  </Typography>
                </Grid>
                <div className={classes.thinGreenLine} />
                <Grid item xs={10}>
                  <Typography>
                    <Translate text="We'll just need you to log in one more time to access your benefits!" />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <GLButton
            color="primary"
            onClick={() => {
              history.push(PATHS.LOG_OUT);
            }}
            className={classes.continueButton}
          >
            Continue
          </GLButton>
        </Grid>
      </Grid>
    </RegistrationPageLayout>
  );
};

export default RegistrationSuccessPage;
