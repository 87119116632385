import { makeStyles } from "@mui/styles";

const OnboardingIntroPageStyles = makeStyles((theme) => ({
  onboardingContainer: {
    background: `linear-gradient(360deg, #E2ECF8 0%, rgba(226, 236, 248, 0) 50%)`,
    backgroundSize: `cover`,
    //height: 880,
    /* Set rules to fill background */
    minHeight: `100%`,
    minWidth: 360,

    /* Set up proportionate scaling */
    width: `100%`,
    height: `auto`,

    /* Set up positioning */
    position: `fixed`,
    top: 0,
    left: 0
  },
  greenColor: {
    color: theme.palette.primary.green_dark,
    fontWeight: 700
  }
}));

export default OnboardingIntroPageStyles;
