import moment from "moment";
import jwt_decode from "jwt-decode";
import libphonenumbers from "libphonenumbers";
import { themeBreakpoints } from "../theme/themeBreakpoints";
const CryptoJS = require("crypto-js");
const { REACT_APP_ENCRYPT_KEY } = process.env;

/**
 * @function validateEmailString
 * @param {string} email
 * @description verify if an email is valid
 * @returns true if valid, false if not
 */
export const validateEmailString = (email) => {
  // Regex Validation: https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
  /* eslint-disable no-control-regex */
  try {
    const mailformat = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return mailformat.test(email.toLowerCase().trim());
  } catch {
    console.error("error assessing email");
    return false;
  }
};

/**
 * returns the time that has passed from the provided date
 * @param {*} timeToLookup time that will be the start point to say how much time has passed
 * @param {String} format format to parse the date to calculate the dates
 * @returns {String} the time that has passed from the provided date
 */

export const validateToken = (token) => {
  try {
    const decodedToken = jwt_decode(token);
    const now = moment();
    const expirationDate = moment(decodedToken.exp * 1000);
    return expirationDate > now;
  } catch (e) {
    return false;
  }
};

/**
 * Function that allows determinate if a date is 18 years from today or not
 * @param {*} date the date to evaluate
 * @returns {boolean} if the birth year is superior to 18
 */
export const convertToDateFormat = (date, format) => {
  return date ? moment(date).format(format) : moment().format(format);
};

/**
 * @function This functions allow us to encrypt the password data
 * @description We are using the AES algorithm to encrypt the password data, in a CBC mode.
 * You can provide to this function the password property and the Algorithm must encrypted
 * and return the new value
 * @param {string} password
 * @returns Password encrypted
 */
export const encryptPassword = (password) => {
  const ctObj = CryptoJS.AES.encrypt(password, REACT_APP_ENCRYPT_KEY);
  const ctStr = ctObj.toString();
  return ctStr;
};

/**
 * Function to convert Unix timestamp date to normal date using a specific separator
 * @param {*} date the unix timestamp to convert
 * @param {string} separator a symbol to separate day, month, year
 * @returns {string} the normal date with the separator
 */
export const convertUnixTimestampToDateFormat = (unixDate, separator) => {
  const momentDate = moment(new Date(unixDate * 1000));
  return momentDate.format(`DD${separator || "-"}MM${separator || "-"}YYYY`);
};

/**
 *  this function is used to download files
 * @param {string} fileName the name of the file to download
 * @param {string} data base64 data that came from the endpoint
 */
export const downloadFile = (fileName, data) => {
  let a = document.createElement("a");
  a.href = "data:application/octet-stream;base64," + data;
  a.download = `${fileName}.pdf`;
  a.click();
};

/**
 *  function used to validate if an a person is Major based on its DOB
 * @param {string} DOB
 * @returns age value
 */
export const isAdultBasedOnDob = (DOB) => {
  let today = new Date();
  let date_of_birth = new Date(DOB);
  let age = today.getFullYear() - date_of_birth.getFullYear();
  let m = today.getMonth() - date_of_birth.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < date_of_birth.getDate())) {
    age--;
  }
  return age > 19;
};

/**
 *  function used to separate the phone number from the country code
 * @param {phoneNumber} string
 * @returns phone number and country code
 */

export const separatePhoneNumber = (wholePhoneNumber) => {
  let unmaskNumber = wholePhoneNumber.replace(/\D/g, "");
  let phoneNumber = unmaskNumber.substr(unmaskNumber.length - 10);
  let countryCode = unmaskNumber.charAt(0);
  return { phoneNumber, countryCode };
};

export function isBlankOrNull(str) {
  return !str || /^\s*$/.test(str);
}

/**
 * This functions allows to set up a validation value depending if an us phone number is valid
 * @param {String} usNumber  phone number to asses
 * @param {function} dispatchFunction dispatch function to update the value state
 * @param {String} dispatchType  the type that the dispatchFunction will update
 * @param {function} validationStateHandler the validator state function
 * @returns
 */

export const validateAndDispatchUSNumber = (
  usNumber,
  dispatchFunction,
  dispatchType,
  validationStateHandler
) => {
  const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance();
  const validUsTerritories = ["AS", "GU", "MP", "PR", "VI"];

  try {
    // Parse number with US country code and keep raw input
    const number = phoneUtil.parseAndKeepRawInput(usNumber, "US");
    let regionalNumber = number.getNationalNumber();
    if (dispatchFunction && dispatchType) {
      if (String(regionalNumber).length > 10) {
        dispatchFunction({
          type: dispatchType,
          value: ""
        });
      }
    }
    if (phoneUtil.isValidNumberForRegion(number, "US")) {
      //It's a valid US phone
      validationStateHandler && validationStateHandler(true);
      return true;
    } else {
      let region = phoneUtil.getRegionCodeForNumber(number);
      if (validUsTerritories.includes(region)) {
        validationStateHandler && validationStateHandler(true);
        return true;
      } else {
        validationStateHandler && validationStateHandler(false);
        return false;
      }
    }
  } catch (error) {
    validationStateHandler && validationStateHandler(false);
    return false;
  }
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const validateUSPhoneNumber = (rawNumber) => {
  try {
    const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput(rawNumber, "US");
    const digitsOfRawNumber = rawNumber.replace(/\D/g, "");
    const validUsTerritories = ["US", "AS", "GU", "MP", "PR", "VI"];
    const region = phoneUtil.getRegionCodeForNumber(number);
    return (
      validUsTerritories.includes(region) && digitsOfRawNumber.length === 11
    );
  } catch (error) {
    return false;
  }
};
export const maskUnformattedUsPhoneNumber = (rawNumber) => {
  if (rawNumber && rawNumber.length > 0) {
    if (rawNumber.includes("+1")) {
      return rawNumber;
    }
    return `+1(${rawNumber.substring(0, 3)})${rawNumber.substring(
      3,
      6
    )}-${rawNumber.substring(6)}`;
  }
  return rawNumber;
};

export const getCurrentScreenSizeBreakpoint = (screenWidth) => {
  const breakpoints = themeBreakpoints.values;
  const breakpointNames = ["xs", "sm", "md", "lg", "xl"];
  const breakpointObject = breakpointNames.map((breakpoint) => {
    return { id: breakpoint, breakpoint: breakpoints[breakpoint] };
  });
  return breakpointObject.find((breakpointObj) => {
    return screenWidth <= breakpointObj.breakpoint;
  });
};

export const validateAllKitsHaveBeenRequested = (
  availableKits,
  kitsRequested
) => {
  if (availableKits.length > kitsRequested.length) {
    return false;
  }
  const kitsRequestedIds = kitsRequested.map((order) => {
    return order.description.uuid;
  });

  return (
    availableKits.filter((kit) => {
      return kitsRequestedIds.includes(kit.test_uuid);
    }).length >= availableKits.length
  );
};

export const loadScript = (src, id) => {
  if (!document.querySelector(`#${id}`)) {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.defer = true;
    script.id = id;
    document.head.appendChild(script);
  }
};

export const focusElementByIdOnTab = (event, elementId) => {
  if (event.code === "Tab" && elementId) {
    event.preventDefault();
    const element = document.getElementById(elementId);
    if (element) {
      element.focus();
    }
  }
};

export const doesObjectHaveChangesComparedToOtherObject = (
  actualObject,
  expectedObject
) => {
  if (typeof actualObject !== "object" || typeof expectedObject !== "object") {
    console.error("one of the elements is not an object");
  }
  const paramArray = Object.keys(expectedObject);
  const filteredArray = paramArray.filter((param) => {
    return (
      expectedObject[param] && actualObject[param] !== expectedObject[param]
    );
  });
  return filteredArray.length > 0;
};

export const combineArrayOfWordsWithCommasAndAnAndForLastItem = (arr) => {
  if (arr.length === 0) {
    return "";
  } else if (arr.length === 1) {
    return arr[0];
  } else if (arr.length === 2) {
    return arr.join(" and ");
  } else {
    const lastItem = arr.pop();
    return arr.join(", ") + ", and " + lastItem;
  }
};
