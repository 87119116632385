import mixpanel from "mixpanel-browser";
const { REACT_APP_MIXPANEL_TOKEN } = process.env;

if (REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(REACT_APP_MIXPANEL_TOKEN);
}

const shouldReportInCurrentEnvironment = true && REACT_APP_MIXPANEL_TOKEN; //["production", "test"].includes(process.env.NODE_ENV) && REACT_APP_MIXPANEL_TOKEN;

const Mixpanel = {
  identify: (id) => {
    if (shouldReportInCurrentEnvironment) mixpanel.identify(id);
  },
  alias: (id) => {
    if (shouldReportInCurrentEnvironment) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (shouldReportInCurrentEnvironment) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (shouldReportInCurrentEnvironment) mixpanel.people.set(props);
    }
  }
};

export default Mixpanel;
