import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
import SurveyWrapperStyles from "./SurveyWrapper.styles";
import {
  selectSurveyInfo,
  setSurveyDataForSpecificSurvey,
  selectAllPreviousSurveyAnswers,
  setSurveyCurrentPage,
  updateSurveyAnswersForAllPreviousSurveys
} from "../../../../../store/reducers/survey/SurveySlice";
import {
  getSurveyJson,
  postSurveyAnswers
} from "../../../../../apis/surveyApi";
import { rehydrateSurveyWithAnyPreviousAnswers } from "../../Utilities/util";
import * as SurveyReact from "survey-react-ui";
import { RendererFactory } from "survey-core";
import ChipSelect from "../CustomQuestions/ChipSelect";
import LoadingAnimation from "../../../sharedComponents/LoadingIndicators/LoadingAnimation";
import { openGLSnackbar } from "../../../../../store/reducers/snackbar/openGLSnackbarSlice";
import Mixpanel from "../../../../../utils/mixpanel";

SurveyReact.ReactQuestionFactory.Instance.registerQuestion(
  "gl-chip-select",
  (props) => {
    return React.createElement(ChipSelect, props);
  }
);

RendererFactory.Instance.registerRenderer(
  "checkbox",
  "chip-select",
  "gl-chip-select"
);

const SurveyWrapper = (props) => {
  const {
    surveyId,
    onSurveyInstantiated,
    focusFirstQuestionAutomatic,
    onCurrentPageChanging,
    onComplete,
    surveyJsonOverride,
    overrideNextButtonEnabled,
    children
  } = props;
  StylesManager.applyTheme("defaultV2");
  const classes = SurveyWrapperStyles();
  const [surveyInstance, setSurveyInstance] = useState();
  const [surveyDataIsLoadedFromApi, setSurveyDataIsLoadedFromApi] = useState(
    false
  );
  const [surveyVersionId, setSurveyVersionId] = useState();
  const [surveyIsRendered, setSurveyIsRendered] = useState(false);
  const dispatch = useDispatch();
  const allPreviousSurveyAnswers = useSelector(selectAllPreviousSurveyAnswers);

  const buildSurveyModel = (surveyJson) => {
    return new Model({
      ...surveyJson,
      showProgressBar: "bottom",
      progressBarType: "questions",
      showQuestionNumbers: "off",
      pageNextText: "NEXT >",
      pagePrevText: "< BACK",
      showCompletedPage: false,
      hideRequiredErrors: false,
      checkErrorsMode: "onValueChanged"
    });
  };

  const possibleStoredSurveys = useSelector(selectSurveyInfo);

  const applyPossbileStoredSurveyData = (survey, surveyVersionId) => {
    const possibleStoredSurvey = possibleStoredSurveys[surveyVersionId];

    if (possibleStoredSurvey && possibleStoredSurvey.data) {
      survey.data = possibleStoredSurvey.data;
      survey.currentPageNo = possibleStoredSurvey.currentPageNumber;
    }
  };

  const setupSurveyInStore = (survey, surveyVersionId) => {
    if (!!onSurveyInstantiated) {
      onSurveyInstantiated(survey);
    }
    setSurveyVersionId(surveyVersionId);
    setSurveyInstance(survey);
    setSurveyDataIsLoadedFromApi(true);
    dispatch(
      setSurveyDataForSpecificSurvey({
        surveyIdentifier: surveyVersionId,
        surveyData: survey.data
      })
    );
  };

  useEffect(() => {
    if (!surveyDataIsLoadedFromApi && !surveyIsRendered) {
      if (!!surveyJsonOverride) {
        let survey = buildSurveyModel(surveyJsonOverride);
        applyPossbileStoredSurveyData(survey, surveyId);
        setupSurveyInStore(survey, "overridden");
      } else {
        getSurveyJson(surveyId)
          .then((response) => {
            Mixpanel.track(`Survey ${surveyId} started`);
            const surveyJson = { ...response.data.structure };
            let survey = buildSurveyModel(surveyJson);

            rehydrateSurveyWithAnyPreviousAnswers(
              survey,
              allPreviousSurveyAnswers
            );

            applyPossbileStoredSurveyData(survey, response.data.surveyId);

            setupSurveyInStore(survey, response.data.surveyId);
          })
          .catch((error) => {
            console.error("error fetching json response", error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyIsRendered, possibleStoredSurveys]);

  const handleOnCurrentPageChanging = (surveyModel, options) => {
    if (!!onCurrentPageChanging) {
      onCurrentPageChanging(surveyModel, options);
    }
  };

  const handleOnCurrentPageChanged = (surveyModel, options) => {
    dispatch(
      setSurveyCurrentPage({
        surveyIdentifier: surveyVersionId,
        currentPageNumber: surveyModel.currentPageNo
      })
    );
    setNextButtonAvailability(surveyModel);
  };

  const handleOnAfterRenderPage = (surveyModel, options) => {
    setNextButtonAvailability(surveyModel);
  };
  const handleOnAfterRenderSurvey = (surveyModel, options) => {
    setNextButtonAvailability(surveyModel);
    setSurveyIsRendered(true);
  };

  const handleOnValueChanged = (surveyModel) => {
    dispatch(
      setSurveyDataForSpecificSurvey({
        surveyIdentifier: surveyVersionId,
        surveyData: surveyModel.data
      })
    );
    setNextButtonAvailability(surveyModel);
  };

  const handleOnComplete = (surveyModel, options) => {
    try {
      const formattedPayload = Object.entries(surveyModel.data).map(
        (answer) => {
          const questionName = answer[0];
          const question = surveyModel.getQuestionByName(questionName);
          const formattedAnswer = {
            name: questionName,
            value: answer[1]
          };
          let questionAsJson = question.toJSON();
          if (["taking-medication"].includes(questionAsJson.name)) {
            questionAsJson = {
              ...questionAsJson,
              choices: ["removed, list was too long"]
            };
          }
          const formattedQuestion = {
            ...questionAsJson,
            type: question.getType(),
            isRequired: question.isRequired,
            isVisible: question.isVisible
          };
          return { ...formattedAnswer, questionAsAsked: formattedQuestion };
        }
      );

      const payload = { surveyId: surveyVersionId, answers: formattedPayload };

      if (surveyJsonOverride) {
        console.log(JSON.stringify(surveyModel.data));
        console.log(payload);
        onComplete(surveyModel);
      } else {
        postSurveyAnswers(payload)
          .then((response) => {
            Mixpanel.track(`Survey ${surveyId} completed`);
            // This needs to get ALL previous answers as soon as we have that endpoint
            getSurveyJson("getting-started-survey-with-preferred-contact-flat")
              .then((updatedAnswers) => {
                dispatch(
                  updateSurveyAnswersForAllPreviousSurveys(updatedAnswers)
                );
              })
              .then((response) => {
                surveyModel.currentPageNo = 0;
                dispatch(
                  setSurveyDataForSpecificSurvey({
                    surveyIdentifier: surveyVersionId,
                    surveyData: {}
                  })
                );
                onComplete(surveyModel);
              });
          })

          .catch((error) => {
            Mixpanel.track(`Survey ${surveyId} save to server failed`);
            dispatch(
              openGLSnackbar({
                variant: "error",
                header: "Error saving survey",
                subText:
                  "Sorry, we were unable to save your answers. Please try again, and reach out to us if that doesn't help!"
              })
            );
            window.document.location.reload(true);
          });
      }
    } catch (e) {
      Mixpanel.track(`Survey ${surveyId} answer parsing failed`, {
        surveyData: surveyModel.data
      });
      dispatch(
        openGLSnackbar({
          variant: "error",
          header: "Error saving survey",
          subText:
            "Sorry, we were unable to save your answers. Please try again, and reach out to us if that doesn't help!"
        })
      );
      window.document.location.reload(true);
    }
  };

  const setNextButtonAvailability = (surveyModel) => {
    if (overrideNextButtonEnabled) {
      var nextButton = document.querySelector(".sd-navigation__next-btn");
      var completeButton = document.querySelector(
        ".sd-navigation__complete-btn"
      );
      if (nextButton) {
        nextButton.disabled = surveyModel.currentPage.hasErrors(false, false);
      }
      if (completeButton) {
        completeButton.disabled = surveyModel.currentPage.hasErrors(
          false,
          false
        );
      }
    }
  };

  const handleOnUpdateQuestionCssClasses = (surveyModel, options) => {
    options.cssClasses.root += ` gl-question-root ${
      options.question.name
    } ${options.question.getType()}`;
  };

  return (
    <Grid container className={classes.surveyContainer}>
      {children}
      {surveyInstance ? (
        <Survey
          model={surveyInstance}
          focusFirstQuestionAutomatic={focusFirstQuestionAutomatic}
          onAfterRenderSurvey={handleOnAfterRenderSurvey}
          onCurrentPageChanging={handleOnCurrentPageChanging}
          onCurrentPageChanged={handleOnCurrentPageChanged}
          onAfterRenderPage={handleOnAfterRenderPage}
          onValueChanged={handleOnValueChanged}
          onUpdateQuestionCssClasses={handleOnUpdateQuestionCssClasses}
          onComplete={handleOnComplete}
        />
      ) : (
        <Grid container className={classes.loadingContainer}>
          <LoadingAnimation />
        </Grid>
      )}
    </Grid>
  );
};

export default SurveyWrapper;

SurveyWrapper.propTypes = {
  surveyId: PropTypes.string.isRequired,
  onCurrentPageChanging: PropTypes.func
};
