import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import OptionComponent from "../../../sharedComponents/OptionComponent";
import CheckCirleIcon from "../../../../assets/images/CheckCirleIcon.svg";
import OutlineCheck from "../../../../assets/images/OutlineCheck.svg";
import Translate from "../../../../translate/Translate";

const CheckboxForm = (props) => {
  const {
    options,
    selectedOptions,
    selectOptionHandler,
    isMutuallyExclusive,
    index,
    isSurveyQuestion,
    allOption
  } = props;
  const optionArray = selectedOptions ? selectedOptions : options;
  const [optionsObject, setOptions] = useState(optionArray);
  const selectAllButton = allOption
    ? selectedOptions.find((option) => {
        return option.id === allOption;
      })
    : null;
  const dispatch = useDispatch();
  useEffect(() => {
    const selectedOptionArray = selectedOptions
      ? selectedOptions.map((option) => {
          if (
            selectAllButton &&
            allOption &&
            option.id !== allOption &&
            selectAllButton.isChecked
          ) {
            return { ...option, isChecked: false };
          }
          return option;
        })
      : options;

    setOptions(selectedOptionArray);
  }, [selectedOptions, options, allOption, selectAllButton]);

  const handleArrayMapping = (option, optionId) => {
    if (option.id === optionId) {
      return { ...option, isChecked: !option.isChecked };
    }
    if (isMutuallyExclusive) {
      return { ...option, isChecked: false };
    }
    if (selectAllButton && selectAllButton.isChecked) {
      return { ...option, isChecked: false };
    }
    if (allOption && option.id === allOption) {
      return { ...option, isChecked: false };
    }
    return option;
  };

  const setOption = (optionId) => {
    const optionIndex = optionsObject.findIndex((option) => {
      return option.id === optionId;
    });

    if (optionIndex !== -1) {
      const newArray = Object.assign([], optionsObject);

      const modifiedArray = newArray.map((option) => {
        return handleArrayMapping(option, optionId);
      });
      setOptions(modifiedArray);
      if (selectOptionHandler) {
        selectOptionHandler(modifiedArray, index);
      }
    }
  };
  const onclickFunction = (option) => {
    const { persistHandler } = option;

    setOption(option.id);
    if (persistHandler) {
      dispatch(persistHandler(!option.isChecked));
    }
  };

  return (
    <Grid container>
      {optionsObject
        ? optionsObject.map((option, index) => (
            <Grid
              container
              item
              xs={12}
              lg={isSurveyQuestion ? 6 : 12}
              justifyContent="center"
              alignItems="center"
              key={`${index}-${option.value}`}
            >
              <OptionComponent
                onClick={() => onclickFunction(option)}
                isChecked={option.isChecked}
                option={option.value}
                isSurveyQuestion={isSurveyQuestion}
                answer={<Translate text={option.label} />}
                icon={
                  !option.isChecked ? (
                    <img alt="check Icon" src={OutlineCheck} />
                  ) : (
                    <img src={CheckCirleIcon} alt="check Icon" />
                  )
                }
              />
            </Grid>
          ))
        : null}
    </Grid>
  );
};

export default CheckboxForm;
