import React from "react";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useHistory } from "react-router-dom";
import InfoStepComponentStyles from "./InfoStepComponent.styles";
import Translate from "../../../../translate/Translate";
import { PATHS } from "../../../../constants/pathConstants";

const InfoStepComponent = (props) => {
  const { img, text } = props;
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const classes = InfoStepComponentStyles();
  const history = useHistory();

  return (
    <Grid container spacing={3} className={classes.content}>
      <Grid item xs={12} className={classes.boxButton}>
        <Button
          className={classes.button}
          onClick={() => history.push(PATHS.TERMS_OF_SERVICE)}
          id={"skip-intro-button"}
        >
          <Translate text="SKIP INTRO" />
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.infoStep}>
        <Grid item>
          <img src={img} className={classes.img} alt="DNA" />
        </Grid>
        <Grid item className={classes.boxTitle}>
          <Typography variant={smDown ? "h6" : "h5"} className={classes.title}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InfoStepComponent;
