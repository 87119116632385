import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Translate from "../../../../../translate/Translate";
//Constants
import { LABELS } from "../../../../../constants/labelConstants";
import LoadingBarsGif from "../../../../../assets/images/LoadingBars.gif";
//Styles
import LoadingAnimationStyles from "./LoadingAnimation.styles";

const LoadingAnimation = (props) => {
  const { loadingText } = props;
  const classes = LoadingAnimationStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      className={classes.loadingContainer}
      container
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={LoadingBarsGif}
          style={
            smDown ? { width: 80, height: 39 } : { width: 160, height: 78 }
          }
          alt={`${LABELS.LABEL_CANCER_GUARDIAN_LOGO_ALT_TEXT}`}
        />
        <Grid item className={classes.boxTitle}>
          <Typography variant={smDown ? "h2" : "h1"} className={classes.title}>
            <Translate text={loadingText ? loadingText : "Loading ..."} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default LoadingAnimation;
