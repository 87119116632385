import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const LinkBoxGridStyles = makeStyles((theme) => ({
  container: {
    padding: "0 55px 50px 55px",
    margin: "0px 0 40px"
  },
  linkBoxContainer: {
    display: "flex"
  },
  linkBox: {
    borderBottom: `1px solid ${COLOR_CONSTANTS.COLOR_GRAY_30}`,
    width: "95%",
    paddingBottom: "25px",
    marginBottom: "30px"
  },
  header: {
    marginBottom: 15,
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px"
  },
  link: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY,
    "& h5": {
      fontSize: "18px",
      fontWeight: 400,
      color: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY,
      marginLeft: "30px",
      marginBottom: "10px"
    }
  }
}));

export default LinkBoxGridStyles;
