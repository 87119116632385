export const US_STATES = [
  { label: "Alabama", id: "AL", value: "AL" },
  { label: "Alaska", id: "AK", value: "AK" },
  { label: "Arizona", id: "AZ", value: "AZ" },
  { label: "Arkansas", id: "AR", value: "AR" },
  { label: "American Samoa", id: "AS", value: "AS" },
  { label: "California", id: "CA", value: "CA" },
  { label: "Colorado", id: "CO", value: "CO" },
  { label: "Connecticut", id: "CT", value: "CT" },
  { label: "Delaware", id: "DE", value: "DE" },
  { label: "District of Columbia", id: "DC", value: "DC" },
  { label: "Florida", id: "FL", value: "FL" },
  { label: "Georgia", id: "GA", value: "GA" },
  { label: "Guam", id: "GU", value: "GU" },
  { label: "Hawaii", id: "HI", value: "HI" },
  { label: "Idaho", id: "ID", value: "ID" },
  { label: "Illinois", id: "IL", value: "IL" },
  { label: "Indiana", id: "IN", value: "IN" },
  { label: "Iowa", id: "IA", value: "IA" },
  { label: "Kansas", id: "KS", value: "KS" },
  { label: "Kentucky", id: "KY", value: "KY" },
  { label: "Louisiana", id: "LA", value: "LA" },
  { label: "Maine", id: "ME", value: "ME" },
  { label: "Maryland", id: "MD", value: "MD" },
  { label: "Massachusetts", id: "MA", value: "MA" },
  { label: "Michigan", id: "MI", value: "MI" },
  { label: "Minnesota", id: "MN", value: "MN" },
  { label: "Mississippi", id: "MS", value: "MS" },
  { label: "Missouri", id: "MO", value: "MO" },
  { label: "Montana", id: "MT", value: "MT" },
  { label: "Nebraska", id: "NE", value: "NE" },
  { label: "Nevada", id: "NV", value: "NV" },
  { label: "New Hampshire", id: "NH", value: "NH" },
  { label: "New Jersey", id: "NJ", value: "NJ" },
  { label: "New Mexico", id: "NM", value: "NM" },
  { label: "New York", id: "NY", value: "NY" },
  { label: "North Carolina", id: "NC", value: "NC" },
  { label: "North Dakota", id: "ND", value: "ND" },
  { label: "Northern Mariana Islands", id: "MP", value: "MP" },
  { label: "Ohio", id: "OH", value: "OH" },
  { label: "Oklahoma", id: "OK", value: "OK" },
  { label: "Oregon", id: "OR", value: "OR" },
  { label: "Pennsylvania", id: "PA", value: "PA" },
  { label: "Puerto Rico", id: "PR", value: "PR" },
  { label: "Rhode Island", id: "RI", value: "RI" },
  { label: "South Carolina", id: "SC", value: "SC" },
  { label: "South Dakota", id: "SD", value: "SD" },
  { label: "Tennessee", id: "TN", value: "TN" },
  { label: "Texas", id: "TX", value: "TX" },
  { label: "Trust Territories", id: "TT", value: "TT" },
  { label: "Utah", id: "UT", value: "UT" },
  { label: "Vermont", id: "VT", value: "VT" },
  { label: "Virginia", id: "VA", value: "VA" },
  { label: "Virgin Islands", id: "VI", value: "VI" },
  { label: "Washington", id: "WA", value: "WA" },
  { label: "West Virginia", id: "WV", value: "WV" },
  { label: "Wisconsin", id: "WI", value: "WI" },
  { label: "Wyoming", id: "WY", value: "WY" }
];
