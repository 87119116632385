import { createTheme } from "@mui/material";
import { adaptV4Theme } from "@mui/material/styles";
import {
  COLOR_CONSTANTS,
  FONT_CONSTANTS,
  FLEX_CONSTANTS,
  GENERIC_CONSTANTS
} from "../constants/StyleConstants";
import { themeBreakpoints } from "./themeBreakpoints";
import { themeOverrides } from "./themeOverrides";
import { themePalette } from "./themePalette";
import { themeTypography } from "./themeTypography";
import { TYPOGRAPHY, TYPOGRAPHY_VARIANT_MAPPING } from "./v2/typography";

let theme = createTheme();

const Theme = createTheme({
  ...theme,
  breakpoints: themeBreakpoints,
  overrides: themeOverrides,
  palette: themePalette,
  typography: { ...themeTypography, ...TYPOGRAPHY },
  components: {
    ...themeOverrides,
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          v2_h2: "h2",
          v2_h3: "h3",
          v2_h4: "h4",
          v2_h5: "h5"
        }
      }
    }
  },
  paddings: {
    xs: 10,
    sm: 15,
    md: 20,
    lg: 25,
    xl: 30,
    xxl: 40,
    xxxl: 65
  },
  margins: {
    xs: 10,
    sm: 15,
    md: 20,
    lg: 25,
    xl: 30,
    xxl: 40,
    xxxl: 65
  },
  header: {
    display: FLEX_CONSTANTS.FLEX,
    backgroundColor: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
    height: 100,
    color: COLOR_CONSTANTS.COLOR_WHITE,
    fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_HEAVY,
    justifyContent: FLEX_CONSTANTS.CENTER,
    alignItems: FLEX_CONSTANTS.CENTER
  },
  footer: {
    primary: {
      container: {
        backgroundColor: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY
      },
      h2: {
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_DOMAIN_DISPLAY_SEMIBOLD,
        fontStyle: FONT_CONSTANTS.FONT_SIZE_NORMAL,
        fontWeight: 600,
        fontSize: 20,
        display: FLEX_CONSTANTS.FLEX,
        alignItems: FLEX_CONSTANTS.CENTER,
        textAlign: FLEX_CONSTANTS.CENTER,
        color: COLOR_CONSTANTS.COLOR_WHITE,
        marginTop: 30
      },
      body: {
        fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
        fontWeight: 400,
        fontSize: 14,
        textAlign: FLEX_CONSTANTS.CENTER,
        color: COLOR_CONSTANTS.COLOR_WHITE,
        marginTop: 20,
        marginBottom: 20
      },
      contact: {
        fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
        fontWeight: 400,
        fontSize: 16,
        textAlign: FLEX_CONSTANTS.LEFT,
        marginBottom: 15,
        color: COLOR_CONSTANTS.COLOR_WHITE,
        textDecoration: GENERIC_CONSTANTS.NONE
      }
    },
    secondary: {
      container: {
        backgroundColor: COLOR_CONSTANTS.COLOR_GRAY_60
      },
      h2: {
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_DOMAIN_DISPLAY_SEMIBOLD,
        fontStyle: FONT_CONSTANTS.FONT_SIZE_NORMAL,
        fontWeight: 600,
        fontSize: 20,
        display: FLEX_CONSTANTS.FLEX,
        alignItems: FLEX_CONSTANTS.CENTER,
        textAlign: FLEX_CONSTANTS.CENTER,
        color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
        marginTop: 30
      },
      body: {
        fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
        fontWeight: 400,
        fontSize: 14,
        textAlign: FLEX_CONSTANTS.CENTER,
        color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
        marginTop: 20,
        marginBottom: 20
      },
      contact: {
        fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
        fontWeight: 400,
        fontSize: 16,
        textAlign: FLEX_CONSTANTS.LEFT,
        marginBottom: 15,
        color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY,
        textDecoration: GENERIC_CONSTANTS.NONE
      }
    }
  },
  card: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderDarker: "1px solid rgba(0, 0, 0, 0.25)"
  },
  scrollPrimaryColors: {
    "&::-webkit-scrollbar": {
      width: 10,
      borderRadius: 100,
      backgroundColor: COLOR_CONSTANTS.COLOR_GRAY_60
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      background: COLOR_CONSTANTS.GENOMIC_LIFE_LINK_COLOR,
      borderRadius: 100
    },
    "scrollbar-color": `${COLOR_CONSTANTS.GENOMIC_LIFE_LINK_COLOR} ${COLOR_CONSTANTS.COLOR_GRAY_60}`,
    "scrollbar-width": "inherit"
  }
});
export default Theme;
