import React from "react";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useHistory } from "react-router-dom";
import IntroInfoStyles from "./IntroInfo.styles";
import HeaderLogo from "../../../../assets/images/GL-Logo.svg";
import { LABELS } from "../../../../constants/labelConstants";
import { PATHS } from "../../../../constants/pathConstants";
import Translate from "../../../../translate/Translate";

const IntroInfo = (props) => {
  const { onSkip } = props;
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const classes = IntroInfoStyles();
  const history = useHistory();

  return (
    <Grid container className={classes.contentIntro}>
      <Grid item>
        <Button
          className={classes.button}
          onClick={() => {
            if (onSkip) {
              onSkip();
            }
            history.push(PATHS.TERMS_OF_SERVICE);
          }}
        >
          <Translate text="SKIP INTRO" />
        </Button>
      </Grid>
      <Grid item>
        <img
          src={HeaderLogo}
          className={classes.headerLogo}
          alt={`${LABELS.LABEL_CANCER_GUARDIAN_LOGO_ALT_TEXT}`}
        />
      </Grid>
      <Grid item className={classes.boxTitle}>
        <Typography variant={smDown ? "h2" : "h1"} className={classes.title}>
          <Translate text="What is included in your" />{" "}
          <span className={classes.membership}>
            <Translate text="membership" />
          </span>
          ?
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.boxSubTitle}>
        <Typography variant={smDown ? "h6" : "h5"} className={classes.subTitle}>
          <Translate text="At Genomic Life, we believe" />{" "}
          <span className={classes.everyone}>
            <Translate text="everyone" />
          </span>{" "}
          <Translate
            text="deserves access
            to genomic medicine and we are committed to closing the gap between
            genomic science and healthcare."
          />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IntroInfo;
