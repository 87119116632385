import Translate from "../../../../../../../translate/Translate";
import React from "react";
import {
  selectSpouseInformation,
  selectUserPersonalInformation
} from "../../../../../../../store/reducers/user/UserAccountSlice";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PATHS } from "../../../../../../../constants/pathConstants";
import ToDoListCardContainer from "../../ToDoListCardContainer";
import GLButton from "../../../../../sharedComponents/GLButton";
import inviteSpouseIcon from "../../../../../../../assets/icons/icon-invite-spouse.svg";
import Mixpanel from "../../../../../../../utils/mixpanel";

const InviteSpouseToDoCard = () => {
  const history = useHistory();
  const spouseInformation = useSelector(selectSpouseInformation);
  const isMemberActive = useSelector(selectUserPersonalInformation)
    .isMemberActive;

  if (!spouseInformation) return null;

  const showSpouseCard = spouseInformation.canInviteASpouse;

  if (!showSpouseCard) return null;

  const spouseHasBeenInvitedButHasntAccepted =
    showSpouseCard && spouseInformation && spouseInformation.lastInvited;

  const title = <Translate text={"Invite your spouse"} />;

  const renderBody = () => {
    if (spouseHasBeenInvitedButHasntAccepted) {
      return (
        <>
          <Translate text={"You invited your spouse"} /> 3{" "}
          <Translate
            text={
              "days ago, but they haven't confirmed their account. Invite them again!"
            }
          />
        </>
      );
    } else {
      return (
        <Translate
          text={"You can invite your spouse. Let’s get them started."}
        />
      );
    }
  };

  const renderButton = () => {
    const buttonText = spouseHasBeenInvitedButHasntAccepted
      ? "RE-INVITE"
      : "INVITE";
    return (
      <GLButton
        text={buttonText}
        size={"small"}
        disabled={!isMemberActive}
        onClick={() => {
          Mixpanel.track(`To Do Card CTA Clicked - Invite spouse`);
          history.push(PATHS.INVITE_SPOUSE);
        }}
      />
    );
  };

  return (
    <ToDoListCardContainer
      icon={inviteSpouseIcon}
      title={title}
      body={renderBody()}
      buttons={renderButton()}
    ></ToDoListCardContainer>
  );
};

export default InviteSpouseToDoCard;
