import { makeStyles } from "@mui/styles";

const OptionCardsStyles = makeStyles((theme) => ({
  iconFontSize: {
    [theme.breakpoints.up("md")]: {
      fontSize: 38
    }
  },
  billingIcon: {
    [theme.breakpoints.down('md')]: {
      width: 22
    }
  }
}));

export default OptionCardsStyles;
