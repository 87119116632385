import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  snackbarStatus: {
    open: false,
    variant: "",
    header: "",
    subText: ""
  }
};

export const openGLSnackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    closeSnackbar(state) {
      state.snackbarStatus.open = false;
      state.snackbarStatus.variant = "";
      state.snackbarStatus.header = "";
      state.snackbarStatus.subText = "";
    },
    openGLSnackbar(state, { payload }) {
      const { variant, header, subText } = payload;
      state.snackbarStatus.open = true;
      state.snackbarStatus.variant = variant;
      state.snackbarStatus.header = header;
      state.snackbarStatus.subText = subText;
    }
  }
});

export const {
  closeSnackbar,
  openGLSnackbar
} = openGLSnackbarSlice.actions;

export const selectSnackbarStatus = (state) =>
  state.snackbar ? state.snackbar.snackbarStatus : null;

export default openGLSnackbarSlice.reducer;
