import { Notifications, Person, Security } from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import React /*{ useState }*/ from "react";
//import { useSelector } from "react-redux";
import { PATHS } from "../../../../constants/pathConstants";
import OptionCard from "../../../sharedComponents/OptionCard";
import OptionCardsStyles from "./OptionCard.styles";
import PaymentIcon from "../../../../assets/images/PaymentIcon.svg";
/*import ConnectionTypesModal from "../ConnectionsPage/ConnectionTypesModal";
import {
  selectConnectionTypes,
  totalOfConnections
} from "../../../../store/reducers/connection/ConnectionsInformationSlice";*/

const OptionCards = () => {
  const classes = OptionCardsStyles();
  //const [open, setOpen] = useState(false);
  //const totalConnections = useSelector(totalOfConnections);
  //const connectionTypes = useSelector(selectConnectionTypes);
  //const handleOpen = () => setOpen(true);
  //const handleClose = () => setOpen(false);

  const optionCardElements = [
    {
      id: "mem-info",
      option: "Member Information",
      icon: (
        <Person style={{ color: "#294764" }} className={classes.iconFontSize} />
      ),
      redirectTo: PATHS.ACCOUNT_MANAGEMENT_MEMBER_INFORMATION
    },
    {
      id: "pay-info",
      option: "Payment Information",
      icon: (
        <img
          src={PaymentIcon}
          className={classes.billingIcon}
          alt="Payment Information"
        />
      ),
      redirectTo: PATHS.ACCOUNT_MANAGEMENT_PAYMENT_INFORMATION
    },
    {
      id: "connect-sec",
      option: "Connections",
      icon: (
        <GroupIcon
          style={{ color: "#294764" }}
          className={classes.iconFontSize}
        />
      ),
      redirectTo:
        PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS /* totalConnections > 0
      ? PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS
      : () => handleOpen()*/,
      itsNew: true
    },
    {
      id: "pri-sec",
      option: "Privacy & Security",
      icon: (
        <Security
          style={{ color: "#294764" }}
          className={classes.iconFontSize}
        />
      ),
      redirectTo: PATHS.ACCOUNT_MANAGEMENT_PRIVACY_AND_POLICY
    },
    {
      id: "not-set",
      option: "Notification Settings",
      icon: (
        <Notifications
          style={{ color: "#294764" }}
          className={classes.iconFontSize}
        />
      ),
      redirectTo: PATHS.ACCOUNT_MANAGEMENT_NOTIFICATIONS_SETTINGS
    }
  ];

  return (
    <div>
      {/*<ConnectionTypesModal
        open={open}
        connections={connectionTypes}
        handleClose={handleClose}
  />*/}
      {optionCardElements.map((element) => (
        <OptionCard
          icon={element.icon}
          text={element.option}
          key={element.id}
          redirectTo={element.redirectTo}
          chip={element.itsNew}
        />
      ))}
    </div>
  );
};

export default OptionCards;
