import { makeStyles } from "@mui/styles";

const ModalWrapperStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalContainer: {
    borderRadius: 10,
    padding: 25,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  }
}));
export default ModalWrapperStyles;
