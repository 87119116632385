import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const ConnectionManagmentStepStyles = makeStyles((theme) => ({
  connectionContainer: {
    marginTop: theme.paddings.xxl,
    marginBottom: 0
  },
  titleText: {
    color: COLOR_CONSTANTS.COLOR_TITLES
  },
  contactInformationCard: {
    marginTop: theme.paddings.sm
  },
  counterContainer: {
    marginTop: 25,
    marginBottom: 0
  }
}));

export default ConnectionManagmentStepStyles;
