import { makeStyles } from "@mui/styles";

const LookingForMoreStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.primary.font_blue,
    paddingBottom: 40
  },
  questionCards: {
    marginTop: 20,
    cursor: `pointer`
  },
  cardsContainer: {
    [theme.breakpoints.down('md')]: {
      width: `95vw`
    },
    [theme.breakpoints.only("sm")]: {
      width: `70vw`
    },
    [theme.breakpoints.only("sm")]: {
      width: `70vw`
    },
    [theme.breakpoints.only("md")]: {
      width: `95vw`
    },
    [theme.breakpoints.only("lg")]: {
      width: `70vw`
    },
    [theme.breakpoints.up("xl")]: {
      width: `40vw`
    },
    [theme.breakpoints.up(3000)]: {
      width: `25vw`
    }
  },
  gotQuestionTitle: {
    marginTop: 20,
    marginBottom: 11,
    color: theme.palette.primary.white
  }
}));

export default LookingForMoreStyles;
