import { makeStyles } from "@mui/styles";
import { FONT_CONSTANTS } from "../../../constants/StyleConstants";
const QcardStyles = makeStyles((theme) => ({
  cardContainer: {
    width: 180,
    height: 180,
    borderRadius: 5,
    backgroundColor: theme.palette.primary.white,
    padding: 6
  },
  cardTitleContainer: { height: 60 },
  cardTitle: {
    marginLeft: 20,
    marginTop: 15,
    fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_HEAVY,
    fontSize: 14,
    lineHeight: `18px`,
    color: theme.palette.primary.font_blue,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      lineHeight: `24px`
    }
  },
  imageContainer: { minHeight: 100 },
  iconSize: { maxHeight: 100 },
  cardIcon: {
    marginBottom: 20
  }
}));

export default QcardStyles;
