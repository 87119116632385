import React from "react";
import { Box, Container, Typography, Link } from "@mui/material";
import { ENVIRONMENT_CONSTANTS } from "../../../constants/environmentConstants";
import iconContactUsPhone from "../../../assets/images/icons_ContactUsPhone.svg";
import iconContactUsMail from "../../../assets/images/icons_ContactUsMail.svg";
import iconContactUsPhoneSecondary from "../../../assets/images/phone_dark.svg";
import iconContactUsMailSecondary from "../../../assets/images/mail_dark.svg";
import { useTheme } from "@mui/styles";
import ContactUsStyles from "./ContactUs.styles";
import Translate from "../../../translate/Translate";
export default function ContactUs(props) {
  const { type } = props;
  const theme = useTheme();
  const classes = ContactUsStyles();
  const MEMBER_SERVICES_EMAIL =
    process.env[
      ENVIRONMENT_CONSTANTS.REACT_APP_MEMBER_PORTAL_MEMBER_SERVICES_EMAIL
    ];

  return (
    <Container
      maxWidth={false}
      style={
        type ? theme.footer[type].container : theme.footer.primary.container
      }
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography
          variant="h2"
          component="h2"
          style={type ? theme.footer[type].h2 : theme.footer.primary.h2}
        >
          <Translate text="Contact Us" />
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography
          align="center"
          style={type ? theme.footer[type].body : theme.footer.primary.body}
        >
          <Translate text="Genomic Life has a team of Genetic Health Navigators available to support you." />
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box display="block" justifyContent="left" alignItems="left">
          <Typography
            align="left"
            display="block"
            style={
              type ? theme.footer[type].contact : theme.footer.primary.contact
            }
          >
            <Link
              href={`mailto: ${MEMBER_SERVICES_EMAIL}`}
              style={
                type ? theme.footer[type].contact : theme.footer.primary.contact
              }
              underline="hover">
              <img
                src={
                  type === "secondary"
                    ? iconContactUsMailSecondary
                    : iconContactUsMail
                }
                className={classes.icon}
                alt="contact us mail icon"
                style={{ color: "blue" }}
              />
              {MEMBER_SERVICES_EMAIL}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
