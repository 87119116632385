import axios from "axios";
import { getCookie } from "../utils/CookieUtil";

export function formatDateStringForBackEnd(dateString) {
  const parts = dateString.split("/");
  const year = parts[2];
  const month = parts[0].padStart(2, "0");
  const day = parts[1].padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function doApiCall(request) {
  const accessToken = getCookie("accessToken");
  request.headers = {
    "content-type": "application/json",
    authorization: accessToken
  };

  return axios(request)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}
