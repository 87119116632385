import { makeStyles } from "@mui/styles";
import { GENERIC_CONSTANTS } from "../../../../constants/StyleConstants";

const AccountManagementPrivacySecurityStyles = makeStyles((theme) => ({
  backToContainer: {
    marginTop: 13,
    marginBottom: 60,
    [theme.breakpoints.down('md')]: {
      marginRight: `35%`
    },
    [theme.breakpoints.between("sm", 'lg')]: {
      marginRight: `60%`
    },
    [theme.breakpoints.between("md", 'xl')]: {
      marginRight: `74%`
    },
    [theme.breakpoints.between("lg", "xl")]: {
      marginRight: `65%`
    },
    [theme.breakpoints.up("xl")]: {
      marginRight: `55%`
    }
  },
  privacyPolicyTitle: {
    marginBottom: 40
  },
  secureInformationContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: 130,
      marginBottom: 25
    },
    [theme.breakpoints.between("md", 'xl')]: {
      marginTop: 340,
      marginBottom: 81
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 135,
      marginBottom: 42
    }
  },
  linkButton: {
    textDecoration: GENERIC_CONSTANTS.NONE
  }
}));

export default AccountManagementPrivacySecurityStyles;
