import { makeStyles } from "@mui/styles";

const SessionTimerDialogStyles = makeStyles((theme) => ({
  modalContainer: {
    borderRadius: 10,
    padding: 25,
    [theme.breakpoints.down('md')]: {
      width: 336,
      height: 323
    },
    [theme.breakpoints.between("sm", 'lg')]: {
      width: 506,
      height: 283
    },
    [theme.breakpoints.up("md")]: {
      width: 524,
      height: 283
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  },
  modalTitle: {
    fontSize: `24px !important`,
    color: theme.palette.primary.link,
    lineHeight: `28px`,
    [theme.breakpoints.between("sm", 'lg')]: {
      marginBottom: 10
    }
  },
  closeIcon: {
    cursor: `pointer`,
    marginLeft: 25,
    marginTop: `-10px`,
    [theme.breakpoints.between("sm", 'lg')]: {
      marginLeft: 25
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10
    }
  },
  dismissBtn: {
    color: theme.palette.primary.cancel_button_text
  },
  modalFooterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    [theme.breakpoints.down(475)]: {
      justifyContent: "space-evenly"
    }
  },
  second: {
    color: theme.palette.primary.cancel_button_text,
    fontSize: 18
  }
}));

export default SessionTimerDialogStyles;
