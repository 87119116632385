import React from "react";
import NoButtonsInfoDialog from "../ModalComponents/NoButtonsInfoDialog";
import Translate from "../../../../../translate/Translate";
import SupportContactInfoBlock from "../../SupportContactInfoBlock/SupportContactInfoBlock";

const NeedSupportModal = (props) => {
  const { headerText, isOpen, onClose } = props;

  return (
    <NoButtonsInfoDialog
      isOpen={isOpen}
      headerText={
        <Translate text={`${headerText ? headerText : "Need support?"}`} />
      }
      onClose={onClose}
    >
      <SupportContactInfoBlock />
    </NoButtonsInfoDialog>
  );
};

export default NeedSupportModal;
