import { doApiCall } from "./util";
import Mixpanel from "../utils/mixpanel";

const { REACT_APP_MEMBER_PORTAL_ENDPOINT_URL } = process.env;

export const postCancerDiagnosis = (diagnosis) => {
  if (diagnosis) {
    Mixpanel.track("Cancer reported");
  } else {
    Mixpanel.track("Cancer remission reported");
  }
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/cancer-status`,
    data: { cancerStatus: diagnosis },
    body: JSON.stringify({ cancerStatus: diagnosis })
  };

  return doApiCall(request);
};

export const getCancerDiagnosis = () => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "GET",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/cancer-status`
  };

  return doApiCall(request);
};
