import React, { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import QAMatrixStyles from "./QAMatrix.styles";
import {
  KeyboardArrowRight,
  ExitToApp,
  PictureAsPdf,
  VideoLibrary
} from "@mui/icons-material";
import VideoModal from "../../../sharedComponents/Modals/VideoModal";

const QAMatrixCard = (props) => {
  const { cardJson } = props;
  const classes = QAMatrixStyles();
  const [videoIsOpen, setVideoIsOpen] = useState(false);

  const renderCardContents = (icon) => {
    return (
      <Grid className={classes.cardContainer}>
        <Grid container>
          <Grid item xs={11} className={classes.cardHeaderContainer}>
            <Typography variant="h6">{cardJson.header.en}</Typography>
          </Grid>
          <Grid item xs={1}>
            {icon}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderLinkTypeCard = (icon, href, isExternal) => {
    return (
      <Grid item xs={6}>
        <a
          href={href}
          className={classes.cardLink}
          target={isExternal ? "_blank" : ""}
        >
          {renderCardContents(icon)}
        </a>
      </Grid>
    );
  };

  const renderVideoTypeCard = (icon, videoId) => {
    return (
      <>
        <VideoModal
          channel="youtube"
          autoplay
          isOpen={videoIsOpen}
          videoId={videoId}
          onClose={() => setVideoIsOpen(false)}
        />

        <Grid item xs={6}>
          <Box onClick={() => setVideoIsOpen(true)}>
            {renderCardContents(icon)}
          </Box>
        </Grid>
      </>
    );
  };

  const renderCardBasedOnLinkType = () => {
    if (!!cardJson.externalLink) {
      return renderLinkTypeCard(
        <ExitToApp className={classes.iconFontSize} />,
        cardJson.externalLink
      );
    } else if (!!cardJson.internalLink) {
      return renderLinkTypeCard(
        <KeyboardArrowRight className={classes.iconFontSize} />,
        cardJson.internalLink
      );
    } else if (!!cardJson.pdfLink) {
      return renderLinkTypeCard(
        <PictureAsPdf className={classes.iconFontSize} />,
        cardJson.pdfLink
      );
    } else if (!!cardJson.youtubeLink) {
      return renderVideoTypeCard(
        <VideoLibrary className={classes.iconFontSize} />,
        cardJson.youtubeLink.videoId
      );
    } else return null;
  };

  return renderCardBasedOnLinkType();
};

export default QAMatrixCard;
