import React from "react";
import Layout from "../../../layout/Layout";
import { Grid } from "@mui/material";
import PageTitle from "../../../../utils/PageTitle";
import { PATHS } from "../../../../constants/pathConstants";
import Breadcrumb from "../../../sharedComponents/Breadcrumb";
import CancerSurvey from "../../Surveys/CancerReportingSurvey/Index";

const CancerSurveyPage = (props) => {
  PageTitle("Cancer diagnosis survey");

  return (
    <div>
      <Breadcrumb text={"Back to Dashboard"} redirectTo={PATHS.LANDING_PAGE} />
      <Layout headerType="logo" hasNoFooter={true}>
        <Grid container xs={12} alignItems="center" direction="column">
          <CancerSurvey />
        </Grid>
      </Layout>
    </div>
  );
};

export default CancerSurveyPage;
