import { makeStyles } from "@mui/styles";
import { GENERIC_CONSTANTS } from "../../../../constants/StyleConstants";
const SetNotificationPageStyles = makeStyles((theme) => ({
  body: {
    marginTop: theme.paddings.sm,
    marginBottom: theme.paddings.sm
  },
  subtitle: {
    marginBottom: theme.paddings.sm
  },
  cardContainer: {
    width: "100%",
    marginBottom: theme.paddings.sm
  },
  cardDescription: {
    ...theme.typography.subtitleRegular,
    color: theme.palette.secondary.gray_button,
    marginLeft: 50,
    marginRight: 60,
    [theme.breakpoints.down('md')]: {
      marginLeft: 40
    }
  },
  link: {
    textDecoration: GENERIC_CONSTANTS.NONE,
    color: theme.palette.primary.link
  },
  cardTitleCheckBox: {
    ...theme.typography.subtitleHeavy,
    color: theme.palette.primary.link,
    marginTop: 27,
    marginLeft: 20
  },
  chipEmail: {
    backgroundColor: theme.palette.primary.chip_blue,
    marginLeft: 40,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginLeft: 20
    }
  },
  checkboxEmail: {
    marginTop: 20,
    marginLeft: 20
  },
  boldChip: {
    fontWeight: 700
  }
}));

export default SetNotificationPageStyles;
