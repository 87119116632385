import { useEffect } from "react";
import { useLogout } from "../../../../hooks/useLogout";

const LogutPage = () => {
  const loggoutFunction = useLogout();

  useEffect(() => {
    loggoutFunction();
  }, []);

  return null;
};

export default LogutPage;
