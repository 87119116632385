import React, { Fragment } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PATHS } from "../../../../../../constants/pathConstants";
import DeleteConnectionDialogStyles from "./DeleteConnectionDialog.styles";
import {
  deleteConnection,
  selectConnectionId,
  setConnectionId
} from "../../../../../../store/reducers/connection/ConnectionsInformationSlice";
import Translate from "../../../../../../translate/Translate";
import { deleteConnectionByID } from "../../../../../../apis/connectionsApi";

const DeleteConnectionDialog = (props) => {
  const { handleClose, connection } = props;
  const classes = DeleteConnectionDialogStyles();
  const history = useHistory();
  const dispatchReducer = useDispatch();
  const connectionId = useSelector(selectConnectionId);
  const deleteConnectionHandler = () => {
    dispatchReducer(deleteConnection(connection));
    deleteConnectionByID(connectionId)
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatchReducer(setConnectionId(""));
          history.push(PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS, {
            type: "delete"
          });
        }
      })
      .catch((error) => {
        console.error("error deleting connection", error);
      });
  };

  return (
    <Fragment>
      <Grid container className={classes.modalContainer} id="modal-container">
        <Grid container className={classes.modalTitleContainer}>
          <Grid item xs={10}>
            <Typography variant="h5" className={classes.title}>
              <Translate text="Do you want to remove this connection?" />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Close onClick={handleClose} className={classes.icon} />
          </Grid>
        </Grid>
        <Grid item className={classes.modalBodyContainer}>
          <Grid item className={classes.boxInfo}>
            <Typography variant="subtitle" className={classes.deleteInfo}>
              <Translate text="Removing this connection will delete all information associated between you and this person. This could also impact your membership plan coverage." />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.modalFooterContainer}>
          <Button
            id="confirm"
            onClick={deleteConnectionHandler}
            className={classes.removeBtn}
          >
            <Translate text="REMOVE" />
          </Button>
          <Button
            id="confirm"
            color="secondary"
            variant="contained"
            onClick={handleClose}
          >
            <Translate text="NEVERMIND" />
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DeleteConnectionDialog;
