import { makeStyles } from "@mui/styles";

const SectionHeaderStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.secondary.backgrd_blue,
    marginTop: theme.margins.xs,
    marginBottom: theme.margins.lg,
    width: "100%"
  }
}));

export default SectionHeaderStyles;
