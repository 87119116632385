import { Typography } from "@mui/material";
import React from "react";
import SecondaryHeaderStyles from "./SecondaryHeader.styles";
import Translate from "../../../../translate/Translate";

const SecondaryHeader = (props) => {
  let { headerText } = props;
  const classes = SecondaryHeaderStyles();
  return (
    <div className={classes.header}>
      <Typography variant="h2" className={classes.headerTitle} gutterBottom>
        <Translate text={headerText} />
      </Typography>
    </div>
  );
};

export default SecondaryHeader;
