import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  getPlacePredictions,
  getPlaceDetailsByPlaceId,
  parseFullAddress
} from "./placesApiUtils";
import { Autocomplete } from "@mui/lab";
import { Fade, Grid, TextField } from "@mui/material";
import { SharedFormFieldStyles } from "../../Form.styles";
import Translate from "../../../../../translate/Translate";
import { extractSizingProps } from "../../FormUtils";
import { focusElementByIdOnTab } from "../../../../../utils/utils";

/**
 * GLPlacesAutocomplete - Component that uses google places api to predict other field values
 * @param {string} label
 * @param {string} id
 * @param {*} value
 * @param {func} onChange
 * @param {boolean} required
 * @param {boolean} disabled
 * @param {func} additionalValidation
 * @param {boolean} autoSelect
 * @param {boolean} autoComplete
 * @param {string} fieldType field type allows the lookup value to be overriden with a subset from the place details
 * @param {string} linkFieldOnTab this parameters focuses to the field id passed  once the tab key is hit
 * @param {Array} dependantFields this array should be composed of
 * {id: the dependant field id, parameter: the places parameter that this will be related to}
 * @returns {JSX}
 */
const GLPlacesAutocomplete = (props) => {
  const {
    label,
    id,
    value,
    onChange,
    required,
    disabled,
    additionalValidation,
    autoSelect,
    autoComplete,
    fieldType,
    linkFieldOnTab,
    dependantFields,
    shouldResetDependantFields
  } = props;

  const classes = SharedFormFieldStyles();

  const [predictions, setPredictions] = useState([]);
  const [options, setOptions] = useState([]);

  const [hasFocus, setHasFocus] = useState(false);
  const [hasReceivedFocus, setHasReceivedFocus] = useState(false);

  const handleInputChange = (inputId, value) => {
    onChange({
      e: { target: { name: inputId, value: value } },
      isInvalid: !!calculateHelperText(value)
    });
  };

  const overrideContentByFieldType = (resultObject) => {
    let result = null;
    if (fieldType === "location") {
      result = resultObject.name;
    }
    if (fieldType === "address") {
      result = parseFullAddress(resultObject.address_components);
    }
    if (result) {
      handleInputChange(id, result);
    }
  };

  const fillDependantFields = (resultsObject) => {
    const addressComponents = resultsObject.address_components;
    const addressName = parseFullAddress(addressComponents);
    if (dependantFields && addressComponents) {
      dependantFields.forEach((field) => {
        addressComponents.forEach((component) => {
          if (component.types && component.types.includes(field.parameter)) {
            const value = component.short_name;
            handleInputChange(field.id, value);
          }
        });
        if (field.parameter === "location") {
          const locationName = resultsObject?.name;
          handleInputChange(field.id, locationName);
        }
        if (field.parameter === "fullAddress") {
          handleInputChange(field.id, addressName);
        }
      });
    }
  };

  const resetField = () => {
    setOptions([]);
    resetDependantFields();
  };

  const resetDependantFields = () => {
    if (
      shouldResetDependantFields &&
      dependantFields &&
      dependantFields.length > 0
    ) {
      dependantFields.forEach((field) => {
        handleInputChange(field.id, "");
      });
    }
  };

  const handlePlaceDetailsByPlaceId = async (placeId) => {
    const results = await getPlaceDetailsByPlaceId(placeId);
    if (results && fieldType) {
      overrideContentByFieldType(results);
    }
    if (results && dependantFields) {
      fillDependantFields(results);
    }
  };

  useEffect(() => {
    const handlePlacePrediction = async (value) => {
      const results = await getPlacePredictions(value);
      if (results) {
        setPredictions(results);
      }
    };
    if (value && value.length > 0) {
      handlePlacePrediction(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (predictions.length > 0) {
      setOptions(
        predictions.map((prediction) => {
          return {
            id: prediction.place_id,
            label: prediction.description,
            value: prediction.description
          };
        })
      );
    }
  }, [predictions]);

  const requiredFlag = (
    <Fade in={required && [null, undefined, ""].includes(value) && !hasFocus}>
      <div className={classes.requiredFlag}>Required</div>
    </Fade>
  );

  const calculateHelperText = (valueToValidate) => {
    if (required && (!valueToValidate || valueToValidate === "")) {
      return <Translate text="This field is required" />;
    }
    if (valueToValidate && valueToValidate.length > 0 && additionalValidation) {
      return additionalValidation(valueToValidate);
    }
    return "";
  };

  const optionalLabel = (
    <span>
      {" ("}
      <Translate text={required ? "required" : "optional"} />
      {")"}
    </span>
  );

  const translatedLabel = (
    <span>
      <Translate text={label} /> {optionalLabel}
    </span>
  );

  return (
    <Grid
      item
      {...extractSizingProps(props)}
      style={{ width: "100%" }}
      className={`${classes.inputContainer} `}
    >
      <Autocomplete
        options={options}
        freeSolo
        autoSelect={autoSelect}
        autoComplete={autoComplete}
        value={value}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.label
        }
        filterOptions={(x) => x}
        onChange={(event, newValue, reason) => {
          if (reason === "clear" || !newValue) {
            resetField();
          }
          if (newValue && newValue.id) {
            handlePlaceDetailsByPlaceId(newValue.id);
          }
        }}
        onInputChange={(event, value, reason) => {
          if (reason === "clear" || value === "") {
            resetField();
          }
          handleInputChange(id, value);
        }}
        isOptionEqualToValue={(option) => {
          return option ? option.value : "";
        }}
        renderInput={(params) => (
          <div>
            {requiredFlag}
            <TextField
              id={id}
              name={id}
              variant="outlined"
              label={translatedLabel}
              required={required}
              disabled={disabled}
              className={classes.textInput}
              value={value}
              helperText={
                !hasFocus && hasReceivedFocus
                  ? calculateHelperText(value)
                  : null
              }
              error={
                hasReceivedFocus && !hasFocus && !!calculateHelperText(value)
              }
              fullWidth={true}
              onFocus={() => {
                setHasReceivedFocus(true);
                setHasFocus(true);
              }}
              onBlur={() => {
                setHasFocus(false);
              }}
              onKeyDown={(e) => {
                if (linkFieldOnTab) {
                  focusElementByIdOnTab(e, linkFieldOnTab);
                }
              }}
              {...params}
            />
          </div>
        )}
      />
    </Grid>
  );
};
export default GLPlacesAutocomplete;

GLPlacesAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalValidation: PropTypes.func,
  autoSelect: PropTypes.bool,
  autoComplete: PropTypes.bool,
  fieldType: PropTypes.string,
  linkFieldOnTab: PropTypes.string,
  dependantFields: PropTypes.arrayOf(PropTypes.object)
};
GLPlacesAutocomplete.defaultProps = {
  value: ""
};
