import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const DashboardPageStyles = makeStyles((theme) => ({
  diagnosedTitle: {
    textAlign: `center`,
    marginTop: 20,
    marginBottom: "30px",
    color: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY
  },
  submitButtonContainer: {
    marginTop: 10,
    paddingBottom: 80
  }
}));

export default DashboardPageStyles;
