import { makeStyles } from "@mui/styles";
import { FLEX_CONSTANTS } from "../../../constants/StyleConstants";

const LoadingPageStylesStyles = makeStyles((theme) => ({
  root: {
    display: FLEX_CONSTANTS.FLEX,
    flexDirection: FLEX_CONSTANTS.COLUMN,
    flex: 1,
    justifyContent: FLEX_CONSTANTS.FLEX_END
  },
  body: {
    marginTop: theme.spacing(2)
  },
  containerSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  containerSpacingLarge: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  buttonContainer: { marginTop: 75 },
  loadingGif: {
    width: 80,
    height: 39
  },
  loadingContainer: {
    backgroundColor: "#FFFFFFD9",
    height: "100%"
  }
}));

export default LoadingPageStylesStyles;
