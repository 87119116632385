import { makeStyles } from "@mui/styles";

const InactiveMembershipStyles = makeStyles((theme) => ({
  main: {
    display: `flex`,
    backgroundColor: theme.palette.primary.white,
    paddingBottom: 20,
    justifyContent: `center`,
    alignItems: `center`
  },
  container: {
    background: theme.palette.warning.light,
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: 4,
    marginBottom: 20,
    marginTop: 20,
    padding: 20,
    width: 692,
    height: 128,
    [theme.breakpoints.down(703)]: {
      width: 338,
      height: 213,
      padding: 10
    }
  },
  icon: {
    fontSize: 18,
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.warning.main,
    borderRadius: `50%`,
    padding: 5
  },
  title: {
    fontWeight: 700,
    [theme.breakpoints.down(703)]: {
      fontSize: 14
    }
  },
  text: {
    [theme.breakpoints.down('xl')]: {
      fontSize: 14
    }
  },
  link: {
    fontWeight: 700,
    textDecoration: `none`,
    color: theme.palette.info.main
  }
}));

export default InactiveMembershipStyles;
