import Translate from "../../../../../../../translate/Translate";
import React, { useState } from "react";
import ToDoListCardContainer from "../../ToDoListCardContainer";
import GLButton from "../../../../../sharedComponents/GLButton";
import resultsIcon from "../../../../../../../assets/icons/icon-results-available.svg";
import { downloadOrderDocuments } from "../../../../../../../apis/ordersApi";
import { downloadFile } from "../../../../../../../utils/utils";
import ErrorMessageSupportModal from "../../../../../sharedComponents/Modals/ErrorMessageSupportModal";
import Mixpanel from "../../../../../../../utils/mixpanel";
import DocumentNotApprovedModal from "../../../../../sharedComponents/Modals/DocumentNotApprovedModal";
import { PATHS } from "../../../../../../../constants/pathConstants";
import { useHistory } from "react-router-dom";

const ViewTestResultsToDoCard = ({ order }) => {
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [docNotReadyModalIsOpen, setDocNotReadyModalIsOpen] = useState(false);
  const history = useHistory();

  const body = `Your results are in for ${order.description.name}. We recommend that you share them with your healthcare provider.`;

  const handleDownloadButtonClick = () => {
    history.push(`${PATHS.TEST_RESULTS_PAGE}/${order.glOrderId}`);
  };

  const renderButtons = () => {
    return [
      <GLButton
        text={"See results"}
        size={"small"}
        onClick={handleDownloadButtonClick}
      />
    ];
  };

  return (
    <>
      <ToDoListCardContainer
        icon={resultsIcon}
        title={<Translate text={`${order.description.name} results`} />}
        body={body}
        buttons={renderButtons()}
      ></ToDoListCardContainer>
      <ErrorMessageSupportModal
        isOpen={errorModalIsOpen}
        onClose={() => setErrorModalIsOpen(false)}
      />
      <DocumentNotApprovedModal
        isOpen={docNotReadyModalIsOpen}
        onClose={() => setDocNotReadyModalIsOpen(false)}
      />
    </>
  );
};

export default ViewTestResultsToDoCard;
