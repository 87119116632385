import { createSlice } from "@reduxjs/toolkit";
const initialState = {};

export const SurveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setSurveyDataForSpecificSurvey(state, { payload }) {
      if (!state[payload.surveyIdentifier]) {
        state[payload.surveyIdentifier] = {};
      }
      state[payload.surveyIdentifier]["data"] = payload.surveyData;
    },
    updateSurveyAnswersForAllPreviousSurveys(state, { payload }) {
      payload.data.previousAnswers.forEach((newAnswer) => {
        if (state["surveyAnswersForAllPreviousSurveys"]) {
          const possibleIndexOfAnswerInState = state[
            "surveyAnswersForAllPreviousSurveys"
          ].findIndex((answerInState) => {
            return answerInState.name === newAnswer.name;
          });

          if (possibleIndexOfAnswerInState !== -1) {
            state["surveyAnswersForAllPreviousSurveys"][
              possibleIndexOfAnswerInState
            ] = newAnswer;
          } else {
            state["surveyAnswersForAllPreviousSurveys"].push(newAnswer);
          }
        } else {
          state["surveyAnswersForAllPreviousSurveys"] = [newAnswer];
        }
      });
    },
    setSurveyCurrentPage(state, { payload }) {
      const { surveyIdentifier, currentPageNumber } = payload;
      // Because we don't know what version of the survey the user is looking at
      //  we set the page number for all of them. This is a hack and needs to be
      //  done better. The ugly part will be letting a component using the SurveyWrapper
      //  reset the page number without exposing the survey version to the containing
      //  component
      const surveyKeys = Object.keys(state).filter((k) =>
        k.startsWith(surveyIdentifier)
      );

      surveyKeys.forEach((key) => {
        if (state[key] && typeof currentPageNumber === "number") {
          state[key]["currentPageNumber"] = currentPageNumber;
        }
      });
    },
    reset: (state) => (state = initialState)
  }
});

export const {
  setSurveyDataForSpecificSurvey,
  updateSurveyAnswersForAllPreviousSurveys,
  setSurveyCurrentPage,
  reset
} = SurveySlice.actions;

export const selectSurveyInfo = (state) => {
  return state.survey;
};

export const selectAllPreviousSurveyAnswers = (state) => {
  return state.survey.surveyAnswersForAllPreviousSurveys
    ? state.survey.surveyAnswersForAllPreviousSurveys
    : [];
};

export default SurveySlice.reducer;
