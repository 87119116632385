import { makeStyles } from "@mui/styles";

const SpinnerStyles = makeStyles((theme) => ({
  spinner: {
    animation: "$rotate 2s linear infinite",
    zIndex: "2",
    marginRight: "-4px",
    marginLeft: "4px",
    width: "24px",
    height: "30px"
  },
  path: {
    stroke: "#777777",
    strokeLinecap: "round",
    strokeOpacity: "0.7",
    animation: "$dash 1.5s ease-in-out infinite"
  },
  "@keyframes rotate": {
    "100%": {
      transform: "rotate(360deg)"
    }
  },
  "@keyframes dash": {
    "0%": {
      strokeDasharray: "1, 150",
      strokeDashoffset: "0"
    },
    "50%": {
      strokeDasharray: "90, 150",
      strokeDashoffset: "-35"
    },
    "100%": {
      strokeDasharray: "90, 150",
      strokeDashoffset: "-124"
    }
  }
}));

export default SpinnerStyles;
