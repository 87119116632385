import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CheckCirleIcon from "../../../assets/images/CheckCirleIcon.svg";
//style
import StepBoxStyles from "./StepBox.styles";
import { COLOR_CONSTANTS } from "../../../constants/StyleConstants";

const StepBox = (props) => {
  const {
    title,
    icon,
    iconWidth,
    iconHeight,
    stepNumber,
    description,
    completed,
    highlighted
  } = props;
  const classes = StepBoxStyles();
  const styleHelper = () => {
    if (completed) {
      return {
        body: `${classes.body} ${classes.stepSuccess}`,
        stroke: COLOR_CONSTANTS.COLOR_SUCCESS,
        stepIndicator: classes.stepIndicatorHidden
      };
    }
    if (highlighted) {
      return {
        body: `${classes.body} ${classes.stepSelected}`,
        stroke: COLOR_CONSTANTS.COLOR_BLACK_75,
        stepIndicator: `${classes.stepIndicator} ${classes.stepSelectedIndicator}`
      };
    }
    return {
      body: classes.body,
      stroke: COLOR_CONSTANTS.COLOR_BLACK_75,
      stepIndicator: classes.stepIndicator
    };
  };
  const styles = styleHelper();
  return (
    <div className={styles.body}>
      <Grid container direction="column" justifyContent="center">
        <Grid container justifyContent="center">
          {icon && iconWidth && iconHeight ? (
            <Grid item xs={2}>
              <div className={classes.iconContainer}>
                <img
                  width={iconWidth}
                  height={iconHeight}
                  src={icon}
                  alt="icon"
                />
              </div>
            </Grid>
          ) : null}
          <Grid item xs={10}>
            <div className={classes.titleContainer}>
              {title ? <Typography variant="h6">{title}</Typography> : null}
            </div>
          </Grid>
          <Grid item xs>
            <div className={classes.descriptionContainer}>
              {description ? (
                <Typography variant="body1">{description}</Typography>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div className={styles.stepIndicator}>
        {!completed && stepNumber ? (
          <div className={classes.stepContainer}>
            <Typography variant="body2">{stepNumber}</Typography>
          </div>
        ) : (
          <div width={28} height={28}>
            <img src={CheckCirleIcon} alt="check Icon" />
          </div>
        )}
      </div>
    </div>
  );
};
export default StepBox;
