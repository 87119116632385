import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Layout from "../../layout/Layout";
import BackTo from "../../sharedComponents/BackTo";
import { useWindowSize } from "../../../hooks/useWindowSize";
import ChangePasswordPageStyles from "./ChangePasswordPage.styles";
import PageTitle from "../../../utils/PageTitle";
import Translate from "../../../translate/Translate";
import EmailIcon from "@mui/icons-material/Email";
import { useDispatch, useSelector } from "react-redux";
import { openGLSnackbar } from "../../../store/reducers/snackbar/openGLSnackbarSlice";
import Mixpanel from "../../../utils/mixpanel";
import SecurityMessage from "../../sharedComponents/SecurityMessage";
import GLButton from "../../v2/sharedComponents/GLButton";
import { selectUserPersonalInformation } from "../../../store/reducers/user/UserAccountSlice";
import axios from "axios";

const ChangePassword = (props) => {
  const { backTo } = props;
  const dispatch = useDispatch();
  const classes = ChangePasswordPageStyles();
  const screenDimentions = useWindowSize();
  const usableHeight = screenDimentions.height - 260;
  const userEmail = useSelector(selectUserPersonalInformation).email;
  const [emailSent, setEmailSent] = useState(false);
  const { REACT_APP_AUTH0_DOMAIN } = process.env;
  const { REACT_APP_AUTH0_CLIENT_ID } = process.env;
  const { REACT_APP_AUTH0_CONNECTION } = process.env;

  const sendPasswordChangeEmail = () => {
    var options = {
      method: "POST",
      url: `https://${REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
      headers: { "content-type": "application/json" },
      data: {
        client_id: REACT_APP_AUTH0_CLIENT_ID,
        email: userEmail,
        connection: REACT_APP_AUTH0_CONNECTION
      }
    };
    axios
      .request(options)
      .then(function(response) {
        setEmailSent(true);
      })
      .catch(function(error) {
        Mixpanel.track("Password change email send faild");
        dispatch(
          openGLSnackbar({
            variant: "error",
            subText:
              "Something went wrong sending this email, please try again and reach out to us if you need to!"
          })
        );
        Mixpanel.track("Password change email sent", {
          error: error
        });
      });
  };

  PageTitle("Reset Password");

  const lowerSection = emailSent ? (
    <Typography className={classes.subtext}>
      <Translate text="Email sent! Keep an eye on your inbox, and don't forget to check you junk mail folder if you haven't heard from us in a few minutes." />
    </Typography>
  ) : (
    <GLButton
      loadingSpinnerWhenClicked
      color="primary"
      startIcon={<EmailIcon />}
      className={classes.continueButton}
      onClick={sendPasswordChangeEmail}
    >
      SEND PASSWORD CHANGE EMAIL
    </GLButton>
  );

  return (
    <Layout headerType="primary">
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        style={{ minHeight: usableHeight }}
      >
        <Grid item xs={12} sm={10} md={8}>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="center"
          >
            {backTo ? (
              <div className={classes.backToContainer}>
                <BackTo text="Privacy & Security" backTo={backTo} />
              </div>
            ) : null}
          </Grid>
        </Grid>
        <Grid
          className={classes.boxFrame}
          container
          xs={11}
          md={5}
          alignItems="center"
          direction="column"
        >
          <Grid
            item
            className={[
              classes.contentBoxSection,
              classes.contentBoxSectionTop
            ]}
          >
            <Grid item>
              <Typography className={classes.title} variant="h6">
                <Translate text="Change password" />
              </Typography>
              <Typography className={classes.subtext}>
                <Translate text="Click the button below and we'll send you an email with a link to change your password." />
              </Typography>
            </Grid>
          </Grid>

          <Grid item className={classes.contentBoxSection}>
            <Grid item>{lowerSection}</Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="flex-start"
        xs={12}
      >
        <Grid>
          <SecurityMessage
            title="Your information is safe with us"
            desc="Genomic Life uses SSL encryption to protect your information."
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ChangePassword;
