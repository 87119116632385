import React from "react";
import Layout from "../../../layout/Layout";
import { Grid, Box } from "@mui/material";
import CMSDrivenPageTemplateStyles from "./CMSDrivenPageTemplate.styles";
import RainbowPeopleCTA from "../CMSSharedComponents/RainbowPeopleCTA";
import QuoteCarousel from "../CMSSharedComponents/QuoteCarousel";
import ImageAnchoredParagraph from "../CMSSharedComponents/ImageAnchoredParagraph";
import CardGrid from "../CMSSharedComponents/CardGrid";
import QAMatrix from "../CMSSharedComponents/QAMatrix";
import HeroHeader from "../CMSSharedComponents/HeroHeader";
import ImageWithFooterCard from "../CMSSharedComponents/ImageWithFooterCard";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";
import OptionWithIconCard from "../CMSSharedComponents/OptionWithIconCard";
import ResourceGrid from "../CMSSharedComponents/ResourceGrid";
import ResourceGridMatrix from "../CMSSharedComponents/ResourceGridMatrix";
import Article from "../CMSSharedComponents/Article";
import FullWidthDividerBandFat from "../CMSSharedComponents/FullWidthDividerBandFat";
import LinkBoxGrid from "../CMSSharedComponents/LinkBoxGrid";

const CMSDrivenPageTemplate = (props) => {
  const { pageDefinitionJson, breadcrumb } = props;
  const classes = CMSDrivenPageTemplateStyles();
  window.scrollTo(0, 0);

  const renderPageElementsFromJson = () => {
    return pageDefinitionJson.pageElements.map((elementContents, index) => {
      if (elementContents.componentType === "ImageAnchoredParagraph") {
        return <ImageAnchoredParagraph key={index} {...elementContents} />;
      } else if (elementContents.componentType === "OptionWithIconCardGrid") {
        return (
          <CardGrid
            key={index}
            columnsAtMaxWidth={2}
            cardSpacing={3}
            CardToUse={OptionWithIconCard}
            backgroundColor={COLOR_CONSTANTS.GENOMIC_LIFE_GREEN_CHIP}
            {...elementContents}
          />
        );
      } else if (elementContents.componentType === "FooterCardGrid") {
        return (
          <CardGrid
            key={index}
            columnsAtMaxWidth={3}
            cardSpacing={2}
            CardToUse={ImageWithFooterCard}
            {...elementContents}
          />
        );
      } else if (elementContents.componentType === "RainbowPeopleCTA") {
        return <RainbowPeopleCTA key={index} {...elementContents} />;
      } else if (elementContents.componentType === "QuoteCarousel") {
        return <QuoteCarousel key={index} {...elementContents} />;
      } else if (elementContents.componentType === "QAMatrix") {
        return <QAMatrix key={index} {...elementContents} />;
      } else if (elementContents.componentType === "ResourceGrid") {
        return <ResourceGrid key={index} {...elementContents} />;
      } else if (elementContents.componentType === "LinkBoxGrid") {
        return <LinkBoxGrid key={index} {...elementContents} />;
      } else if (elementContents.componentType === "ResourceGridMatrix") {
        return <ResourceGridMatrix key={index} {...elementContents} />;
      } else if (elementContents.componentType === "HeroHeader") {
        return <HeroHeader key={index} {...elementContents} />;
      } else if (elementContents.componentType === "Article") {
        return (
          <Article
            key={index}
            {...elementContents}
            gutterElements={pageDefinitionJson.gutterElements}
          />
        );
      } else if (elementContents.componentType === "FullWidthDividerBandFat") {
        return <FullWidthDividerBandFat key={index} {...elementContents} />;
      } else return null;
    });
  };

  return (
    <Layout headerType="primary">
      {breadcrumb}
      <Grid container justifyContent="center">
        <Box className={classes.contentWrapper}>
          {renderPageElementsFromJson()}
        </Box>
      </Grid>
    </Layout>
  );
};

export default CMSDrivenPageTemplate;
