import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  getPlacePredictions,
  getPlaceDetailsByPlaceId,
  parseFullAddress,
  getPlacePredictionsByRegion
} from "../placesApiUtils";
import GLTextField from "../../GLTextField";

/**
 * GLPlacesTextfield - Component that uses google places api to predict other field values
 * @param {string} label
 * @param {string} id
 * @param {*} value
 * @param {boolean} required
 * @param {boolean} disabled
 * @param {func} additionalValidation
 *  @param {Array} dependantFields this array should be composed of
 * {id: the dependant field id, parameter: the places parameter that this will be related to}
 * @returns {JSX}
 */
const GLPlacesTextfield = (props) => {
  const {
    label,
    id,
    value,
    onChange,
    required,
    disabled,
    additionalValidation,
    willItpredictByRegion,
    dependantFields,
    shouldResetDependantFields
  } = props;

  const resetDependantFields = () => {
    if (
      shouldResetDependantFields &&
      dependantFields &&
      dependantFields.length > 0
    ) {
      dependantFields.forEach((field) => {
        onChange({
          e: { target: { name: field.id, value: "" } }
        });
      });
    }
  };

  const fillDependantFields = (resultsObject) => {
    const addressComponents = resultsObject.address_components;
    const addressName = parseFullAddress(addressComponents);
    if (dependantFields && addressComponents) {
      dependantFields.forEach((field) => {
        if (field.parameter === "fullAddress") {
          return onChange({
            e: { target: { name: field.id, value: addressName } }
          });
        }
        addressComponents.forEach((component) => {
          if (component.types && component.types.includes(field.parameter)) {
            const value = component.short_name;
            onChange({
              e: { target: { name: field.id, value: value } }
            });
          }
        });
      });
    }
  };

  const handlePlaceDetailsByPlaceId = async (placeId) => {
    await getPlaceDetailsByPlaceId(placeId)
      .then((results) => {
        fillDependantFields(results);
      })
      .catch((error) => {
        resetDependantFields();
      });
  };

  useEffect(() => {
    const handlePlacePrediction = async (value) => {
      try {
        const functionToLookupBy = willItpredictByRegion
          ? getPlacePredictionsByRegion
          : getPlacePredictions;
        const results = await functionToLookupBy(value);
        if (results && results[0]) {
          handlePlaceDetailsByPlaceId(results[0].place_id);
        }
      } catch {
        resetDependantFields();
      }
    };
    if (value && value !== "") {
      handlePlacePrediction(value);
    } else {
      resetDependantFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <GLTextField
      {...props}
      label={label}
      id={id}
      value={value ? value : ""}
      onChange={onChange}
      additionalValidation={additionalValidation}
      required={required}
      disabled={disabled}
    />
  );
};
export default GLPlacesTextfield;

GLPlacesTextfield.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalValidation: PropTypes.func,
  willItpredictByRegion: PropTypes.bool,
  dependantFields: PropTypes.arrayOf(PropTypes.object)
};
