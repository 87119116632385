import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import EnglishTranslation from "./translations/translation.en.json";
import SpanishTranslation from "./translations/translation.es.json";

const languages = ["en", "en-US", "es"];

const resource = {
  en: {
    translation: EnglishTranslation
  },
  "en-US": {
    translation: EnglishTranslation
  },
  es: {
    translation: SpanishTranslation
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: { order: ["path", "navigator"] },
    fallbackLng: "en",
    resources: resource,
    whitelist: languages,
    debug: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    keySeparator: ">",
    nsSeparator: "|"
  });
