import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Translate from "../../../translate/Translate";
import ModalComponent from "../ModalComponent";
//Constants
import { LABELS } from "../../../constants/labelConstants";
import LoadingBarsGif from "../../../assets/images/LoadingBars.gif";
//Styles
import LoadingPageStyles from "./LoadingPage.styles";

const LoadingPage = (props) => {
  const { isProccesing, loadingText } = props;
  const classes = LoadingPageStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const body = (
    <Grid
      className={classes.loadingContainer}
      container
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={LoadingBarsGif}
          style={
            smDown ? { width: 80, height: 39 } : { width: 160, height: 78 }
          }
          alt={`${LABELS.LABEL_CANCER_GUARDIAN_LOGO_ALT_TEXT}`}
        />
        <Grid item className={classes.boxTitle}>
          <Typography variant={smDown ? "h2" : "h1"} className={classes.title}>
            <Translate text={loadingText ? loadingText : "Loading ..."} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
  return <ModalComponent open={isProccesing} body={body} />;
};
export default LoadingPage;
