import React, { useState, Fragment } from "react";
import { Grid, Typography, Box } from "@mui/material";
import VideoModalStyles from "./VideoModal.styles";

import ModalVideo from "react-modal-video";

const VideoModal = (props) => {
  const { channel, videoId, isOpen, onClose } = props;
  const classes = VideoModalStyles();

  return (
    <div className={classes.videoModalContainer}>
      <ModalVideo
        channel={channel}
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        onClose={onClose}
      />
    </div>
  );
};

export default VideoModal;
