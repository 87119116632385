import { makeStyles } from "@mui/styles";

const CustomizedMobileStepperStyles = makeStyles((theme) => ({
  container: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `center`
  },
  rootClass: {
    background: theme.palette.primary.white,
    color: theme.palette.secondary.gray_button,
    fontWeight: 700,
    margin: `auto`,
    [theme.breakpoints.between("md", "xl")]: {
      width: 1280,
      paddingTop: 20,
      height: 60
    },
    [theme.breakpoints.between("sm", 'lg')]: {
      width: 834,
      paddingTop: 20,
      height: 60
    },
    [theme.breakpoints.between("xs", 'md')]: {
      width: 375,
      paddingTop: 20,
      height: 60
    }
  },
  fullWidth: {
    width: `100%`
  },
  button: {
    color: theme.palette.primary.link,
    "&.hide": {
      visibility: `hidden`
    },
    "&:hover": {
      background: `transparent`
    }
  },
  content: {
    display: "flex",
    alignItems: "center"
  }
}));

export default CustomizedMobileStepperStyles;
