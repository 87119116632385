import { makeStyles } from "@mui/styles";
import {
  FLEX_CONSTANTS,
  GENERIC_CONSTANTS
} from "../../../constants/StyleConstants";

const ChangePasswordPageStyles = makeStyles((theme) => ({
  boxFrame: {
    borderRadius: "10px",
    border: "1px solid #C4C4C4",
    marginBottom: "60px"
  },
  contentBoxSection: {
    padding: "40px",
    textAlign: "center",
    width: "100%"
  },
  contentBoxSectionTop: {
    padding: "40px",
    textAlign: "center",
    borderBottom: "1px solid #C4C4C4"
  },
  continueButton: {
    marginTop: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    maxWidth: "none"
  },
  backToContainer: {
    display: FLEX_CONSTANTS.FLEX,
    flexDirection: FLEX_CONSTANTS.ROW,
    flex: 1,
    alignItems: FLEX_CONSTANTS.CENTER,
    marginTop: theme.paddings.sm,
    textDecoration: GENERIC_CONSTANTS.NONE,
    color: theme.palette.info.main,
    marginBottom: 60
  }
}));

export default ChangePasswordPageStyles;
