import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import QAMatrixStyles from "./QAMatrix.styles";
import SideScrollingMenu from "../../../sharedComponents/SideScrollingMenu";
import QAMatrixCard from "./QAMatrixCard";

const QAMatrix = (props) => {
  const { tabs } = props;
  const classes = QAMatrixStyles();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const renderCardsForCardBlock = (cardsJson) => {
    return cardsJson.map((cardJson, index) => {
      return <QAMatrixCard cardJson={cardJson} key={index} />;
    });
  };

  const renderCardBlockForTab = (tab) => {
    return (
      <Grid container spacing={3}>
        {renderCardsForCardBlock(tab.cards)}
      </Grid>
    );
  };

  const handleMenuClick = (index) => {
    setCurrentTabIndex(index);
  };

  const maybeRenderSubheader = () => {
    return !!tabs[currentTabIndex].subHeader ? (
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.subHeader}>
          {tabs[currentTabIndex].subHeader.en}
        </Typography>
      </Grid>
    ) : null;
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <SideScrollingMenu
          menuItems={tabs.map((tab) => tab.header.en)}
          currentlySelectedItemIndex={currentTabIndex}
          onSelectItem={handleMenuClick}
        />
      </Grid>
      <Grid container className={classes.cardBlock}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.header}>
            {tabs[currentTabIndex].header.en}
          </Typography>
        </Grid>
        {maybeRenderSubheader()}
        <Grid item xs={12}>
          {renderCardBlockForTab(tabs[currentTabIndex])}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QAMatrix;
