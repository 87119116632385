import React from "react";
import PropTypes from "prop-types";
import GLAutocompleteDropdown from "..";
import { Box } from "@mui/material";
import { US_STATES } from "../../../../../../constants/generalConstants";

const GLUsStateAutocomplete = (props) => {
  const { label, id, value, onChange, required, disabled } = props;

  const renderStateLabel = (option, additionalProps) => {
    return (
      <Box component="li" {...additionalProps}>
        {option.id} - {option.label}
      </Box>
    );
  };

  const renderSelectedState = (option) => {
    return `${option.id} - ${option.label}`;
  };

  return (
    <GLAutocompleteDropdown
      {...props}
      options={US_STATES}
      label={label}
      id={id}
      value={value ? value : ""}
      onChange={onChange}
      required={required}
      disabled={disabled}
      renderListLabelFunction={renderStateLabel}
      renderSelectedElementFunction={renderSelectedState}
    />
  );
};
export default GLUsStateAutocomplete;

GLUsStateAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
