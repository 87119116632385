import React from "react";
import PropTypes from "prop-types";
import GLTextField from "../GLTextField";
import Translate from "../../../../../translate/Translate";

const GLNameTextField = (props) => {
  const { label, id, value, required, disabled, onChange } = props;

  const validateNameField = (textToTest) => {
    const regex = new RegExp("^[a-zA-z]+([\\s][a-zA-Z]+)*$");
    if (!regex.test(textToTest)) {
      return (
        <Translate text="Field must contain only A-Z or a-z and should not have trailing spaces" />
      );
    }
  };

  return (
    <GLTextField
      {...props}
      id={id}
      label={label}
      value={value}
      required={required}
      onChange={onChange}
      disabled={disabled}
      inputProps={{ maxLength: 45 }}
      additionalValidation={validateNameField}
    />
  );
};

export default GLNameTextField;

GLNameTextField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
