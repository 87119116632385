import { makeStyles } from "@mui/styles";

const LargeHelloHeaderStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingBottom: 40,
    color: "white"
  },
  innerContainer: { color: "white" },
  blueBackground: {
    top: 0
  },
  blueConcaveCurve: {
    width: "100%",
    maxWidth: "100%",
    height: "230px",
    background: `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' preserveAspectRatio='none' fill='${theme.palette.primary.font_blue.replace(
      "#",
      "%23"
    )}'><path d='M0 0 L0 13 C16 6 48 6 64 13 L64 0 Z' /></svg>") top center/auto no-repeat`
  },
  blueCurveMask: {
    height: 48,
    overflow: "hidden"
  },
  blueRectangle: {
    width: "100%",
    paddingTop: 60,
    backgroundColor: theme.palette.primary.font_blue,
    position: "relative"
  },
  header: { color: "white", marginBottom: 10 },
  subheader: {
    ...theme.typography.h6HeadlineHeavy
  },
  subsectionPart: {
    color: theme.palette.primary.font_blue,
    "& h2": { marginTop: 20 },
    textAlign: "center",
    "& p": { fontSize: 16, marginTop: 20 },
    "& a": { color: theme.palette.primary.font_blue }
  }
}));

export default LargeHelloHeaderStyles;
