import { Box, Button, Typography } from "@mui/material";
import React from "react";
import mustBeAdultModalStyles from "./mustBeAdultModal.styles";
import CloseIcon from "@mui/icons-material/Close";
import SecureIcon from "../../../../../assets/images/EigtheenYearsBlock.svg";
import CenteredContent from "../../../../sharedComponents/CenteredContent";
import Translate from "../../../../../translate/Translate";
const MustBeAdultModal = (props) => {
  const { handleClose, onSendConfirmation } = props;

  const classes = mustBeAdultModalStyles();
  return (
    <Box className={classes.modalContainer}>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <CloseIcon onClick={handleClose} className={classes.closeIcon} />
      </Box>
      <CenteredContent>
        <img src={SecureIcon} alt="secure" />
      </CenteredContent>
      <CenteredContent>
        <Typography
          variant="subtitle1"
          align="center"
          className={classes.modalDescription}
        >
          <Translate text="Sorry we are not able to provide kits to minors at this time" />
        </Typography>
      </CenteredContent>
      <CenteredContent>
        <Button
          variant="contained"
          color="secondary"
          className={classes.backHomeButton}
          onClick={handleClose}
        >
          CLOSE
        </Button>
      </CenteredContent>
    </Box>
  );
};

export default MustBeAdultModal;
