import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import ResourceGridMatrixStyles from "./ResourceGridMatrix.styles";
import SideScrollingMenu from "../../../sharedComponents/SideScrollingMenu";
import ResourceGrid from "../ResourceGrid";

const ResourceGridMatrix = (props) => {
  const { tabs } = props;
  const classes = ResourceGridMatrixStyles();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const renderResourceMatrixForTab = (tabJson) => {
    return <ResourceGrid {...tabJson} />;
  };

  const handleMenuClick = (index) => {
    setCurrentTabIndex(index);
  };

  const maybeRenderSubheader = () => {
    return !!tabs[currentTabIndex].subHeader ? (
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.subHeader}>
          {tabs[currentTabIndex].subHeader.en}
        </Typography>
      </Grid>
    ) : null;
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <SideScrollingMenu
          menuItems={tabs.map((tab) => tab.title.en)}
          currentlySelectedItemIndex={currentTabIndex}
          onSelectItem={handleMenuClick}
        />
      </Grid>
      <Grid container className={classes.cardBlock}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.header}>
            {tabs[currentTabIndex].header.en}
          </Typography>
        </Grid>
        {maybeRenderSubheader()}
        <Grid item xs={12}>
          {renderResourceMatrixForTab(tabs[currentTabIndex])}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResourceGridMatrix;
