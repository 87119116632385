import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Fade, TextField } from "@mui/material";
import Translate from "../../../../../translate/Translate";
import { SharedFormFieldStyles } from "../../Form.styles";
import { defaultSizingProps, extractSizingProps } from "../../FormUtils";

// There is a lot of bizwack in here around validation to support requests
//  from design. They want the field not to light up invalid until a user
//  has focused and defocused it, but they still want the save button
//  disabled. So the validity we report to the form that drives the button
//  disable is more strict than the validty the field itself shows, since
//  it may not show invalid if you are working on it or have not focused it before.
//  It's brittle. Be careful with it.

/*
interface GLTextFieldProps {
  label: string
  id?: ?string
  value?: string
  onChange: () => void
  required?: ?boolean
  diabled?: ?boolean,
  type?:string,
  additionalValidation?: ?func
  xs: int
  sm: int
  md: int
  lg: int
  xl: int
}
*/

const GLTextField = (props) => {
  const {
    label,
    id,
    value,
    onChange,
    required,
    disabled,
    type,
    additionalValidation
  } = props;

  const [hasFocus, setHasFocus] = useState(false);
  const [hasReceivedFocus, setHasReceivedFocus] = useState(false);

  useEffect(() => {
    onChange({
      e: { target: { name: id, value: value } },
      isInvalid: !!calculateHelperText(value)
    });
  }, [hasReceivedFocus]); // eslint-disable-line
  // I want this hook only dependent on that one var so that it only
  //  fires when the form loads and if the input is ever focused

  const classes = SharedFormFieldStyles();

  const optionalLabel = (
    <span>
      {" ("}
      <Translate text={required ? "required" : "optional"} />
      {")"}
    </span>
  );

  const translatedLabel = (
    <span>
      <Translate text={label} /> {optionalLabel}
    </span>
  );

  const calculateHelperText = (valueToValidate) => {
    if (required && !valueToValidate) {
      return <Translate text="This field is required" />;
    }
    if (valueToValidate && valueToValidate.length > 0 && additionalValidation) {
      return additionalValidation(valueToValidate);
    }

    if (!!valueToValidate && valueToValidate.length > 200) {
      return <Translate text="Exceeds 200 characters" />;
    }
    return "";
  };

  const handleChange = (e) => {
    onChange({
      e,
      isInvalid: !!calculateHelperText(e.target.value)
    });
  };

  const requiredFlag = (
    <Fade
      in={
        !hasFocus &&
        hasReceivedFocus &&
        required &&
        [null, undefined, ""].includes(value) &&
        !hasFocus
      }
    >
      <div className={classes.requiredFlag}>Required</div>
    </Fade>
  );

  const shouldBeShowingErrorState =
    !hasFocus &&
    ((value && !hasReceivedFocus) || hasReceivedFocus) &&
    !!calculateHelperText(value);

  const getFilteredProps = () => {
    const { additionalValidation, ...clearedProps } = props;
    return clearedProps;
  };

  return (
    <Grid
      item
      {...extractSizingProps(props)}
      className={classes.inputContainer}
    >
      {requiredFlag}
      <TextField
        {...getFilteredProps()}
        label={translatedLabel}
        variant="outlined"
        fullWidth={true}
        id={id}
        name={id}
        type={type}
        value={value || ""}
        onChange={handleChange}
        className={classes.textInput}
        error={shouldBeShowingErrorState}
        helperText={
          shouldBeShowingErrorState ? calculateHelperText(value) : null
        }
        disabled={disabled}
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasReceivedFocus(true);
          setHasFocus(false);
        }}
      />
    </Grid>
  );
};

GLTextField.defaultProps = defaultSizingProps;

export default GLTextField;

GLTextField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  additionalValidation: PropTypes.func
};
