import React from "react";
import { useTheme } from "@mui/styles";
import { Grid } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CustomizedMobileStepperStyles from "./CustomizedMobileStepper.styles";
import Translate from "../../../translate/Translate";

/**
 * This component is a Mobile Stepper implementing a compact stepper suitable for a mobile device
 * @param {object} props porperties passed to the instance of the component
 * @param {string} variant the variant is the type to use into the stepper could be (text, dots, progress).
 * @param {string} position Set the positioning type of the stepper. (static, bottom, top)
 * @param {Array} contentSteps array of strings or objects to display as content
 * @returns {JSX} A mobile Steper with the content of each step for the component
 */

export default function CustomizedMobileStepper(props) {
  const {
    variant,
    position,
    contentSteps,
    handleNext,
    handlePrevious,
    showContent,
    fullWidth,
    isNextDisabled,
    finalText,
    activeStep,
    isBlocked
  } = props;
  const classes = CustomizedMobileStepperStyles();
  const theme = useTheme();
  const maxSteps = contentSteps.length;

  return (
    <Grid className={classes.container}>
      {showContent && contentSteps.length > 0 ? (
        <Grid className={classes.content}>{contentSteps[activeStep]}</Grid>
      ) : null}
      <MobileStepper
        steps={maxSteps}
        position={position}
        variant={variant || "text"}
        activeStep={activeStep}
        classes={{
          root: classes.rootClass
        }}
        className={`${fullWidth && classes.fullWidth}`}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            className={`${classes.button} `}
            disabled={isNextDisabled}
            id="next-arrow-button"
          >
            {activeStep === maxSteps - 1 ? (
              <Translate text={finalText ? finalText : "Start"} />
            ) : (
              <Translate text="Next" />
            )}
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handlePrevious}
            className={`${classes.button} ${
              activeStep === 0 && !isBlocked ? "hide" : ""
            }`}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            <Translate text="Back" />
          </Button>
        }
      />
    </Grid>
  );
}
