import { useAuth0 } from "@auth0/auth0-react";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  loginWithRedirect();
  return null;
};

export default LoginPage;
