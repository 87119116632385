import { doApiCall } from "./util";

const { REACT_APP_MEMBER_PORTAL_ENDPOINT_URL } = process.env;
const url = `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`;

/**
 * @param {payload} payload connection Information
 * @returns 200 if the connection was created successfully.
 * @description This endpoint create a new connection for the user.
 */
export const createUserConnection = (payload) => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `${url}/member/connections`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

/**
 * @param {payload} payload connection Information
 * @returns 200 if the connection was updated successfully.
 * @description This endpoint create a new connection for the user.
 */
export const updateUserConnection = (payload) => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "PUT",
    url: `${url}/member/connections`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

/**
 * @returns 200 if the connection was created successfully.
 * @description This endpoint fetch all the available connections.
 */
export const getAvailableUserConnections = () => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "GET",
    url: `${url}/member/connections`
  };

  return doApiCall(request);
};

/**
 * @param {connectionId} ConnectionId Connection Id to remove
 * @returns 200 if the connection was created successfully.
 * @description This endpoint delete an specific connection, based on
 * the Id provided.
 */
export const deleteConnectionByID = (connectionId) => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "DELETE",
    url: `${url}/member/connections/${connectionId}`
  };

  return doApiCall(request);
};
