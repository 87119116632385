import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const ArticleStyles = makeStyles((theme) => ({
  container: {
    margin: "10px 20px 100px",
    color: "black",
    fontSize: "18px",
    "& ul": { "padding-inline-start": "30px" },
    "& li": {
      paddingBottom: 15,
      "&::marker": { fontSize: 26 },
      "& a": {
        color: COLOR_CONSTANTS.GENOMIC_LIFE_FONT_BLUE,
        position: "relative",
        top: -2
      }
    }
  },
  articleContainer: {
    paddingRight: 20,
    "& img": { width: "100%", height: "auto" }
  },
  header: { color: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY, margin: "40px 0 0" },
  summary: { fontSize: 16, marginBottom: 40 }
}));

export default ArticleStyles;
