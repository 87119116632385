import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { useEffect } from "react";
import { getCookie, setCookie } from "../../utils/CookieUtil";

const RefreshTimer = () => {
  const DEBUG_LOGGING = false;
  //const REFRESH_TOKEN_INTERVAL_IN_MILLISECONDS = 1000 * 60 * 13;
  const REFRESH_TOKEN_INTERVAL_IN_MILLISECONDS = 90000;
  const CHECK_FOR_STALE_TOKEN_INTERVAL_IN_MILLISECONDS = 5000;
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (getCookie("refreshTimerId")) {
        const possibleRefreshTimerCookie = parseInt(getCookie("refreshTimer"));
        if (!!possibleRefreshTimerCookie) {
          if (DEBUG_LOGGING) {
            console.log("Refresh timer found");
            console.log(possibleRefreshTimerCookie);
            console.log("Next refresh:");
            console.log(
              possibleRefreshTimerCookie +
                REFRESH_TOKEN_INTERVAL_IN_MILLISECONDS
            );
          }
          if (
            possibleRefreshTimerCookie +
              REFRESH_TOKEN_INTERVAL_IN_MILLISECONDS <
            moment().valueOf()
          ) {
            if (!getCookie("accessToken")) {
              if (DEBUG_LOGGING) {
                console.log("skipping");
              }
            } else {
              if (DEBUG_LOGGING) {
                console.log("Refreshing access token");
              }
              getAccessTokenSilently({ cacheMode: "on" }).then(
                (accessToken) => {
                  setCookie("accessToken", accessToken);
                }
              );
              setCookie("refreshTimer", moment().valueOf());
            }
          } else {
            if (DEBUG_LOGGING) {
              console.log("Token not yet stale");
            }
          }
        }
      } else {
        if (DEBUG_LOGGING) {
          console.log("Refresh timer not found, creating one");
        }
        setCookie("refreshTimerId", intervalId);
        setCookie("refreshTimer", moment().valueOf());
        if (DEBUG_LOGGING) {
          console.log("Set up refresh interval timer, interval id:");
          console.log(intervalId);
        }
      }
    }, CHECK_FOR_STALE_TOKEN_INTERVAL_IN_MILLISECONDS);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default RefreshTimer;
