import { doApiCall } from "./util";

const { REACT_APP_MEMBER_PORTAL_ENDPOINT_URL } = process.env;

export const getUserAgreementHtml = () => {
  const payload = {
    parameterId: "consent"
  };
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/tos`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

export const postUserAgreement = (payload) => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "POST",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/compliance`,
    data: payload,
    body: JSON.stringify(payload)
  };

  return doApiCall(request);
};

export const getUserAgreementVersionAcceptanceStates = () => {
  const request = {
    host: `${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}`,
    method: "GET",
    url: `https://${REACT_APP_MEMBER_PORTAL_ENDPOINT_URL}/member/compliance`
  };

  return doApiCall(request);
};
