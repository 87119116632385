import React, { useReducer } from "react";
import Translate from "../../../translate/Translate";
import { COLOR_CONSTANTS } from "../../../constants/StyleConstants";

export const useFormFieldState = (initialFormState) => {
  const addFieldToArrayIfNotPresent = (array, field) => {
    if (array.indexOf(field) === -1) array.push(field);
    return array;
  };

  const connectionFormReducer = (previousFormState, action) => {
    const newInvalidFieldList = action.isInvalid
      ? addFieldToArrayIfNotPresent(
          previousFormState.formStatus.invalidFields,
          action.field
        )
      : previousFormState.formStatus.invalidFields.filter(
          (field) => field !== action.field
        );

    switch (action.type) {
      case "HANDLE_INPUT_CHANGE":
        return {
          ...previousFormState,
          formFields: {
            ...previousFormState.formFields,
            [action.field]: action.value
          },
          formStatus: {
            ...previousFormState.formStatus,
            invalidFields: newInvalidFieldList
          }
        };
      default:
        return previousFormState;
    }
  };

  const [formState, dispatchForm] = useReducer(connectionFormReducer, {
    formFields: initialFormState,
    formStatus: { invalidFields: [] }
  });

  const handleInputChange = ({ e, isInvalid }) => {
    dispatchForm({
      type: "HANDLE_INPUT_CHANGE",
      field: e.target.name,
      value: e.target.value,
      isInvalid: isInvalid
    });
  };

  return {
    formFields: formState.formFields,
    formStatus: formState.formStatus,
    handleInputChange
  };
};

export const translateLabelStringWithOptionalIfAppropriate = (
  label,
  required
) => {
  const optionalLabel = (
    <span>
      {" ("}
      <Translate text={"optional"} />
      {")"}
    </span>
  );

  return (
    <span>
      <Translate text={label} /> {required ? "" : optionalLabel}
    </span>
  );
};

export const translateLabelStringWithRequiredIfAppropriate = (
  label,
  required,
  filled
) => {
  const requiredLabel = (
    <span>
      {" ("}
      <span
        style={{
          color: filled
            ? "inherit"
            : COLOR_CONSTANTS.GENOMIC_LIFE_CANCEL_BUTTON_TEXT
        }}
      >
        <Translate text={"required"} />
      </span>
      {")"}
    </span>
  );

  return (
    <span>
      <Translate text={label} /> {required ? requiredLabel : ""}
    </span>
  );
};

export const defaultSizingProps = {
  xs: 12,
  sm: 12
};

export const extractSizingProps = (props) => {
  return (({ xs, sm, md, lg, xl }) => ({ xs, sm, md, lg, xl }))(props);
};

export const validateZipCode = (valueToTest) => {
  const regex = new RegExp("(^\\d{5}$)|(^\\d{5}-\\d{4}$)");
  if (!regex.test(valueToTest)) {
    return (
      <Translate text="Please enter a valid zip code (##### or #####-####)" />
    );
  }
};
