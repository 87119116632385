import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { PATHS } from "../../../../constants/pathConstants";
import FormSummary from "../../../sharedComponents/FormSummary/FormSummaryComponent";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useSelector, useDispatch } from "react-redux";
import {
  selectKitsSelectedToBeOrdered,
  setKitsSelectedToBeOrdered,
  selectTestKitsGLIsCurrentlyOffering,
  setExistingOrders,
  setUuidsOfKitsJustOrderedToBeShownOnConclusionScreen
} from "../../../../store/reducers/order/OrderInformationSlice";
import LoadingPage from "../../../sharedComponents/LoadingPage";
import OrderKitRequestConfirmationStyles from "./OrderKitRequestConfirmation.styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";
import Translate from "../../../../translate/Translate";
import { requestOrder, getExistingKitOrders } from "../../../../apis/ordersApi";
import { openGLSnackbar } from "../../../../store/reducers/snackbar/openGLSnackbarSlice";
import Mixpanel from "../../../../utils/mixpanel";
import { selectUserPersonalInformation } from "../../../../store/reducers/user/UserAccountSlice";

const OrderKitRequestConfirmation = (props) => {
  const { onBackToMemberInfo, onBackToShippingInfo } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const kitsSelectedToBeOrdered = useSelector(selectKitsSelectedToBeOrdered);
  const availableOrders = useSelector(selectTestKitsGLIsCurrentlyOffering);
  const [fields, setFields] = useState([]);

  const memberInformation = useSelector(selectUserPersonalInformation);

  const [isProccesing, setIsProccesing] = useState(false);

  const windowSize = useWindowSize();
  const usableHeight = windowSize.height - 205;
  const buttonPadding = usableHeight * 0.1;
  const classes = OrderKitRequestConfirmationStyles();

  const setSexFullName = (sexValue) => {
    switch (sexValue) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      case "I":
        return "Intersex";
      case "U":
      default:
        return "Unknown";
    }
  };
  const fullName = `${memberInformation.firstName}${
    memberInformation.middleName && memberInformation.middleName.length > 0
      ? ` ${memberInformation.middleName[0]}.`
      : ""
  } ${memberInformation.lastName}`;
  const sexAssignedAtBirth =
    memberInformation.sexAssignedAtBirth &&
    memberInformation.sexAssignedAtBirth.length > 0
      ? `${setSexFullName(
          memberInformation.sexAssignedAtBirth[0].toUpperCase()
        )}`
      : "";
  const gender =
    memberInformation && memberInformation.gender
      ? `${memberInformation.gender[0].toUpperCase()}${memberInformation.gender.substring(
          1,
          memberInformation.gender.length
        )}`
      : "";
  const pronouns =
    memberInformation && memberInformation.pronouns
      ? `${memberInformation.pronouns[0].toUpperCase()}${memberInformation.pronouns.substring(
          1,
          memberInformation.pronouns.length
        )}`
      : "";

  useEffect(() => {
    let data = [...fields];
    kitsSelectedToBeOrdered &&
      kitsSelectedToBeOrdered.forEach((uuid) => {
        let [order] = availableOrders
          ? availableOrders.filter((test) => test.test_uuid === uuid)
          : [];
        if (order) {
          data.push({
            name: order.test_name,
            value: `${order.test_name}`
          });
          data.push({
            name: "consent",
            value: "Informed Consent Acknowledged",
            variant: "body2",
            style: { marginTop: "-20px", fontStyle: "italic" }
          });
        } else {
          return null;
        }
      });
    setFields(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackToKitSelectionPage = () => {
    history.push(PATHS.ORDER_KIT_SELECTION);
  };

  const formSections = [
    {
      title: "Test Kit information",
      handleBackTo: handleBackToKitSelectionPage,
      fields: fields,
      subFields: fields
    },
    {
      title: "Member information",
      handleBackTo: onBackToMemberInfo,
      fields: [
        { name: "name", value: fullName, label: "Name" },
        {
          name: "sexAssignedAtBirth",
          value: sexAssignedAtBirth,
          label: "Sex assigned at birth"
        },
        { name: "gender", value: gender, label: "Gender" },
        { name: "pronouns", value: pronouns, label: "Pronouns" }
      ]
    },
    {
      title: "Shipping information",
      handleBackTo: onBackToShippingInfo,
      fields: [
        {
          name: "sendToSavedAddress",
          value: "Send to saved address",
          variant: "body2",
          style: { fontStyle: "italic" }
        },
        {
          name: "name",
          value: fullName,
          style: { color: COLOR_CONSTANTS.COLOR_GRAY }
        },
        {
          name: "address",
          value: `${memberInformation.addressLine1}${
            memberInformation.addressLine2
              ? `, ${memberInformation.addressLine2}`
              : ""
          }${
            memberInformation.addressLine3
              ? `, ${memberInformation.addressLine3}`
              : ""
          }`,
          style: { marginTop: "-20px" }
        },
        {
          name: "cityAddress",
          value: `${memberInformation.city}, ${memberInformation.state} ${memberInformation.postalCode}`,
          style: { marginTop: "-20px" }
        },
        {
          name: "country",
          value: memberInformation.country,
          style: { marginTop: "-20px" }
        }
      ]
    }
  ];

  const handleRequest = async () => {
    setIsProccesing(true);
    dispatch(
      setUuidsOfKitsJustOrderedToBeShownOnConclusionScreen(
        kitsSelectedToBeOrdered
      )
    );
    const memberPersonalInfo = (({
      email,
      dob,
      firstName,
      middleName,
      lastName,
      gender,
      phoneNumber,
      countryCode,
      preferredName,
      pronouns,
      sexAssignedAtBirth,
      suffix
    }) => ({
      email,
      dob,
      firstName,
      middleName,
      lastName,
      gender,
      phoneNumber,
      countryCode,
      preferredName,
      pronouns,
      sexAssignedAtBirth,
      suffix
    }))(memberInformation);
    const shippingInformation = (({
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      country,
      postalCode,
      state
    }) => ({
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      country,
      postalCode,
      state
    }))(memberInformation);
    for (let index = 0; index < kitsSelectedToBeOrdered.length; index++) {
      await requestOrder({
        testUuid: kitsSelectedToBeOrdered[index],
        memberData: memberPersonalInfo,
        shippingAddress: shippingInformation
      })
        .then((response) => {
          if (response.status === 201) {
            Mixpanel.track(
              `Kit order ${kitsSelectedToBeOrdered[index]} successful`
            );
            dispatch(
              openGLSnackbar({
                variant: "success",
                header: "Success",
                subText: "The kit was requested successfully!"
              })
            );
            getExistingKitOrders()
              .then((existingKitOrdersResponse) => {
                dispatch(setExistingOrders(existingKitOrdersResponse));
                dispatch(setKitsSelectedToBeOrdered([]));
              })
              .then(() => {
                setIsProccesing(false);
                history.push(PATHS.ORDER_KIT_CONFIRMATION);
              });
          } else {
            Mixpanel.track(
              `Kit order ${kitsSelectedToBeOrdered[index]} failed`
            );
            dispatch(
              openGLSnackbar({
                variant: "error",
                header: true,
                subText:
                  "Sorry, your submission failed. Please try again, and reach out to us if you keep having trouble."
              })
            );
          }
        })
        .catch((error) => {
          setIsProccesing(false);
          let errorDescription = "";

          if (error.response.status === 409) {
            errorDescription =
              "The email you provided cannot be used by our clinical partner. Please edit your order to provide another email address. If you are having problems, please contact us.";
          } else {
            errorDescription =
              "The secure network connection between Genomic Life and our partner is not accepting order data. Please click to try again or constact us and we will manually process your test kit order";
          }
          dispatch(
            openGLSnackbar({
              variant: "error",
              header: "We're sorry...",
              subText: errorDescription
            })
          );
        });
    }
  };

  return (
    <div className={classes.root}>
      <LoadingPage
        isProccesing={isProccesing}
        loadingText={"Processing your request ..."}
      />
      <Grid container>
        <Grid
          container
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className={classes.body}
        >
          <Grid
            container
            xs={10}
            sm={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h5"
              align="center"
              className={classes.requestConfirmationTitle}
            >
              <Translate text="Almost done! Please review the following." />
            </Typography>
          </Grid>
          <Grid item xs={11} sm={9} md={7} lg={3} alignItems="stretch">
            <FormSummary formSections={formSections} />
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={11} sm={9} md={7} lg={3}>
              <Grid
                style={{
                  marginTop: buttonPadding,
                  marginBottom: buttonPadding
                }}
                container
                justifyContent={"center"}
              >
                <Button
                  onClick={handleRequest}
                  variant="contained"
                  color="primary"
                >
                  <Translate text="COMPLETE KIT REQUEST" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderKitRequestConfirmation;
