import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import Layout from "../../layout/Layout";
import { OrderingPageStyles } from "./OrderingPage.styles";
import OrderKitMemberInformation from "./OrderKitMemberInformation";
import OrderKitShipmentInformation from "./OrderKitShipmentInformation";
import OrderKitRequestConfirmation from "./OrderKitRequestConfirmation";
import { PATHS } from "../../../constants/pathConstants";
import PageTitle from "../../../utils/PageTitle";
import StepperWrapper from "../../v2/sharedComponents/StepperWrapper";
import { useSelector } from "react-redux";
import { selectKitsSelectedToBeOrdered } from "../../../store/reducers/order/OrderInformationSlice";

const getSteps = () => {
  return ["Member information", "Shipping information", "Request confirmation"];
};

export default function OrderingPage(props) {
  const history = useHistory();
  const classes = OrderingPageStyles();
  const [activeStep, setActiveStep] = useState(0);

  const [stepOverrideIndex, setStepOverrideIndex] = useState(null);

  const steps = getSteps();
  const hasOrdersSelected =
    useSelector(selectKitsSelectedToBeOrdered).length > 0;

  useEffect(() => {
    if (!hasOrdersSelected) {
      history.push(PATHS.ORDER_KIT_SELECTION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOrdersSelected]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const handleNext = () => {
    scrollToTop();
    if (stepOverrideIndex) {
      setActiveStep(stepOverrideIndex);
      setStepOverrideIndex(null);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBackToMemberInfo = () => {
    setActiveStep(activeStep - 2);
    setStepOverrideIndex(2);
  };

  const handleBackToShippingInfo = () => {
    setActiveStep(activeStep - 1);
    setStepOverrideIndex(2);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Grid item xs={12}>
            <OrderKitMemberInformation handleNext={handleNext} />
          </Grid>
        );
      case 1:
        return (
          <Grid item xs={12}>
            <OrderKitShipmentInformation handleNext={handleNext} />
          </Grid>
        );
      case 2:
        return (
          <Grid item xs={12}>
            <OrderKitRequestConfirmation
              onBackToMemberInfo={handleBackToMemberInfo}
              onBackToShippingInfo={handleBackToShippingInfo}
            />
          </Grid>
        );
      default:
        console.error("Unknown step");
    }
  };

  PageTitle(steps[activeStep]);

  return (
    <Fragment>
      <Layout>
        <Grid className={classes.boxStepper}>
          <StepperWrapper
            stepsArray={steps}
            currentStepIndex={activeStep}
            setCurrentStepIndexHandler={setActiveStep}
            isUpperStepperNonClickable={true}
            hideLowerStepper={true}
            breadcrumbText="Dashboard"
            breadcrumbRoute={PATHS.LANDING_PAGE}
          >
            {getStepContent(activeStep)}
          </StepperWrapper>
        </Grid>
      </Layout>
    </Fragment>
  );
}
