import React from "react";
import NoButtonsInfoDialog from "../../sharedComponents/Modals/ModalComponents/NoButtonsInfoDialog";
import HeaderLogo from "../../../../assets/images/LogoIconDefault.svg";
import { Grid, Typography } from "@mui/material";
import Translate from "../../../../translate/Translate";
import RegistrationHelpModalStyles from "./RegistrationHelpModal.styles";

const RegistrationHelpModal = (props) => {
  const { isOpen, onClose } = props;
  const classes = RegistrationHelpModalStyles();

  return (
    <NoButtonsInfoDialog isOpen={isOpen} onClose={onClose}>
      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={12} className={classes.logo}>
            <img src={HeaderLogo} className={classes.headerLogo} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              <Translate text="Need help? We're here!" />
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              <Translate text="Having trouble with your code or don’t have one? No worries! Please reach out and we’ll be more than happy to assist you." />
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.highlightedBox}>
            <a
              className={classes.link}
              href="mailto:navigation@genomiclife.com"
            >
              navigation@genomiclife.com
            </a>
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.extraInfo}>
              <Translate text="We may ask the same information you just gave us, this is to ensure your privacy and security." />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </NoButtonsInfoDialog>
  );
};

export default RegistrationHelpModal;
