import { makeStyles } from "@mui/styles";
import {
  COLOR_CONSTANTS,
  FLEX_CONSTANTS
} from "../../../constants/StyleConstants";

const ChipDropdownStyles = makeStyles((theme) => ({
  formContainer: { width: "100%" },
  textfield: {
    backgroundColor: COLOR_CONSTANTS.COLOR_WHITE,
    "&:focused": {
      backgroundColor: COLOR_CONSTANTS.COLOR_WHITE
    },
    border: "none"
  },
  wrapper: { ...theme.scrollPrimaryColors },
  dropdownField: {
    display: FLEX_CONSTANTS.FLEX,
    flexWrap: FLEX_CONSTANTS.FLEX_WRAP,
    gap: 0.5
  },
  chip: {
    backgroundColor: theme.palette.primary.chip_blue
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.light
  },
  menu: {
    display: FLEX_CONSTANTS.FLEX,
    alignItems: FLEX_CONSTANTS.CENTER,
    justifyContent: FLEX_CONSTANTS.FLEX_START,
    ...theme.scrollPrimaryColors
  }
}));

export default ChipDropdownStyles;
