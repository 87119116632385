const GENERIC_CONSTANTS = {
  NONE: "none",
  POINTER: "pointer",
  RELATIVE: "relative"
};
const COLOR_CONSTANTS = {
  //Genomic life theme colors
  GENOMIC_LIFE_PRIMARY: "#336AB3", //Old color #4E8AC9
  GENOMIC_LIFE_SECONDARY: "#294764",
  GENOMIC_LIFE_BLUE: "#294264",
  GENOMIC_LIFE_LIGHT_BLUE: "#E2ECF8",
  GENOMIC_LIFE_EXTRA_LIGHT_BLUE: "#F6FAFF",
  GENOMIC_LIFE_GREEN: "#6DB027", //Old color #A3D165
  GENOMIC_LIFE_GREEN_CHIP: "#E8F3D9",
  GENOMIC_LIFE_LINK_COLOR: "#336AB3", //Old color #4F8AC9
  GENOMIC_LIFE_LINK_SECONDARY_COLOR: "#4E8AC9",
  GENOMIC_LIFE_CANCEL_BUTTON_TEXT: "#D9704C",
  GENOMIC_LIFE_ACTION_BUTTON_TEXT: "#FFFFFF",
  GENOMIC_FORM_FOCUSED: "#89C2FF",
  GENOMIC_LIFE_FONT_BLUE: "#294764",
  GENOMIC_LIFE_BACKGROUND_TAN: "#EFEDEA",
  GENOMIC_LIFE_BACKGROUND_TAN_DARK: "#948F73",
  GENOMIC_LIFE_CHIP_BLUE: "#CDDFF5",
  GENOMIC_LIFE_BLUE_FONT: "#336AB3",
  GENOMIC_LIFE_LIGHT_GRAY: "#828282",
  //Generic colors
  COLOR_WHITE: "#FFFFFF",
  COLOR_ERROR: "#F15E5E",
  COLOR_ERROR_10: "#FDDADA",
  COLOR_GRAY: "#A7A7A7",
  COLOR_GRAY_25: "#ACAAAA",
  COLOR_GRAY_60: "#F3F5F8",
  COLOR_GRAY_40: "#DADADA",
  COLOR_GRAY_90: "#E5E5E5",
  COLOR_GRAY_30: "#C4C4C4",
  COLOR_BORDER_GRAY: "#DFE1E5",
  COLOR_BOX_GRAY: "#F3F6F8",
  COLOR_GRAY_SUBTITLE: "#666666",
  COLOR_TITLES: "#294764",
  COLOR_SUCCESS: "#8CC53F",
  COLOR_SUCCESS_LIGHT: "#F6FAF0",
  COLOR_SUCCESS_10: "#F4F9EC",
  COLOR_SUCCESS_25: "rgba(140, 197, 63, 0.2)",
  COLOR_SUCCESS_40: "rgba(163, 209, 101, 0.1)",
  COLOR_INFO: "#4F8AC9",
  COLOR_INFO_10: "#E2ECF8",
  COLOR_BLACK_75: "#000000BF",
  COLOR_BLACK: "#000000",
  COLOR_WARNING: "#FFC500",
  COLOR_WARNING_LIGHT: "#FBECBC",
  COLOR_WARNING_DARK: "#FFE38B",
  COLOR_SECURITY: "#DFE1E5",
  COLOR_SECURITY_10: "#F3F6F8",
  COLOR_ROUNDED_BLUE: "#1F8BFF",
  COLOR_STEPPER: "#3BC9DC",
  COLOR_BRAND_GREEN_DARK: "#6DB027",
  COLOR_INFO_MEDIUM: "#FFE38B",
  COLOR_BRAND_SALMON: "#C03417",
  COLOR_YELLOW_MUTED: "#E6C967",
  //Order kit progress colors
  COLOR_IN_PROGRESS: "#3BC9DC",
  COLOR_ON_HOLD: "#FFC500",
  COLOR_CANCELLED: "#F15E5E",
  COLOR_READY: "#8CC53F",
  COLOR_GREEN_MUTED: "#C3E19B",
  COLOR_BACKGRD_BLUE: "#E2ECF8"
};
const FONT_CONSTANTS = {
  FONT_AVENIRLT_PRO_BOOK: "AvenirLTProBook",
  FONT_AVENIRLT_PRO_HEAVY: "AvenirLTProHeavy",
  FONT_AVENIRLT_PRO_MEDIUM: "AvenirLTProMedium",
  FONT_AVENIRLT_PRO_ROMAN: "AvenirLTProRoman",
  FONT_AVENIRLT_PRO_BLACK: "AvenirLTProBlack",
  FONT_FAMILY_DOMAIN_DISPLAY_SEMIBOLD: "DomaineDisplaySemibold",
  FONT_SIZE_NORMAL: "normal"
};
const FLEX_CONSTANTS = {
  FLEX: "flex",
  CENTER: "center",
  LEFT: "left",
  ROW: "row",
  COLUMN: "column",
  FLEX_END: "flex-end",
  FLEX_START: "flex-start",
  FLEX_WRAP: "flexWrap"
};
export { GENERIC_CONSTANTS, COLOR_CONSTANTS, FONT_CONSTANTS, FLEX_CONSTANTS };
