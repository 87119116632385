import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LABELS } from "../../../constants/labelConstants";
import LoadingBarsGif from "../../../assets/images/LoadingBars.gif";
import Translate from "../../../translate/Translate";
import LoadingSectionStyles from "./LoadingSection.styles";

const LoadingSection = (props) => {
  const { loadingText } = props;
  const classes = LoadingSectionStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.loadingContainer}
    >
      <Grid item xs={12}>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={LoadingBarsGif}
            style={
              smDown ? { width: 80, height: 39 } : { width: 160, height: 78 }
            }
            alt={`${LABELS.LABEL_CANCER_GUARDIAN_LOGO_ALT_TEXT}`}
          />
          <Grid item className={classes.boxTitle}>
            <Typography
              variant={smDown ? "h2" : "h1"}
              className={classes.loadingTitle}
            >
              <Translate text={loadingText ? loadingText : "Loading ..."} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default LoadingSection;
