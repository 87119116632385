import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import AppRouter from "./AppRouter";
import RefreshTimer from "./components/SessionTimer/RefreshTimer";
import GLSnackbar from "./components/v2/GLSnackbar";
import { validateEmailString } from "./utils/utils";

function App(props) {
  // This is global once defined anywhere. Sort of gross,
  //  but I think this is roughly the place for it. Maybe
  //  andother file of these should be included here?

  ValidatorForm.addValidationRule("isValidEmail", (emailString) => {
    if (emailString.length === 0) return true;
    return validateEmailString(emailString);
  });

  return (
    <div className="App">
      <AppRouter />
      <RefreshTimer />
      <GLSnackbar />
    </div>
  );
}

export default App;

/*
<div className="App">
  <AppRouter />
  <RefreshTimer />
  <GLSnackbar />
</div>
*/
