import React, { Fragment } from "react";
import ModalComponent from "../../../../sharedComponents/ModalComponent";
import UnSavedChangeDialog from "./UnSavedChangeDialog";

const UnSavedChangeModal = (props) => {
  const { open, handleClose, firstTime, connection } = props;

  return (
    <Fragment>
      <ModalComponent
        handleClose={handleClose}
        open={open}
        body={
          <UnSavedChangeDialog
            handleClose={handleClose}
            firstTime={firstTime}
            connection={connection}
          />
        }
      />
    </Fragment>
  );
};

export default UnSavedChangeModal;
