import React from "react";
import { Grid } from "@mui/material";
import GutterCardStyles from "./GutterCard.styles";
import ContentResolver from "../ContentResolver";

const GutterCard = (props) => {
  const { text } = props;
  const classes = GutterCardStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <ContentResolver content={text.en} />
      </Grid>
    </Grid>
  );
};

export default GutterCard;
