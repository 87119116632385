import React from "react";
import PillImageButtonStyles from "./PillImageButton.styles";

const PillImageButton = (props) => {
  const {
    isSelected,
    icon,
    pillOverrideStyle,
    iconOverrideStyle,
    pillActiveOverrideStyle,
    iconActiveOverrideStyle
  } = props;
  const Icon = icon;
  const classes = PillImageButtonStyles();
  const activeHandler = () => {
    if (isSelected) {
      return {
        pill: pillActiveOverrideStyle
          ? pillActiveOverrideStyle
          : `${classes.pill} ${classes.pillActive}`,
        icon: iconActiveOverrideStyle
          ? iconActiveOverrideStyle
          : `${classes.icon} ${classes.iconActive}`
      };
    }
    return {
      pill: pillOverrideStyle ? pillOverrideStyle : classes.pill,
      icon: iconOverrideStyle ? iconOverrideStyle : classes.icon
    };
  };
  const pillStyle = activeHandler().pill;
  const iconStyle = activeHandler().icon;
  if (icon) {
    return (
      <div className={pillStyle}>
        <Icon className={iconStyle} />
      </div>
    );
  }
  return null;
};
export default PillImageButton;
