import React from "react";
import { Grid, Typography } from "@mui/material";
import ResourceGridStyles from "./ResourceGrid.styles";
import ContentResolver from "../ContentResolver";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const ResourceGrid = (props) => {
  const { title, articles } = props;
  const classes = ResourceGridStyles();

  const renderCards = () => {
    return articles.map((article, index) => {
      return (
        <Grid item xs={12} md={6} key={index} className={classes.linkContainer}>
          <a
            href={`${window.location.href}/${article.name}`}
            className={classes.linkContainer}
          >
            <Grid className={classes.cardContainer}>
              <Grid container className={classes.innerCardContainer}>
                <Grid item xs={11} className={classes.cardHeaderContainer}>
                  <Typography variant="h6">{article.title.en}</Typography>
                  <Typography>{article.summary.en}</Typography>
                </Grid>
                <Grid className={classes.arrow} xs={1}>
                  <KeyboardArrowRight className={classes.iconFontSize} />
                </Grid>
              </Grid>
            </Grid>
          </a>
        </Grid>
      );
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.header}>
          <ContentResolver content={title.en} />
        </Typography>
        <Grid container spacing={3}>
          {renderCards()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResourceGrid;
