import { makeStyles } from "@mui/styles";
import {
  COLOR_CONSTANTS,
  FONT_CONSTANTS
} from "../../../../constants/StyleConstants";

const OrderKitConclusionStyles = makeStyles((theme) => ({
  cardContainer: {
    height: "100%",
    borderRadius: 10,

    border: "1px solid #C4C4C4"
  },
  subheader: {
    marginBottom: "30px"
  },
  card: {
    padding: "20px 20px 10px 20px",
    color: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY
  },
  stepNumber: {
    color: theme.palette.primary.green,
    fontSize: "24px",
    fontWeight: "600",
    marginTop: "50px"
  },
  backToButton: {
    minWidth: 264,
    minHeight: 45,
    fontSize: 13,
    marginTop: "50px",
    marginBottom: 83,
    fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
    [theme.breakpoints.only("md")]: {
      marginBottom: 323
    },
    [theme.breakpoints.up(1100)]: {
      marginBottom: 129
    }
  },
  imageCardcontainer: {
    backgroundColor: "lightGray",
    padding: 0,
    margin: "0",
    boxShadow: "0px 2px 10px 0px #4E81C933",
    borderRadius: "10px"
  },
  imageContainer: {
    height: "240px",
    width: "100%",
    "& img": {
      objectFit: "cover",
      width: "100%",
      height: "100%"
    }
  },
  imageCardfooter: {
    color: theme.palette.primary.font_blue,
    textAlign: "center",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "0 0 10px 10px"
  }
}));

export default OrderKitConclusionStyles;
