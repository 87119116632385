import Translate from "../../../../../../../translate/Translate";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ToDoListCardContainer from "../../ToDoListCardContainer";
import chatWithGeneticCounselingIcon from "../../../../../../../assets/icons/icon-pre-screening.svg";
import NeedSupportModal from "../../../../../sharedComponents/Modals/NeedSupportModal";
import GLButton from "../../../../../sharedComponents/GLButton";
import { selectAllPreviousSurveyAnswers } from "../../../../../../../store/reducers/survey/SurveySlice";
import { selectSubsetOfAnswersFromSurveyAnswers } from "../../../../../Surveys/Utilities/util";
import { selectUserPersonalInformation } from "../../../../../../../store/reducers/user/UserAccountSlice";
import Mixpanel from "../../../../../../../utils/mixpanel";

const ContactGeneticCounselingToDoCard = () => {
  const [supportModalIsOpen, setSupportModalIsOpen] = useState(false);
  const previousSurveyAnswers = useSelector(selectAllPreviousSurveyAnswers);
  const userFirstName = useSelector(selectUserPersonalInformation).firstName;

  const relevantSurveyAnswers = selectSubsetOfAnswersFromSurveyAnswers(
    previousSurveyAnswers,
    ["dna-transplant"]
  );

  if (
    !relevantSurveyAnswers["dna-transplant"] ||
    relevantSurveyAnswers["dna-transplant"] === "Neither"
  ) {
    return null;
  }

  const body = (
    <>
      {userFirstName},{" "}
      <Translate
        text={
          "based on what you shared we feel it will be helpful to talk with one of our genomic health navigators to ensure you have the service you deserve."
        }
      />
    </>
  );

  const renderButton = () => {
    return (
      <GLButton
        text={"Contact Us"}
        size={"small"}
        onClick={() => {
          Mixpanel.track(`To Do Card CTA Clicked - Contact genetic counseling`);
          setSupportModalIsOpen(true);
        }}
      />
    );
  };

  return (
    <>
      <ToDoListCardContainer
        icon={chatWithGeneticCounselingIcon}
        title={
          <Translate text={"A pre-screening conversation is recommended"} />
        }
        body={body}
        buttons={renderButton()}
      ></ToDoListCardContainer>
      <NeedSupportModal
        headerText={"Contact us"}
        isOpen={supportModalIsOpen}
        onClose={() => setSupportModalIsOpen(false)}
      />
    </>
  );
};

export default ContactGeneticCounselingToDoCard;
