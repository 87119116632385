import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Translate from "../../../../../translate/Translate";
import { Box, Grid, Typography } from "@mui/material";
import DocumentNotApprovedModalStyles from "./DocumentNotApprovedModal.styles";
import GLButton from "../../GLButton";
import ModalWrapper from "../ModalComponents/ModalWrapper";

const DocumentNotApprovedModal = (props) => {
  const { isOpen, onClose } = props;
  const classes = DocumentNotApprovedModalStyles();

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      onClickOutsideModal={onClose}
    >
      <Grid
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        className={classes.modalContainer}
      >
        <Typography className={classes.title} variant="h4">
          <Translate text={"Your results will be ready in a few days!"} />
        </Typography>

        <CloseIcon onClick={onClose} className={classes.closeIcon} />

        <Typography className={classes.message} variant="subtitle1">
          <Translate
            text={
              "The lab has processed your kit and your results will be available for download soon. You are now able to schedule a meeting with a genomic counselor to discuss your results or you can wait until they are available here."
            }
          />
        </Typography>
        <a target="_blank" href="https://www.genomemedical.com">
          <GLButton
            className={classes.button}
            size={"small"}
            color={"secondary"}
            onClick={onClose}
          >
            Schedule with Genome Medical
          </GLButton>
        </a>
      </Grid>
    </ModalWrapper>
  );
};

export default DocumentNotApprovedModal;
