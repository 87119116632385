import React, { useState, useEffect, Fragment } from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { PATHS } from "../../../../constants/pathConstants";
import Layout from "../../../layout/Layout";
import BriefInformationCard from "../../../sharedComponents/BriefInformationCard";
import PaymentInformationStyles from "./PaymentInformation.styles";
import PageTitle from "../../../../utils/PageTitle";
import Translate from "../../../../translate/Translate";
import ModalComponent from "../../../sharedComponents/ModalComponent";
import AsperatoPaymentForm from "./AsperatoPaymentForm";
import LoadingSection from "../../../sharedComponents/LoadingSection";
import AddIcon from "@mui/icons-material/Add";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";
import { useSelector } from "react-redux";
import { selectUserPersonalInformation } from "../../../../store/reducers/user/UserAccountSlice";
import ChipRowInfoCard from "../../../v2/sharedComponents/ChipRowInfoCard";
import { getAsperatoForm } from "../../../../apis/asperatoApi";

const PaymentInformation = (props) => {
  const classes = PaymentInformationStyles();
  const [openPaymentModal, setPaymentModalVisible] = useState(false);
  const userInformation = useSelector(selectUserPersonalInformation);
  const [formUrl, setFormUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  PageTitle("Payment information");

  useEffect(() => {
    getAsperatoForm(userInformation.glMemberId)
      .then(({ data }) => {
        if (data.body.response && data.body.response.eCommerce_URL) {
          const anchor = data.body.response.eCommerce_URL;
          const substring = anchor.substring(
            anchor.indexOf(">") + 1,
            anchor.lastIndexOf("<")
          );
          setFormUrl(substring);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setFormUrl(null);
        setIsLoading(false);
      });
  }, [userInformation.glMemberId]);

  const handlePaymentForm = () => {
    setPaymentModalVisible(!openPaymentModal);
  };

  const renderBenefitsPackage = () => {
    return (
      <Typography variant="h5" className={classes.benefitsPackage}>
        Benefits package
      </Typography>
    );
  };

  const benefitsChip = () => {
    return (
      <Box display="flex" flexDirection="row">
        <Chip
          label={
            <Typography variant="subtitle2" className={classes.chipBenefits}>
              <Translate
                text={userInformation.isMemberActive ? "Selected" : "Inactive"}
              />
            </Typography>
          }
          className={
            userInformation.isMemberActive
              ? classes.chipSuccess
              : classes.chipInfo
          }
        />
      </Box>
    );
  };

  const renderDescriptionCard = () => {
    return (
      <Typography variant="h5" className={classes.benefitDescription}>
        <Translate text="Your Genomic Life Membership is part of your employee benefits package. If you change employers or health plans, you may opt to extend the benefit as an individual. Please contact your Human Resources Department or Genomic Life for more information." />
      </Typography>
    );
  };
  const renderHandler = () => {
    if (isLoading) {
      return <LoadingSection />;
    } else {
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.paymentMethodText}>
              <Translate text="Payment Method" />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.benefitPackageCard}>
            <ChipRowInfoCard
              id="emailSetting"
              chipRowElements={benefitsChip()}
              chipRowDescription={renderDescriptionCard()}
              leftThirdContent={renderBenefitsPackage()}
            />
          </Grid>
          <Grid item xs={12}>
            {formUrl ? (
              <div
                id="addCreditCardButton"
                className={classes.cardContainer}
                onClick={handlePaymentForm}
              >
                <BriefInformationCard
                  title={"Add Credit Card"}
                  cardContainerStyles={classes.creditCardContainer}
                  chipStyles={classes.chipText}
                  chipBold={true}
                  hasIcon={true}
                  icon={
                    <AddIcon
                      style={{
                        color: COLOR_CONSTANTS.COLOR_GRAY
                      }}
                      alt={"Add credit card"}
                    />
                  }
                  chipType={"systemError"}
                  chipText={"Required for membership"}
                  chipSize={"medium"}
                />
              </div>
            ) : null}
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <div>
      <Fragment>
        <ModalComponent
          handleClose={handlePaymentForm}
          open={openPaymentModal}
          body={
            <AsperatoPaymentForm
              formTitle="Add Credit Card"
              formUrl={formUrl}
              handleClose={handlePaymentForm}
            />
          }
        />
      </Fragment>
      <Layout
        headerType="integrated"
        headerText="Payment Information"
        goBackPath={PATHS.ACCOUNT_MANAGEMENT}
        goBackTitle={"Account Management"}
      >
        {renderHandler()}
      </Layout>
    </div>
  );
};

export default PaymentInformation;
