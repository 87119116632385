import { makeStyles } from "@mui/styles";
import {
  FLEX_CONSTANTS,
  GENERIC_CONSTANTS
} from "../../../../../constants/StyleConstants";

const ConnectionDetailsPageStyles = makeStyles((theme) => ({
  root: {
    display: FLEX_CONSTANTS.FLEX,
    flexDirection: FLEX_CONSTANTS.COLUMN,
    flex: 1,
    justifyContent: FLEX_CONSTANTS.FLEX_END,
    marginTop: 10
  },
  addContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  checkboxText: {
    marginTop: 7,
    color: theme.palette.secondary.gray
  },
  buttonContainer: { marginTop: 75 },
  section: {
    marginTop: theme.paddings.lg,
    marginBottom: theme.paddings.lg
  },
  guardianInformationContainer: {
    marginTop: theme.paddings.sm,
    backgroundColor: theme.palette.secondary.gray_60,
    "& .MuiTextField-root": {
      backgroundColor: theme.palette.secondary.gray_60
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.primary.white
    }
  },
  geneticText: {
    marginTop: 20
  },
  divider: {
    color: theme.palette.secondary.backgrd_blue,
    marginTop: theme.margins.xs,
    marginBottom: theme.margins.xs
  },
  boxButtons: {
    marginTop: theme.margins.xxl,
    marginBottom: theme.margins.xxl
  },
  deleteBtn: {
    color: theme.palette.primary.blue_font
  },
  boxContainer: {
    marginBottom: theme.margins.xxl
  },
  checkboxContainer: {
    marginBottom: theme.margins.xs
  },
  textConnect: {
    ...theme.typography.subtitleHeavy,
    color: theme.palette.secondary.gray_30
  },
  disabledCheckbox: {
    color: theme.palette.secondary.gray_button
  },
  textError: {
    color: theme.palette.error.main
  },
  breadcrumbContainer: {
    [theme.breakpoints.down('lg')]: {
      height: 40
    },
    [theme.breakpoints.up("md")]: {
      height: 50
    }
  },
  breadcrumbLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    cursor: `pointer`,
    textDecoration: `none`,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 19
    },
    [theme.breakpoints.between("md", 'xl')]: {
      marginLeft: 33
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 168
    }
  },
  breadcrumbText: {
    color: theme.palette.primary.link,
    marginTop: 4
  },
  breadcrumbIcon: {
    color: theme.palette.primary.link,
    marginRight: 15,
    fontSize: 25
  },
  names: {
    [theme.breakpoints.down(475)]: {
      marginRight: theme.margins.xxl
    }
  },
  modalContainer: {
    marginTop: theme.paddings.sm,
    marginBottom: theme.paddings.sm,
    marginLeft: 27
  },
  modalLink: {
    cursor: GENERIC_CONSTANTS.POINTER,
    color: theme.palette.primary.blue_font
  },
  formTitle: {
    ...theme.typography.h5HeadlineBlack,
    paddingTop: theme.paddings.lg
  }
}));

export default ConnectionDetailsPageStyles;
