import { makeStyles } from "@mui/styles";
import { FLEX_CONSTANTS } from "../../../../constants/StyleConstants";

const OrderKitRequestConfirmationStyles = makeStyles((theme) => ({
  root: {
    display: FLEX_CONSTANTS.FLEX,
    flexDirection: FLEX_CONSTANTS.COLUMN,
    flex: 1,
    justifyContent: FLEX_CONSTANTS.FLEX_END
  },
  body: {
    marginTop: theme.spacing(2)
  },
  checkboxText: {
    marginTop: 7,
    color: theme.palette.secondary.gray
  },
  containerSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  containerSpacingLarge: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  buttonContainer: { marginTop: 75 },
  loadingGif: {
    width: 80,
    height: 39
  },
  loadingContainer: {
    backgroundColor: "#FFFFFFD9",
    height: "100%"
  },
  requestConfirmationTitle: {
    ...theme.typography.h5HeadlineBlack,
    color: theme.palette.primary.font_blue,
    marginBottom: 20,
    marginTop: 25,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      paddingRigth: 30
    }
  }
}));

export default OrderKitRequestConfirmationStyles;
