import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Grid, MenuItem, Typography } from "@mui/material";
import Translate from "../../../../translate/Translate";
import FormModalWrapper from "../FormComponents/FormModalWrapper";
import ConnectionFormStyles from "./ConnectionForm.styles";
import { FormStyles } from "../Form.styles";
import {
  selectConnection,
  setConnections,
  resetConnectionsArray
} from "../../../../store/reducers/connection/ConnectionsInformationSlice";
import GLSelectField from "../FormComponents/GLSelectField";
import GLTextField from "../FormComponents/GLTextField";
import SectionHeader from "../FormComponents/SectionHeader";
import GLPhoneNumberField from "../FormComponents/GLPhoneNumberField";
import InfoExpander from "../../sharedComponents/InfoExpander";
import GLCheckboxField from "../FormComponents/GLCheckboxField";
import GLEmailTextField from "../FormComponents/GLEmailTextField";
import { useFormFieldState, validateZipCode } from "../FormUtils";
import {
  createUserConnection,
  deleteConnectionByID,
  getAvailableUserConnections,
  updateUserConnection
} from "../../../../apis/connectionsApi";
import GLUsStateAutocomplete from "../FormComponents/GLAutocompleteDropdown/GLUsStateAutocompleteDropdown";
import GLPlacesAutocomplete from "../FormComponents/GLPlacesAutocomplete";
import { loadPlacesScript } from "../FormComponents/GLPlacesAutocomplete/placesApiUtils";
import GLPlacesTextfield from "../FormComponents/GLPlacesAutocomplete/GLPlacesTextfield";
import { doesObjectHaveChangesComparedToOtherObject } from "../../../../utils/utils";
import GLNameTextField from "../FormComponents/GLNameTextField";

/*
For later:

const SEXES = ["M", "F", "U", "I"];
const GENDERS = [
  "",
  "male",
  "female",
  "transgender - male to female",
  "trasgender - female to male",
  "unknown - unborn child",
  "agender",
  "genderqueer/gender-fluid/gender nonconforming",
  "other"
];
const PRONOUNS = ["", "he/him/his", "she/her/hers", "they/them/their"];
*/

const RELATIONSHIPS = ["Primary care provider", "Practitioner"];

const ConnectionForm = (props) => {
  const { connectionId, onSaved, onCancelled, onDeleted } = props;
  const sharedFormClasses = FormStyles();
  const connectionFormCustomClasses = ConnectionFormStyles();
  const dispatchReducer = useDispatch();

  const loadedScript = useRef(false);

  useEffect(() => {
    if (!loadedScript.current) {
      loadPlacesScript();
      loadedScript.current = true;
    }
  }, [loadedScript]);

  const maybeExistingConnection = useSelector((store) => {
    return selectConnection(store, connectionId);
  });
  const connection = maybeExistingConnection
    ? { ...maybeExistingConnection, addressCountry: "United States of America" }
    : {
        authorizeReleaseOfConfidentialHealth: false,
        isLegalGuardian: false,
        addressCountry: "United States of America"
      };

  const { formFields, formStatus, handleInputChange } = useFormFieldState(
    connection
  );
  const formHasEditedAndUnsavedFields = doesObjectHaveChangesComparedToOtherObject(
    connection,
    formFields
  );

  const mapPayloadBasedOnRole = () => {
    const isACareTeamMember = [
      "Primary care provider",
      "Practitioner"
    ].includes(formFields.relationship);
    if (isACareTeamMember) {
      let newPayload = {
        address1: formFields.address1,
        address2: formFields.address2,
        addressCity: formFields.addressCity,
        addressCountry: formFields.addressCountry,
        addressState: formFields.addressState,
        addressZip: formFields.addressZip,
        authorizeReleaseOfConfidentialHealth:
          formFields.authorizeReleaseOfConfidentialHealth,
        connectionId: formFields.connectionId,
        faxNumber: formFields.faxNumber,
        firstName: formFields.firstName,
        hospitalOrOrganization: formFields.hospitalOrOrganization,
        isLegalGuardian: false,
        lastName: formFields.lastName,
        middleName: formFields.middleName,
        phoneNumber: formFields.phoneNumber,
        preferredName: formFields.preferredName,
        relationship: formFields.relationship
      };
      if (formFields.email && formFields.email.length > 0) {
        newPayload = {
          ...newPayload,
          email: formFields.email
        };
      }
      return newPayload;
    }
    return {
      ...formFields,
      connectionId: connectionId
    };
  };

  const onClickSaveOrUpdate = () => {
    const apiMethod = connectionId
      ? updateUserConnection
      : createUserConnection;

    const payload = mapPayloadBasedOnRole();
    return apiMethod(payload).then(() => {
      updateConnectionsInStoreAndClose(onSaved);
    });
  };

  const onClickDelete = async () => {
    return deleteConnectionByID(connectionId).then(() => {
      updateConnectionsInStoreAndClose(onDeleted);
    });
  };

  const updateConnectionsInStoreAndClose = (closeHandler) => {
    getAvailableUserConnections().then((res) => {
      dispatchReducer(resetConnectionsArray());
      res?.data?.length > 0 &&
        res.data.forEach((element) => {
          dispatchReducer(setConnections(element));
        });
      closeHandler();
    });
  };

  const fieldRequirementBasedOnRelationship = () => {
    if (
      true
      // When we add more types we need to test here, and make sure
      //  we come up with a smooth way to shift between form contents
      //  and requirements.
      //["Primary care provider", "Practitioner"].includes(
      //  formFields.relationship
      //)
    ) {
      return {
        firstName: true,
        lastName: true,
        faxNumber: true,
        hospitalOrOrganization: true,
        address1: true,
        addressCity: true,
        addressState: true,
        addressZip: true,
        addressCountry: true,
        authorizeReleaseOfConfidentialHealth: true
      };
    }
  };

  const renderRoleMenuItems = () => {
    //This will get more complicated as we add connection types
    return RELATIONSHIPS.map((item) => {
      return (
        <MenuItem name={item} value={item} key={item}>
          <Translate text={item} />
        </MenuItem>
      );
    });
  };

  const renderNameTextField = (id, label, value, isRequired) => {
    return (
      <GLNameTextField
        id={id}
        label={label}
        value={value}
        required={isRequired}
        onChange={handleInputChange}
        md={6}
      />
    );
  };

  return (
    <FormModalWrapper
      itemType={"connection"}
      footerMessage="CheckEm"
      headerText={`${connection.connectionId ? "Edit" : "Create"} connection`}
      submitDisabled={formStatus.invalidFields.length > 0}
      onSaved={onClickSaveOrUpdate}
      onCancelled={onCancelled}
      onDeleted={connection.connectionId ? onClickDelete : null}
      formHasUnsavedChanges={formHasEditedAndUnsavedFields}
    >
      <Grid item xs={11}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          className={sharedFormClasses.boxContainer}
        >
          <Grid
            container
            direction="row"
            alignItems="stretch"
            className={sharedFormClasses.titleContainer}
          >
            <Grid container className={sharedFormClasses.section}>
              <Grid container spacing={4}>
                <GLSelectField
                  md={6}
                  label={"Relationship to you"}
                  id="relationship"
                  value={formFields.relationship}
                  onChange={handleInputChange}
                  required={true}
                >
                  {renderRoleMenuItems()}
                </GLSelectField>
              </Grid>
            </Grid>
            <Grid container className={sharedFormClasses.section}>
              <SectionHeader header={"Personal information"} />
              <Grid container spacing={4}>
                {renderNameTextField(
                  "firstName",
                  "First name",
                  formFields.firstName,
                  !!fieldRequirementBasedOnRelationship()["firstName"]
                )}
                {renderNameTextField(
                  "middleName",
                  "Middle name",
                  formFields.middleName,
                  !!fieldRequirementBasedOnRelationship()["middleName"]
                )}
              </Grid>
              <Grid container spacing={4}>
                {renderNameTextField(
                  "lastName",
                  "Last name",
                  formFields.lastName,
                  !!fieldRequirementBasedOnRelationship()["lastName"]
                )}
                {renderNameTextField(
                  "preferredName",
                  "Preferred name",
                  formFields.preferredName,
                  !!fieldRequirementBasedOnRelationship()["preferredName"]
                )}
              </Grid>
            </Grid>

            <Grid container className={sharedFormClasses.section}>
              <SectionHeader
                header={"Contact"}
                subheader={
                  "No spam, we promise. We use this information for verification purposes only."
                }
              />
              <Grid container spacing={4}>
                <GLPhoneNumberField
                  md={3}
                  required={
                    !!fieldRequirementBasedOnRelationship()["faxNumber"]
                  }
                  label={"Fax number"}
                  value={formFields.faxNumber}
                  id="faxNumber"
                  onChange={handleInputChange}
                />
                <GLPhoneNumberField
                  required={
                    !!fieldRequirementBasedOnRelationship()["phoneNumber"]
                  }
                  md={3}
                  label={"Phone number"}
                  value={formFields.phoneNumber}
                  id="phoneNumber"
                  onChange={handleInputChange}
                />
                <GLEmailTextField
                  required={!!fieldRequirementBasedOnRelationship()["email"]}
                  md={6}
                  label={"Email"}
                  id="email"
                  value={formFields.email}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Grid container className={sharedFormClasses.section}>
              <SectionHeader header={"Address"} />
              <Grid container spacing={4}>
                <GLPlacesAutocomplete
                  required={
                    !!fieldRequirementBasedOnRelationship()[
                      "hospitalOrOrganization"
                    ]
                  }
                  label={"Hospital or Organization"}
                  id="hospitalOrOrganization"
                  value={formFields.hospitalOrOrganization}
                  onChange={handleInputChange}
                  fieldType="location"
                  dependantFields={[
                    {
                      id: "address1",
                      parameter: "fullAddress"
                    },
                    { id: "addressCity", parameter: "locality" },
                    {
                      id: "addressZip",
                      parameter: "postal_code"
                    },
                    {
                      id: "addressState",
                      parameter: "administrative_area_level_1"
                    }
                  ]}
                />
                <GLPlacesAutocomplete
                  required={!!fieldRequirementBasedOnRelationship()["address1"]}
                  md={6}
                  label={"Address 1"}
                  id="address1"
                  fieldType="address"
                  value={formFields.address1}
                  onChange={handleInputChange}
                  dependantFields={[
                    { id: "hospitalOrOrganization", parameter: "location" },
                    { id: "addressCity", parameter: "locality" },
                    {
                      id: "addressZip",
                      parameter: "postal_code"
                    },
                    {
                      id: "addressState",
                      parameter: "administrative_area_level_1"
                    }
                  ]}
                  linkFieldOnTab="addressZip"
                />
                <GLTextField
                  required={!!fieldRequirementBasedOnRelationship()["address2"]}
                  md={6}
                  label={"address2"}
                  id="address2"
                  value={formFields.address2}
                  onChange={handleInputChange}
                />
                <GLTextField
                  required={
                    !!fieldRequirementBasedOnRelationship()["addressCity"]
                  }
                  md={4}
                  label={"City"}
                  id="addressCity"
                  value={formFields.addressCity}
                  inputProps={{ maxLength: 255 }}
                  onChange={handleInputChange}
                />
                <GLUsStateAutocomplete
                  required={
                    !!fieldRequirementBasedOnRelationship()["addressState"]
                  }
                  xs={12}
                  md={4}
                  label={"State"}
                  id="addressState"
                  value={formFields.addressState}
                  onChange={handleInputChange}
                />
                <GLPlacesTextfield
                  required={
                    !!fieldRequirementBasedOnRelationship()["addressZip"]
                  }
                  md={4}
                  label={"Zip/Postal code"}
                  id="addressZip"
                  value={formFields.addressZip}
                  onChange={handleInputChange}
                  additionalValidation={validateZipCode}
                  willItpredictByRegion
                  dependantFields={[
                    {
                      id: "addressState",
                      parameter: "administrative_area_level_1"
                    },
                    { id: "addressCity", parameter: "locality" }
                  ]}
                />
                <GLTextField
                  required={
                    !!fieldRequirementBasedOnRelationship()["addressCountry"]
                  }
                  md={6}
                  label={"Country"}
                  id="addressCountry"
                  value={"United States of America"}
                  //value={formFields.addressCountry}
                  onChange={handleInputChange}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container className={sharedFormClasses.section}>
              <SectionHeader header={"Member connection"} />
              <GLCheckboxField
                required={
                  !!fieldRequirementBasedOnRelationship()[
                    "authorizeReleaseOfConfidentialHealth"
                  ]
                }
                label={`I authorize the release of confidential health information about me. You may release a copy of my medical records, or a summary or narrative of my protected health information to this connection.`}
                id="authorizeReleaseOfConfidentialHealth"
                checked={!!formFields.authorizeReleaseOfConfidentialHealth}
                onChange={handleInputChange}
                error={
                  !formFields.authorizeReleaseOfConfidentialHealth
                    ? "Review the form above and check the box to authorize care team members added to your connections."
                    : null
                }
              />
              <InfoExpander
                label="Review HIPAA Authorization"
                className={connectionFormCustomClasses.hipaaExpander}
              >
                <Grid>
                  <Typography paragraph variant="body2">
                    <Translate text="IMPORTANT: This transmission contains confidential information, which may be protected health information as defined by the Health Insurance Portability and Accountability Act (HIPAA) Privacy Rule. This transmission is intended for the exclusive use of the individual or entity to whom it is addressed and may contain information that is proprietary, privileged, confidential, and/or exempt from disclosure under applicable law." />
                  </Typography>
                  <Typography
                    className={sharedFormClasses.paddingParagraph}
                    variant="body2"
                  >
                    <Translate text="If you are not the intended recipient (or an employee or agent responsible for delivering this facsimile transmission to the intended recipient), you are hereby notified that any disclosure, dissemination, distribution or copying of this information is strictly prohibited and may be subject to legal restriction or sanction. Please notify the sender by telephone (number listed above) to arrange the return or destruction of the information and all copies." />
                  </Typography>
                </Grid>
              </InfoExpander>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormModalWrapper>
  );
};

export default ConnectionForm;

ConnectionForm.propTypes = {
  connectionId: PropTypes.string,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired
};
