import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const ReportSelectionStepStyles = makeStyles((theme) => ({
  questionContainer: {
    marginTop: theme.paddings.xxl,
    marginBottom: theme.paddings.md
  },
  questionText: {
    color: COLOR_CONSTANTS.COLOR_TITLES
  },
  counterContainer: {
    marginTop: 25,
    marginBottom: 0
  }
}));

export default ReportSelectionStepStyles;
