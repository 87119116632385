import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const LoginCallbackPageStyles = makeStyles((theme) => ({
  formContainer: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 10px 0px #00000024",
    padding: "50px",
    "& h5": {
      marginBottom: "20px"
    }
  },
  center: {
    textAlign: "center",
    margin: "25px auto",
    "& button": {
      marginRight: 0
    }
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
    display: "inline"
  },
  lightSubheader: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_GREEN
  },
  continueButton: {
    borderRadius: 0,
    marginTop: "10px",
    marginLeft: "0",
    marginBottom: "30px",
    width: "100%"
  }
}));

export default LoginCallbackPageStyles;
