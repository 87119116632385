import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const MaintenancePageStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    textAlign: "center"
  },
  logo: {
    marginTop: "30px",
    marginBottom: "100px"
  },
  icon: {
    fontSize: "48px",
    marginBottom: "20px"
  },
  titleHeader: {
    fontWeight: 700,
    fontSize: 36,
    color: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY,
    marginBottom: "10px"
  },
  highlightedBox: {
    border: `2px solid ${COLOR_CONSTANTS.COLOR_SUCCESS}`,
    borderRadius: "15px",
    padding: "15px",
    width: "100%",
    marginBottom: "15px",
    marginTop: "20px",
    "& p": {
      marginTop: "5px",
      marginBottom: "0px"
    }
  },
  link: {
    color: "black",
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: "700"
  },
  phone: { fontWeight: 700 },
  extraInfo: {
    fontStyle: "italic"
  }
}));

export default MaintenancePageStyles;
