import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const TransmissionConclusionPageStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.paddings.md
  },
  buttonContainer: {
    marginTop: theme.paddings.lg,
    marginBottom: theme.paddings.lg
  },
  subheader: {
    marginTop: theme.paddings.sm,
    ...theme.typography.h6
  },
  subheaderHeavy: {
    ...theme.typography.h6HeadlineHeavy
  },
  textDescription: {
    color: COLOR_CONSTANTS.COLOR_TITLES
  },
  boldText: {
    fontWeight: "bold"
  },
  chipQueuedColor: {
    backgroundColor: COLOR_CONSTANTS.COLOR_INFO_MEDIUM
  },
  datePosition: {
    marginTop: theme.paddings.lg
  },
  dateText: {
    ...theme.typography.body1Heavy
  },
  pillContainer: {
    marginLeft: theme.paddings.md
  },
  printIcon: {
    color: COLOR_CONSTANTS.COLOR_BLACK
  }
}));

export default TransmissionConclusionPageStyles;
