import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const SupportContactInfoBlockStyles = makeStyles((theme) => ({
  contactLinks: {
    "& a": {
      color: COLOR_CONSTANTS.GENOMIC_LIFE_FONT_BLUE,
      fontSize: 18,
      marginBottom: 30
    }
  },
  contactLink: {
    marginBottom: 20
  },
  iconContainer: { textAlign: "right" },
  icon: {
    cursor: `pointer`,
    position: "relative",
    top: "5px",
    width: 30,
    marginRight: 15
  },
  emailSubheader: {
    paddingLeft: 47
  },
  hours: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_FONT_BLUE,
    fontStyle: "Italic"
  }
}));

export default SupportContactInfoBlockStyles;
