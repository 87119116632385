import Translate from "../../../../../../../translate/Translate";
import React from "react";
import { useSelector } from "react-redux";
import ToDoListCardContainer from "../../ToDoListCardContainer";
import screeningOrderedIcon from "../../../../../../../assets/icons/icon-screening-ordered.svg";
import { selectExistingOrders } from "../../../../../../../store/reducers/order/OrderInformationSlice";
import { combineArrayOfWordsWithCommasAndAnAndForLastItem } from "../../../../../../../utils/utils";

const KitOrderedToDoCard = () => {
  const kitOrders = useSelector(selectExistingOrders);
  const ordersForKitsThatAreSubmittedButNotCompleteYet = kitOrders.filter(
    (order) => {
      return (
        order.documentIds?.length === 0 && !["cancelled"].includes(order.status)
      );
    }
  );

  if (ordersForKitsThatAreSubmittedButNotCompleteYet.length === 0) return null;

  const testNamesArray = ordersForKitsThatAreSubmittedButNotCompleteYet.map(
    (order) => {
      return `<strong>${order.description.name}</strong>`;
    }
  );

  const testNamesString = combineArrayOfWordsWithCommasAndAnAndForLastItem(
    testNamesArray
  );

  const body = (
    <div
      dangerouslySetInnerHTML={{
        __html: `Your ${testNamesString} order${
          testNamesArray.length > 1 ? "s were" : " was"
        } placed. You will receive emails or texts from our partners who
        are experts in genomic testing and counseling. Please just know, it’s 
        because they care ❤️.`
      }}
    />
  );

  return (
    <ToDoListCardContainer
      icon={screeningOrderedIcon}
      title={<Translate text={"Screening requested"} />}
      body={body}
    ></ToDoListCardContainer>
  );
};

export default KitOrderedToDoCard;
