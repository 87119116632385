import React, { Fragment } from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography
} from "@mui/material";
import ImageCardStyles from "./ImageCard.styles";
import { Link } from "react-router-dom";
import CenteredContent from "../CenteredContent";
import Translate from "../../../translate/Translate";

const ImageCard = (props) => {
  const { img, title, redirectTo, isHorizontal } = props;
  const classes = ImageCardStyles();

  const cardStyleHandler = () => {
    return isHorizontal ? classes.horizontalCard : classes.cardContent;
  };

  const imageStyleHandler = () => {
    return isHorizontal ? classes.horizontalImage : classes.image;
  };

  const cardContainer = () => (
    <Card className={cardStyleHandler()}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt={title}
          image={img}
          title={title}
          className={imageStyleHandler()}
        />
      </CardActionArea>
      <CardActions
        className={classes.actions}
        name={`${title.toLowerCase()} section`}
      >
        <CenteredContent>
          <Typography variant={"subtitle2"} className={classes.title}>
            <Translate text={title} />
          </Typography>
        </CenteredContent>
      </CardActions>
    </Card>
  );
  return (
    <Fragment>
      {redirectTo ? (
        <Link to={redirectTo} style={{ textDecoration: `none` }}>
          {cardContainer()}
        </Link>
      ) : (
        cardContainer()
      )}
    </Fragment>
  );
};

export default ImageCard;
