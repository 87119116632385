import { makeStyles } from "@mui/styles";

const WorkflowConclusionTemplateStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "20px",
    paddingBottom: "80px"
  },
  headerImage: {
    marginTop: 30,
    marginBottom: 30
  },
  titleSection: {
    minHeight: 150,

    backgroundColor: theme.palette.primary.brand_blue
  },
  title: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: 10,
      marginRight: 10
    },
    color: theme.palette.primary.white
  }
}));

export default WorkflowConclusionTemplateStyles;
