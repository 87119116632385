import { makeStyles } from "@mui/styles";
import { FONT_CONSTANTS } from "../../../../constants/StyleConstants";

const RightDrawerStyles = makeStyles((theme) => ({
  paper: {
    height: `100%`,
    width: 341,
    backgroundColor: theme.palette.primary.titles,
    color: theme.palette.primary.white,
    boxShadow: `-4px 4px 10px rgba(0, 0, 0, 0.1)`,
    [theme.breakpoints.down(501)]: {
      width: 217
    }
  },
  rootClass: {
    "& .MuiBackdrop-root": {
      background: `rgba(41, 71, 100, 0.22)`
    }
  },
  MenuHamburguerIcon: {
    display: `flex`,
    width: 28,
    height: 28,
    [theme.breakpoints.down(501)]: {
      width: 31,
      height: 30
    },
    [theme.breakpoints.up(501)]: {
      marginLeft: 25
    },

    cursor: `pointer`
  },
  drawerWidth: {
    padding: 10,
    marginLeft: 20,
    [theme.breakpoints.down(501)]: {
      marginLeft: 0
    }
  },
  CloseIcon: {
    cursor: `pointer`,
    display: `flex`,
    fontSize: 35
  },
  iconXContainer: {
    width: 280,
    height: 50,
    [theme.breakpoints.down(501)]: {
      width: 200
    }
  },

  optionContent: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    [theme.breakpoints.down(501)]: {
      flexDirection: `column`,
      justifyContent: `center`,
      alignItems: `center`
    }
  },
  iconContent: {
    marginRight: 15,
    [theme.breakpoints.down(501)]: {
      marginRight: 0,
      width: 150
    }
  },
  linkTextContent: {
    //marginBottom: 15,
  },
  LinkTextItem: {
    textAlign: `center`,
    fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
    fontSize: 18
  },
  LogoutButton: {
    maxWidth: 150,
    maxHeight: 44,
    background: theme.palette.primary.cancel_button_text,
    borderRadius: 100,
    marginTop: 50,
    [theme.breakpoints.between("sm", "lg")]: {
      width: 169,
      height: 50,
      marginTop: 70
    },
    "&:hover": {
      background: theme.palette.primary.cancel_button_text
    }
  }
}));

export default RightDrawerStyles;
