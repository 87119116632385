import React from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import SideScrollingMenuStyles from "./SideScrollingMenu.styles";

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  const classes = SideScrollingMenuStyles();

  return (
    <div
      className={classes.arrowAndMaskBackground}
      style={{
        display: disabled ? "none" : "block"
      }}
    >
      <div
        className={`${classes.arrowBackground} ${classes.arrowBackgroundLeft}`}
      ></div>
      <div className={classes.gradientMaskLeft}></div>
      <button
        disabled={disabled}
        onClick={() => scrollPrev()}
        className={`${classes.arrows} ${classes.arrowLeft}`}
      >
        <KeyboardArrowLeft />
      </button>
    </div>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(
    VisibilityContext
  );
  const classes = SideScrollingMenuStyles();

  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <div
      className={classes.arrowAndMaskBackground}
      style={{
        display: disabled ? "none" : "block"
      }}
    >
      <div className={classes.gradientMaskRight}></div>
      <div className={classes.arrowBackground}></div>
      <button
        disabled={disabled}
        onClick={() => scrollNext()}
        className={`${classes.arrows} ${classes.arrowRight}`}
      >
        <KeyboardArrowRight />
      </button>
    </div>
  );
}
