import { makeStyles } from "@mui/styles";

const IntroInfoStyles = makeStyles((theme) => ({
  contentIntro: {
    width: `100%`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `center`
  },
  title: {
    color: theme.palette.primary.font_blue
  },
  boxTitle: {
    width: 628,
    height: 153,
    textAlign: `center`,
    [theme.breakpoints.down('lg')]: {
      width: 628,
      height: 153
    },
    [theme.breakpoints.down(703)]: {
      width: 295,
      height: 131,
      textAlign: `left`
    }
  },
  subTitle: {
    color: theme.palette.primary.font_blue
  },
  boxSubTitle: {
    width: 690,
    height: 93,
    marginTop: 46,
    [theme.breakpoints.down('lg')]: {
      wwidth: 628,
      height: 93
    },
    [theme.breakpoints.down(703)]: {
      width: 295,
      height: 228,
      marginTop: 30
    }
  },
  membership: {
    color: theme.palette.primary.link
  },
  everyone: {
    color: theme.palette.primary.green_dark,
    fontWeight: 700
  },
  headerLogo: {
    width: 195,
    height: 46,
    textAlign: `center`,
    [theme.breakpoints.down('lg')]: {
      wwidth: 150,
      height: 35
    },
    [theme.breakpoints.down(703)]: {
      width: 127,
      height: 30
    }
  },
  button: {
    color: theme.palette.primary.link,
    fontSize: 14,
    fontWeight: 700,
    marginTop: 40
  }
}));

export default IntroInfoStyles;
