import { makeStyles } from "@mui/styles";
const AccountManagementMFAPageStyles = makeStyles((theme) => ({
  body: {
    marginTop: theme.paddings.md,
    marginBottom: theme.paddings.sm
  },
  title: {
    marginBottom: theme.paddings.sm,
    textAlign: "center"
  },
  titleGap: {
    marginTop: "40px"
  },
  titleSmall: {
    fontSize: "16px",
    fontWeight: 700
  },
  continueButton: {
    marginTop: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    maxWidth: "none"
  },
  boxFrame: {
    borderRadius: "10px",
    marginTop: "40px",
    border: "1px solid #C4C4C4",
    marginBottom: "60px"
  },
  contentBoxSection: {
    padding: "40px",
    textAlign: "center",
    width: "100%"
  },
  contentBoxSectionTop: {
    padding: "40px",
    textAlign: "center",
    borderBottom: "1px solid #C4C4C4"
  },
  subtext: {
    color: "#828282",
    textAlign: "center",
    fontSize: "16px",
    fontStyle: "italic"
  },
  warnSubtext: {
    color: "#C03417",
    textAlign: "center",
    fontSize: "12px"
  },
  successSubtext: {
    color: "#8CC53F",
    textAlign: "center",
    fontSize: "12px"
  },
  factorList: {
    marginTop: "30px"
  },
  phoneNumber: {
    color: "#828282",
    fontSize: "16px",
    fontWeight: 700,
    fontStyle: "italic"
  },
  textInputContainer: {
    textAlign: "left",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "30px"
  },
  validationCodeInputContainer: {
    marginTop: "40px",
    textAlign: "left"
  }
}));

export default AccountManagementMFAPageStyles;
