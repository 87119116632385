import { makeStyles } from "@mui/styles";

const PillImageButtonStyles = makeStyles((theme) => ({
  pill: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.gray_60,
    width: 50,
    height: 30,
    borderRadius: 20
  },
  icon: {
    color: theme.palette.secondary.gray_button
  },
  pillActive: {
    backgroundColor: theme.palette.secondary.green_40
  },
  iconActive: {
    color: theme.palette.primary.green
  }
}));

export default PillImageButtonStyles;
