import {
  COLOR_CONSTANTS,
  FONT_CONSTANTS,
  GENERIC_CONSTANTS
} from "../../constants/StyleConstants";
export const themeOverrides = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
        fontWeight: `800`,
        fontSize: 14,
        disabled: {
          backgroundColor: COLOR_CONSTANTS.COLOR_WHITE,
          borderColor: COLOR_CONSTANTS.COLOR_GRAY
        },
        height: 45,
        maxWidth: 330,
        margin: 10
      },
      contained: {
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_BUTTON,
        fontWeight: `800`,
        fontSize: `14px`,
        borderRadius: `100px`,
        color: COLOR_CONSTANTS.COLOR_WHITE,
        minWidth: 150,
        "&:hover": {
          opacity: 0.3
        },
        "&:disabled": {
          backgroundColor: COLOR_CONSTANTS.COLOR_WHITE,
          border: `2px solid ${COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY}`,
          color: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY
        },
        elevation: GENERIC_CONSTANTS.NONE,
        boxShadow: ""
      },
      outlined: {
        fontFamily: FONT_CONSTANTS.FONT_FAMILY_BUTTON,
        fontWeight: `800`,
        fontSize: `14px`,
        borderRadius: `100px`,
        color: COLOR_CONSTANTS.COLOR_WHITE,
        minWidth: 150,
        boxShadow: ""
      },
      outlinedSizeSmall: {
        minwidth: 85,
        height: 35,
        fontSize: `11px`,
        marginTop: `15px`,
        marginLeft: `10px`,
        color: COLOR_CONSTANTS.COLOR_GRAY,
        boxShadow: ""
      },
      text: {
        borderRadius: `100px`,
        color: COLOR_CONSTANTS.COLOR_WHITE
      },
      containedPrimary: {
        backgroundColor: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY
      },
      containedSecondary: {
        backgroundColor: COLOR_CONSTANTS.GENOMIC_LIFE_GREEN,
        "&:hover": {
          backgroundColor: COLOR_CONSTANTS.GENOMIC_LIFE_GREEN,
          opacity: 0.3
        }
      },
      textPrimary: {
        color: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY
      },
      textSecondary: {
        color: COLOR_CONSTANTS.COLOR_ERROR
      },
      outlinedSecondary: {
        color: COLOR_CONSTANTS.COLOR_GRAY,
        borderColor: COLOR_CONSTANTS.COLOR_GRAY
      }
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      colorSecondary: {
        "&$checked": {
          color: COLOR_CONSTANTS.GENOMIC_LIFE_GREEN
        }
      }
    }
  },
  MuiLink: {
    styleOverrides: {
      root: {
        fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_HEAVY,
        fontSize: 14,
        color: COLOR_CONSTANTS.GENOMIC_LIFE_LINK_COLOR,
        lineHeight: `20px`,
        letterSpacing: `0.25px`,
        "&:hover": {
          textDecoration: `none`
        }
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "&$focused": {
          borderColor: COLOR_CONSTANTS.GENOMIC_FORM_FOCUSED
        },
        "&$error": {
          color: COLOR_CONSTANTS.COLOR_ERROR
        }
      },
      input: {
        paddingRight: 15,
        fontSize: 14,
        fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_BOOK].join(","),
        fontStyle: `normal`
      },
      adornedEnd: {
        width: "100%"
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        "&$focused": {
          color: COLOR_CONSTANTS.GENOMIC_FORM_FOCUSED,
          borderColor: COLOR_CONSTANTS.GENOMIC_FORM_FOCUSED
        },
        focused: {},
        error: {
          color: COLOR_CONSTANTS.COLOR_ERROR
        }
      }
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        "&$error": {
          color: COLOR_CONSTANTS.COLOR_ERROR,
          fontFamily: [FONT_CONSTANTS.FONT_AVENIRLT_PRO_BOOK].join(","),
          fontStyle: `normal`,
          fontWeight: 300
        }
      },
      contained: {
        size: 10,
        marginLeft: 0
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        border: "0px"
      },
      input: {
        border: "0px",
        color: COLOR_CONSTANTS.COLOR_BLACK
      },
      inputRoot: {
        backgroundColor: COLOR_CONSTANTS.COLOR_WHITE,
        borderRadius: `5px`,
        minHeight: 45,
        color: COLOR_CONSTANTS.COLOR_BLACK,
        "&$focused": {
          backgroundColor: COLOR_CONSTANTS.COLOR_WHITE,
          borderColor: COLOR_CONSTANTS.GENOMIC_FORM_FOCUSED
        }
      },
      tag: {
        fontWeight: `800`,
        marginLeft: 10,
        backgroundColor: COLOR_CONSTANTS.GENOMIC_LIFE_GREEN_CHIP
      },
      listbox: {
        "&::-webkit-scrollbar": {
          width: 5,
          borderRadius: 100,
          backgroundColor: COLOR_CONSTANTS.COLOR_GRAY_60
        },
        "&::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
        },
        "&::-webkit-scrollbar-thumb": {
          background: COLOR_CONSTANTS.GENOMIC_LIFE_LINK_COLOR,
          borderRadius: 100
        },
        "scrollbar-color": `${COLOR_CONSTANTS.GENOMIC_LIFE_LINK_COLOR} ${COLOR_CONSTANTS.COLOR_GRAY_60}`,
        "scrollbar-width": "inherit"
      },
      endAdornment: {
        color: COLOR_CONSTANTS.COLOR_BLACK
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM
      },
      deleteIcon: {
        color: COLOR_CONSTANTS.GENOMIC_LIFE_GREEN,
        "&:hover": {
          color: COLOR_CONSTANTS.GENOMIC_LIFE_GREEN
        }
      }
    }
  }
};
