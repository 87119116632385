import { makeStyles } from "@mui/styles";
import {
  FLEX_CONSTANTS,
  GENERIC_CONSTANTS
} from "../../../constants/StyleConstants";

const TermsOfServicePageStyles = makeStyles((theme) => ({
  headerContainer: {
    margin: theme.paddings.sm,
    paddingBottom: theme.paddings.xs
  },
  marginTop: {
    marginTop: theme.paddings.xs
  },
  marginTopMd: {
    marginTop: theme.paddings.md
  },
  disclaimer: {
    color: theme.palette.primary.black,
    margin: theme.paddings.md
  },
  bodyContainer: {
    display: FLEX_CONSTANTS.FLEX,
    flex: 1,
    alignItems: FLEX_CONSTANTS.FLEX_START,
    justifyContent: FLEX_CONSTANTS.CENTER,
    marginTop: theme.paddings.sm,
    backgroundColor: theme.palette.primary.background_tan
  },
  buttonLink: {
    textDecoration: GENERIC_CONSTANTS.NONE,
    color: theme.palette.primary.black,
    "&:visited": {
      textDecoration: GENERIC_CONSTANTS.NONE,
      color: theme.palette.primary.black
    }
  }
}));

export default TermsOfServicePageStyles;
