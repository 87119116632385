import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../constants/StyleConstants";

const optionComponentStyles = makeStyles((theme) => ({
  notCheckedButton: {
    width: 300,
    height: 90,
    [theme.breakpoints.down('md')]: {
      width: 335,
      height: 90
    },
    marginTop: 10,
    textAlign: "left",
    textTransform: "none",
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.black,
    borderRadius: `10px`,
    border: "1px solid rgba(0, 0, 0, 0.15)",
    cursor: `pointer`,
    lineHeight: `22px`,
    "&.notActive": {
      border: `2px solid ${theme.palette.secondary.gray_button}`,
      backgroundColor: "#f2f2f2"
    }
  },
  checkedButton: {
    width: 300,
    height: 90,
    [theme.breakpoints.down('md')]: {
      width: 335,
      height: 90
    },
    marginTop: `10px`,
    textAlign: "left",
    backgroundColor: theme.palette.secondary.green_40,
    color: theme.palette.primary.black,
    borderRadius: `10px`,
    border: `2px solid ${theme.palette.secondary.green}`,
    textTransform: `none`,
    cursor: `pointer`,
    justifyContent: "center",
    alignItems: "center",
    lineHeight: `22px`
  },
  optionContainer: {
    paddingLeft: `10px`,
    paddingTop: `2px`,
    paddingBottom: `2px`
  },
  iconContainer: {
    marginLeft: 20,
    marginRight: 20
  },
  textAnswer: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_FONT_BLUE,
    "&.notActive": {
      color: theme.palette.secondary.gray_button
    }
  }
}));

export default optionComponentStyles;
