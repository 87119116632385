import { makeStyles } from "@mui/styles";

const ContactUsStyles = makeStyles((theme) => ({
  h2: {
    fontFamily: "DomaineDisplaySemibold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 20,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.primary.white,
    marginTop: 30
  },
  body: {
    fontFamily: "AvenirLTProMedium",
    fontWeight: 400,
    fontSize: "14px",
    textAlign: "center",
    color: theme.palette.primary.white,
    marginTop: 20,
    marginBottom: 20
  },
  contact: {
    fontFamily: "AvenirLTProMedium",
    fontWeight: 400,
    fontSize: "16px",
    textAlign: "left",
    marginBottom: 15,
    color: theme.palette.primary.white,
    textDecoration: "none"
  },
  phoneContact: {
    marginBottom: 25
  },
  icon: {
    marginRight: 15
  }
}));

export default ContactUsStyles;
