import { makeStyles } from "@mui/styles";

const VideoModalStyles = makeStyles((theme) => ({
  "@keyframes modal-video": {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  },

  "@keyframes modal-video-inner": {
    from: {
      transform: "translate(0, 100px)"
    },
    to: {
      transform: "translate(0, 0)"
    }
  },
  videoModalContainer: {
    "& .modal-video": {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000000,
      cursor: "pointer",
      opacity: 1,
      animationTimingFunction: "ease-out",
      animationDuration: "0.3s",
      animationName: "modal-video",
      WebkitTransition: "opacity 0.3s ease-out",
      MozTransition: "opacity 0.3s ease-out",
      msTransition: "opacity 0.3s ease-out",
      OTransition: "opacity 0.3s ease-out",
      transition: "opacity 0.3s ease-out"
    },
    "& .modal-video-effect-exit": { opacity: 0 },
    "& .modal-video-effect-exit .modal-video-movie-wrap": {
      WebkitTransform: "translate(0, 100px)",
      MozTransform: "translate(0, 100px)",
      msTransform: "translate(0, 100px)",
      OTransform: "translate(0, 100px)",
      transform: "translate(0, 100px)"
    },
    "& .modal-video-body": {
      maxWidth: "960px",
      width: "100%",
      height: "100%",
      margin: "0 auto",
      padding: "0 10px",
      display: "flex",
      justifyContent: "center",
      boxSizing: "border-box"
    },
    "& .modal-video-inner": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%"
    },
    "& .modal-video-movie-wrap": {
      width: "100%",
      height: "0",
      position: "relative",
      paddingBottom: "56.25%",
      backgroundColor: "#333",
      animationTimingFunction: "ease-out",
      animationDuration: "0.3s",
      animationName: "modal-video-inner",
      WebkitTransform: "translate(0, 0)",
      MozTransform: "translate(0, 0)",
      msTransform: "translate(0, 0)",
      OTransform: "translate(0, 0)",
      transform: "translate(0, 0)",
      WebkitTransition: "-webkit-transform 0.3s ease-out",
      MozTransition: "-moz-transform 0.3s ease-out",
      msTransition: "-ms-transform 0.3s ease-out",
      OTransition: "-o-transform 0.3s ease-out",
      transition: "transform 0.3s ease-out"
    },
    "& .modal-video-movie-wrap iframe": {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%"
    },
    "& .modal-video-close-btn": {
      position: "absolute",
      zIndex: 2,
      top: "-45px",
      right: "0px",
      display: "inline-block",
      width: "35px",
      height: "35px",
      overflow: "hidden",
      border: "none",
      background: "transparent"
    },
    "@media (orientation: landscape)": {
      ".modal-video-inner": { padding: "10px 60px", boxSizing: "border-box" },
      ".modal-video-close-btn": { top: "0", right: "-45px" }
    },
    "& .modal-video-close-btn:before": { transform: "rotate(45deg)" },
    "& .modal-video-close-btn:after": { transform: "rotate(-45deg)" },
    "& .modal-video-close-btn:before, .modal-video-close-btn:after": {
      content: "''",
      position: "absolute",
      height: "2px",
      width: "100%",
      top: "50%",
      left: "0",
      marginTop: ["-1px", "-6px"],
      background: "#fff",
      borderRadius: "5px"
    }
  }
}));

export default VideoModalStyles;
