import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Translate from "../../../../../translate/Translate";
import FormSummary from "../../../../sharedComponents/FormSummary/FormSummaryComponent";
import TransmitConfirmationStepStyles from "./TransmitConfirmation.styles";
import { selectConnections } from "../../../../../store/reducers/connection/ConnectionsInformationSlice";

const TransmitConfirmationStep = (props) => {
  const {
    reports,
    transmissionModes,
    handleBackReportSelection,
    handleBackCareTeamConnections,
    aknowledgementState,
    aknowledgementStateHandler,
    adviceAknowledgementState,
    adviceAknowledgementStateHandler
  } = props;
  const classes = TransmitConfirmationStepStyles();

  const availableConnections = useSelector(selectConnections);

  const mapConnectionsWithTransmissions = (transmissions, recipients) => {
    if (transmissions.length > 0 && recipients.length > 0) {
      return recipients.filter((recipient) => {
        return transmissions.find((transmission) => {
          return (
            transmission.connectionId === recipient.connectionId &&
            transmission.channels &&
            transmission.channels.includes("FAX")
          );
        });
      });
    }
    return [];
  };
  const [reportsToSend, setReportsToSend] = useState([]);
  const [connectionToSend, setConnectionsToSend] = useState([]);
  const [akwnoledgementSelected, setAkwnoledgementSelected] = useState(
    aknowledgementState
  );
  const [
    adviceAkwnoledgementSelected,
    setAdviceAkwnoledgementSelected
  ] = useState(adviceAknowledgementState);

  useEffect(() => {
    let reportData = [];
    let connectionData = [];

    reports &&
      reports.forEach((report) => {
        reportData.push({
          name: report.name,
          value: report.name
        });
      });
    const recipients = mapConnectionsWithTransmissions(
      transmissionModes,
      availableConnections
    );
    recipients &&
      recipients.forEach((connection) => {
        connectionData.push({
          name: `${connection.firstName} ${
            connection.middleName ? connection.middleName : ""
          } ${connection.lastName}`,
          value: `${connection.firstName}  ${
            connection.middleName ? connection.middleName : ""
          } ${connection.lastName}`,
          style: { fontWeight: 800, paddingBottom: 10 },
          subFields: [
            {
              name: "faxNumber",
              value: `Fax: ${connection.faxNumber}`
            }
          ]
        });
      });
    setReportsToSend(reportData);
    setConnectionsToSend(connectionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, availableConnections, transmissionModes]);

  const formSections = [
    {
      title: "Report Selection",
      handleBackTo: handleBackReportSelection,
      fields: reportsToSend
    },
    {
      title: "Care Team Connections",
      handleBackTo: handleBackCareTeamConnections,
      fields: connectionToSend
    }
  ];
  return (
    <Grid
      direction="column"
      container
      alignContent="center"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={11} sm={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="h5" className={classes.requestConfirmationTitle}>
            <Translate text="Almost done! Please confirm your selections." />
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11} sm={9} md={7} lg={3}>
          <FormSummary formSections={formSections} />
        </Grid>
      </Grid>
      <Grid container alignContent="center" justifyContent="center">
        <Grid item xs={11} sm={9} md={7} lg={3}>
          <Grid item xs={11} className={classes.checkboxContainer}>
            <FormControlLabel
              value={akwnoledgementSelected}
              control={
                <Checkbox
                  size="small"
                  color="secondary"
                  name="authorizeReleaseOfConfidentialHealth"
                  id="authorizeReleaseOfConfidentialHealth"
                  onChange={() => {
                    setAkwnoledgementSelected(!akwnoledgementSelected);
                    aknowledgementStateHandler &&
                      aknowledgementStateHandler(!akwnoledgementSelected);
                  }}
                  checked={akwnoledgementSelected}
                />
              }
              label={
                <p>
                  I understand that sending faxes cannot be undone, and I have
                  double checked my Care Team connection information and affirm
                  it is correct.
                </p>
              }
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={12} className={classes.checkboxContainer}>
            <FormControlLabel
              value={adviceAkwnoledgementSelected}
              control={
                <Checkbox
                  size="small"
                  color="secondary"
                  name="authorizeReleaseOfConfidentialHealthAdvice"
                  id="authorizeReleaseOfConfidentialHealthAdvice"
                  onChange={() => {
                    setAdviceAkwnoledgementSelected(
                      !adviceAkwnoledgementSelected
                    );
                    adviceAknowledgementStateHandler &&
                      adviceAknowledgementStateHandler(
                        !adviceAkwnoledgementSelected
                      );
                  }}
                  checked={adviceAkwnoledgementSelected}
                />
              }
              label={
                <p>
                  We advise you to contact the recipient ahead of time so they
                  know your documents are on the way. Faxing your results report
                  may take an additional hour. We will let you know when the
                  transmission completes. If your fax fails to reach the
                  recipient or if you become aware that a fax was misdirected,
                  contact the receiver and ask that your material be returned or
                  destroyed."
                </p>
              }
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TransmitConfirmationStep;
