import { useDispatch } from "react-redux";
import { removeCookie } from "../utils/CookieUtil";
import { useAuth0 } from "@auth0/auth0-react";

export const useLogout = (params) => {
  const dispatcher = useDispatch();
  const { logout } = useAuth0();

  return () => {
    dispatcher({ type: "USER_LOGOUT" });
    removeCookie("accessToken");

    logout({
      logoutParams: { returnTo: window.location.origin + "/login" }
    });
  };
};
