import { makeStyles } from "@mui/styles";

const UserAgreementDetailStyles = makeStyles((theme) => ({
  fileContentIntegrated: {},
  checkboxText: {
    marginTop: 7
  },
  lastUpdated: {
    color: theme.palette.secondary.gray_25
  },
  termsContainer: { margin: "40px" }
}));

export default UserAgreementDetailStyles;
