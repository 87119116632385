import { makeStyles } from "@mui/styles";

const ResourceGridMatrixtyles = makeStyles((theme) => ({
  container: { padding: 25, margin: "0" },
  header: { marginBottom: 10 },
  subHeader: {
    marginBottom: 35
  },
  cardBlock: { padding: 30 },
  cardContainer: {
    boxShadow: "0px 2.70968px 13.5484px rgba(79, 138, 201, 0.2)",
    borderRadius: 7,
    padding: 26
  },
  cardLink: { textDecoration: "none" },
  cardHeaderContainer: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main
  },
  iconFontSize: {
    fontSize: 30,
    float: "right",
    color: theme.palette.secondary.gray_button
  }
}));

export default ResourceGridMatrixtyles;
