import { COLOR_CONSTANTS } from "../../constants/StyleConstants";
export const themePalette = {
  primary: {
    main: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY, // This is the Genomic Life Blue color
    blue: COLOR_CONSTANTS.GENOMIC_LIFE_BLUE,
    green: COLOR_CONSTANTS.GENOMIC_LIFE_GREEN,
    blue_light: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_BLUE,
    cancel_button_text: COLOR_CONSTANTS.GENOMIC_LIFE_CANCEL_BUTTON_TEXT,
    action_button_text: COLOR_CONSTANTS.GENOMIC_LIFE_ACTION_BUTTON_TEXT,
    disabled_button_text: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY,
    white: COLOR_CONSTANTS.COLOR_WHITE,
    black: COLOR_CONSTANTS.COLOR_BLACK,
    gray_60: COLOR_CONSTANTS.COLOR_GRAY_60,
    black_75: COLOR_CONSTANTS.COLOR_BLACK_75,
    titles: COLOR_CONSTANTS.COLOR_TITLES,
    link: COLOR_CONSTANTS.GENOMIC_LIFE_LINK_COLOR,
    brand_blue: COLOR_CONSTANTS.GENOMIC_LIFE_LINK_COLOR,
    font_blue: COLOR_CONSTANTS.GENOMIC_LIFE_FONT_BLUE,
    green_dark: COLOR_CONSTANTS.COLOR_BRAND_GREEN_DARK,
    background_tan: COLOR_CONSTANTS.GENOMIC_LIFE_BACKGROUND_TAN,
    background_tan_dark: COLOR_CONSTANTS.GENOMIC_LIFE_BACKGROUND_TAN_DARK,
    chip_green: COLOR_CONSTANTS.GENOMIC_LIFE_GREEN_CHIP,
    chip_blue: COLOR_CONSTANTS.GENOMIC_LIFE_CHIP_BLUE,
    blue_font: COLOR_CONSTANTS.GENOMIC_LIFE_BLUE_FONT,
    brand_salmon: COLOR_CONSTANTS.COLOR_BRAND_SALMON
  },
  secondary: {
    main: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY, // This is the Genomic Life Blue Gray color
    gray: COLOR_CONSTANTS.COLOR_GRAY,
    gray_button: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY,
    gray_25: COLOR_CONSTANTS.COLOR_GRAY_25,
    gray_30: COLOR_CONSTANTS.COLOR_GRAY_30,
    gray_40: COLOR_CONSTANTS.COLOR_GRAY_40,
    gray_60: COLOR_CONSTANTS.COLOR_GRAY_60,
    gray_90: COLOR_CONSTANTS.COLOR_GRAY_90,
    gray_box: COLOR_CONSTANTS.COLOR_BOX_GRAY,
    gray_border: COLOR_CONSTANTS.COLOR_BORDER_GRAY,
    gray_subtitle: COLOR_CONSTANTS.COLOR_GRAY_SUBTITLE,
    rounded_button_blue: COLOR_CONSTANTS.COLOR_ROUNDED_BLUE,
    green_25: COLOR_CONSTANTS.COLOR_SUCCESS_25,
    green_40: COLOR_CONSTANTS.COLOR_SUCCESS_40,
    green_step: COLOR_CONSTANTS.COLOR_STEPPER,
    yellow_muted: COLOR_CONSTANTS.COLOR_YELLOW_MUTED,
    green_muted: COLOR_CONSTANTS.COLOR_GREEN_MUTED,
    backgrd_blue: COLOR_CONSTANTS.COLOR_BACKGRD_BLUE
  },
  success: {
    main: COLOR_CONSTANTS.COLOR_SUCCESS,
    light: COLOR_CONSTANTS.COLOR_SUCCESS_10
  },
  info: {
    main: COLOR_CONSTANTS.COLOR_INFO,
    light: COLOR_CONSTANTS.COLOR_INFO_10,
    dark: COLOR_CONSTANTS.COLOR_INFO_MEDIUM
  },
  warning: {
    main: COLOR_CONSTANTS.COLOR_WARNING,
    light: COLOR_CONSTANTS.COLOR_WARNING_LIGHT,
    dark: COLOR_CONSTANTS.COLOR_WARNING_DARK
  },
  error: {
    main: COLOR_CONSTANTS.COLOR_ERROR,
    light: COLOR_CONSTANTS.COLOR_ERROR_10
  },
  security: {
    main: COLOR_CONSTANTS.COLOR_SECURITY,
    light: COLOR_CONSTANTS.COLOR_SECURITY_10
  }
};
