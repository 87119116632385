import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const RegistrationHelpModalStyles = makeStyles((theme) => ({
  logo: { textAlign: "center", marginTop: "-30px", marginBottom: 30 },
  container: {
    textAlign: "center",
    "& p": { marginBottom: "15px" },
    "& h4": { marginBottom: "20px" }
  },
  highlightedBox: {
    border: `2px solid ${COLOR_CONSTANTS.COLOR_SUCCESS}`,
    borderRadius: "15px",
    padding: "15px",
    width: "100%",
    marginBottom: "15px",
    "& p": {
      marginTop: "5px",
      marginBottom: "0px"
    }
  },
  link: {
    color: "black",
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: "700"
  },
  phone: { fontWeight: 700 },
  extraInfo: {
    fontStyle: "italic"
  }
}));

export default RegistrationHelpModalStyles;
