import React from "react";
import Translate from "../translate/Translate";

export const LABELS = {
  LABEL_EMAIL: <Translate text="Email" />,
  LABEL_PASSWORD: <Translate text="Password" />,
  LABEL_FORGOT_YOUR_PASSWORD: <Translate text="Forgot your password?" />,
  LABEL_LOGIN_UPPERCASE: <Translate text="LOGIN" />,
  LABEL_DONT_HAVE_AN_ACCOUNT: <Translate text="Don't have an account?" />,
  LABEL_SIGN_UP: <Translate text="Sign up" />,
  LABEL_CANCER_GUARDIAN_LOGO_ALT_TEXT: (
    <Translate text="Cancer Guardian by Genomic Life logo" />
  ),
  LABEL_HOME: <Translate text="Home" />,
  LABEL_ACCOUNT: <Translate text="Account" />,
  LABEL_LOGGED_OUT: <Translate text="Logout" />,
  LABEL_LOGIN: <Translate text="Login" />,
  LABEL_ACCOUNT_INFO: <Translate text="Account Information" />,
  LABEL_SAVE_CHANGES: <Translate text="Save changes" />,
  LABEL_NAME_ON_ACCOUNT: <Translate text="Name On Account" />,
  LABEL_ROUTING_NUMBER: <Translate text="Routing Number" />,
  LABEL_BANK_NAME: <Translate text="Bank Name" />,
  LABEL_ACCOUNT_NUMBER: <Translate text="Account Number" />,
  LABEL_ACCOUNT_TYPE: <Translate text="Account Type" />,
  LABEL_CHECKING: <Translate text="Checking" />,
  LABEL_BUSINESS_CHECKING: <Translate text="Business Checking" />,
  LABEL_SAVINGS: <Translate text="Savings" />,
  LABEL_ORDER_GENETIC_SCREEN: (
    <Translate text="Request my genetic health screen" />
  ),
  LABEL_MANAGE_MY_ACCOUNT: <Translate text="Manage my account" />,
  LABEL_LEARN_MORE: <Translate text="Learn more about Genomics" />,
  LABEL_BILLING_INFO: <Translate text="Billing Information" />,
  LABEL_STREET_ADDRESS: <Translate text="Street Address" />,
  LABEL_APT_SUITE: <Translate text="Apt/Suite" />,
  LABEL_CITY: <Translate text="City" />,
  LABEL_STATE: <Translate text="State" />,
  LABEL_ZIP_CODE: <Translate text="Zip Code" />,
  LABEL_COUNTRY: <Translate text="Country" />,
  LABEL_CARD_HOLDER_NAME: <Translate text="Card Holder Name" />,
  LABEL_CARD_TYPE: <Translate text="Card Type" />,
  LABEL_VISA: <Translate text="Visa" />,
  LABEL_MASTERCARD: <Translate text="Mastercard" />,
  LABEL_DISCOVER: <Translate text="Discover" />,
  LABEL_AMEX: <Translate text="American Express" />,
  LABEL_MC_EUROCARD: <Translate text="MC Eurocard" />,
  LABEL_UK_MAESTRO: <Translate text="UK Maestro" />,
  LABEL_JCB_CARD: <Translate text="JCB Card" />,
  LABEL_DINERS_CLUB: <Translate text="Diners Club" />,
  LABEL_CARD_NUMBER: <Translate text="Card Number" />,
  LABEL_CVV: <Translate text="CVV" />,
  LABEL_EXPIRATION_DATE: <Translate text="Expiration Date" />,
  LABEL_START_WITH_MONTH: <Translate text="Start with the month" />,
  LABEL_WELCOME_BACK: <Translate text="Welcome back" />,
  LABEL_ARTICLES_TITLE: <Translate text="Genetics & Cancer" />,
  LABEL_ARTICLES_SUBTITLE: (
    <Translate text="Articles & Resources about Genomics" />
  ),
  LABEL_VALID_NUMBER: (
    <Translate text="Please enter a valid number. We only accept U.S. numbers at this time" />
  ),
  LABEL_US_VALID_NUMBER: (
    <Translate text="Please enter a valid number. We only accept U.S. numbers at this time" />
  ),
  LABEL_HI_THERE: <Translate text="Hi there!" />,
  LABEL_VALID_EMAIL: <Translate text="Please enter a valid e-mail address" />,
  LABEL_CONFIRM_DIAGNOSED_CANCER_SUBMISSION: (
    <Translate text="Our internal team of specialists  will be notified that you have been or are currently diagnosed with cancer and will reach out to you in 1-2 business days with details on your membership offerings and next steps." />
  ),
  LABEL_CONFIRM_DIAGNOSED_CANCER_SUBMISSION_IMPORTANT: (
    <Translate text="*This information will not be shared with anyone other than our HIPAA compliant nurse navigators and nurse advocates." />
  ),
  LABEL_STEM_CELL_BONE_MARROW_FIRST: (
    <Translate text="You’ve indicated that you’ve had a bone marrow and/or stem cell transplant.  Because of the nature of these procedures, we are not able to offer you testing at this time without genetic counseling, as a different testing method may be required." />
  ),
  LABEL_STEM_CELL_BONE_MARROW_SECOND: (
    <Translate text="We would love to help direct you to the proper testing method, please give us a call at" />
  ),
  LABEL_STEM_CELL_BONE_MARROW_THIRD: (
    <Translate text=" to schedule a genetic counseling session. There is no additional cost to speak to a genetic counselor. " />
  ),
  LABEL_CONFIRM_SUBMISSION: <Translate text="Confirm Submission" />,
  LABEL_THANK_YOU: <Translate text="Thank you" />
};
