import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import Translate from "../../../translate/Translate";
import StepBox from "../../sharedComponents/StepBox";
import GL_Logo from "../../../assets/images/GL-Logo.svg";
import partnership from "../../../assets/images/partnership.svg";
import shield from "../../../assets/images/shield.svg";
import safe from "../../../assets/images/safe.svg";
import { PATHS } from "../../../constants/pathConstants";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useSelector } from "react-redux";
import {
  selectHasAcceptedCurrentHipaaAuthorization,
  selectHasAcceptedCurrentPrivacyPolicy,
  selectHasAcceptedCurrentTermsAndConditions,
  selectUserAgreementData,
  selectUserHasAcceptedAllCurrentAgreements
} from "../../../store/reducers/termsOfService/TermsOfServiceSlice";
import TermsOfServicePageStyles from "./TermsOfServicePage.styles";
import PageTitle from "../../../utils/PageTitle";
import ModalMessageWrapper from "../../v2/sharedComponents/ModalMessageWrapper";
import UserAgreementDetail from "./UserAgreementDetail";

const TermsOfServicePage = () => {
  const windowSize = useWindowSize();
  const bodyHeight = windowSize.height * 0.78;
  const imageWidth = windowSize.height * 0.2;
  const classes = TermsOfServicePageStyles();
  const history = useHistory();
  const allAgreementsCurrent = useSelector(
    selectUserHasAcceptedAllCurrentAgreements
  );
  const userAgreementData = useSelector(selectUserAgreementData);

  const userHasAgreedToEverythingBeforeButADocHasBeenUpdated =
    userAgreementData.privacyPolicy.acceptedVersion !== "0.0.0" &&
    userAgreementData.termsAndConditions.acceptedVersion !== "0.0.0" &&
    userAgreementData.hipaaAuthorization.acceptedVersion !== "0.0.0";

  const userHasNeverAgreedToEverything =
    userAgreementData.privacyPolicy.acceptedVersion === "0.0.0" ||
    userAgreementData.termsAndConditions.acceptedVersion === "0.0.0" ||
    userAgreementData.hipaaAuthorization.acceptedVersion === "0.0.0";

  const [
    isUpdateMessageModalShowing,
    setIsUpdateMessageModalShowing
  ] = useState(userHasAgreedToEverythingBeforeButADocHasBeenUpdated);

  const [isAgreeMessageModalShowing, setIsAgreeMessageModalShowing] = useState(
    userHasNeverAgreedToEverything
  );

  const privacyPolicyComplete = useSelector(
    selectHasAcceptedCurrentPrivacyPolicy
  );
  const termsAndConditionsComplete = useSelector(
    selectHasAcceptedCurrentTermsAndConditions
  );
  const hipaaAuthorizationComplete = useSelector(
    selectHasAcceptedCurrentHipaaAuthorization
  );

  PageTitle("Terms of Service");

  const [docDetailToShow, setDocDetailToShow] = useState(null);

  const docOrDocSelection = docDetailToShow ? (
    docDetailToShow
  ) : (
    <div className={classes.bodyContainer} style={{ minHeight: bodyHeight }}>
      <Grid
        container
        item
        xs={12}
        md={8}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid id="privacyPolicyBox" item className={classes.marginTopMd}>
          <div
            className={classes.buttonLink}
            onClick={() => {
              setDocDetailToShow(
                <UserAgreementDetail
                  agreementKey={"privacyPolicy"}
                  onSave={() => {
                    setDocDetailToShow(null);
                  }}
                />
              );
            }}
          >
            <StepBox
              title={<Translate text="Privacy Policy" />}
              stepNumber={1}
              description={
                <Translate text="Privacy is our priority. We do not share or sell your information without permission." />
              }
              icon={shield}
              iconWidth={30}
              iconHeight={25}
              highlighted={true}
              completed={privacyPolicyComplete}
            />
          </div>
        </Grid>
        <Grid id="termsAndConditionsBox" item className={classes.marginTopMd}>
          <div
            className={classes.buttonLink}
            onClick={() => {
              setDocDetailToShow(
                <UserAgreementDetail
                  agreementKey={"termsAndConditions"}
                  onSave={() => {
                    setDocDetailToShow(null);
                  }}
                />
              );
            }}
          >
            <StepBox
              title={<Translate text="Terms & Conditions" />}
              stepNumber={2}
              description={
                <Translate text="This document clarifies the relationship between you, our member, and Genomic Life" />
              }
              icon={partnership}
              iconWidth={25}
              iconHeight={22}
              highlighted={true}
              completed={termsAndConditionsComplete}
            />
          </div>
        </Grid>
        <Grid id="hipaaAuthBox" item className={classes.marginTopMd}>
          <div
            className={classes.buttonLink}
            onClick={() => {
              setDocDetailToShow(
                <UserAgreementDetail
                  agreementKey={"hipaaAuthorization"}
                  onSave={() => {
                    setDocDetailToShow(null);
                  }}
                />
              );
            }}
          >
            <StepBox
              title={<Translate text="HIPAA Authorization" />}
              stepNumber={3}
              description={
                <Translate text="Required form for uses of protected health information" />
              }
              icon={safe}
              iconWidth={22}
              iconHeight={22}
              highlighted={true}
              completed={hipaaAuthorizationComplete}
            />
          </div>
        </Grid>
        <Grid item className={classes.marginTopMd}>
          <Typography className={classes.disclaimer} variant="body1">
            <Translate
              text="* By granting Genomic Life temporary permission we can to order
              your screenings, show you results, get you genetic counselling,
              find cancer studies, and help you navigate your genomic findings."
            />
          </Typography>
        </Grid>
        <div className={classes.disclaimer}>
          <Button
            disabled={!allAgreementsCurrent}
            onClick={() => history.push(PATHS.LANDING_PAGE)}
            variant="contained"
            color="primary"
            id="enter-portal-button"
          >
            <Translate text="ENTER PLATFORM" />
          </Button>
        </div>
      </Grid>
    </div>
  );

  return (
    <div>
      <ModalMessageWrapper
        isOpen={isUpdateMessageModalShowing}
        modalVisibilityHandler={() => {
          setIsUpdateMessageModalShowing(false);
        }}
        modalTitle={"User agreements updated"}
        modalBodyMessage={`Please review and accept these updated legal documents before proceeding.`}
        dissmisButtonMessage={"Continue"}
      />
      <ModalMessageWrapper
        isOpen={isAgreeMessageModalShowing}
        modalVisibilityHandler={() => {
          setIsAgreeMessageModalShowing(false);
        }}
        modalTitle={"User agreements"}
        modalBodyMessage={`Please review and accept these legal documents before proceeding.`}
        dissmisButtonMessage={"Continue"}
      />
      <div className={classes.headerContainer}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <img width={imageWidth} alt="check Icon" src={GL_Logo} />
          <Typography className={classes.marginTopMd} variant="h2">
            <Translate text="Terms of Service" />
          </Typography>
          <Typography className={classes.marginTopMd} variant="body1">
            <Translate text="Please read and acknowledge these documents before proceeding." />
          </Typography>
        </Grid>
      </div>
      {docOrDocSelection}
    </div>
  );
};

export default TermsOfServicePage;
