import React from "react";
import { Grid, Typography } from "@mui/material";
import FullWidthDividerBandFatStyles from "./FullWidthDividerBandFat.styles";

const FullWidthDividerBandFat = (props) => {
  const { header } = props;
  const classes = FullWidthDividerBandFatStyles();

  return (
    <Grid container className={classes.container} justifyContent="center">
      <Grid className={classes.textContainer}>
        <Typography variant="h4" className={classes.content}>
          {header.en}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FullWidthDividerBandFat;
