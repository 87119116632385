import { makeStyles } from "@mui/styles";

const DocumentNotApprovedModalStyles = makeStyles((theme) => ({
  modalContainer: {
    position: "relative",
    textAlign: "center"
  },
  closeIcon: {
    position: "absolute",
    top: "-15px",
    right: "-15px",
    cursor: "pointer"
  },
  title: {
    fontSize: `22px !important`,
    color: theme.palette.primary.link,
    lineHeight: `28px`,
    fontWeight: "800",
    textAlign: "center"
  },
  message: {
    marginBottom: 30,
    marginTop: 20,
    color: "black",
    textAlign: "center"
  },
  button: {
    "&.small": {
      paddingLeft: "15px",
      paddingRight: "15px"
    }
  }
}));

export default DocumentNotApprovedModalStyles;
