import { Grid } from "@mui/material";
import React from "react";
import InviteSpouseToDoCard from "./ToDoListCards/InviteSpouseToDoCard";
import HaveAQuestionToDoCard from "./ToDoListCards/HaveAQuestionToDoCard";
import { useSelector } from "react-redux";
import { selectAllPreviousSurveyAnswers } from "../../../../../store/reducers/survey/SurveySlice";
import OrderGeneticScreeningsToDoCard from "./ToDoListCards/OrderGeneticScreeningToDoCard";
import ViewTestResultsToDoCard from "./ToDoListCards/ViewTestResultsToDoCard";
import { selectExistingOrders } from "../../../../../store/reducers/order/OrderInformationSlice";
import KitOrderedToDoCard from "./ToDoListCards/KitOrderedToDoCard";
import ContactOncologyToDoCard from "./ToDoListCards/ContactOncologyToDoCard";
import ContactGeneticCounselingToDoCard from "./ToDoListCards/ContactGeneticCounselingToDoCard";
import { hasUserCompletedEnoughOfGettingStartedSurvey } from "../../../Surveys/Utilities/util";

const ToDoList = () => {
  const hasDoneGettingStartedSurvey = hasUserCompletedEnoughOfGettingStartedSurvey(
    useSelector(selectAllPreviousSurveyAnswers)
  );
  const orders = useSelector(selectExistingOrders);
  const ordersWithDocs = orders.filter((order) => {
    return order.documentIds?.length > 0;
  });

  // This is a little gross. It's a special case becuase the big page header may
  //  or may not show this card based on whether the user has completed the 'getting started' card.
  //  for now at least, we are determining that by chacking to see if they have completed the survey.
  const inviteSpouseCard = hasDoneGettingStartedSurvey ? (
    <InviteSpouseToDoCard />
  ) : null;

  const docDownloadCards = ordersWithDocs.map((order) => {
    return <ViewTestResultsToDoCard order={order} key={order.glOrderId} />;
  });

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        container
        xs={10}
        sm={10}
        md={6}
        spacing={2}
        direction={"column"}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Grid container spacing={2}>
            {inviteSpouseCard}
            <ContactOncologyToDoCard />
            <ContactGeneticCounselingToDoCard />
            <OrderGeneticScreeningsToDoCard />
            <KitOrderedToDoCard />
            {docDownloadCards}
            <HaveAQuestionToDoCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ToDoList;
