import { Grid } from "@mui/material";
import RegistrationPageLayoutStyles from "./RegistrationPageLayout.styles";
import { useWindowSize } from "../../../../hooks/useWindowSize";

const RegistrationPageLayout = (props) => {
  const { children } = props;
  const classes = RegistrationPageLayoutStyles();
  const windowSize = useWindowSize();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.pageContainer}
      style={{
        position: "relative",
        minHeight: windowSize.height
      }}
    >
      {children}
    </Grid>
  );
};

export default RegistrationPageLayout;
