import { makeStyles } from "@mui/styles";
import { FLEX_CONSTANTS } from "../../../../constants/StyleConstants";

const OrderKitShipmentInformation = makeStyles((theme) => ({
  root: {
    display: FLEX_CONSTANTS.FLEX,
    flexDirection: FLEX_CONSTANTS.COLUMN,
    flex: 1,
    justifyContent: FLEX_CONSTANTS.FLEX_END
  },
  titleContainer: {
    marginTop: theme.paddings.sm
  }
}));

export default OrderKitShipmentInformation;
