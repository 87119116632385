import { makeStyles } from "@mui/styles";
import {
  FONT_CONSTANTS,
  COLOR_CONSTANTS
} from "../../../constants/StyleConstants";

const AccountManagementStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#8CC53F",
    [theme.breakpoints.down('md')]: {
      width: 45,
      height: 45,
      marginBottom: 15
    },
    [theme.breakpoints.up("md")]: {
      width: 65,
      height: 65,
      marginBottom: 15
    }
  },
  avatarFont: {
    fontFamily: FONT_CONSTANTS.FONT_AVENIRLT_PRO_MEDIUM,
    marginTop: 4,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 25
    }
  },
  accountManTitle: {
    marginTop: 40,
    marginBottom: 14
  },
  AccountManageClientName: {
    color: theme.palette.primary.titles
  },
  AccountManageClientSince: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_LIGHT_GRAY,
    fontSize: 14,
    marginBottom: 15
  },
  ActiveChip: {
    width: 144,
    height: 33,
    fontSize: 16,
    fontWeight: 700,
    backgroundColor: `#FFE38B`,
    color: theme.palette.primary.black,
    marginBottom: 25
  },
  boxOptions: {
    marginBottom: theme.margins.xl
  }
}));

export default AccountManagementStyles;
