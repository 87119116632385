import { makeStyles } from "@mui/styles";

const ImageAnchoredParagraphStyles = makeStyles((theme) => ({
  container: {
    padding: "40px 60px",
    "& a": {
      textDecoration: "underline",
      color: "black",
      fontWeight: 700
    },
    "& img": {
      objectFit: "cover"
    }
  },
  anchorImage: { height: 290, width: 295, borderRadius: 4 },
  textContainer: { paddingLeft: 15 },
  header: {
    color: theme.palette.primary.main
  }
}));

export default ImageAnchoredParagraphStyles;
