import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import TransmissionReasonManagmentStepStyles from "./TransmissionReasonManagmentStep.styles";
import Translate from "../../../../../translate/Translate";
import ChipDropdown from "../../../../sharedComponents/ChipDropdown";
const TransmissionReasonManagmentStep = (props) => {
  const {
    reasonsToTransmit,
    reasonsForTransmitHandler,
    otherReason,
    setOtherReason,
    additionalComments,
    additionalCommentsHandler
  } = props;
  const classes = TransmissionReasonManagmentStepStyles();

  const options = [
    "Add this to my medical record.",
    "Seeking guidance and recommendations.",
    "Planning for a family and would like next steps.",
    "Consultation to understand my results.",
    "Other"
  ];
  const hasOther = reasonsToTransmit.includes("Other");

  return (
    <Grid item xs={12}>
      <Grid container direction="column">
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs={11} sm={8} md={6} lg={4}>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              className={classes.connectionContainer}
            >
              <Typography variant="h5" className={classes.titleText}>
                <Translate text="Provide a reason for sharing your report(s)." />
              </Typography>
              <div className={classes.connectionContainer}>
                <Typography variant="body1" className={classes.subtitleText}>
                  <Translate text="A cover letter with your reasons for transmission is included with your report. Certain reports with clinically actionable results will also include your clinic action plan." />
                </Typography>
              </div>
              <ChipDropdown
                options={options}
                title={"Select from the following."}
                selectedOptions={reasonsToTransmit}
                selectedHandler={reasonsForTransmitHandler}
              />
              {hasOther && setOtherReason ? (
                <Grid xs={12} className={classes.contactInformationCard}>
                  <TextField
                    label={<Translate text={"Please describe 'Other'"} />}
                    variant="outlined"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    fullWidth
                    multiline
                    name="other-desc-field"
                  />
                </Grid>
              ) : null}
              {reasonsToTransmit.length > 0 ? (
                <Grid item xs={12}>
                  <Grid
                    container
                    alignContent="center"
                    alignItems="center"
                    className={classes.connectionContainer}
                  >
                    <Typography variant="h5" className={classes.titleText}>
                      <Translate text="Anything else you would like to share with your care team connection?" />
                    </Typography>
                  </Grid>
                  <TextField
                    data-cy="reasonsToTransmit-details"
                    label={
                      <Translate
                        text={"Please describe in 120 characters or less"}
                      />
                    }
                    variant="outlined"
                    value={additionalComments}
                    onChange={(e) =>
                      additionalCommentsHandler
                        ? additionalCommentsHandler(e.target.value)
                        : null
                    }
                    fullWidth
                    multiline
                    inputProps={{ maxLength: 120 }}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TransmissionReasonManagmentStep;
