import Translate from "../../../../../../../translate/Translate";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ToDoListCardContainer from "../../ToDoListCardContainer";
import GLButton from "../../../../../sharedComponents/GLButton";
import orderScreeningIcon from "../../../../../../../assets/icons/icon-order-genetic-testing.svg";
import {
  selectExistingOrders,
  selectTestKitsGLIsCurrentlyOffering
} from "../../../../../../../store/reducers/order/OrderInformationSlice";
import { selectAllPreviousSurveyAnswers } from "../../../../../../../store/reducers/survey/SurveySlice";
import { PATHS } from "../../../../../../../constants/pathConstants";
import { combineArrayOfWordsWithCommasAndAnAndForLastItem } from "../../../../../../../utils/utils";
import {
  selectSubsetOfAnswersFromSurveyAnswers,
  hasUserCompletedEnoughOfGettingStartedSurvey
} from "../../../../../Surveys/Utilities/util";
import { figureOutKitsToOfferMemberRightNow } from "../../../../../../../utils/testKitUtils";
import Mixpanel from "../../../../../../../utils/mixpanel";
import { selectUserPersonalInformation } from "../../../../../../../store/reducers/user/UserAccountSlice";

const OrderGeneticScreeningsToDoCard = () => {
  const history = useHistory();
  const previousSurveyAnswers = useSelector(selectAllPreviousSurveyAnswers);
  const kitsAlreadyOrdered = useSelector(selectExistingOrders);
  const kitsGLCurrentlyOffers = useSelector(
    selectTestKitsGLIsCurrentlyOffering
  );
  const isMemberActive = useSelector(selectUserPersonalInformation)
    .isMemberActive;

  if (!hasUserCompletedEnoughOfGettingStartedSurvey(previousSurveyAnswers)) {
    return null;
  }

  const relevantSurveyAnswers = selectSubsetOfAnswersFromSurveyAnswers(
    previousSurveyAnswers,
    [
      "active-cancer-diagnosis",
      "dna-transplant",
      "taking-medication-want-to-learn",
      "taking-following-types-medication",
      "taking-medication",
      "taking-5-plus-medications",
      "planning-pregnancy-within-a-year"
    ]
  );

  // If these aren't satisfied, we can't offer kits before a consult
  if (
    relevantSurveyAnswers["active-cancer-diagnosis"] === true ||
    relevantSurveyAnswers["dna-transplant"] !== "Neither"
  ) {
    return null;
  }

  const kitsToOfferMemberRightNow = figureOutKitsToOfferMemberRightNow(
    kitsGLCurrentlyOffers,
    kitsAlreadyOrdered,
    relevantSurveyAnswers
  );

  if (kitsToOfferMemberRightNow.length === 0) return null;

  const testNamesArray = kitsToOfferMemberRightNow.map((kit) => {
    return `<strong>${kit.test_name}</strong>`;
  });

  const testNamesString = combineArrayOfWordsWithCommasAndAnAndForLastItem(
    testNamesArray
  );

  const body = (
    <div
      dangerouslySetInnerHTML={{
        __html: `The ${testNamesString} test${
          testNamesArray.length > 1 ? "s" : ""
        } may be beneficial to your health`
      }}
    />
  );

  const handleOrderButtonClick = () => {
    Mixpanel.track(`To Do Card CTA Clicked - Order recommended kits`);
    history.push(PATHS.ORDER_KIT_SELECTION);
  };

  const renderButton = () => {
    return (
      <GLButton
        text={"Request"}
        size={"small"}
        disabled={!isMemberActive}
        onClick={handleOrderButtonClick}
      />
    );
  };

  return (
    <ToDoListCardContainer
      icon={orderScreeningIcon}
      title={<Translate text={"Request genetic screening"} />}
      body={body}
      buttons={renderButton()}
    ></ToDoListCardContainer>
  );
};

export default OrderGeneticScreeningsToDoCard;
