import React, { useState, Fragment } from "react";
import { Grid, Typography, Link } from "@mui/material";
import InviteSpousePageStyles from "./InviteSpousePage.styles";
import Layout from "../../layout/Layout";
import ScreeningCardsInfo from "./ScreeningCardsInfo";
import GLEmailTextField from "../../v2/Forms/FormComponents/GLEmailTextField";
import GLTextField from "../../v2/Forms/FormComponents/GLTextField";
import GLDateField from "../../v2/Forms/FormComponents/GLDateField";
import GLCheckboxField from "../../v2/Forms/FormComponents/GLCheckboxField";
import GLButton from "../../v2/sharedComponents/GLButton";
import Translate from "../../../translate/Translate";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../constants/pathConstants";
import {
  selectSpouseInformation,
  setSpouseInformation
} from "../../../store/reducers/user/UserAccountSlice";
import { selectUserPersonalInformation } from "../../../store//reducers/user/UserAccountSlice";
import { postSpouseInvitation } from "../../../apis/spouseApi";
import { useFormFieldState } from "../../v2/Forms/FormUtils";
import { formatDateStringForBackEnd } from "../../../apis/util";
import ModalWrapper from "../../v2/sharedComponents/Modals/ModalComponents/ModalWrapper";
import moment from "moment";
import Mixpanel from "../../../utils/mixpanel";
import { openGLSnackbar } from "../../../store/reducers/snackbar/openGLSnackbarSlice";

const InviteSpousePage = (props) => {
  const classes = InviteSpousePageStyles();
  const userInformation = useSelector(selectUserPersonalInformation);
  const spouseInformation = useSelector(selectSpouseInformation);
  const history = useHistory();
  const dispatch = useDispatch();
  const [
    memberDoesWantToInviteAgain,
    setMemberDoesWantToInviteAgain
  ] = useState(null);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const { formFields, formStatus, handleInputChange } = useFormFieldState({});

  const validateEmailIsNotCurrentUsersEmail = (enteredEmail) => {
    if (enteredEmail === userInformation.email) {
      return "This is your email address, we need a different email address for your spouse.";
    }
  };

  const handleSubmitForm = () => {
    postSpouseInvitation({
      spouseEmail: formFields.spouseEmail,
      spouseFirstName: formFields.spouseFirstName,
      spouseLastName: formFields.spouseLastName,
      spouseDob: formatDateStringForBackEnd(formFields.spouseDob)
    })
      .then((response) => {
        dispatch(setSpouseInformation(response.data));
        dispatch(
          openGLSnackbar({
            variant: "success",
            header: false,
            subText: "Invitation has been sent to your spouse"
          })
        );
        Mixpanel.track("Spouse invitation successful");
        history.push(PATHS.LANDING_PAGE);
      })
      .catch(() => {
        Mixpanel.track("Spouse invitation failed");
        setConfirmDialogIsOpen(false);
      });
  };

  const reInviteConfirmationSection = (
    <Grid item className={classes.formCard}>
      <Grid item xs={12}>
        <Typography className={classes.title} variant="h6">
          {`Hey ${userInformation.firstName}, you invited someone ${moment
            .utc(spouseInformation.lastInvited, "YYYY-MM-DD-HH-mm-ss")
            .fromNow()}...`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.subTitle} variant="body1">
          Would you like to send them another invite? Please click below to send
          them a new one.
        </Typography>
        <Typography className={classes.subTitle} variant="body1">
          Keep in mind invites expire after 5 days. Sending this will also
          de-activate the old invite link.
        </Typography>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <GLButton
          name="confirmSpouse"
          variant="contained"
          color="secondary"
          type="submit"
          onClick={() => {
            setMemberDoesWantToInviteAgain(true);
          }}
        >
          <Translate text="I WANT TO RE-SEND INVITE" />
        </GLButton>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Link className={classes.link} href="/" underline="hover">
          <Translate text="Nevermind, I clicked this by mistake" />
        </Link>
      </Grid>
    </Grid>
  );

  const formSection = (
    <Grid item className={classes.formCard}>
      <Grid item xs={12}>
        <Typography className={classes.title} variant="h6">
          {`Hey ${userInformation.firstName}, ready to invite your spouse?`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.subTitle} variant="body1">
          Please enter their info below. Double check, because this the info
          they’ll get when they receive their invite email.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Grid item xs={12} className={classes.box}>
              <Grid className={classes.fieldsPadding} container spacing={4}>
                <GLTextField
                  id="spouseFirstName"
                  name="spouseFirstName"
                  variant="outlined"
                  label={"Spouse's First Name"}
                  value={formFields.spouseFirstName}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.box}>
              <Grid className={classes.fieldsPadding} container spacing={4}>
                <GLTextField
                  id="spouseLastName"
                  name="spouseLastName"
                  label={"Spouse's Last Name"}
                  value={formFields.spouseLastName}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.box}>
              <Grid className={classes.fieldsPadding} container spacing={4}>
                <GLEmailTextField
                  id="spouseEmail"
                  name="spouseEmail"
                  label={"Spouse's email"}
                  value={formFields.spouseEmail}
                  onChange={handleInputChange}
                  size="small"
                  additionalValidation={validateEmailIsNotCurrentUsersEmail}
                  required
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.box}>
              <Grid className={classes.fieldsPadding} container spacing={4}>
                <GLDateField
                  id="spouseDob"
                  name="spouseDob"
                  label={"Spouse's Date of Birth"}
                  value={formFields.spouseDob}
                  onChange={handleInputChange}
                  size="small"
                  required
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.box}>
              <Grid className={classes.fieldsPadding} container spacing={4}>
                <GLCheckboxField
                  label={`By submitting this form, you confirm that you have provided information that is accurate and truthful.`}
                  id="confirmIsSpousesInfo"
                  checked={!!formFields.confirmIsSpousesInfo}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <GLButton
                name="confirmSpouse"
                color="secondary"
                onClick={() => {
                  setConfirmDialogIsOpen(true);
                }}
                disabled={
                  formStatus.invalidFields.length > 0 ||
                  !formFields.confirmIsSpousesInfo
                }
              >
                <Translate text="CONFIRM" />
              </GLButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const confirmationModal = (
    <ModalWrapper
      isOpen={confirmDialogIsOpen}
      onClickOutsideModal={() => {
        setConfirmDialogIsOpen(false);
      }}
    >
      <Fragment>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.modalHeader}>
            Confirm Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {`${userInformation.firstName}, please confirm that you would like to invite ${formFields.spouseFirstName} ${formFields.spouseLastName}
            as your spouse to Genomic Life.`}
          </Typography>
        </Grid>
        <Grid container className={classes.outlinedWell}>
          <Grid item xs={12}>
            {`${formFields.spouseFirstName} ${formFields.spouseLastName}`}
          </Grid>
          <Grid item xs={12}>
            {`${formFields.spouseEmail}`}
          </Grid>
        </Grid>

        <Grid container className={classes.modalButtons}>
          <GLButton
            name="abortConfirmSpouse"
            preset={"CANCEL"}
            onClick={() => {
              setConfirmDialogIsOpen(false);
            }}
          >
            <Translate text="DISMISS" />
          </GLButton>
          <GLButton
            loadingSpinnerWhenClicked
            name="confirmSpouse"
            variant="contained"
            color="secondary"
            type="submit"
            onClick={handleSubmitForm}
            disabled={false}
          >
            <Translate text="CONFIRM" />
          </GLButton>
        </Grid>
      </Fragment>
    </ModalWrapper>
  );

  return (
    <Layout headerType="tertiary">
      <div className={classes.containerContent}>
        <Grid container className={classes.container}>
          <ScreeningCardsInfo />
          <Grid item>
            {confirmationModal}
            {!!spouseInformation.lastInvited && !memberDoesWantToInviteAgain
              ? reInviteConfirmationSection
              : formSection}
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default InviteSpousePage;
