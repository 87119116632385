import React, { useReducer } from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import FormModalWrapperStyles from "./FormModalWrapper.styles";
import {
  Backdrop,
  Box,
  Fade,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import Translate from "../../../../../translate/Translate";
import GLButton, { BUTTON_PRESETS } from "../../../sharedComponents/GLButton";

const FormModalWrapper = (props) => {
  const {
    itemType,
    headerText,
    onSaved,
    onCancelled,
    onDeleted,
    children,
    submitDisabled,
    formHasUnsavedChanges
  } = props;

  const classes = FormModalWrapperStyles();
  const { height } = useWindowSize();
  const theme = useTheme();
  const isScreenWidthSmallOrXs = useMediaQuery(theme.breakpoints.down("md"));
  const isScreenWidthXs = useMediaQuery(theme.breakpoints.down("sm"));

  const itemTypeString = itemType ? itemType : "record";

  const handleSubmitForm = () => {
    dispatchFooterMode({ type: "SAVING" });
    onSaved().catch(() => {
      dispatchFooterMode({
        type: "DEFAULT",
        errorMessage: "There was an error saving, please try again"
      });
    });
  };

  const handleDeleteRecord = () => {
    dispatchFooterMode({ type: "DELETING" });
    onDeleted().catch(() => {
      dispatchFooterMode({
        type: "DEFAULT",
        errorMessage: "There was an error deleting, please try again"
      });
    });
  };

  const footerDefaultState = () => {
    const secondaryButtonProps = !!onDeleted
      ? {
          secondaryButtonText: `DELETE ${itemTypeString}`,
          secondaryButtonPreset: BUTTON_PRESETS.DELETE,
          secondaryButtonAction: () => {
            dispatchFooterMode({ type: "CONFIRM_DELETE" });
          },
          secondaryButtonDisabled: false,
          secondaryButtonLoading: false,
          secondaryButton: "DELETE",
          secondaryButtonActionName: "initiateDelete"
        }
      : {};
    return {
      mode: "DEFAULT",
      message: null,
      errorMessage: null,
      maskForm: false,
      primaryButtonText: null,
      // Can't set the primary button handler here, see note below
      primaryButtonAction: null,
      primaryButtonPreset: BUTTON_PRESETS.SAVE,
      primaryButtonDisabled: false,
      primaryButtonLoading: false,
      primaryButtonActionName: "createOrUpdate",
      ...secondaryButtonProps
    };
  };

  const footerStateReducer = (state, action) => {
    switch (action.type) {
      case "CONFIRM_DELETE":
        return {
          ...state,
          mode: action.type,
          message: `Are you sure you want to delete this ${itemTypeString}?`,
          errorMessage: null,
          maskForm: true,
          primaryButtonText: `Keep ${itemTypeString}`,
          primaryButtonAction: () => {
            dispatchFooterMode({ type: "DEFAULT" });
          },
          primaryButtonActionName: "abortDelete",
          primaryButtonPreset: BUTTON_PRESETS.CONTINUE,
          secondaryButtonPreset: BUTTON_PRESETS.CANCEL,
          secondaryButtonText: "confirm deletion",
          secondaryButtonAction: handleDeleteRecord,
          secondaryButtonActionName: "confirmDelete"
        };
      case "DELETING":
        return {
          ...state,
          mode: action.type,
          primaryButtonDisabled: true,
          secondaryButtonLoading: true
        };
      case "SAVING":
        return {
          ...state,
          mode: action.type,
          primaryButtonLoading: true,
          secondaryButtonDisabled: true
        };
      case "CONFIRM_CANCEL":
        return {
          ...state,
          mode: action.type,
          message: "You have unsaved changes!",
          errorMessage: null,
          maskForm: true,
          primaryButtonText: "Stay on form",
          primaryButtonAction: () => {
            dispatchFooterMode({ type: "DEFAULT" });
          },
          primaryButtonActionName: "abortCancel",
          primaryButtonPreset: BUTTON_PRESETS.CONTINUE,
          secondaryButtonPreset: BUTTON_PRESETS.CANCEL,
          secondaryButtonText: "exit anyway",
          secondaryButtonAction: onCancelled,
          secondaryButtonActionName: "confirmCancel"
        };
      case "DEFAULT":
        return {
          ...footerDefaultState(),
          errorMessage: action.errorMessage
        };
      default:
        return { ...state };
    }
  };

  const [footerState, dispatchFooterMode] = useReducer(
    footerStateReducer,
    footerDefaultState()
  );

  const handleCloseModelClick = () => {
    formHasUnsavedChanges
      ? dispatchFooterMode({ type: "CONFIRM_CANCEL" })
      : onCancelled();
  };

  // It takes a fair bit of wrangling to get the buttons and
  //  footer all laid out nicely for all the cases. I tried
  //  to name these vars to make what's happening as clear as I can.
  const foldoutShouldShow = !!footerState.message || !!footerState.errorMessage;

  const modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing =
    isScreenWidthSmallOrXs && foldoutShouldShow && !!onDeleted;

  const modifySecondaryButtonIfDeleteShowingAndXsWidth =
    isScreenWidthXs && footerState.mode === "DEFAULT";

  const calculateFooterHeight = () => {
    const heightIfFooterMessageShown = modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing
      ? "170px"
      : "126px";
    const heightIfNoFooterMessage = modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing
      ? "120px"
      : "57px";
    return foldoutShouldShow
      ? heightIfFooterMessageShown
      : heightIfNoFooterMessage;
  };

  const calculateMaskHeight = () => {
    const heightReductionIfFooterMessageShown = modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing
      ? 250
      : 205;
    return `calc(100% - ${
      foldoutShouldShow ? heightReductionIfFooterMessageShown : 177
    }px)`;
  };

  const calculateModalBodyHeight = () => {
    const heightReductionDependingOnMessageShowing = foldoutShouldShow
      ? 279
      : 210;
    return (
      height -
      (modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing
        ? 323
        : heightReductionDependingOnMessageShowing)
    );
  };

  const renderPrimaryButton = () => {
    return (
      <GLButton
        key={`${footerState.mode}-primary-$`}
        fullWidth={
          modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing ||
          (!onDeleted && isScreenWidthXs)
        }
        // This switch in the click handler feels gross, but I can't find a way
        //  to store the save handler in the reducer in a way that doesn't cache
        //  the values in the parent handler, preventing it from saving changes
        //  beyond the first render of the form. Would love to see a better solution!
        onClick={
          footerState.mode === "DEFAULT"
            ? handleSubmitForm
            : footerState.primaryButtonAction
        }
        preset={footerState.primaryButtonPreset}
        text={footerState.primaryButtonText}
        disabled={
          (footerState.mode === "DEFAULT" && submitDisabled) ||
          footerState.primaryButtonDisabled
        }
        loading={footerState.primaryButtonLoading}
        data-cy={footerState.primaryButtonActionName}
        id="primary-form-button"
      />
    );
  };

  const renderSecondaryButton = () => {
    const shortenedButtonTextIfXsDeleteMode = modifySecondaryButtonIfDeleteShowingAndXsWidth
      ? "DELETE"
      : footerState.secondaryButtonText;
    return footerState.secondaryButtonAction ? (
      <GLButton
        key={`${footerState.mode}-secondary`}
        fullWidth={modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing}
        onClick={footerState.secondaryButtonAction}
        loadingSpinnerWhenClicked={footerState.mode === "CONFIRM_DELETE"}
        preset={footerState.secondaryButtonPreset}
        text={shortenedButtonTextIfXsDeleteMode}
        disabled={footerState.secondaryButtonDisabled}
        loading={footerState.secondaryButtonLoading}
        data-cy={footerState.secondaryButtonActionName}
        id="secondary-form-button"
      />
    ) : null;
  };

  const renderFooterButtonCenteredIfSmallScreenWidth = (button) => {
    return modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing ||
      (!onDeleted && isScreenWidthXs) ? (
      <Grid xs={12}>
        <Box textAlign={"center"}>{button}</Box>
      </Grid>
    ) : (
      button
    );
  };

  const renderFooterButtons = () => {
    const spaceDeleteButtonBasedOnScreenWidth = isScreenWidthXs
      ? "space-between"
      : "flex-end";
    return (
      <Grid item xs={12}>
        <Grid
          container
          alignItems={
            modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing
              ? "center"
              : "flex-end"
          }
          justifyContent={
            modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing
              ? "center"
              : spaceDeleteButtonBasedOnScreenWidth
          }
          style={{
            marginTop: modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing
              ? "0"
              : "15px"
          }}
        >
          {renderFooterButtonCenteredIfSmallScreenWidth(
            renderSecondaryButton()
          )}
          {renderFooterButtonCenteredIfSmallScreenWidth(renderPrimaryButton())}
        </Grid>
      </Grid>
    );
  };

  const renderFooter = () => {
    const translatedMessageOrErrorMessage = (
      <span
        className={footerState.errorMessage ? classes.actionErrorMessage : ""}
      >
        <Translate text={footerState.errorMessage || footerState.message} />
      </span>
    );
    return (
      <Grid
        id="formModalFooter"
        item
        xs={12}
        className={classes.footerContainer}
        style={{ height: calculateFooterHeight() }}
      >
        <Fade
          in={foldoutShouldShow}
          timeout={500}
          className={classes.footerFader}
        >
          <Grid container direction="column">
            <Grid className={classes.confirmationMessage} container>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={
                    modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing
                      ? "center"
                      : "flex-start"
                  }
                >
                  <Typography
                    className={classes.confirmationMessage}
                    variant={"subtitle1"}
                  >
                    {translatedMessageOrErrorMessage}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {renderFooterButtons()}
          </Grid>
        </Fade>
        <Fade
          in={!foldoutShouldShow}
          timeout={500}
          className={classes.footerFader}
        >
          <Grid
            container
            direction="column"
            justifyContent={
              modifyFooterLayoutWhenSmallWidthAndFoldoutIsShowing
                ? "center"
                : "flex-end"
            }
          >
            {renderFooterButtons()}
          </Grid>
        </Fade>
      </Grid>
    );
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={handleCloseModelClick}
        aria-labelledby="title-modal"
        aria-describedby="simple-modal-description"
        className={`${classes.modal} ${footerState.mode}`}
        closeAfterTransition
      >
        <Grid container xs={12} sm={10} md={10} lg={8}>
          <Grid item className={classes.modalContainer}>
            <Grid
              id="formModalTitle"
              container
              alignItems="center"
              justifyContent="center"
              className={classes.modalTitleContainer}
            >
              <Grid item xs={11}>
                <Typography className={classes.title} variant={"subtitle1"}>
                  <Translate text={headerText} />
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Close
                    id="closingButton"
                    onClick={handleCloseModelClick}
                    className={classes.icon}
                    data-cy={"closeModal"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              id="formModalBody"
              item
              xs={12}
              className={classes.formContainer}
              style={{
                maxHeight: calculateModalBodyHeight(),
                overflowY: "auto"
              }}
            >
              <Grid container alignItems="center" justifyContent="center">
                {footerState.maskForm ? (
                  <Backdrop
                    className={classes.maskOverlay}
                    style={{
                      height: calculateMaskHeight()
                    }}
                    open={true}
                  ></Backdrop>
                ) : null}
                {children}
              </Grid>
            </Grid>
            {renderFooter()}
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default FormModalWrapper;

FormModalWrapper.propTypes = {
  itemType: PropTypes.string,
  headerText: PropTypes.string,
  onDeleted: PropTypes.func,
  onCancelled: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  onSaved: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  formHasUnsavedChanges: PropTypes.bool
};
