import React from "react";
import Translate from "../../../../../translate/Translate";
import GLTextField from "../GLTextField";

const GLDateField = (props) => {
  const {
    id,
    label,
    value,
    onChange,
    required,
    disabled,
    additionalValidation
  } = props;

  const validateDateTextFormat = (dateToTest) => {
    const regex = new RegExp(
      "(0[1-9]|1[012])/(0[1-9]|[12][0-9]|3[01])/(19|20)[0-9]{2}"
    );
    if (!regex.test(dateToTest)) {
      return <Translate text="The date format should be MM/DD/YYYY" />;
    }
    if (additionalValidation) {
      return additionalValidation(dateToTest);
    }
  };

  return (
    <GLTextField
      {...props}
      id={id}
      label={label}
      value={value}
      required={required}
      disabled={disabled}
      onChange={onChange}
      additionalValidation={validateDateTextFormat}
    />
  );
};

export default GLDateField;
