import { createSlice } from "@reduxjs/toolkit";

function combineObjectsWithoutClearingExistingValues(obj1, obj2) {
  Object.keys(obj2).forEach((key) => {
    // unless it's one of these keys and the new value is blank, update.
    if (
      !(
        ["email", "glMemberId", "firstName", "lastName"].includes(key) &&
        !obj2[key]
      )
    ) {
      obj1[key] = obj2[key];
    }
  });
  return obj1;
}

export const INITIAL_STATE = {
  greatInitialApiScrapeIsHappening: false,
  greatInitialApiScrapeIsComplete: false,
  userPersonalInformation: {
    glMemberId: "",
    primaryGlMemberId: "",
    prefix: "",
    suffix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    preferredName: "",
    dob: "",
    sexAssignedAtBirth: "",
    phoneNumber: "",
    email: "",
    gender: "",
    pronouns: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    isMemberActive: null
  },
  spouseInformation: {
    canInviteASpouse: false,
    lastInvited: null,
    spouseEmail: null
  },
  userSettings: {
    emailNotifications: false,
    phoneNumberNotifications: false,
    desktopNotifications: false
  },
  introPageVisited: false,
  hasActiveCancerDiagnosis: null
};

export const UserAccountSlice = createSlice({
  name: "userAccount",
  initialState: INITIAL_STATE,
  reducers: {
    setGreatInitialApiScrapeIsHappening(state, { payload }) {
      state.greatInitialApiScrapeIsHappening = payload;
    },
    setGreatInitialApiScrapeIsComplete(state, { payload }) {
      state.greatInitialApiScrapeIsComplete = payload;
    },
    updateFieldsInUserPersonalInformation(state, { payload }) {
      state.userPersonalInformation = combineObjectsWithoutClearingExistingValues(
        state.userPersonalInformation,
        payload
      );
    },
    setUserPersonalInformation(state, { payload }) {
      state.userPersonalInformation = payload;
    },
    setSpouseInformation: (state, { payload }) => {
      state.spouseInformation = payload;
    },
    setNotificationSettings: (state, { payload }) => {
      state.userSettings.emailNotifications = payload.emailNotifications;
      state.userSettings.phoneNumberNotifications =
        payload.phoneNumberNotifications;
      state.userSettings.desktopNotifications = payload.desktopNotifications;
    },
    setIntroPageVisited: (state, { payload }) => {
      state.introPageVisited = payload.introPageVisited;
    },
    setHasActiveCancerDiagnosis: (state, { payload }) => {
      state.hasActiveCancerDiagnosis = payload;
    }
  }
});

export const {
  setGreatInitialApiScrapeIsHappening,
  setGreatInitialApiScrapeIsComplete,
  updateFieldsInUserPersonalInformation,
  setUserPersonalInformation,
  setSpouseInformation,
  setNotificationSettings,
  setIntroPageVisited,
  setHasActiveCancerDiagnosis
} = UserAccountSlice.actions;

export const selectGreatInitialApiScrapeIsHappening = (state) =>
  state.user.greatInitialApiScrapeIsHappening;
export const selectGreatInitialApiScrapeIsComplete = (state) =>
  state.user.greatInitialApiScrapeIsComplete;
export const selectUserPersonalInformation = (state) =>
  state.user.userPersonalInformation;
export const selectSpouseInformation = (state) =>
  state.user.spouseInformation || {};
export const selectUserSettings = (state) =>
  state.user.userSettings ? state.user.userSettings : null;
export const selectIntroPageVisited = (state) =>
  state.user.introPageVisited === true || state.user.introPageVisited === null;
export const selectHasActiveCancerDiagnosis = (state) =>
  state.user.hasActiveCancerDiagnosis;

export default UserAccountSlice.reducer;
