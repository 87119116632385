import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import thunk from "redux-thunk";

//Slices
import userAccountReducer from "../store/reducers/user/UserAccountSlice";
import OrderInformationReducer from "../store/reducers/order/OrderInformationSlice";
import TermsOfServiceReducer from "../store/reducers/termsOfService/TermsOfServiceSlice";
import ConnectionInformationReducer from "../store/reducers/connection/ConnectionsInformationSlice";
import openGLSnackbarReducer from "./reducers/snackbar/openGLSnackbarSlice";
import SurveySlice from "./reducers/survey/SurveySlice";

const persistConfig = {
  key: "genomic_life_store",
  storage
};

const reducers = combineReducers({
  user: userAccountReducer,
  order: OrderInformationReducer,
  termsOfService: TermsOfServiceReducer,
  connection: ConnectionInformationReducer,
  snackbar: openGLSnackbarReducer,
  survey: SurveySlice
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:genomic_life");
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const persist = persistedReducer;

export default configureStore({
  reducer: persist,
  middleware: [thunk]
});
