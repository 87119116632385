import React, { useState } from "react";
import { Button } from "@mui/material";
import Translate from "../../../../translate/Translate";
import { Check } from "@mui/icons-material";
import Spinner from "../../Icons/Spinner";
import GLButtonStyles from "./GLButton.styles";
import BlueTrashIcon from "../../../../assets/images/BlueTrashIcon.svg";
import GrayTrashIcon from "../../../../assets/images/GrayTrashIcon.svg";
import PropTypes from "prop-types";

/*
interface GLButtonProps {
  preset?: "CONFIRM"
  className?: ?string
  color?: "primary" | "secondary"
  disabled?: bool
  disableOnClick?: bool
  endIcon?: JSX.Element
  startIcon?: JSX.Element
  id?: string
  loadingSpinnerWhenClicked?: bool
  lodaing?: bool
  name?: string
  onClick: () => void
  size?: "default" | "large" | "small"
  fullWidth?: boolean
  text?: string
  variant?: 'contained' | 'text'
}
*/

export const BUTTON_PRESETS = {
  CONFIRM: "CONFIRM",
  CONTINUE: "CONTINUE",
  BLUE_CTA: "BLUE_CTA",
  SAVE: "SAVE",
  CANCEL: "CANCEL",
  DELETE: "DELETE"
};

const GLButton = (props) => {
  const {
    preset,
    className,
    color,
    disabled,
    disableOnClick,
    endIcon,
    startIcon,
    fullWidth,
    id,
    loading,
    loadingSpinnerWhenClicked,
    name,
    onClick,
    size,
    text,
    variant,
    children
  } = props;
  const classes = GLButtonStyles();
  const [clicked, setClicked] = useState(false);

  if (disableOnClick && loadingSpinnerWhenClicked) {
    console.warn(
      "disableOnClick and loadingSpinnerWhenClicked should not be used together, behavior may be unpredictable"
    );
  }

  if (children && text) {
    console.warn(
      "children and text should not be used together, behavior may be unpredictable"
    );
  }

  const END_ICONS_BY_BUTTON_PRESET = {
    CONFIRM: clicked ? <Spinner /> : <Check fontSize="small" />,
    SAVE: clicked ? <Spinner /> : null
  };

  const START_ICONS_BY_BUTTON_PRESET = {
    DELETE:
      disabled || loading || (disableOnClick && clicked) ? (
        <img src={GrayTrashIcon} alt="Trash Icon" />
      ) : (
        <img src={BlueTrashIcon} alt="Trash Icon" />
      )
  };

  const DEFAULT_COLOR_BY_BUTTON_PRESET = {
    CONFIRM: "secondary",
    CONTINUE: "secondary",
    SAVE: "secondary",
    DELETE: "primary",
    BLUE_CTA: "primary"
  };

  const TEXT_BY_BUTTON_PRESET = {
    CONFIRM: "CONFIRM",
    CONTINUE: "CONTINUE",
    CANCEL: "CANCEL",
    SAVE: "SAVE",
    DELETE: "DELETE"
  };

  const VARIANT_BY_BUTTON_PRESET = {
    CONFIRM: "contained",
    CONTINUE: "contained",
    BLUE_CTA: "contained",
    CANCEL: "text",
    SAVE: "contained",
    DELETE: "text"
  };

  const handleClick = (e) => {
    setClicked(true);
    onClick(e);
  };

  const isDisabled = () => {
    if (loading === true) {
      return true;
    }
    if (disabled === true) {
      return true;
    }
    if (disableOnClick && clicked) {
      return true;
    }
    if (loadingSpinnerWhenClicked && clicked) {
      return true;
    }
    if (preset === BUTTON_PRESETS.CONFIRM && clicked) {
      return true;
    }
    if (preset === BUTTON_PRESETS.SAVE && clicked) {
      return true;
    }
    return false;
  };

  const calculateVariant = () => {
    if (variant) {
      return variant;
    }
    if (VARIANT_BY_BUTTON_PRESET[preset]) {
      return VARIANT_BY_BUTTON_PRESET[preset];
    }
    return "contained";
  };

  const calculateColor = () => {
    if (color) {
      return color;
    }
    if (DEFAULT_COLOR_BY_BUTTON_PRESET[preset]) {
      return DEFAULT_COLOR_BY_BUTTON_PRESET[preset];
    }
    return "primary";
  };

  const calculateEndIcon = () => {
    if (endIcon) {
      return endIcon;
    }
    if (loading) {
      return <Spinner />;
    }
    if (loadingSpinnerWhenClicked && clicked) {
      return <Spinner />;
    }
    if (END_ICONS_BY_BUTTON_PRESET[preset]) {
      return END_ICONS_BY_BUTTON_PRESET[preset];
    }
    return null;
  };

  const calculateStartIcon = () => {
    if (startIcon) {
      return startIcon;
    }
    if (START_ICONS_BY_BUTTON_PRESET[preset]) {
      return START_ICONS_BY_BUTTON_PRESET[preset];
    }
    return null;
  };

  const calculateButtonContents = () => {
    if (children) {
      return typeof children === "string" ? (
        <Translate text={children} />
      ) : (
        children
      );
    }
    if (text) {
      return <Translate text={text} />;
    }
    if (TEXT_BY_BUTTON_PRESET[preset]) {
      return <Translate text={TEXT_BY_BUTTON_PRESET[preset]} />;
    }
    return null;
  };

  const classString = `${classes.buttonStyles} ${size ? size : ""} ${preset} ${
    className ? className : ""
  }`;

  return (
    <Button
      fullWidth={fullWidth}
      id={id}
      name={name}
      color={calculateColor()}
      className={classString}
      variant={calculateVariant()}
      endIcon={calculateEndIcon()}
      startIcon={calculateStartIcon()}
      onClick={handleClick}
      disabled={isDisabled()}
      data-cy={props["data-cy"]}
    >
      {calculateButtonContents()}
    </Button>
  );
};

export default GLButton;

GLButton.propTypes = {
  preset: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  disableOnClick: PropTypes.bool,
  endIcon: PropTypes.element,
  startIcon: PropTypes.element,
  id: PropTypes.string,
  loadingSpinnerWhenClicked: PropTypes.bool,
  lodaing: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  text: PropTypes.string,
  variant: PropTypes.string
};
