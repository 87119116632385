import { makeStyles } from "@mui/styles";

const SecurityMessageStyles = makeStyles((theme) => ({
  securityMessageContainer: {
    background: theme.palette.secondary.gray_box,
    border: `1px solid ${theme.palette.secondary.gray_border}`,
    boxSizing: `border-box`,
    borderRadius: 4,
    marginBottom: 20,
    marginTop: 25,
    minHeight: 99,
    [theme.breakpoints.down('md')]: {
      width: 360,
      height: "auto",
      transition: "height 0.15s ease-out"
    },
    [theme.breakpoints.between("md", 'xl')]: {
      width: 545
    },
    [theme.breakpoints.up("lg")]: {
      width: 470
    }
  },
  securityMessageFullWidth: {
    width: "100%"
  },
  SecurityCardIcon: {
    marginTop: 13,
    marginLeft: 18
  },
  securityCardTitle: {
    fontStyle: `normal`,
    fontWeight: `bold`,
    fontSize: 14,
    lineHeight: `17px`,
    color: theme.palette.primary.black,
    marginTop: 18,
    marginBottom: 10
  },
  securityCardSubtitle: {
    fontStyle: `normal`,
    fontWeight: `normal`,
    fontSize: 14,
    lineHeight: `20px`,
    color: theme.palette.secondary.gray_button,
    marginBottom: 13,
    paddingRight: 10
  }
}));

export default SecurityMessageStyles;
