import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { getCookie } from "../../utils/CookieUtil";
import ModalComponent from "../sharedComponents/ModalComponent";
import SessionTimerDialog from "./SessionTimerDialog";
import { useLogout } from "../../hooks/useLogout";
import { useAuth0 } from "@auth0/auth0-react";

const IdleTimer = () => {
  const DEBUG_LOGGING = false;
  const IDLE_TIMOUT_IN_MILLISECONDS =
    window.Cypress && window.Cypress.env("idleTimeout")
      ? window.Cypress.env("idleTimeout")
      : 1000 * 60 * 14;

  const IDLE_PROMPT_TIMOUT_IN_MILLISECONDS =
    window.Cypress && window.Cypress.env("modalInactionTimeout")
      ? window.Cypress.env("modalInactionTimeout")
      : 1000 * 59;

  const [logoutModalIsOpen, setLogoutModalIsOpen] = useState(false);
  const [remainingTimeToAct, setRemainingTimeToAct] = useState(
    IDLE_PROMPT_TIMOUT_IN_MILLISECONDS
  );
  const logoutFunction = useLogout();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    setRemainingTimeToAct(getRemainingTime());
    const sessionCheckIntervalTimerId = setInterval(() => {
      const totalTimeLeft = getRemainingTime();
      setRemainingTimeToAct(totalTimeLeft);
      if (totalTimeLeft > IDLE_PROMPT_TIMOUT_IN_MILLISECONDS) {
        setLogoutModalIsOpen(false);
      }
    }, 1000);
    return () => {
      clearInterval(sessionCheckIntervalTimerId);
    };
    // eslint-disable-next-line
  }, []);

  /**
   * @description onActive
    Called when the user makes one of the tracked actions
   */
  const onAction = (e) => {
    if (DEBUG_LOGGING) {
      console.log("User action happened, resetting idle timer");
    }
    reset();
  };

  /**
   * @description onActive
    This function is executed once the modal has been displayed
    and the user clicks the "Stay Logged In" button.
   */
  const onActive = (e) => {
    setLogoutModalIsOpen(false);
    if (DEBUG_LOGGING) {
      console.log("User wants to stay active");
    }
    reset();
  };

  /**
   * @description onIdle
    This function is called when the time interval has expired, modal has been shown,
    and user did not take action, so they will be logged out.
   */
  const onIdle = () => {
    if (isAuthenticated) {
      setLogoutModalIsOpen(false);
      if (DEBUG_LOGGING) {
        console.log("User has gone idle, logging out");
      }
      logoutHandler();
    }
  };

  /**
   * @description onPrompt
    Called when user has been inactive long enough to be prompted to stay logged in
    or get logged out.
   */
  const onPrompt = () => {
    if (getCookie("accessToken")) {
      setLogoutModalIsOpen(true);
    }
  };

  const logoutHandler = () => {
    logoutFunction();
  };

  /**
   * @description useIdleTimer
   useIdleTimer is the hook that we are using to be able to use the react-idle-timer service.
   Documentation reference: https://idletimer.dev/
   */

  const { reset, getRemainingTime } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: IDLE_TIMOUT_IN_MILLISECONDS,
    promptBeforeIdle: IDLE_PROMPT_TIMOUT_IN_MILLISECONDS,
    events: ["click", "scroll", "keydown"],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false
  });
  return (
    <React.Fragment>
      <ModalComponent
        open={logoutModalIsOpen}
        body={
          <SessionTimerDialog
            handleClose={onActive}
            logOut={logoutHandler}
            second={parseInt(remainingTimeToAct / 1000)}
          />
        }
      />
    </React.Fragment>
  );
};

export default IdleTimer;
