import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../../constants/StyleConstants";
const ToDoListCardContainerStyles = makeStyles((theme) => ({
  cardItem: {
    width: "100%"
  },
  cardContainer: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.white,
    padding: "30px 20px",
    border: "2px solid #0000001A",
    boxShadow: "0px 0px 10px 0px #0000001A"
  },
  icon: { width: 72, height: 72, overflow: "hidden" },
  cardContentsContainer: {
    width: "calc(100% - 80px)",
    paddingLeft: 20,
    color: COLOR_CONSTANTS.COLOR_TITLES
  },
  header: {
    ...theme.typography.h6HeadlineHeavy,
    paddingBottom: 5
  },
  buttonList: {
    paddingTop: 10
  },
  buttons: {
    minWidth: 10,
    height: 40,
    margin: 0,
    padding: "0px 20px"
  }
}));

export default ToDoListCardContainerStyles;
