import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../constants/v2/colors";

const TestResultsPageStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "70px",
    paddingBottom: "50px"
  },
  section: {
    marginTop: "90px"
  },
  breadcrumbs: {
    marginTop: "10px",
    marginBottom: "30px",
    "& a": {
      textDecoration: "none"
    }
  },
  faqAccordian: {
    border: "none",
    boxShadow: "none",
    "&:before": {
      backgroundColor: COLORS.FOREST
    },
    "& .MuiPaper-root": {
      border: "none",
      boxShadow: "none",
      borderBottomColor: "black"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "30px"
    },
    "& .MuiAccordionSummary-root": {
      padding: 0
    },
    "& .MuiAccordionSummary-content": {
      margin: "25px 0 25px 0"
    }
  },
  sideMenuContainer: {
    paddingRight: "20px"
  },
  sideMenu: {
    backgroundColor: COLORS.OCEAN_EXTRA_LIGHT,
    borderRadius: "10px",
    padding: "30px",
    position: "sticky",
    top: 0,
    "& a": { color: "inherit" }
  },
  whatsNextHeader: {
    borderBottom: `1px solid ${COLORS.LIGHT_GRAY}`,

    paddingBottom: "13px"
  },
  whatsNextArrow: {
    fontSize: "18px",
    position: "relative",
    top: "2px"
  }
}));

export default TestResultsPageStyles;
