import React from "react";
import { Collapse } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Translate from "../../../../translate/Translate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoExpanderStyles from "./InfoExpander.styles";

/*
interface InfoExpanderProps {
  label: string
  className: string
  children: string | JSX.Element
}
*/

const InfoExpander = (props) => {
  const { children, className, label } = props;
  const [expanded, setExpanded] = React.useState(false);
  const classes = InfoExpanderStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const maybeTranslatedChildren =
    typeof children === "string" ? <Translate text={children} /> : children;

  return (
    <div className={className}>
      <IconButton
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label={label}
        className={classes.label}
        disableRipple
        size="large">
        {label}
        <ExpandMoreIcon
          style={{ transform: !expanded ? "rotate(0deg)" : "rotate(180deg)" }}
        />
      </IconButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {maybeTranslatedChildren}
      </Collapse>
    </div>
  );
};

export default InfoExpander;
