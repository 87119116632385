import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import IdleTimer from "./components/SessionTimer/IdleTimer";
import AccountManagementPage from "./components/pages/AccountManagementPage";
import AccountManagementMFAPage from "./components/pages/AccountManagementPage/AccountManagementMFAPage";
import AccountManagementMemberInfoPage from "./components/pages/AccountManagementPage/AccountManagementMemberInfoPage";
import AccountManagementPrivacySecurityPage from "./components/pages/AccountManagementPage/AccountManagementPrivacySecurityPage";
import AccountManagementTermsOfService from "./components/pages/AccountManagementPage/AccountManagementTermsOfService";
import ConnectionsPage from "./components/pages/AccountManagementPage/ConnectionsPage";
import ConnectionDetailsPage from "./components/pages/AccountManagementPage/ConnectionsPage/ConnectionDetailsPage";
import PaymentInformation from "./components/pages/AccountManagementPage/PaymentInformation";
import SetNotificationPage from "./components/pages/AccountManagementPage/SetNotificationPage";
import ChangePassword from "./components/pages/ChangePasswordPage";
import InviteSpousePage from "./components/pages/InviteSpousePage";
import OnboardingIntroPage from "./components/pages/OnboardingIntroPage";
import FinalOnboardingInfo from "./components/pages/OnboardingIntroPage/FinalOnboardingInfo";
import OrderingPage from "./components/pages/OrderingPage";
import OrderKitConclusionPage from "./components/pages/OrderingPage/OrderKitConclusionPage";
import ReportingSharingPage from "./components/pages/ReportingSharingPage/ReportingSurvey";
import TransmissionConclusionPage from "./components/pages/ReportingSharingPage/ReportingSurvey/TransmissionConclusionPage";
import RequestPasswordResetEmailFailurePage from "./components/pages/RequestPasswordResetEmailFailurePage";
import RequestPasswordResetEmailPage from "./components/pages/RequestPasswordResetEmailPage";
import RequestPasswordResetEmailSuccessPage from "./components/pages/RequestPasswordResetEmailSuccessPage";
import TermsOfServicePage from "./components/pages/TermsOfServicePage";
import TermsOfServiceReviewPage from "./components/pages/TermsOfServiceReviewPage";
import CancerResourceLibraryPage from "./components/v2/CMSDriven/Pages/CancerResourceLibraryPage";
import DashboardPage from "./components/v2/Pages/DashboardPage";
import GettingStartedSurveyPage from "./components/v2/Pages/GettingStartedSurveyPage";
import LoggedOutPage from "./components/v2/Pages/LoggedOutPage";
import LoginPage from "./components/v2/Pages/LoginPage";
import LoginCallbackPage from "./components/v2/Pages/LoginPage/LoginCallbackPage";
import ScrapeApiPage from "./components/v2/Pages/LoginPage/ScrapeApiPage";
import LogoutPage from "./components/v2/Pages/LogoutPage";
import TestKitSelectionPage from "./components/v2/Pages/TestKitSelectionPage";
import { PATHS } from "./constants/pathConstants";
import { selectUserHasAcceptedAllCurrentAgreements } from "./store/reducers/termsOfService/TermsOfServiceSlice";
import {
  selectGreatInitialApiScrapeIsComplete,
  selectIntroPageVisited,
  selectUserPersonalInformation
} from "./store/reducers/user/UserAccountSlice";
import { getCookie } from "./utils/CookieUtil";
import { validateToken } from "./utils/utils";
import HelpCenterPage from "./components/v2/CMSDriven/Pages/HelpCenter";
import RegistrationWelcomePage from "./components/v2/Pages/RegistrationPage/RegistrationWelcomePage";
import RegistrationSuccessPage from "./components/v2/Pages/RegistrationPage/RegistrationSuccessPage";
import MaintenancePage from "./components/v2/Pages/MaintenancePage";
import CancerSurveyPage from "./components/v2/Pages/CancerSurveyPage";
import InformedConsentSurveyPage from "./components/v2/Pages/InformedConsentSurveyPage";
import TestResultsPage from "./components/v2/Pages/TestResultsPage";
import TestResultDocumentPage from "./components/v2/Pages/TestResultDocumentPage";

export const PAGES_ALLOWED_TO_UNAUTHENTICATED_USERS = [
  PATHS.MAINTENANCE,
  PATHS.WELCOME,
  PATHS.REGISTRATION_SUCCESS,
  PATHS.LOGIN,
  PATHS.LOGIN_CALLBACK,
  PATHS.API_SCRAPE,
  PATHS.LOG_OUT,
  PATHS.LOGGED_OUT,
  PATHS.REQUEST_PASSWORD_EMAIL,
  PATHS.REQUEST_PASSWORD_EMAIL_SUCCESS,
  PATHS.REQUEST_PASSWORD_EMAIL_FAILURE
];

export const PAGES_FORBIDDEN_TO_LOGGED_IN_USERS = [PATHS.WELCOME];

const PAGES_FORBIDDEN_TO_INACTIVE_USERS = [
  PATHS.ORDER_KIT,
  PATHS.ORDER_KIT_SELECTION,
  PATHS.ORDER_KIT_CONFIRMATION,
  PATHS.GETTING_STARTED_SURVEY,
  PATHS.CANCER_SURVEY,
  PATHS.INVITE_SPOUSE
];

const TERMS_AND_CONDITION_PAGES = [
  PATHS.TERMS_OF_SERVICE,
  PATHS.TERMS_OF_SERVICE_PRIVACY_POLICY,
  PATHS.TERMS_OF_SERVICE_TERMS_AND_CONDITIONS,
  PATHS.TERMS_OF_SERVICE_HIPAA,
  PATHS.ONBOARDING_INTRO,
  PATHS.FINAL_ONBOARDING_INFO,
  PATHS.LOG_OUT
];

function AppRouter(props) {
  const { REACT_APP_UNDER_MAINTENANCE } = process.env;

  const visitorIsAMember = !!useSelector(selectUserPersonalInformation)
    .glMemberId;

  const isMemberActive = useSelector(selectUserPersonalInformation)
    .isMemberActive;

  const allAgreementsCurrent = useSelector(
    selectUserHasAcceptedAllCurrentAgreements
  );
  const hasIntroPageBeenVisited = useSelector(selectIntroPageVisited);

  const greatInitialApiScrapeIsComplete = useSelector(
    selectGreatInitialApiScrapeIsComplete
  );

  const sessionIsValid = validateToken(getCookie("accessToken"));

  // There are a few possible states related to authentication here,
  //  and each must be re-renderable as many times as is needed, since
  //  there are multiple dispatches and redirects in the process.
  // These tests are the gatekeepers. This will need refactoring if it
  //  gets much more complicated.

  if (
    REACT_APP_UNDER_MAINTENANCE &&
    window.location.pathname !== PATHS.MAINTENANCE
  ) {
    return <Redirect to={PATHS.MAINTENANCE} />;
  }

  if (
    (!sessionIsValid || !greatInitialApiScrapeIsComplete) &&
    !PAGES_ALLOWED_TO_UNAUTHENTICATED_USERS.includes(window.location.pathname)
  ) {
    return <Redirect to={PATHS.LOG_OUT} />;
  }

  if (sessionIsValid && !visitorIsAMember) {
    //return <Redirect to={PATHS.LOG_OUT} />;
  }

  if (
    sessionIsValid &&
    greatInitialApiScrapeIsComplete &&
    !allAgreementsCurrent &&
    !TERMS_AND_CONDITION_PAGES.includes(window.location.pathname)
  ) {
    return <Redirect to={PATHS.TERMS_OF_SERVICE} />;
  }

  if (
    !isMemberActive &&
    PAGES_FORBIDDEN_TO_INACTIVE_USERS.includes(window.location.pathname)
  ) {
    return <Redirect to={PATHS.LANDING_PAGE} />;
  }

  if (
    hasIntroPageBeenVisited &&
    window.location.pathname === PATHS.ONBOARDING_INTRO
  ) {
    return <Redirect to={PATHS.TERMS_OF_SERVICE} />;
  }
  if (
    sessionIsValid &&
    PAGES_FORBIDDEN_TO_LOGGED_IN_USERS.includes(window.location.pathname)
  ) {
    return <Redirect to={PATHS.LANDING_PAGE} />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={PATHS.MAINTENANCE}>
          <MaintenancePage exact path={PATHS.MAINTENANCE} />
        </Route>
        <Route exact path={PATHS.LANDING_PAGE}>
          <DashboardPage exact path={PATHS.LANDING_PAGE} />
        </Route>
        <Route exact path={PATHS.REQUEST_PASSWORD_EMAIL}>
          <RequestPasswordResetEmailPage {...props} />
        </Route>
        <Route exact path={PATHS.REQUEST_PASSWORD_EMAIL_FAILURE}>
          <RequestPasswordResetEmailFailurePage {...props} />
        </Route>
        <Route exact path={PATHS.REQUEST_PASSWORD_EMAIL_SUCCESS}>
          <RequestPasswordResetEmailSuccessPage {...props} />
        </Route>
        <Route exact path={PATHS.WELCOME}>
          <RegistrationWelcomePage {...props} />
        </Route>
        <Route exact path={PATHS.REGISTRATION_SUCCESS}>
          <RegistrationSuccessPage {...props} />
        </Route>
        <Route exact path={PATHS.LOGIN}>
          <LoginPage {...props} />
        </Route>
        <Route exact path={PATHS.API_SCRAPE}>
          <ScrapeApiPage {...props} />
        </Route>
        <Route exact path={PATHS.LOGIN_CALLBACK}>
          <LoginCallbackPage {...props} />
        </Route>
        <Route exact path={PATHS.LOG_OUT}>
          <LogoutPage {...props} />;
        </Route>
        <Route exact path={PATHS.LOGGED_OUT}>
          <LoggedOutPage {...props} />;
        </Route>
        <Route exact path={PATHS.ONBOARDING_INTRO}>
          <OnboardingIntroPage {...props} />;
        </Route>
        <Route exact path={PATHS.FINAL_ONBOARDING_INFO}>
          <FinalOnboardingInfo {...props} />;
        </Route>
        <Route exact path={PATHS.TERMS_OF_SERVICE}>
          <TermsOfServicePage {...props} />;
        </Route>
        <Route exact path={PATHS.TERMS_OF_SERVICE_PRIVACY_POLICY}>
          <TermsOfServiceReviewPage
            {...props}
            title={"Privacy Policy"}
            agreementKey={"privacyPolicy"}
          />
        </Route>
        <Route exact path={PATHS.TERMS_OF_SERVICE_TERMS_AND_CONDITIONS}>
          <TermsOfServiceReviewPage
            {...props}
            title={"Terms & Conditions"}
            agreementKey={"termsAndConditions"}
          />
        </Route>
        <Route exact path={PATHS.TERMS_OF_SERVICE_HIPAA}>
          <TermsOfServiceReviewPage
            {...props}
            title={"HIPAA Authorization"}
            agreementKey={"hipaaAuthorization"}
          />
        </Route>
        <Route exact path={PATHS.INVITE_SPOUSE}>
          <InviteSpousePage {...props} />;
        </Route>
        <Route exact path={PATHS.GETTING_STARTED_SURVEY}>
          <GettingStartedSurveyPage {...props} />;
        </Route>
        <Route exact path={PATHS.CANCER_SURVEY}>
          <CancerSurveyPage {...props} />
        </Route>
        <Route exact path={PATHS.ORDER_KIT_INFORMED_CONSENT_SURVEY}>
          <InformedConsentSurveyPage {...props} />;
        </Route>
        <Route exact path={PATHS.ORDER_KIT}>
          <OrderingPage
            goBackPath={PATHS.LANDING_PAGE}
            goBackTitle={"Dashboard"}
            {...props}
          />
          ;
        </Route>
        <Route exact path={PATHS.ORDER_KIT_SELECTION}>
          <TestKitSelectionPage
            goBackPath={PATHS.LANDING_PAGE}
            goBackTitle={"Dashboard"}
            {...props}
          />
        </Route>
        <Route exact path={PATHS.ORDER_KIT_CONFIRMATION}>
          <OrderKitConclusionPage {...props} />
        </Route>
        <Route path={`${PATHS.TEST_RESULTS_PAGE}/:glOrderId/:documentId`}>
          <TestResultDocumentPage {...props} />
        </Route>
        <Route path={`${PATHS.TEST_RESULTS_PAGE}/:glOrderId`}>
          <TestResultsPage {...props} />
        </Route>
        <Route exact path={PATHS.REPORT_SHARING_SURVEY}>
          <ReportingSharingPage {...props} />;
        </Route>
        <Route exact path={PATHS.REPORT_SHARING_CONCLUSION}>
          <TransmissionConclusionPage {...props} />;
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT}>
          <AccountManagementPage {...props} />;
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_PRIVACY_AND_POLICY}>
          <AccountManagementPrivacySecurityPage {...props} />;
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_TERMS_OF_SERVICE}>
          <AccountManagementTermsOfService {...props} />;
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_MEMBER_INFORMATION}>
          <AccountManagementMemberInfoPage {...props} />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_MFA}>
          <AccountManagementMFAPage {...props} />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_PAYMENT_INFORMATION}>
          <PaymentInformation {...props} />;
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS}>
          <ConnectionsPage
            {...props}
            goBackPath={PATHS.ACCOUNT_MANAGEMENT}
            goBackTitle="Account Management"
          />
        </Route>
        <Route
          exact
          path={PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS_CONNECTION_DETAILS}
        >
          <ConnectionDetailsPage
            {...props}
            goBackPath={PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS}
            goBackTitle="Connections"
          />
        </Route>
        <Route exact path={PATHS.ACCOUNT_MANAGEMENT_NOTIFICATIONS_SETTINGS}>
          <SetNotificationPage backTo={PATHS.ACCOUNT_MANAGEMENT} {...props} />
        </Route>
        <Route exact path={PATHS.ACCOUNT_PRIVACY_AND_POLICY_RESET_PASSWORD}>
          <ChangePassword
            backTo={PATHS.ACCOUNT_MANAGEMENT_PRIVACY_AND_POLICY}
            {...props}
          />
        </Route>

        <Route exact path={PATHS.CANCER_RESOURCE_LIBRARY}>
          <CancerResourceLibraryPage {...props} />;
        </Route>
        <Route exact path={PATHS.HELP_CENTER}>
          <HelpCenterPage {...props} />;
        </Route>

        <Route exact path={PATHS.RESOURCE_CENTER}>
          <Redirect to={"/content/help-center/"} />;
        </Route>
        <Route exact path={PATHS.PROGRAM_INFORMATION}>
          <Redirect
            to={
              "/content/help-center/articles/get-the-most-out-of-your-membership/"
            }
          />
          ;
        </Route>
        <Route exact path={PATHS.CANCER_AND_GENOMICS}>
          <Redirect
            to={
              "/content/help-center/topics/cancer-navigation/about-cancer-navigation/"
            }
          />
          ;
        </Route>
        <Route exact path={PATHS.WHY_GENOMICS}>
          <Redirect to={"/content/help-center/articles/why-genomics/"} />;
        </Route>
        <Route exact path={PATHS.CANCER_GUARDIAN_RESOURCE}>
          <Redirect
            to={
              "/content/help-center/topics/cancer-navigation/about-cancer-navigation/"
            }
          />
          ;
        </Route>
      </Switch>
      <IdleTimer />
    </BrowserRouter>
  );
}

export default AppRouter;
