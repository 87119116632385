import { makeStyles } from "@mui/styles";

const RainbowPeopleCTAStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    padding: "40px 60px"
  },
  rainbowPeople: { marginBottom: 20 },
  header: {
    fontWeight: 700,
    marginBottom: 20
  }
}));

export default RainbowPeopleCTAStyles;
