import React from "react";
import { Grid, Typography } from "@mui/material";
import RainbowPeopleCTAStyles from "./RainbowPeopleCTA.styles";
import DesktopPeople from "../../../../../assets/images/DesktopPeople.svg";
import GLButton from "../../../sharedComponents/GLButton";

const RainbowPeopleCTA = (props) => {
  const { header, text, button } = props;
  const classes = RainbowPeopleCTAStyles();

  return (
    <Grid container className={classes.container} justifyContent="center">
      <Grid item xs={12} className={classes.rainbowPeople}>
        <img src={DesktopPeople} alt="Rainbow people" />
      </Grid>
      <Grid item xs={12} className={classes.rainbowPeople}>
        <Typography variant="subtitle1" className={classes.header}>
          {header.en}
        </Typography>
        <Typography variant="subtitle1">
          <div dangerouslySetInnerHTML={{ __html: text.en }} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <GLButton preset="BLUE_CTA">{button.text.en}</GLButton>
      </Grid>
    </Grid>
  );
};

export default RainbowPeopleCTA;
