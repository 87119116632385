import Translate from "../../../translate/Translate";
import { Grid, Typography } from "@mui/material";
import React from "react";
import QcardStyles from "./Qcard.styles";
const Qcard = ({ title, icon }) => {
  const clasess = QcardStyles();
  return (
    <Grid container className={clasess.cardContainer}>
      <Grid className={clasess.cardTitleContainer} item xs={8} md={11}>
        <Typography className={clasess.cardTitle}>
          <Translate text={title} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          className={clasess.imageContainer}
          container
          alignContent="center"
          justifyContent="center"
        >
          <img className={clasess.iconSize} src={icon} alt="icon" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Qcard;
