import { makeStyles } from "@mui/styles";

const SideScrollingMenuStyles = makeStyles((theme) => ({
  container: {
    margin: "40px 0",
    "& .react-horizontal-scrolling-menu--inner-wrapper": {
      position: "relative"
    },
    "& .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar": {
      display: "none"
    },

    "& .react-horizontal-scrolling-menu--scroll-container": {
      "-ms-overflow-style": "none",
      scrollbarWidth: "none"
    },
    "& .react-horizontal-scrolling-menu--arrow-left, .react-horizontal-scrolling-menu--arrow-right": {
      width: 0,
      position: "absolute",
      zIndex: 500
    },
    "& .react-horizontal-scrolling-menu--arrow-right": {
      left: "calc(100% - 50px)"
    }
  },
  menuItemButton: {
    borderColor: theme.palette.secondary.gray_40,
    color: theme.palette.secondary.gray_40,
    whiteSpace: "nowrap",
    minWidth: "max-content",
    padding: "20px"
  },
  menuItemButtonSelected: {
    borderColor: "none",
    backgroundColor: theme.palette.primary.green,
    color: "White"
  },
  arrows: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    userSelect: "none",
    height: 40,
    minWidth: 40,
    marginTop: "16px",
    borderRadius: "50%",
    background: "none",
    border: "1px solid",
    borderColor: theme.palette.secondary.gray_40,
    position: "absolute",
    top: 0
  },
  arrowRight: {
    right: -50
  },
  arrowAndMaskBackground: {
    width: 100,
    height: 60
  },
  gradientMaskRight: {
    width: 50,
    height: 60,
    right: 50,
    position: "relative",
    backgroundImage:
      "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1))"
  },
  gradientMaskLeft: {
    width: 50,
    height: 60,
    right: -50,
    top: -50,
    position: "relative",
    backgroundImage:
      "linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1))"
  },
  arrowBackground: {
    backgroundColor: "white",
    width: 50,
    height: 60,
    position: "relative",
    top: -50
  },
  arrowBackgroundLeft: {
    top: 0
  }
}));

export default SideScrollingMenuStyles;
