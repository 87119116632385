import { Grid, Typography } from "@mui/material";
import RegistrationPageLayout from "../RegistrationPage/RegistrationPageLayout";
import Translate from "../../../../translate/Translate";
import MaintenancePageStyles from "./MaintenancePage.styles";
import HeaderLogo from "../../../../assets/images/GL-Logo.svg";

const MaintenancePage = () => {
  const classes = MaintenancePageStyles();

  return (
    <RegistrationPageLayout>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <img src={HeaderLogo} alt={`header-logo`} className={classes.logo} />
        <Grid item className={classes.icon}>
          🚧
        </Grid>
        <Grid item></Grid>
        <Grid item xs={10} md={5}>
          <Typography variant="h4" className={classes.titleHeader}>
            <Translate text="Sorry, we’re down for scheduled maintenance" />
          </Typography>
        </Grid>
        <Grid item xs={10} md={5}>
          <Typography variant="h6">
            <Translate text="We love that you’re here! We apologize for the inconvenience and expect to be back soon." />
          </Typography>
        </Grid>
        <Grid item xs={8} md={4} className={classes.highlightedBox}>
          <Grid item>
            <Typography>
              <Translate text="For immediate assistance, please reach out!" />
            </Typography>
          </Grid>
          <a className={classes.link} href="mailto:navigation@genomiclife.com">
            navigation@genomiclife.com
          </a>
        </Grid>
      </Grid>
    </RegistrationPageLayout>
  );
};

export default MaintenancePage;
