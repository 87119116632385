export const checkForTriggerAnswer = (
  survey,
  options,
  nameOfQuestionToCheck,
  answerEvaluatorMethod
) => {
  if (
    options.oldCurrentPage.elements
      .map((e) => e.name)
      .includes(nameOfQuestionToCheck)
  ) {
    const question = survey.getQuestionByName(nameOfQuestionToCheck);
    return (
      question && options.isNextPage && answerEvaluatorMethod(question.value)
    );
  }
  return false;
};

export const replaceAnswerValueInSurveyJsonForQuestionByName = (
  surveyJson,
  questionName,
  newValue
) => {
  let jsonToUpdate = { ...surveyJson };
  jsonToUpdate.pages.forEach((page) => {
    let element = page.elements.find(
      (element) => element.name === questionName
    );
    if (element) {
      element.defaultValue = newValue;
    }
  });
  return jsonToUpdate;
};

export const mapSurveyAnswerByType = (type, value) => {
  switch (type) {
    case "boolean":
      if (value === "True") {
        return true;
      } else {
        return false;
      }
    default:
      return value;
  }
};

export const rehydrateSurveyWithAnyPreviousAnswers = (
  survey,
  previousAnswers
) => {
  previousAnswers.forEach((previousAnswer) => {
    const question = survey.getQuestionByName(previousAnswer.name);
    if (!!question) {
      question.value = previousAnswer.value;
    }
  });
};

export const selectSubsetOfAnswersFromSurveyAnswers = (
  surveyAnswers,
  namesOfAnswersToExtract
) => {
  if (!surveyAnswers) return {};
  return surveyAnswers.reduce((result, answer) => {
    if (namesOfAnswersToExtract.includes(answer.name)) {
      result[answer.name] = answer.value;
    }
    return result;
  }, {});
};

export const confirmAllSpecifiedQuestionsHaveBeenAnswered = (
  surveyAnswers,
  namesOfAnswersToCheckFor
) => {
  if (!surveyAnswers) return true;
  const subset = selectSubsetOfAnswersFromSurveyAnswers(
    surveyAnswers,
    namesOfAnswersToCheckFor
  );
  return Object.keys(subset).length === namesOfAnswersToCheckFor.length;
};

export const hasUserCompletedEnoughOfGettingStartedSurvey = (usersAnswers) => {
  // Need all of the top list and at least one from the bottom list
  //  if we don't have those, the card needs to show.
  return (
    confirmAllSpecifiedQuestionsHaveBeenAnswered(usersAnswers, [
      "active-cancer-diagnosis",
      "dna-transplant",
      "planning-pregnancy-within-a-year"
    ]) &&
    Object.keys(
      selectSubsetOfAnswersFromSurveyAnswers(usersAnswers, [
        "taking-medication-want-to-learn",
        "taking-5-plus-medications",
        "taking-medication",
        "taking-following-types-medication"
      ])
    ).length > 0
  );
};
