import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Fade, TextField } from "@mui/material";
import Translate from "../../../../../translate/Translate";
import { SharedFormFieldStyles } from "../../Form.styles";
import { defaultSizingProps, extractSizingProps } from "../../FormUtils";

// There is a lot of bizwack in here around validation to support requests
//  from design. They want the field not to light up invalid until a user
//  has focused and defocused it, but they still want the save button
//  disabled. So the validity we report to the form that drives the button
//  disable is more strict than the validty the field itself shows, since
//  it may not show invalid if you are working on it or have not focused it before.
//  It's brittle. Be careful with it.

const GLSelectField = (props) => {
  const { label, id, value, onChange, required, disabled, children } = props;
  const [hasFocus, setHasFocus] = useState(false);
  const [hasReceivedFocus, setHasReceivedFocus] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || "");

  useEffect(() => {
    onChange({
      e: { target: { name: id, value: selectedValue } },
      isInvalid: required && (!selectedValue || selectedValue === "")
    });
  }, [hasReceivedFocus]); // eslint-disable-line
  // I want this hook only dependent on that one var so that it only
  //  fires when the form loads and if the input is ever focused

  const classes = SharedFormFieldStyles();

  const optionalLabel = (
    <span>
      {" ("}
      <Translate text={required ? "required" : "optional"} />
      {")"}
    </span>
  );

  const translatedLabel = (
    <span>
      <Translate text={label} /> {optionalLabel}
    </span>
  );

  const requiredFlag = (
    <Fade
      in={
        !hasFocus &&
        hasReceivedFocus &&
        required &&
        [null, undefined, ""].includes(selectedValue) &&
        !hasFocus
      }
    >
      <div className={classes.requiredFlag}>Required</div>
    </Fade>
  );

  const calculateHelperText = (valueToValidate) => {
    if (required && (!valueToValidate || valueToValidate === "")) {
      return <Translate text="This field is required" />;
    }
    return "";
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    onChange({
      e,
      isInvalid: !!calculateHelperText(e.target.value)
    });
  };

  return (
    <Grid
      item
      {...extractSizingProps(props)}
      className={classes.inputContainer}
    >
      {requiredFlag}
      <TextField
        select
        variant="outlined"
        label={translatedLabel}
        id={id}
        name={id}
        value={selectedValue}
        onChange={handleChange}
        className={classes.textInput}
        disabled={disabled}
        error={
          !!calculateHelperText(selectedValue) && hasReceivedFocus && !hasFocus
        }
        helperText={
          !hasFocus && hasReceivedFocus
            ? calculateHelperText(selectedValue)
            : null
        }
        fullWidth={true}
        onFocus={() => {
          setHasReceivedFocus(true);
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
      >
        {children}
      </TextField>
    </Grid>
  );
};

GLSelectField.defaultProps = defaultSizingProps;

export default GLSelectField;

GLSelectField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.array.isRequired
};
