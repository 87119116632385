import { makeStyles } from "@mui/styles";

const NoButtonsInfoDialogStyles = makeStyles((theme) => ({
  modalTitleContainer: {
    height: `55px`
  },
  title: {
    fontSize: `24px !important`,
    color: theme.palette.primary.link,
    lineHeight: `28px`,
    fontWeight: "800"
  },
  iconContainer: { textAlign: "right" },
  icon: {
    cursor: `pointer`,
    color: theme.palette.secondary.gray_button
  },
  modalBodyContainer: { paddingBottom: "25px" }
}));

export default NoButtonsInfoDialogStyles;
