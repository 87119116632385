import { makeStyles, withStyles } from "@mui/styles";
import StepConnector from "@mui/material/StepConnector";

const OrderingPageStyles = makeStyles((theme) => ({
  boxStepper: {
    display: `flex`,
    flex: 1,
    flexDirection: "column",
    boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.07)`
  }
}));

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.green
    }
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.primary.green
    }
  },
  line: {
    borderColor: theme.palette.secondary.gray_30,
    borderTopWidth: 2,
    borderRadius: 1
  }
}))(StepConnector);

export { OrderingPageStyles, QontoConnector };
