import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { selectUserPersonalInformation } from "../../../../store/reducers/user/UserAccountSlice";
import InactiveMembership from "../../../LandingPage/InactiveMembership";

import LookingForMore from "./LookingForMore";
import Layout from "../../../layout/Layout";

import ProactiveHealthResources from "../../sharedComponents/ProactiveHealthResources";
import PageTitle from "../../../../utils/PageTitle";

import LargeHelloHeader from "./LargeHelloHeader";
import ToDoList from "./ToDoList";
import { Grid, Typography } from "@mui/material";
import DashboardPageStyles from "./DashboardPage.styles";
import GLButton from "../../sharedComponents/GLButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { PATHS } from "../../../../constants/pathConstants";

const DashboardPage = () => {
  const isMemberActive = useSelector(selectUserPersonalInformation)
    .isMemberActive;
  const classes = DashboardPageStyles();
  const history = useHistory();

  PageTitle("Home");

  const cancerPart = (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item xs={4} className={classes.diagnosedTitle}>
        <Typography variant="h3">
          Are you currently dealing with cancer?
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.submitButtonContainer}>
        <GLButton
          color="primary"
          id="reportCancer"
          disabled={!isMemberActive}
          onClick={() => {
            history.push(PATHS.CANCER_SURVEY);
          }}
        >
          PLEASE TELL US ABOUT IT
        </GLButton>
      </Grid>
    </Grid>
  );

  return (
    <Layout headerType="primary">
      <LargeHelloHeader />
      {isMemberActive === false && <InactiveMembership />}
      <ToDoList />
      <ProactiveHealthResources />
      {cancerPart}
      <LookingForMore />
    </Layout>
  );
};

export default DashboardPage;
