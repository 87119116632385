import React from "react";

import { PATHS } from "../../../../constants/pathConstants";
import { useHistory } from "react-router-dom";
import SurveyWrapper from "../SurveyComponents/SurveyWrapper";
import { useDispatch } from "react-redux";
import { setHasActiveCancerDiagnosis } from "../../../../store/reducers/user/UserAccountSlice";
import Mixpanel from "../../../../utils/mixpanel";

const SURVEY_ID = "cancer-reporting-survey";

const hardcodedSurveyJson = {};

const CancerSurvey = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnComplete = (surveyModel) => {
    if (
      surveyModel.getQuestionByName("active-cancer-diagnosis").value === true
    ) {
      Mixpanel.track("Active cancer reported via dashboard");
      dispatch(setHasActiveCancerDiagnosis(true));
      history.push(PATHS.LANDING_PAGE);
    } else {
      dispatch(setHasActiveCancerDiagnosis(false));
      history.push(PATHS.LANDING_PAGE);
    }
  };

  return (
    <SurveyWrapper
      surveyId={SURVEY_ID}
      onComplete={handleOnComplete}
      overrideNextButtonEnabled={true}
      //surveyJsonOverride={hardcodedSurveyJson}
    ></SurveyWrapper>
  );
};

export default CancerSurvey;
