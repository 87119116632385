import { makeStyles } from "@mui/styles";

const UnSavedChangeDialogStyles = makeStyles((theme) => ({
  modalContainer: {
    borderRadius: 15,
    padding: 25,
    [theme.breakpoints.down(475)]: {
      width: 345,
      height: 349,
      left: 15,
      top: 349,
      margin: 10
    },
    [theme.breakpoints.down('lg')]: {
      width: 512,
      height: 307,
      left: 15,
      top: 608
    },
    [theme.breakpoints.up("md")]: {
      width: 512,
      height: 307,
      left: 8,
      top: 439
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  },
  modalTitleContainer: {
    height: `20%`,
    marginBottom: theme.margins.md
  },
  title: {
    fontSize: `24px`,
    lineHeight: `28px`,
    color: theme.palette.primary.blue_font
  },
  bodyText: {
    lineHeight: 22,
    paddingBottom: theme.paddings.md
  },
  icon: {
    paddingLeft: 30,
    cursor: `pointer`,
    color: theme.palette.primary.black,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 30
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 60
    }
  },
  modalBodyContainer: {
    height: `33%`
  },
  boxInfo: {
    marginBottom: theme.margins.md,
    marginTop: theme.margins.lg
  },
  deleteInfo: {
    color: theme.palette.primary.black
  },
  modalFooterContainer: {
    height: `15%`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    [theme.breakpoints.down(475)]: {
      justifyContent: "space-evenly",
      flexDirection: "column"
    }
  },
  removeBtn: {
    color: theme.palette.error.main
  }
}));

export default UnSavedChangeDialogStyles;
