// React
import React, { Fragment, useState, useReducer } from "react";
// MUI
import { Grid, Typography, Box, useMediaQuery, useTheme } from "@mui/material";

import { KeyboardArrowLeft } from "@mui/icons-material";
// react and redux
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  selectUpdateConnection,
  setConnectionId,
  setConnections,
  updateConnection
} from "../../../../../store/reducers/connection/ConnectionsInformationSlice";
// Styles
import ConnectionDetailsPageStyles from "./ConnectionDetailsPage.styles";
// Translate
import Translate from "../../../../../translate/Translate";
import Layout from "../../../../layout/Layout";
// Utils
import { isAdultBasedOnDob } from "../../../../../utils/utils";
// Modals
import DeleteConnectionModal from "../DeleteConnectionModal";
import UnSavedChangeModal from "../UnSavedChangeModal";
import { PATHS } from "../../../../../constants/pathConstants";
import ConnectionDetailForm from "./ConnectionDetailForm";
import {
  createUserConnection,
  updateUserConnection
} from "../../../../../apis/connectionsApi";
import { openGLSnackbar } from "../../../../../store/reducers/snackbar/openGLSnackbarSlice";

const ConnectionBreadcrumb = (props) => {
  const { text, onClick } = props;
  const classes = ConnectionDetailsPageStyles();
  const theme = useTheme();
  const changeVariant = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      className={classes.breadcrumbContainer}
      name="breadcrumb"
    >
      <Grid className={classes.breadcrumbLink} name={text} onClick={onClick}>
        <KeyboardArrowLeft className={classes.breadcrumbIcon} />
        <Typography
          variant={changeVariant ? "subtitle1" : "body1"}
          className={classes.breadcrumbText}
        >
          <Translate text={text} />
        </Typography>
      </Grid>
    </Box>
  );
};

function ConnectionDetailsPage(props) {
  const { goBackPath, goBackTitle } = props;
  const history = useHistory();
  const dispatchReducer = useDispatch();
  const [isNotMajorValidator, setIsNotMajorValidator] = useState(false);
  const [isPrimaryCare, setIsPrimaryCare] = useState(false);
  const [type, setType] = useState(
    () =>
      history.location?.state?.redirectState?.type ||
      history.location?.state?.type ||
      ""
  );
  const { connectionId } = useSelector(selectUpdateConnection);
  const [isFieldEdited, setIsFieldEdited] = useState(false);
  const classes = ConnectionDetailsPageStyles();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUnSavedModal, setOpenUnSavedModal] = useState(false);
  const theme = useTheme();
  const changeVariant = useMediaQuery(theme.breakpoints.down('sm'));

  let changeVariantValue = changeVariant ? "h3" : "h2";

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleOpenUnSavedModal = () => setOpenUnSavedModal(true);
  const handleCloseUnSavedModal = () => setOpenUnSavedModal(false);

  const dispatch = useDispatch();
  const connectionData = history.location?.state?.redirectState
    ? history.location?.state?.redirectState?.state
    : {};

  let connectionDataIsNotEmpty =
    connectionData && Object.keys(connectionData).length > 0;

  const isNotEmpty = (field, isString = true) => {
    let defaultValue = isString ? "" : false;
    return connectionDataIsNotEmpty ? field : defaultValue;
  };

  const initialConnectionForm = () => ({
    connectionId: connectionData ? connectionData.connectionId : null,
    relationship: connectionData.relationship,
    specialization: isNotEmpty(connectionData.specialization),
    firstName: isNotEmpty(connectionData.firstName),
    middleName: isNotEmpty(connectionData.middleName),
    lastName: isNotEmpty(connectionData.lastName),
    preferredName: isNotEmpty(connectionData.preferredName),
    email: isNotEmpty(connectionData.email),
    phoneNumber: isNotEmpty(connectionData.phoneNumber),
    faxNumber: isNotEmpty(connectionData.faxNumber),
    sex: isNotEmpty(connectionData.sex),
    gender: isNotEmpty(connectionData.gender),
    pronouns: isNotEmpty(connectionData.pronouns),
    dateOfBirth: isNotEmpty(connectionData.dateOfBirth),
    isLegalGuardian: isNotEmpty(connectionData.isLegalGuardian, false),
    coverUnderMembershipPlan: isNotEmpty(
      connectionData.coverUnderMembershipPlan,
      false
    ),
    authorizeReleaseOfConfidentialHealth: isNotEmpty(
      connectionData.authorizeReleaseOfConfidentialHealth,
      false
    ),
    hospitalOrOrganization: isNotEmpty(connectionData.hospitalOrOrganization),
    address1: isNotEmpty(connectionData.address1),
    address2: isNotEmpty(connectionData.address2),
    addressCity: isNotEmpty(connectionData.addressCity),
    addressState: isNotEmpty(connectionData.addressState),
    addressZip: isNotEmpty(connectionData.addressZip),
    addressCountry: connectionDataIsNotEmpty
      ? connectionData.addressCountry
      : "United States of America"
  });

  const connectionFormReducer = (initialState, action) => {
    switch (action.type) {
      case "HANDLE_INPUT_TEXT":
        return {
          ...initialState,
          [action.field]: action.value
        };
      case "HANDLE_CHECKBOXES":
        return {
          ...initialState,
          [action.field]: !action.value
        };
      default:
        return initialState;
    }
  };

  const [state, dispatchForm] = useReducer(
    connectionFormReducer,
    initialConnectionForm()
  );

  const handleTextChange = (e) => {
    dispatchForm({
      type: "HANDLE_INPUT_TEXT",
      field: e.target.name,
      value: e.target.value
    });
    setIsFieldEdited(true);
  };

  const handleCheckboxChange = (e) => {
    dispatchForm({
      type: "HANDLE_CHECKBOXES",
      field: e.target.name,
      value: state[e.target.name]
    });
    setIsFieldEdited(true);
  };

  const addAndEditConnectionHandler = () => {
    const newConnectionState = {
      ...state
    };

    const payload = connectionId
      ? {
          ...newConnectionState,
          connectionId: connectionId
        }
      : {
          newConnectionState
        };

    if (type === "edit") {
      updateUserConnection(payload)
        .then((response) => {
          response?.data?.connectionId &&
            dispatch(setConnectionId(response.data.connectionId));
          dispatchReducer(
            updateConnection({
              connectionId: response.data.connectionId,
              ...newConnectionState
            })
          )
            .then(
              history.push(PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS, {
                type: "edit"
              })
            )
            .catch(() => {
              dispatchReducer(
                openGLSnackbar({
                  variant: "error",
                  header: "Something went wrong",
                  subText:
                    "We apologize for the inconvenience. Please click to try again."
                })
              );
            });
        })
        .catch(() => {
          dispatchReducer(
            openGLSnackbar({
              variant: "error",
              header: "Something went wrong",
              subText:
                "We apologize for the inconvenience. Please click to try again."
            })
          );
        });
    } else {
      createUserConnection(payload)
        .then((response) => {
          response?.data?.connectionId &&
            dispatch(setConnectionId(response.data.connectionId));

          dispatchReducer(
            setConnections({
              ...newConnectionState,
              connectionId: response.data.connectionId
            })
          );
          history.push(PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS, {
            type: "create"
          });
        })
        .catch(() => {
          dispatchReducer(
            openGLSnackbar({
              variant: "error",
              header: "Somethin went wrong",
              subText:
                "We apologize for the inconvenience. Please click to try again."
            })
          );
        });
      setType("edit");
      setIsFieldEdited(false);
    }
  };

  const deleteConnectionHandler = () => {
    handleOpenDeleteModal();
  };

  const handleClick = () => {
    if (
      state.relationship === "Child" &&
      isAdultBasedOnDob(state.dateOfBirth) &&
      !state.isLegalGuardian
    ) {
      setIsNotMajorValidator(true);
    } else if (
      (state.relationship === "Primary care provider" ||
        state.relationship === "Care team") &&
      !state.authorizeReleaseOfConfidentialHealth
    ) {
      setIsPrimaryCare(true);
    } else {
      addAndEditConnectionHandler();
    }
  };

  const goBackPathHandler = () => {
    if (isFieldEdited) {
      handleOpenUnSavedModal();
    } else {
      history.push(PATHS.ACCOUNT_MANAGEMENT_CONNECTIONS);
    }
  };

  let isFirstTime = type === "edit" ? false : true;
  let reverseButtons = changeVariant ? "column-reverse" : "row";
  let errorValidForAuthorization =
    isPrimaryCare && !state.authorizeReleaseOfConfidentialHealth
      ? classes.textError
      : "";
  return (
    <Fragment>
      <Layout
        headerType="primary"
        goBackPath={goBackPath}
        goBackTitle={goBackTitle}
        hasNoFooter
      >
        <ConnectionBreadcrumb text="Connections" onClick={goBackPathHandler} />
        <Grid item xs={12} className={classes.addContainer}>
          <DeleteConnectionModal
            open={openDeleteModal}
            connectionInfo={state}
            handleClose={handleCloseDeleteModal}
          />
          <UnSavedChangeModal
            open={openUnSavedModal}
            firstTime={isFirstTime}
            connection={state}
            handleClose={handleCloseUnSavedModal}
          />
          <ConnectionDetailForm
            formTitle={"Add Connection"}
            type={type}
            changeVariantValue={changeVariantValue}
            handleClick={handleClick}
            handleTextChange={handleTextChange}
            handleCheckboxChange={handleCheckboxChange}
            isNotMajorValidator={isNotMajorValidator}
            isPrimaryCare={isPrimaryCare}
            errorValidForAuthorization={errorValidForAuthorization}
            reverseButtons={reverseButtons}
            canDelete={true}
            deleteConnectionHandler={deleteConnectionHandler}
            state={state}
          />
        </Grid>
      </Layout>
    </Fragment>
  );
}

export default ConnectionDetailsPage;
