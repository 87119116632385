import React, { Fragment } from "react";
import ModalComponent from "../../../../sharedComponents/ModalComponent";
import DeleteConnectionDialog from "./DeleteConnectionDialog";

const DeleteConnectionModal = (props) => {
  const { open, handleClose, connectionInfo } = props;

  return (
    <Fragment>
      <ModalComponent
        handleClose={handleClose}
        open={open}
        body={
          <DeleteConnectionDialog
            handleClose={handleClose}
            connection={connectionInfo}
          />
        }
      />
    </Fragment>
  );
};

export default DeleteConnectionModal;
