import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const FullWidthDividerBandFatStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    color: "White",
    height: "140px",
    margin: "10px 0 50px"
  },
  textContainer: {
    position: "absolute",
    left: 0,
    width: "100vw",
    padding: "50px 0",
    textAlign: "center",

    backgroundColor: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY
  }
}));

export default FullWidthDividerBandFatStyles;
