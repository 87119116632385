import React from "react";
import { Grid, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import QuoteCarouselStyles from "./QuoteCarousel.styles";
import QuotesIcon from "../../../../../assets/icons/Quotes.svg";

const QuoteCarousel = (props) => {
  const { quotes } = props;
  const classes = QuoteCarouselStyles();

  const renderQuotes = quotes.map((quote, index) => {
    return (
      <Grid item key={index} className={classes.quoteContainer}>
        <img
          className={classes.quotesIcon}
          src={QuotesIcon}
          alt={"Quotes icon"}
        />
        <Typography variant="h5" className={classes.header}>
          {quote.quoteHeader.en}
        </Typography>
        <Typography variant="subtitle1">{quote.quoteSubtext}</Typography>
        <Typography variant="h6" className={classes.quoth}>
          {quote.quoth}
        </Typography>
        <Typography variant="subtitle1" className={classes.relationToGL}>
          {quote.relationToGL.en}
        </Typography>
      </Grid>
    );
  });

  const carusel =
    renderQuotes.length > 1 ? (
      <Carousel>{renderQuotes}</Carousel>
    ) : (
      renderQuotes
    );

  return (
    <Grid container className={classes.container} justifyContent="center">
      <Grid item xs={12} sm={8} className={classes.quotes}>
        {carusel}
      </Grid>
    </Grid>
  );
};

export default QuoteCarousel;
