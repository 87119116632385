import React, { useState, useEffect } from "react";
import {
  InputLabel,
  FormControl,
  Chip,
  Checkbox,
  ListItemText,
  TextField
} from "@mui/material";
import ChipDropdownStyles from "./ChipDropdown.styles";
import Autocomplete from "@mui/lab/Autocomplete";
import Translate from "../../../translate/Translate";

/**
 * This component is a dropdown with chips for selected data and filter when typing
 * @param {object} props porperties passed to the instance of the component
 * @param {string} title the title for the dropdown label
 * @param {Array} options array of strings for the list of options
 * @param {Array} selectedOptions array of strings selected as chips
 * @param {function} selectedHandler function from the parent to update the selectedOptions value
 * @param {string} chipType string to which the chip type will be
 * @returns {JSX} input field for the component
 */

const ChipDropdown = (props) => {
  const {
    title,
    options,
    selectedOptions,
    selectedHandler,
    chipType,
    index,
    isDetectKey,
    filterDrugListBasedOnInput
  } = props;
  const classes = ChipDropdownStyles();
  const [selection, setSelection] = useState(
    selectedOptions ? selectedOptions : []
  );

  const menuOptions = options ? options : [];

  useEffect(() => {
    if (selectedOptions) setSelection(selectedOptions);
    return () => {
      setSelection([]);
    };
  }, [selectedOptions]);

  const chipColorHandler = () => {
    switch (chipType) {
      case "success":
        return [classes.chip, classes.chipSuccess];
      case "primary":
      default:
        return classes.chip;
    }
  };
  const mapOption = (option) => {
    if (isDetectKey) {
      return (
        <div className={classes.menu} key={option.label} value={option.i}>
          <Checkbox checked={selection.indexOf(option.label) > -1} />
          <ListItemText primary={<Translate text={option.label} />} />
        </div>
      );
    } else {
      return (
        <div className={classes.menu} key={option} value={option}>
          <Checkbox checked={selection.indexOf(option) > -1} />
          <ListItemText primary={<Translate text={option} />} />
        </div>
      );
    }
  };

  const handleChange = (value) => {
    setSelection(value);
    if (selectedHandler && index) {
      selectedHandler(value, index);
    }
    if (selectedHandler) {
      selectedHandler(value);
    }
    filterDrugListBasedOnInput && filterDrugListBasedOnInput("");
  };

  const inputFieldHanlder = (event) => {
    if (isDetectKey) {
      filterDrugListBasedOnInput(event.target.value);
    }
  };

  const optionLabelHandler = (option) => {
    if (isDetectKey) {
      return option.label;
    } else {
      return option;
    }
  };

  return (
    <FormControl variant="standard" className={classes.formContainer}>
      {title ? (
        <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>
      ) : null}
      <div>
        <Autocomplete
          value={selection}
          onChange={(event, newValue) => {
            handleChange(newValue);
          }}
          multiple
          filterSelectedOptions
          id="tags-filled"
          data-cy="reasonsToTransmit-provide-reason"
          fullWidth={true}
          freeSolo={isDetectKey}
          options={menuOptions}
          renderOption={(option) => mapOption(option)}
          getOptionLabel={(option) => optionLabelHandler(option)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={
                  isDetectKey
                    ? option.label.concat(`--${index}`)
                    : option.concat(`--${index}`)
                }
                label={<Translate text={isDetectKey ? option.label : option} />}
                className={chipColorHandler()}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              id="autocompleteField"
              variant="outlined"
              fullWidth={true}
              label={<Translate text={title} />}
              className={classes.input}
              onChange={inputFieldHanlder}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                }
              }}
            />
          )}
        />
      </div>
    </FormControl>
  );
};
export default ChipDropdown;
