import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const LoggedOutPageStyles = makeStyles((theme) => ({
  content: { paddingTop: "100px" },
  logo: { height: "36px", marginBottom: "35px" },
  header: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY,
    fontSize: "32px",
    marginBottom: "15px"
  },
  button: { marginBottom: "50px" }
}));

export default LoggedOutPageStyles;
