import { makeStyles } from "@mui/styles";
import { FLEX_CONSTANTS } from "../../constants/StyleConstants";

const LayoutStyles = makeStyles((theme) => ({
  logoHeader: {
    display: FLEX_CONSTANTS.FLEX,
    flex: 1,
    justifyContent: FLEX_CONSTANTS.CENTER,
    alignItems: FLEX_CONSTANTS.CENTER,
    marginTop: theme.paddings.sm,
    marginBottom: theme.paddings.sm
  }
}));

export default LayoutStyles;
