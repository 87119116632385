import { useState, useEffect } from "react";
import { getCurrentScreenSizeBreakpoint } from "../utils/utils";

export const useWindowSize = (params) => {
  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
    breakpoint: null
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        breakpoint: getCurrentScreenSizeBreakpoint(window.innerWidth)?.id
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};
