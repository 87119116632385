import { makeStyles } from "@mui/styles";

const ProactiveHealthResourcesStyles = makeStyles((theme) => ({
  container: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    background: theme.palette.secondary.color_white
  },
  cardContent: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    paddingTop: 30,
    paddingBottom: 40,
    [theme.breakpoints.down(703)]: {
      flexDirection: `column`
    }
  },
  proactiveTitle: {
    color: theme.palette.primary.blue_font,
    paddingTop: 30,
    textAlign: `center`
  },
  proactiveSubtitle: {
    color: theme.palette.primary.font_blue,
    fontWeight: 500,
    paddingTop: 20,
    textAlign: `center`,
    margin: `0 50px`
  },
  first: {
    [theme.breakpoints.up(703)]: {
      marginRight: 40
    },
    [theme.breakpoints.down(703)]: {
      marginBottom: 40
    }
  }
}));

export default ProactiveHealthResourcesStyles;
