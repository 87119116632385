import React from "react";
import { selectUserPersonalInformation } from "../../../../../store/reducers/user/UserAccountSlice";
import { useSelector } from "react-redux";

const ContentResolver = (props) => {
  const userInformation = useSelector(selectUserPersonalInformation);

  const content = props.content.replace(
    "{{{userFirstName}}}",
    userInformation.firstName
  );
  return <span dangerouslySetInnerHTML={{ __html: content }} />;
};

export default ContentResolver;
