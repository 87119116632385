import { makeStyles } from "@mui/styles";
import { FLEX_CONSTANTS } from "../../../constants/StyleConstants";

const LoadingSectionStyles = makeStyles((theme) => ({
  root: {
    display: FLEX_CONSTANTS.FLEX,
    flexDirection: FLEX_CONSTANTS.COLUMN,
    flex: 1,
    justifyContent: FLEX_CONSTANTS.FLEX_END
  },
  boxTitle: {
    marginTop: theme.spacing(4)
  },
  loadingContainer: {
    marginTop: theme.paddings.xxl,
    minHeight: 400
  }
}));

export default LoadingSectionStyles;
