import React from "react";
import NoButtonsInfoDialog from "../ModalComponents/NoButtonsInfoDialog";
import Translate from "../../../../../translate/Translate";
import SupportContactInfoBlock from "../../SupportContactInfoBlock/SupportContactInfoBlock";
import { Typography } from "@mui/material";
import ErrorMessageSupportModalStyles from "./ErrorMessageSupportModal.styles";

const ErrorMessageSupportModal = (props) => {
  const { isOpen, onClose } = props;
  const classes = ErrorMessageSupportModalStyles();

  return (
    <NoButtonsInfoDialog
      isOpen={isOpen}
      headerText={<Translate text={"Whoops!"} />}
      onClose={onClose}
    >
      {" "}
      <Typography className={classes.message} variant="subtitle1">
        <Translate
          text={
            "Something went wrong! We'd really like to make it right, please reach out to us."
          }
        />
      </Typography>
      <SupportContactInfoBlock />
    </NoButtonsInfoDialog>
  );
};

export default ErrorMessageSupportModal;
