import { makeStyles } from "@mui/styles";

const MemberInformationFormStyles = makeStyles((theme) => ({
  containerSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  titleContainer: {
    marginTop: theme.paddings.md
  },
  modalContainer: {
    borderRadius: 10,
    padding: 25,
    [theme.breakpoints.down(375)]: {
      width: `345px`,
      maxHeight: `642px`
    },
    [theme.breakpoints.down('lg')]: {
      width: `345px`,
      maxHeight: `642px`
    },
    [theme.breakpoints.up("md")]: {
      width: `525px`,
      maxHeight: `558px`
    },

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  },
  title: {
    fontSize: `24px !important`,
    color: theme.palette.info.main
  },
  icon: {
    paddingLeft: 30,
    cursor: `pointer`,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 30
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 60
    }
  },
  requestKitButton: {
    minWidth: `156.9px`,
    minHeight: 45,
    fontSize: 13,
    lineHeight: `16.8px`,
    [theme.breakpoints.only("md")]: {
      minWidth: `238.76px`,
      minHeight: 45
    },
    [theme.breakpoints.up("md")]: {
      minWidth: `238.76px`,
      minHeight: 45
    }
  },
  modalBody: {
    marginTop: theme.paddings.md,
    marginBottom: theme.paddings.md
  },
  modalBottomContainer: {
    height: `10%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  geneticText: {
    marginTop: 20
  }
}));
export default MemberInformationFormStyles;
