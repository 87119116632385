import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Typography } from "@mui/material";
import Layout from "../../../layout/Layout";
import HeaderLogo from "../../../../assets/images/GL-Logo.svg";
import { LABELS } from "../../../../constants/labelConstants";
import LoggedOutPageStyles from "./LoggedOutPage.styles";
import Translate from "../../../../translate/Translate";
import GLButton from "../../sharedComponents/GLButton";

const LoggedOutPage = () => {
  const { loginWithRedirect } = useAuth0();
  const classes = LoggedOutPageStyles();

  // Maybe do a double check here and redirect back to logOut if not logged out

  return (
    <Layout headerType="primary">
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.content}
      >
        <img
          src={HeaderLogo}
          className={classes.logo}
          alt={`${LABELS.LABEL_CANCER_GUARDIAN_LOGO_ALT_TEXT}`}
        />
        <Typography align="center" variant="h3" className={classes.header}>
          <Translate text="You have been logged out securely" />
        </Typography>
        <p>
          <Translate text="Thank you for visiting!" />
        </p>
        <GLButton
          onClick={() => {
            loginWithRedirect();
          }}
          className={classes.button}
          value="Log in again"
          color="secondary"
        >
          Log in again
        </GLButton>
      </Grid>
    </Layout>
  );
};

export default LoggedOutPage;
