import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserPersonalInformation,
  updateFieldsInUserPersonalInformation
} from "../../../../store/reducers/user/UserAccountSlice";
import PageTitle from "../../../../utils/PageTitle";
import ModalComponent from "../../../sharedComponents/ModalComponent";
import MustBeAdultModal from "./MustBeAdultModal";
import { isAdultBasedOnDob } from "../../../../utils/utils";
import MemberInformationForm from "../../../v2/Forms/MemberInformationForm";
import {
  getMemberData,
  patchMemberInformation
} from "../../../../apis/membersApi";
import { openGLSnackbar } from "../../../../store/reducers/snackbar/openGLSnackbarSlice";

const OrderKitMemberInformation = (props) => {
  const { handleNext } = props;
  const dispatcher = useDispatch();

  const [mustBeAdultModelIsOpen, setMustBeAdultModelIsOpen] = useState(false);
  PageTitle("Member Information");

  const memberInformation = useSelector(selectUserPersonalInformation);

  const handleSubmit = (newMemberInfo) => {
    if (isAdultBasedOnDob(newMemberInfo.dob)) {
      patchMemberInformation(newMemberInfo)
        .then((response) => {
          if (response.status === 200) {
            dispatcher(
              openGLSnackbar({
                variant: "success",
                header: "Success",
                subText: "Member Information was saved successfully!"
              })
            );
            getMemberData()
              .then(({ data }) => {
                dispatcher(updateFieldsInUserPersonalInformation(data));
              })
              .then(() => {
                handleNext();
              });
            return true;
          } else {
            dispatcher(
              openGLSnackbar({
                variant: "error",
                header: "Error on update Member Information",
                subText: response.data.statusMessage
              })
            );
          }
        })
        .catch((error) => {
          dispatcher(
            openGLSnackbar({
              variant: "error",
              header: "Error on update email",
              subText:
                "Sorry, the email you added is not available. Please provide another email address."
            })
          );
          return false;
        });
    } else {
      setMustBeAdultModelIsOpen(true);
    }
  };

  return (
    <div>
      <MemberInformationForm
        onSubmit={handleSubmit}
        memberData={memberInformation}
      />
      <ModalComponent
        handleClose={() => {
          setMustBeAdultModelIsOpen(false);
        }}
        open={mustBeAdultModelIsOpen}
        body={
          <MustBeAdultModal
            handleClose={() => {
              setMustBeAdultModelIsOpen(false);
            }}
          />
        }
      />
    </div>
  );
};

export default OrderKitMemberInformation;
