import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../../constants/StyleConstants";

const TestKitSelectionFooterBarStyles = makeStyles((theme) => ({
  mainContainer: {
    boxShadow: theme.shadows[5],
    height: 105,
    backgroundColor: COLOR_CONSTANTS.COLOR_WHITE
  },
  genesText: {
    color: theme.palette.text.secondary
  },
  totalText: {
    ...theme.typography.subtitleHeavy,
    fontSize: 14
  }
}));

export default TestKitSelectionFooterBarStyles;
