import { makeStyles } from "@mui/styles";

const GLButtonStyles = makeStyles((theme, props) => ({
  buttonStyles: {
    "&.MuiButton-containedPrimary": {
      "&:hover": {
        backgroundColor: theme.palette.primary.main
      }
    },
    "&.MuiButton-text": {
      paddingLeft: "16px",
      paddingRight: "16px"
    },
    "&.large": {
      width: "300px",
      height: "50px",
      fontSize: "16px"
    },
    "&.small": {
      height: "40px",
      minWidth: "140px",
      margin: 0,
      padding: "5px"
    },

    "&.CANCEL .MuiButton-label": {
      color: theme.palette.primary.cancel_button_text
    },
    "&.CONFIRM .MuiButton-label": {
      color: theme.palette.primary.action_button_text
    },
    "&.Mui-disabled .MuiButton-label": {
      color: theme.palette.primary.disabled_button_text
    }
  }
}));

export default GLButtonStyles;
