import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../constants/StyleConstants";

const PaymentMethodCardStyles = makeStyles((theme) => ({
  cardContainer: {
    background: COLOR_CONSTANTS.COLOR_WHITE,
    boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.14)`,
    borderRadius: 5,
    paddingBottom: theme.paddings.sm,
    maxWidth: 800
  },
  descriptionContainer: {
    marginTop: theme.paddings.md,
    paddingLeft: theme.paddings.sm,
    paddingRight: theme.paddings.sm
  },
  cardTitle: {
    ...theme.typography.subtitleHeavy,
    color: theme.palette.primary.link,
    marginTop: theme.paddings.xs / 4
  },
  cardTitleSuccess: {
    color: theme.palette.primary.green_dark
  },
  cardTitleInfo: {
    color: theme.palette.primary.blue_font
  },
  cardTitleError: {
    color: theme.palette.primary.brand_salmon
  },
  cardTitleWarning: {
    color: theme.palette.secondary.yellow_muted
  },
  titleContainer: {
    marginTop: theme.paddings.sm,
    marginLeft: theme.paddings.lg
  },
  checkboxText: {
    marginLeft: theme.paddings.sm
  },
  cardDescription: {
    ...theme.typography.subtitleRegular,
    color: theme.palette.secondary.gray_button
  },
  chip: {
    backgroundColor: theme.palette.primary.chip_blue,
    minWidth: 113,
    height: 33
  },
  chipContainer: {
    marginLeft: 10
  },
  chipSuccess: {
    backgroundColor: theme.palette.primary.chip_green
  },
  chipInfo: {
    backgroundColor: theme.palette.info.dark
  },
  chipWarning: {
    backgroundColor: theme.palette.warning.dark
  },
  chipError: {
    backgroundColor: theme.palette.error.light
  },
  chipErrorDarker: {
    backgroundColor: theme.palette.error.main
  },
  extraSmall: {
    width: 75,
    height: 33
  },
  bold: {
    fontWeight: 700
  },
  iconContainer: {
    marginTop: theme.paddings.md
  }
}));

export default PaymentMethodCardStyles;
