import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import TestKitSelectionFooterBarStyles from "./TestKitSelectionFooterBar.styles";
import { PATHS } from "../../../../../constants/pathConstants";
import Translate from "../../../../../translate/Translate";
import { useDispatch } from "react-redux";
import { setKitsSelectedToBeOrdered } from "../../../../../store/reducers/order/OrderInformationSlice";
import Mixpanel from "../../../../../utils/mixpanel";

const TestKitSelectionFooterBar = (props) => {
  const { selectedKitUuids } = props;
  const classes = TestKitSelectionFooterBarStyles();
  const history = useHistory();
  const dispatchReducer = useDispatch();

  return (
    <Grid
      container
      xs={12}
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.mainContainer}
      spacing={1}
    >
      <Grid container xs>
        <Box
          component={Grid}
          item
          md={4}
          display={{ xs: "none", sm: "none" }}
        ></Box>
      </Grid>
      <Grid
        container
        xs={6}
        md={4}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.totalText}>
          {selectedKitUuids && selectedKitUuids.length}{" "}
          {selectedKitUuids && selectedKitUuids.length === 1 ? (
            <Translate name="kit-total-tag" text="KIT TOTAL" />
          ) : (
            <Translate name="kits-total-tag" text="KITS TOTAL" />
          )}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={6}
        md={4}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          id="orderKitBarContinueButton"
          variant="contained"
          color="secondary"
          disabled={
            selectedKitUuids && selectedKitUuids.length > 0 ? false : true
          }
          onClick={() => {
            Mixpanel.track("Kits selected, proceeding to order forms");
            dispatchReducer(setKitsSelectedToBeOrdered(selectedKitUuids));
            history.push(PATHS.ORDER_KIT_INFORMED_CONSENT_SURVEY);
          }}
        >
          <Translate text="Continue" />
        </Button>
      </Grid>
    </Grid>
  );
};
export default TestKitSelectionFooterBar;
