import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserPersonalInformation,
  updateFieldsInUserPersonalInformation
} from "../../../../store/reducers/user/UserAccountSlice";
import { PATHS } from "../../../../constants/pathConstants";
import PageTitle from "../../../../utils/PageTitle";
import ModalComponent from "../../../sharedComponents/ModalComponent";
import MustBeAdultModal from "../../OrderingPage/OrderKitMemberInformation/MustBeAdultModal";
import { isAdultBasedOnDob } from "../../../../utils/utils";
import MemberInformationForm from "../../../v2/Forms/MemberInformationForm";
import {
  getMemberData,
  patchMemberInformation
} from "../../../../apis/membersApi";
import { openGLSnackbar } from "../../../../store/reducers/snackbar/openGLSnackbarSlice";
import Layout from "../../../layout/Layout";
import { Grid } from "@mui/material";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import AccountManagementMemberInfoPageStyles from "./AccountManagementMemberInfoPage.styles";
import BackTo from "../../../sharedComponents/BackTo";

const AccountManagementMemberInfoPage = (props) => {
  const history = useHistory();
  const dispatcher = useDispatch();
  const screenDimentions = useWindowSize();
  const usableHeight = screenDimentions.height - 260;
  const classes = AccountManagementMemberInfoPageStyles();

  const [mustBeAdultModelIsOpen, setMustBeAdultModelIsOpen] = useState(false);
  PageTitle("Member Information");

  const memberInformation = useSelector(selectUserPersonalInformation);

  const handleSubmit = (newMemberInfo) => {
    if (isAdultBasedOnDob(newMemberInfo.dob)) {
      patchMemberInformation(newMemberInfo)
        .then((response) => {
          if (response.status === 200) {
            dispatcher(
              openGLSnackbar({
                variant: "success",
                header: "Success",
                subText: "Member Information was saved successfully!"
              })
            );
            getMemberData()
              .then(({ data }) => {
                dispatcher(updateFieldsInUserPersonalInformation(data));
              })
              .then(() => {
                history.push(PATHS.ACCOUNT_MANAGEMENT);
              });
            return true;
          } else {
            dispatcher(
              openGLSnackbar({
                variant: "error",
                header: "Error on update email",
                subText:
                  "Sorry, the email you added is not available. Please provide another email address."
              })
            );
          }
        })
        .catch((error) => {
          dispatcher(
            openGLSnackbar({
              variant: "error",
              header: "Error on update email",
              subText:
                "Sorry, the email you added is not available. Please provide another email address."
            })
          );
          return false;
        });
    } else {
      setMustBeAdultModelIsOpen(true);
    }
  };

  return (
    <Layout headerType="primary">
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        style={{ minHeight: usableHeight }}
      >
        <Grid item xs={12} sm={10} md={8}>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className={classes.backToContainer}>
              <BackTo
                text="Account Management"
                backTo={PATHS.ACCOUNT_MANAGEMENT}
              />
            </div>
          </Grid>
        </Grid>

        <MemberInformationForm
          onSubmit={handleSubmit}
          memberData={memberInformation}
        />
        <ModalComponent
          handleClose={() => {
            setMustBeAdultModelIsOpen(false);
          }}
          open={mustBeAdultModelIsOpen}
          body={
            <MustBeAdultModal
              handleClose={() => {
                setMustBeAdultModelIsOpen(false);
              }}
            />
          }
        />
      </Grid>
    </Layout>
  );
};

export default AccountManagementMemberInfoPage;
