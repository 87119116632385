import React from "react";
import Layout from "../../../layout/Layout";
import { Grid } from "@mui/material";
import PageTitle from "../../../../utils/PageTitle";
import { PATHS } from "../../../../constants/pathConstants";
import Breadcrumb from "../../../sharedComponents/Breadcrumb";
import InformedConsentSurvey from "../../Surveys/InformedConsentSurvey";

const InformedConsentSurveyPage = (props) => {
  PageTitle("Cancer diagnosis survey");

  return (
    <div>
      <Breadcrumb
        text={"Back to test kit selection"}
        redirectTo={PATHS.ORDER_KIT_SELECTION}
      />
      <Layout headerType="logo" hasNoFooter={true}>
        <Grid container xs={12} alignItems="center" direction="column">
          <InformedConsentSurvey />
        </Grid>
      </Layout>
    </div>
  );
};

export default InformedConsentSurveyPage;
