import { makeStyles } from "@mui/styles";

const MemberShippingInformationStyles = makeStyles((theme) => ({
  containerSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  sectionTitle: {
    marginBottom: 15
  }
}));
export default MemberShippingInformationStyles;
