export const PATHS = {
  LANDING_PAGE: "/",
  MAINTENANCE: "/maintenance",
  WELCOME: "/welcome",
  REGISTRATION_SUCCESS: "/registrationSuccess",
  LOGIN: "/login",
  LOGIN_CALLBACK: "/loginCallback",
  API_SCRAPE: "/apiLoad",
  LOG_OUT: "/logout",
  LOGGED_OUT: "/loggedOut",
  ACCOUNT: "/account",
  ACCOUNT_MANAGEMENT: "/account/management",
  ACCOUNT_MANAGEMENT_PRIVACY_AND_POLICY: "/account/management/privacyAndPolicy",
  ACCOUNT_MANAGEMENT_TERMS_OF_SERVICE: "/account/management/termsOfService",
  ACCOUNT_MANAGEMENT_MEMBER_INFORMATION:
    "/account/management/memberInformation",
  ACCOUNT_MANAGEMENT_MFA: "/account/management/mfa",
  ACCOUNT_MANAGEMENT_PAYMENT_INFORMATION:
    "/account/management/paymentInformation",
  ACCOUNT_MANAGEMENT_CONNECTIONS: "/account/management/connections",
  ACCOUNT_MANAGEMENT_CONNECTIONS_CONNECTION_DETAILS:
    "/account/management/connections/connectionDetails",
  ACCOUNT_MANAGEMENT_NOTIFICATIONS_SETTINGS:
    "/account/management/notificationSettings",
  ACCOUNT_PRIVACY_AND_POLICY_RESET_PASSWORD:
    "/account/management/privacyAndPolicy/resetPassword",
  REQUEST_PASSWORD_EMAIL: "/platform",
  REQUEST_PASSWORD_EMAIL_FAILURE: "/forgot-password/failure",
  REQUEST_PASSWORD_EMAIL_SUCCESS: "/forgot-password/success",
  GETTING_STARTED_SURVEY: "/survey/gettingStarted",
  CANCER_SURVEY: "/survey/cancerDiagnosisSurvey",
  ORDER_KIT_INFORMED_CONSENT_SURVEY: "/survey/orderKitInformedConsent",
  ORDER_KIT: "/orderKit",
  ORDER_KIT_SELECTION: "/orderKit/info",
  ORDER_KIT_CONFIRMATION: "/orderKit/confirmation",
  TEST_RESULTS_PAGE: "/testResults",
  VIEW_TEST_PAGE: "/testResults/test",
  TERMS_OF_SERVICE: "/termsOfService",
  TERMS_OF_SERVICE_PRIVACY_POLICY: "/termsOfService/privacyPolicy",
  TERMS_OF_SERVICE_TERMS_AND_CONDITIONS: "/termsOfService/termsAndConditions",
  TERMS_OF_SERVICE_HIPAA: "/termsOfService/hipaaAuthorization",

  INVITE_SPOUSE: "/inviteSpouse",
  REPORT_SHARING_SURVEY: "/reports/survey",
  REPORT_SHARING_CONCLUSION: "/reports/transmitionSuccessful",
  ONBOARDING_INTRO: "/onboardingIntro",
  FINAL_ONBOARDING_INFO: "/finalOnboardingInfo",

  CANCER_AND_GENOMICS: "/cancerAndGenomics",
  RESOURCE_CENTER: "/resource-center",
  CANCER_RESOURCE_LIBRARY: "/content/cancer-resource-library*",
  CANCER_GUARDIAN_RESOURCE: "/cancerGuardianResource",
  PROGRAM_INFORMATION: "/programInformation",
  WHY_GENOMICS: "/whyGenomics",
  HELP_CENTER: "/content/help-center*"
};
