import { makeStyles } from "@mui/styles";
import { COLOR_CONSTANTS } from "../../../../constants/StyleConstants";

const RegistrationWelcomePageStyles = makeStyles((theme) => ({
  container: {
    height: "100%"
  },
  titleHeader: {
    fontWeight: 700,
    fontSize: 60,
    color: COLOR_CONSTANTS.GENOMIC_LIFE_PRIMARY,
    marginBottom: "20px",
    textAlign: "center",
    lineHeight: "60px",
    marginTop: "20px"
  },
  titleSubHeader: {
    textAlign: "center"
  },
  bodyPaper: {
    backgroundColor: "#FFFFFF",
    padding: "35px 15px 10px 45px",
    margin: "40px 0",
    boxShadow: "0px 0px 11px 0px #0000000D"
  },
  paperItem: {
    marginBottom: "40px"
  },
  itemContent: {
    paddingLeft: "26px",
    "& p": {
      lineHeight: "16.8px",
      marginBottom: "15px"
    }
  },
  itemHeader: {
    "& h6": {
      fontSize: "22px"
    },
    marginBottom: "10px"
  },
  lightSubheader: {
    color: COLOR_CONSTANTS.GENOMIC_LIFE_GREEN
  },
  dividerLineLeft: {
    float: "left",
    width: "25%",
    paddingTop: "10px",
    height: "1px",
    borderBottom: "1px solid #828282"
  },
  dividerLineRight: {
    float: "right",
    width: "25%",
    paddingTop: "8px",
    height: "1px",
    borderBottom: "1px solid #828282"
  },
  centered: {
    paddingTop: "2px",
    textAlign: "center",
    color: "#828282"
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
    display: "inline"
  },
  continueButton: {
    borderRadius: 0,
    marginTop: "-20px",
    width: "100%"
  }
}));

export default RegistrationWelcomePageStyles;
