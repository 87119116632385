import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@mui/lab";
import { Fade, Grid, TextField } from "@mui/material";
import Translate from "../../../../../translate/Translate";
import { extractSizingProps } from "../../FormUtils";
import { SharedFormFieldStyles } from "../../Form.styles";

const GLAutocompleteDropdown = (props) => {
  const {
    label,
    id,
    value,
    onChange,
    required,
    disabled,
    options,
    renderListLabelFunction,
    renderSelectedElementFunction
  } = props;

  const classes = SharedFormFieldStyles();

  const [hasFocus, setHasFocus] = useState(false);
  const [hasReceivedFocus, setHasReceivedFocus] = useState(false);

  const mapValueBasedOnId = (id) => {
    return options.find((option) => {
      if (id !== "" && option.id === id) {
        return option.label;
      }
      return null;
    });
  };
  const [selectedValue, setSelectedValue] = useState(
    value ? mapValueBasedOnId(value) : ""
  );

  const mapInputValueBasedOnOptionValue = (selectedOption) => {
    const foundlabel = options.find((option) => {
      if (selectedOption && option.id === selectedOption.id) {
        return option;
      }
      return null;
    });

    return foundlabel ? foundlabel.label : "";
  };

  const [inputValue, setInputValue] = useState(
    selectedValue ? mapInputValueBasedOnOptionValue(selectedValue) : ""
  );

  useEffect(() => {
    const selectedOption = options.find((option) => {
      if (value !== "" && option.id === value) {
        return option.label;
      }
      return null;
    });

    if (selectedOption) {
      setSelectedValue(selectedOption);
      const selectedLabel = renderListLabelFunction
        ? renderSelectedElementFunction(selectedOption)
        : mapInputValueBasedOnOptionValue(selectedOption);

      setInputValue(selectedLabel);
    } else {
      setSelectedValue(null);
      setInputValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    onChange({
      e: {
        target: { name: id, value: selectedValue ? selectedValue.value : null }
      },
      isInvalid: required && !selectedValue
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReceivedFocus, selectedValue, inputValue]);

  const calculateHelperText = (valueToValidate) => {
    if (required && !valueToValidate && !hasFocus) {
      return <Translate text="This field is required" />;
    }
    return "";
  };

  const onChangeHandler = (value) => {
    onChange({
      e: { target: { name: id, value: value ? value.value : null } },
      isInvalid: required && value
    });
  };

  const handleInputChange = (inputValue) => {
    const inputId = options.find((option) => {
      return option.label === inputValue;
    });
    if (inputId) {
      setSelectedValue(inputId);
      onChangeHandler(inputId);
    }
    setInputValue(inputValue);
  };

  const handleChange = (newValue) => {
    setSelectedValue(newValue);
    onChangeHandler(newValue);
  };

  const optionalLabel = (
    <span>
      {" ("}
      <Translate text={required ? "required" : "optional"} />
      {")"}
    </span>
  );

  const translatedLabel = (
    <span>
      <Translate text={label} /> {optionalLabel}
    </span>
  );

  const requiredFlag = (
    <Fade in={required && [null, undefined, ""].includes(value) && !hasFocus}>
      <div className={classes.requiredFlag}>Required</div>
    </Fade>
  );

  const filterOptionsByLabelAndId = (filteredOptions, inputValue) => {
    return filteredOptions.filter((option) => {
      const inputValueToLowerCase = inputValue.toLowerCase();
      const lowercaseLabel = option.label.toLowerCase();
      const lowercaseId = option.value.toLowerCase();
      return (
        lowercaseLabel.includes(inputValueToLowerCase) ||
        lowercaseId.includes(inputValueToLowerCase)
      );
    });
  };

  const filterOptions = (options, { inputValue }) =>
    filterOptionsByLabelAndId(options, inputValue);

  return (
    <Grid
      item
      {...extractSizingProps(props)}
      style={{ width: "100%" }}
      className={`${classes.inputContainer} `}
    >
      <Autocomplete
        options={options}
        autoHighlight
        clearOnBlur
        value={selectedValue}
        inputValue={inputValue}
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return renderListLabelFunction
            ? renderSelectedElementFunction(option)
            : mapInputValueBasedOnOptionValue(option);
        }}
        isOptionEqualToValue={(option) => {
          return option ? option.value : "";
        }}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          handleInputChange(newInputValue ? newInputValue : "");
        }}
        renderOption={
          renderListLabelFunction
            ? (option, props) => {
                return renderListLabelFunction(option, props);
              }
            : null
        }
        renderInput={(params) => (
          <div>
            {requiredFlag}
            <TextField
              id={id}
              name={id}
              variant="outlined"
              label={translatedLabel}
              required={required}
              disabled={disabled}
              className={classes.textInput}
              value={inputValue}
              helperText={
                !hasFocus && hasReceivedFocus
                  ? calculateHelperText(selectedValue)
                  : null
              }
              error={
                !!calculateHelperText(selectedValue) &&
                hasReceivedFocus &&
                !hasFocus
              }
              fullWidth={true}
              onFocus={() => {
                setHasReceivedFocus(true);
                setHasFocus(true);
              }}
              onBlur={() => {
                setHasFocus(false);
              }}
              {...params}
            />
          </div>
        )}
      />
    </Grid>
  );
};

export default GLAutocompleteDropdown;

GLAutocompleteDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  renderListLabelFunction: PropTypes.func,
  renderSelectedElementFunction: PropTypes.func,
  options: PropTypes.array.isRequired
};
