import { makeStyles } from "@mui/styles";

const InfoExpanderStyles = makeStyles((theme) => ({
  label: {
    fontSize: "14px",
    color: theme.palette.primary.link,
    padding: "10px 0 5px 0",
    margin: "10px 0 5px 0",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "& svg": {
      transition: "transform 0.5s"
    }
  }
}));

export default InfoExpanderStyles;
