import { makeStyles } from "@mui/styles";

const CardGridStyles = makeStyles((theme) => ({
  container: {
    padding: "0 55px 50px 55px",
    margin: "10px 0 40px"
  },
  header: {
    textAlign: "center",
    marginBottom: 60,

    "& h2": {
      fontSize: "34px",
      lineHeight: "44px"
    }
  }
}));

export default CardGridStyles;
