import { makeStyles } from "@mui/styles";

const ScreeningCardsInfoStyles = makeStyles((theme) => ({
  containerInfo: {
    backgroundColor: theme.palette.primary.font_blue,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    flexDirection: `column`,
    marginTop: 50,
    marginBottom: 30,
    width: 465,
    height: 615,
    [theme.breakpoints.down('lg')]: {
      flexDirection: `row`,
      width: 730,
      height: 290,
      marginTop: 10,
      marginBottom: 50
    },
    [theme.breakpoints.down(703)]: {
      flexDirection: `column`,
      width: 465,
      height: 615,
      paddingLeft: 20
    }
  },
  cardInfo: {
    display: `flex`,
    borderRadius: `5px`,
    background: theme.palette.info.main,
    width: 463,
    height: 220,
    marginTop: 30,
    padding: `0 25px 30px 25px`,
    [theme.breakpoints.down('lg')]: {
      marginRight: 20,
      width: 375,
      height: 273,
      paddingLeft: 10
    },
    [theme.breakpoints.down(703)]: {
      width: 375,
      height: 269
    }
  },
  mainTitle: {
    color: theme.palette.primary.white,
    [theme.breakpoints.down('lg')]: {
      display: `none`
    },
    [theme.breakpoints.down(703)]: {
      display: `flex`,
      marginTop: 100,
      marginRight: 20,
      marginLeft: 20,
      fontSize: 24,
      textAlign: `center`
    }
  },
  mainTitle2: {
    color: theme.palette.primary.white,
    display: `none`,
    marginTop: 30,
    [theme.breakpoints.down('lg')]: {
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`
    },
    [theme.breakpoints.down(703)]: {
      display: `none`
    }
  },
  title: {
    color: theme.palette.primary.white,
    fontWeight: 700,
    paddingBottom: 10
  },
  subTitle: {
    color: theme.palette.primary.white,
    fontWeight: 500
  },
  textContentFirst: {
    paddingTop: 36,
    [theme.breakpoints.down(703)]: {
      paddingLeft: 10
    }
  },
  textContentSec: {
    paddingTop: 36,
    [theme.breakpoints.down(703)]: {
      paddingLeft: 10
    }
  },
  imgContent: {
    marginTop: 15,
    [theme.breakpoints.down('lg')]: {
      marginRight: 15
    },
    [theme.breakpoints.down(703)]: {
      paddingLeft: 10,
      marginRight: 8
    }
  }
}));

export default ScreeningCardsInfoStyles;
