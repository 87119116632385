import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TestResultsPageStyles from "./TestResultsPage.styles";
import { PATHS } from "../../../../constants/pathConstants";
import Layout from "../../../layout/Layout";
import PageTitle from "../../../../utils/PageTitle";
import { selectExistingOrders } from "../../../../store/reducers/order/OrderInformationSlice";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FileListing from "./FileListing";
import {
  getDescriptionLanguageForTestByTestDescription,
  getFaqLanguageForTestByTestDescription
} from "./testLanguage";
import { useParams } from "react-router-dom";

const TestResultsPage = (props) => {
  const classes = TestResultsPageStyles();
  const { glOrderId } = useParams();
  const order = useSelector(selectExistingOrders).find(
    (order) => order.glOrderId === glOrderId
  );
  const theme = useTheme();
  const screenWidthIsMdOrSmaller = useMediaQuery(theme.breakpoints.down("lg"));

  PageTitle("Test results");

  const renderFAQ = () => {
    return getFaqLanguageForTestByTestDescription(order.description).map(
      (line) => {
        return (
          <Accordion className={classes.faqAccordian} key={line.question}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={"v2_body_dark_heavy"}>
                <div dangerouslySetInnerHTML={{ __html: line.question }} />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant={"v2_body_medium"}>
                <div dangerouslySetInnerHTML={{ __html: line.answer }} />
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      }
    );
  };

  const whatsNextBox = (
    <Grid item sm={4} className={classes.sideMenuContainer}>
      <Grid
        container
        direction={"column"}
        className={`${classes.sideMenu}`}
        spacing={1.5}
      >
        <Grid item>
          <Typography variant={"v2_h4"} className={classes.whatsNextHeader}>
            Do you have any more questions?
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={"v2_h5"}>
            <ArrowForwardIosIcon className={classes.whatsNextArrow} />
            Contact Navigation
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={"v2_body_small"}>
            For questions about this test, you may send us an email at{" "}
            <a href="mailto:navigation@genomiclife.com">
              navigation@genomiclife.com
            </a>
            . A member of our team will reach out within 2 business days.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Layout
        headerType="primary"
        headerText={"The genomics that matter to you"}
        goBackPath={PATHS.LANDING_PAGE}
        goBackTitle="Dashboard"
        hasNotHeader={true}
        footerType="v2"
      >
        <Grid container className={classes.container}>
          <Grid container item md={12} lg={8} justifyContent={"center"}>
            <Grid item xs={11} sm={11}>
              <Grid>
                <Typography variant="v2_h2">
                  {order.description.name}
                </Typography>

                <Breadcrumbs
                  className={classes.breadcrumbs}
                  aria-label="breadcrumb"
                  separator={<NavigateNextIcon fontSize="small" />}
                >
                  <a href={PATHS.LANDING_PAGE}>
                    <Typography variant="v2_body_medium">Dashboard</Typography>
                  </a>
                  <Typography variant="v2_body_medium">
                    {order.description.name}
                  </Typography>
                </Breadcrumbs>
                <Typography variant="v2_body_dark">
                  {getDescriptionLanguageForTestByTestDescription(
                    order.description
                  )}
                </Typography>
              </Grid>
              <Grid className={classes.section}>
                <FileListing order={order} />
              </Grid>
              <Grid className={classes.section}>
                <Grid container>
                  <Grid item>
                    <Grid container direction={"column"}>
                      <Typography variant="v2_h3">
                        Do you have questions?
                      </Typography>
                      <Typography variant="v2_body_dark">
                        Here are some common questions other members have asked
                        us.
                      </Typography>
                      {renderFAQ()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {screenWidthIsMdOrSmaller ? null : whatsNextBox}
        </Grid>
      </Layout>
    </div>
  );
};

export default TestResultsPage;
